import { faAngleRight, faUserCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import IndividualIdentity from '@/components/sessions/individual/IndividualIdentity'
import IndividualResidency from '@/components/sessions/individual/IndividualResidency'
import IndividualAml from '@/components/sessions/individual/IndividualAml'

export default function UboSingleIndividual({ data }) {
    const [currentStep, setCurrentStep] = useState('ubo_identity')

    function AnchorItem({ type, state, step_id }) {
        return (<div className="mx-4 mt-2">
            <div>
                <div onClick={() => setCurrentStep(step_id)} className="flex flex-wrap pb-1.5 justify-between items-center hover:bg-bluegray-50 border border-transparent hover:border-bluegray-100 hover:cursor-pointer transition ease-in duration-200 py-1 px-1.5 rounded-xl">
                    <div className="flex">
                        <div className="mr-3 mt-0.5 flex-shrink-0">
                            {type === 'LIVENESS' ? <img className="h-9" src={'/img/icons/ui/look-in-camera.svg'}></img> : null}
                            {type === 'IDENTITY' ? <img className="h-9" src={'/img/icons/ui/icon-passport.svg'}></img> : null}
                            {type === 'RESIDENCY' ? <img className="h-9" src={'/img/icons/ui/icon-utility-bill.svg'}></img> : null}
                            {type === 'PHONE' ? <img className="h-9" src={'/img/icons/ui/icon-call.svg'}></img> : null}
                            {type === 'AML' ? <img className="h-9" src={'/img/icons/ui/icon-aml-synaps.svg'}></img> : null}
                            {type === 'UBO' ? <img className="h-9" src={'/img/icons/ui/icon-corporate-register.svg'}></img> : null}
                        </div>
                        <div className="relative top-[2px]">
                            {type === 'IDENTITY' ? <p className="text-bluegray-400 text-xs">ID Document</p> : null}
                            {type === 'AML' ? <p className="text-bluegray-400 text-xs">Anti-Money Laundering (AML)</p> : null}
                            {type === 'RESIDENCY' ? <p className="text-bluegray-400 text-xs">Proof of residency</p> : null}
                            {type === 'PHONE' ? <p className="text-bluegray-400 text-xs">Phone number</p> : null}
                            {type === 'LIVENESS' ? <p className="text-bluegray-400 text-xs">Liveness</p> : null}
                            {state === 'PENDING' ? <p className="text-orange-500 text-sm rounded-md">Pending verification</p> : null}
                            {state === 'NOT_STARTED' ? <p className="text-gray-500 text-sm rounded-md">Not started</p> : null}
                            {state === 'REJECTED' ? <p className="text-red-500 text-sm rounded-md">Rejected</p> : null}
                            {state === 'FINAL_REJECTED' ? <p className="text-red-500 text-sm rounded-md">Final Rejected</p> : null}
                            {state === 'VALIDATED' ? <p className="text-green-500 text-sm rounded-md">Verified</p> : null}
                            {state === 'ACTION_REQUIRED' ? <p className="text-orange-500 text-sm rounded-md">Action required</p> : null}
                            {state === 'VIEW' ? <p className="text-gray-500 text-sm rounded-md">View</p> : null}
                        </div>
                    </div>
                    <div className="flex">
                        <FontAwesomeIcon className="mr-2 text-gray-500" icon={faAngleRight} />
                    </div>
                </div>
            </div>
        </div>)
    }

    function IndividualSidebar({ individual }) {
        return (
            <>
                <div className="rounded-xl m-3 pt-3">
                    <div className="h-full mx-auto text-center px-3 pb-3">
                        <FontAwesomeIcon className="text-7xl text-bluegray-300" icon={faUserCircle} />
                    </div>
                    <h2 className="text-xl text-primary-800 text-center mb-1 capitalize">{individual.firstname.toLowerCase()} {individual.lastname.toLowerCase()}</h2>
                    <h2 className="text-sm text-gray-500 text-center mb-4">Individual</h2>
                </div>
                <hr className="mx-6 mb-3 border-bluegray-100" />
                <div>
                    <div>
                        <p className="px-3 uppercase tracking-wide font-medium text-sm mx-3 text-primary-400">Documents</p>
                        <div className="mx-2 mt-1.5">
                            <AnchorItem key={'ubo_identity'} step_id={'ubo_identity'} type={'IDENTITY'} state={individual.identity.state} />
                            <AnchorItem key={'ubo_residency'} step_id={'ubo_residency'} type={'RESIDENCY'} state={individual.residency.state} />
                            <AnchorItem key={'ubo_aml'} step_id={'ubo_aml'} type={'AML'} state={individual.aml.state} />
                        </div>
                    </div>
                </div>
            </>
        )
    }

    function IndividualView({ individual }) {
        return (
            <div className="p-6">
                {currentStep === 'ubo_identity' ? <IndividualIdentity member_id={individual.member_id} step_id={'ubo_identity'} step={individual.identity} /> : null}
                {currentStep === 'ubo_residency' ? <IndividualResidency member_id={individual.member_id} step_id={'ubo_residency'} step={individual.residency} /> : null}
                {currentStep === 'ubo_aml' ? <IndividualAml member_id={individual.member_id} step_id={'ubo_aml'} data={individual.aml} /> : null}
            </div>
        )
    }

    return (
        <div className="flex">
            <div className="core-view-profile">
                <IndividualSidebar individual={data} />
            </div>
            <div className="core-view-main">
                <IndividualView individual={data} />
            </div>
        </div>
    )
}