import { ManagerAuth } from '@/services/axios'

export default {
	GetTasks(app_id) {
		return ManagerAuth.get(`${process.env.REACT_APP_MANAGER_HOST}/v3/tasks/aml/${app_id}`)
	},
	SetAmlDecision(app_id, body) {
		return ManagerAuth.put(`${process.env.REACT_APP_MANAGER_HOST}/v3/tasks/aml/${app_id}`, body)
	},
}
