import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoginPage from "@/pages/login";
import NotFoundPage from "@/pages/not-found";
import AppTeam from "@/pages/app/team";
import AppDeveloper from "@/pages/app/developer";
import AppFlow from "@/pages/app/flow";
import AppIndividualSession from "@/pages/app/individual_session";
import MyApps from "@/pages/my_apps";
import { ToastContainer } from "react-toastify";
import { AuthContext, AuthContextProvider } from "@/context/auth";
import { AppContextProvider } from "@/context/app";
import { SearchContextProvider } from "@/context/search";
import { IndividualSessionContextProvider } from "@/context/individual_session";
import { CorporateSessionContextProvider } from "@/context/corporate_session";
import { useEffect, useState, useContext } from "react";
import AppCorporateSession from "@/pages/app/corporate_session";
import AuthService from "@/services/auth";
import AppSandbox from "@/pages/app/sandbox";
import AppSessions from "@/pages/app/sessions";
import AppTasks from "@/pages/app/tasks";
import AppTier from "@/pages/app/tier";
import AppLogs from "@/pages/app/logs";
import Settings from "@/pages/settings";
import AppWebhooks from "@/pages/app/webhooks";
import AppDashboard from "@/pages/app/dashboard";
import v2Redirection from "@/pages/app/v2_redirection";
import Cookie from "js-cookie";
import { useHistory } from "react-router-dom";
import { b64Decode } from "@/lib/b64";
import Tracker from "@openreplay/tracker";
import posthog from "posthog-js";

function AuthCore(props) {
  const [, authDispatch] = useContext(AuthContext);
  const [ready, setReady] = useState(false);
  const router = useHistory();

  useEffect(() => {
    const s_auth = Cookie.get("s_auth");
    if (s_auth == "" || s_auth == null) {
      if (
        window.location.pathname !== "/" &&
        window.location.pathname !== "/login"
      ) {
        const pathName = encodeURIComponent(window.location.pathname);
        router.push(`/login?redirect_uri=${pathName}`);
      } else {
        router.push("/login");
      }
      setReady(true);
      return;
    }
    setReady(true);

    const sAuth = JSON.parse(b64Decode(s_auth));

    authDispatch({
      type: "SET_AUTH",
      payload: sAuth,
    });
    setReady(true);
  }, []);

  return ready ? props.children : null;
}

function App() {
  useEffect(() => {
    const tracker = new Tracker({
      projectKey: "3NP8X83iVFwo2CwE9Yhp",
      defaultInputMode: 1,
    });
    tracker.start();

    posthog.init(process.env.REACT_APP_POSTHOG_KEY || "", {
      api_host: "https://app.posthog.com",
    });
    posthog.capture("start_manager_session", {});
  }, []);

  return (
    <Router>
      <AuthContextProvider>
        <AppContextProvider>
          <IndividualSessionContextProvider>
            <CorporateSessionContextProvider>
              <SearchContextProvider>
                <AuthCore>
                  <Switch>
                    <Route exact path="/" component={MyApps} />

                    <Route exact path="/login" component={LoginPage} />
                    <Route exact path="/settings" component={Settings} />

                    {/* Apps */}
                    <Route
                      exact
                      path="/app/:app_id/"
                      component={AppDashboard}
                    />
                    <Route
                      exact
                      path="/app/:app_id/sessions/:session_id"
                      component={v2Redirection}
                    />
                    <Route exact path="/app/:app_id/team" component={AppTeam} />
                    <Route
                      exact
                      path="/app/:app_id/developer"
                      component={AppDeveloper}
                    />
                    <Route
                      exact
                      path="/app/:app_id/sandbox"
                      component={AppSandbox}
                    />
                    <Route
                      exact
                      path="/app/:app_id/tasks"
                      component={AppTasks}
                    />
                    <Route
                      exact
                      path="/app/:app_id/webhooks"
                      component={AppWebhooks}
                    />
                    <Route exact path="/app/:app_id/flow" component={AppFlow} />
                    <Route
                      exact
                      path="/app/:app_id/sessions"
                      component={AppSessions}
                    />
                    <Route exact path="/app/:app_id/tier" component={AppTier} />
                    <Route exact path="/app/:app_id/logs" component={AppLogs} />
                    <Route
                      exact
                      path="/app/:app_id/individual/:session_id"
                      component={AppIndividualSession}
                    />
                    <Route
                      exact
                      path="/app/:app_id/corporate/:session_id"
                      component={AppCorporateSession}
                    />

                    <Route path="*" component={NotFoundPage} />
                  </Switch>
                  <ToastContainer
                    position="top-right"
                    autoClose={4000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />
                </AuthCore>
              </SearchContextProvider>
            </CorporateSessionContextProvider>
          </IndividualSessionContextProvider>
        </AppContextProvider>
      </AuthContextProvider>
    </Router>
  );
}

export default App;
