import AuthGuard from '@/guard/auth'
import AppNavbar from '@/components/menu/AppNavbar'
import AppSandboxPage from '@/components/pages/app/AppSandboxPage'
import AppGuard from '@/guard/app'

function MyApps() {
	return (
		<AuthGuard>
			<AppGuard>
				<div className='relative min-h-screen flex flex-col'>
					<AppNavbar></AppNavbar>
					<AppSandboxPage></AppSandboxPage>
				</div>
			</AppGuard>
		</AuthGuard>
	)
}

export default MyApps
