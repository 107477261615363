import { ManagerAuth } from '@/services/axios'

export default {
	RetrieveSessions(app, page, state) {
		return ManagerAuth.get(
			`${process.env.REACT_APP_MANAGER_HOST}/v3/apps/session/${app}?page=${page}&state=${state}`
		)
	},
	InitNewSession(app_id, body) {
		return ManagerAuth.post(`${process.env.REACT_APP_MANAGER_HOST}/v3/apps/init/session/${app_id}`, body)
	},
	SendExportCode(app_id) {
		return ManagerAuth.post(`${process.env.REACT_APP_MANAGER_HOST}/v3/apps/export/session/code/${app_id}`, {})
	},
	GetAppExport(app_id, body) {
		return ManagerAuth.post(`${process.env.REACT_APP_MANAGER_HOST}/v3/apps/export/session/verify/${app_id}`, body)
	},
}
