import { faBellOn, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { faLayerGroup } from '@fortawesome/pro-duotone-svg-icons'
import { useEffect, useState } from 'react'
import Button from '@/components/ui/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from '@/components/ui/Alert'
import AppsService from '@/services/apps'
import { useParams } from 'react-router-dom'

export default function CreateNewFlow(props) {
    const [error, setError] = useState(null)
    const [, setName] = useState(null)
    const [loading, setLoading] = useState(false)
    const [selectedStep, setSelectedStep] = useState('')

    const { app_id } = useParams()

    useEffect(() => {
        setName(props.name)
    }, [])

    function Submit() {
        setLoading(true)

        const body = {
            step: selectedStep,
        }

        AppsService.CreateNewFlow(app_id, body).then((data) => {
            props.onFinish(data.step_id, selectedStep)
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setError(err.message)
            setLoading(false)
        })
    }

    return (

        <div className="modal-view">
            <div className="z-max w-full h-full flex items-center justify-center">
                <div className="flex flex-col w-full h-full rounded">
                    <header className="p-5 pb-3">
                        <div className="flex justify-between">
                            <div>
                                <h2 className="text-xl text-primary-500  font-medium"><FontAwesomeIcon className="mr-1" icon={faLayerGroup} /> Add new step</h2>
                            </div>
                            <div>
                                <h2 className="text-xl relative top-0.5"><span><FontAwesomeIcon onClick={() => props.onClose()} className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-100 ease-in" icon={faTimes}></FontAwesomeIcon></span></h2>
                            </div>
                        </div>
                        <hr className="my-3" />
                    </header>

                    <main
                        className="flex-1 overflow-y-auto p-5 py-0 flow-body"
                        id="individual-main">

                        {error ? (
                            <Alert type="error" title={error}></Alert>
                        ) : null}
                        <div className="flex flex-wrap">
                            <div className="w-full">
                                <div onClick={() => setSelectedStep('LIVENESS')} className={`flex flex-1 border border-gray-200 ${selectedStep === 'LIVENESS' ? 'border-primary-700' : 'hover:border-gray-400 cursor-pointer'} p-2 rounded-xl ease-in transition duration-100`}>
                                    <div>
                                        <img src="/img/icons/ui/look-in-camera.svg"></img>
                                    </div>
                                    <div className="ml-3">
                                        <p className="font-medium text-primary-700">Liveness</p>
                                        <p className="text-gray-500 text-xs">Genuine Assurance Presence</p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full mt-4">
                                <div onClick={() => setSelectedStep('IDENTITY')} className={`flex flex-1 border border-gray-200 ${selectedStep === 'IDENTITY' ? 'border-primary-700' : 'hover:border-gray-400 cursor-pointer'} p-2 rounded-xl ease-in transition duration-100`}>
                                    <div>
                                        <img src="/img/icons/ui/icon-passport.svg"></img>
                                    </div>
                                    <div className="ml-3">
                                        <p className="font-medium text-primary-700">ID Verification</p>
                                        <p className="text-gray-500 text-xs">Verify ID documents from more than 190+ countries</p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full mt-4">
                                <div onClick={() => setSelectedStep('RESIDENCY')} className={`flex flex-1 border border-gray-200  ${selectedStep === 'RESIDENCY' ? 'border-primary-700' : 'hover:border-gray-400 cursor-pointer'} p-2 rounded-xl ease-in transition duration-100`}>
                                    <div>
                                        <img src="/img/icons/ui/icon-utility-bill.svg"></img>
                                    </div>
                                    <div className="ml-3">
                                        <p className="font-medium text-primary-700">Proof of residency</p>
                                        <p className="text-gray-500 text-xs">Address verification for individuals</p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full mt-4">
                                <div onClick={() => setSelectedStep('PHONE')} className={`flex flex-1 border border-gray-200  ${selectedStep === 'PHONE' ? 'border-primary-700' : 'hover:border-gray-400 cursor-pointer'} p-2 rounded-xl ease-in transition duration-100`}>
                                    <div>
                                        <img src="/img/icons/ui/icon-call.svg"></img>
                                    </div>
                                    <div className="ml-3">
                                        <p className="font-medium text-primary-700">Phone</p>
                                        <p className="text-gray-500 text-xs">Mobile phone verification at scale</p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full mt-4">
                                <div onClick={() => setSelectedStep('AML')} className={`flex flex-1 border border-gray-200  ${selectedStep === 'AML' ? 'border-primary-700' : 'hover:border-gray-400 cursor-pointer'} p-2 rounded-xl ease-in transition duration-100`}>
                                    <div>
                                        <img src="/img/icons/ui/icon-aml-synaps.svg"></img>
                                    </div>
                                    <div className="ml-3">
                                        <p className="font-medium text-primary-700">AML/CFT Screening</p>
                                        <p className="text-gray-500 text-xs">Global watchlist and adverse media screening</p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full mt-4">
                                <div onClick={() => setSelectedStep('NOTIFICATION')} className={`flex flex-1 border border-gray-200  ${selectedStep === 'NOTIFICATION' ? 'border-primary-700' : 'hover:border-gray-400 cursor-pointer'} p-2 rounded-xl ease-in transition duration-100`}>
                                    <div>
                                        <img src="/img/icons/ui/icon-notification-bell.svg"></img>
                                    </div>
                                    <div className="ml-3">
                                        <p className="font-medium text-primary-700">Notifications</p>
                                        <p className="text-gray-500 text-xs">Notify users of verification status</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <footer className="p-5">
                        <Button full loading={loading} disabled={selectedStep === ''} theme="success" label="Add step" onClick={() => Submit()}></Button>
                    </footer>
                </div>
            </div>
        </div>
    )
}
