import {
	faSearch,
	faClock,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Moment from 'react-moment'
import Tooltip from '../ui/Tooltip'
import MiniButton from '../ui/MiniButton'
import { useHistory, useParams } from 'react-router-dom'
import { AppContext } from '@/context/app'
import { useContext } from 'react'

export default function AmlTaskItem(props) {
	const { app_id } = useParams()
	const router = useHistory()
	const [app, appDispatch] = useContext(AppContext)

	return (
		<div className="w-full my-3 p-2 rounded-xl border bg-white transition-25 transition duration-200 ease-in">
			<div className="w-full">
				<div className="ml-1">
					<div className="sm:flex justify-between">
						<div className="flex">
							<div className="p-2">
								<img src="/img/icons/ui/icon-decision-aml.svg" />
							</div>
							<div className="ml-3 relative">
								<p className="text-sm font-bold mt-1.5">
									{props.item.session_id}
								</p>
								<div className="flex">
									<div>
										<h1 className="text-sm mt-1">
											<Tooltip auto={true} content={'Creation Date'}>
												<span>
													<FontAwesomeIcon className="text-bluegray-500 mr-1" icon={faClock} />
													<Moment unix fromNow>
														{props.item.creation_date / 1000}
													</Moment>
												</span>
											</Tooltip>
										</h1>
									</div>
								</div>
							</div>
						</div>
						<div className="flex justify-between">
							<div className="mt-2 mr-2 text-right">
								<div className='flex'>
									<div>
										<MiniButton
											onClick={() => router.push(`/app/${app_id}/${app.type === 'WORKFLOW' ? 'individual' : 'corporate'}/${props.item.session_id}`)}
											icon={faSearch}
											theme='pin'
											label='Review'></MiniButton>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
