import { Menu, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faPowerOff } from "@fortawesome/pro-duotone-svg-icons";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "@/context/auth";
import Loader from "../ui/Loader";
import Cookies from "js-cookie";

export default function MiniProfile(props) {
  const [auth] = useContext(AuthContext);
  const [logoutLoading, setLogoutLoading] = useState(false);
  const router = useHistory();

  async function logout() {
    Cookies.remove("s_auth");
    window.location.href = "/login";
    return () => {};
  }

  function goToSettings() {
    window.location.href = "/settings";
  }

  return (
    <div className="flex z-10">
      <div className="relative inline-block text-left">
        <Menu>
          {({ open }) => (
            <>
              <Menu.Button className="inline-flex justify-center w-full text-sm font-medium leading-5 text-gray-200 hover:text-white transition duration-150 ease-in-out border-gray-300 rounded-xl focus:outline-none focus:border-blue-300 focus:shadow-outline-blue">
                <div>
                  <div>
                    <div
                      className={`relative ${
                        props.app_name ? "top-[4px]" : "top-[5px]"
                      } max-w-xs rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500`}
                      id="user-menu"
                      aria-haspopup="true"
                    >
                      <img
                        className="h-7 w-7 rounded-full"
                        src={"/img/icons/ui/icon-profile.svg"}
                        alt=""
                      />
                      <span
                        className={`hidden ml-3 font-medium md:block text-left ${
                          props.app_name ? "text-xs" : "text-sm"
                        }`}
                      >
                        {auth.user.firstname} {auth.user.lastname}
                        <br />
                        <span className="text-xs text-gray-500">
                          {props.app_name}
                        </span>
                      </span>
                      <svg
                        className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 md:block"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </Menu.Button>

              <Transition
                show={open}
                enter="transition ease-in duration-100"
                enterFrom="transform opacity-0"
                enterTo="transform opacity-100"
                leave="transition ease-in duration-100"
                leaveFrom="transform opacity-100"
                leaveTo="transform opacity-0"
              >
                <Menu.Items
                  static
                  className="absolute right-0 z-10  w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-xl shadow outline-none"
                >
                  <div className="px-4 py-3">
                    <p className="text-sm leading-5">Signed in as</p>
                    <p className="text-sm font-medium leading-5 text-gray-900 truncate">
                      {auth.user.email}
                    </p>
                  </div>

                  <div>
                    {/* <Menu.Item>
											<div className="pt-2 px-2 outline-none">
												<a
													className={`rounded-xl outline-none hover:bg-gray-100  transition duration-200 ease-in cursor-pointer flex w-full p-3 text-sm leading-5`}>
													<FontAwesomeIcon
														className="mr-3 h-5 w-5 text-gray-600"
														icon={faCreditCard}></FontAwesomeIcon>
													Billing
												</a>
											</div>
										</Menu.Item> */}
                    <Menu.Item>
                      <div className="px-2 pt-2 py-0 outline-none">
                        <a
                          onClick={() => goToSettings()}
                          className={
                            "rounded-xl outline-none hover:bg-gray-100 transition duration-200 ease-in cursor-pointer flex w-full p-3 text-sm leading-5"
                          }
                        >
                          <FontAwesomeIcon
                            className="mr-3 h-5 w-5 text-gray-600"
                            icon={faCog}
                          ></FontAwesomeIcon>
                          Settings
                        </a>
                      </div>
                    </Menu.Item>
                    <Menu.Item>
                      <div className="pb-2 px-2 outline-none">
                        <a
                          onClick={() => logout()}
                          className={
                            "rounded-xl outline-none hover:bg-red-100 transition duration-200 ease-in  text-red-600 cursor-pointer flex w-full p-3 text-sm leading-5"
                          }
                        >
                          {logoutLoading ? (
                            <Loader
                              className="mr-3"
                              size="medium"
                              color="red-600"
                            ></Loader>
                          ) : (
                            <FontAwesomeIcon
                              className="mr-3 h-5 w-5 text-red-600"
                              icon={faPowerOff}
                            ></FontAwesomeIcon>
                          )}
                          Sign out
                        </a>
                      </div>
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    </div>
  );
}
