import { faGlobe, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from '@/components/ui/Tooltip'

export default function TierItem(props) {
    return (
        <div className="step-view w-full sm:w-1/2 lg:w-1/3 my-4">
            <div className="z-max w-full h-full flex items-center justify-center px-3">
                <div className="rounded-xl bg-white border border-gray-200 step-view hover:border-gray-300 transition-25 transition duration-200 ease-in flex flex-col w-full h-full">
                    <header className="p-5 pb-3">
                        <div className="flex justify-between w-full items-center">
                            <div className="flex items-center w-full items-center">
                                <div>
                                    <img className="h-9 relative mr-2" src="/img/icons/ui/icon-tiers.svg" />
                                </div>
                                <div className="flex w-full justify-between items-center">
                                    <div>
                                        <h2 className="text-lg text-primary-500 font-medium"> {props.items.tier_name}
                                        </h2>
                                    </div>
                                    <div>
                                        <Tooltip auto={true} content="Tier ID">
                                            <span className="relative text-xs bg-bluegray-200 mr-1 px-2 py-0.5 rounded-md text-gray-500">{props.tier}</span>
                                        </Tooltip>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="my-3" />
                    </header>

                    <div
                        className={`flex-1 overflow-y-auto p-5 py-0 ${props.manageable ? 'flow-body' : ''}`}>
                        <div className="flex flex-wrap">
                            <div className="w-full">
                                <div>
                                    <div>
                                        <div>
                                            {props.items.steps.length === 0 ? <p className="text-sm text-gray-400"><FontAwesomeIcon className="mr-1" icon={faGlobe} /> No Steps</p> : null}
                                            <div className="relative z-0">
                                                {props.items.steps.map((item) => {
                                                    return <>
                                                        <div>
                                                            <div className="flex flex-wrap">
                                                                {item.step === 'LIVENESS' ? <>
                                                                    <div className="w-full mb-4">
                                                                        <div className={'flex flex-1 border border-gray-200 p-2 rounded-xl ease-in transition duration-100'}>
                                                                            <div>
                                                                                <img src="/img/icons/ui/look-in-camera.svg"></img>
                                                                            </div>
                                                                            <div className="ml-3 w-full">
                                                                                <div className="flex justify-between items-center">
                                                                                    <div>
                                                                                        <p className="font-medium text-primary-700">Liveness</p>
                                                                                        <p className="text-gray-500 text-xs">Genuine Assurance Presence</p>
                                                                                    </div>
                                                                                    <div>
                                                                                        <Tooltip auto={true} content="Step ID">
                                                                                            <span className="relative top-[-1px] text-xs bg-bluegray-200 mr-1 px-2 py-0.5 rounded-md text-gray-500">{item.step_id}</span>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </> : null}
                                                                {item.step === 'IDENTITY' ? <>
                                                                    <div className="w-full mb-4">
                                                                        <div className={'flex flex-1 border border-gray-200 p-2 rounded-xl ease-in transition duration-100'}>
                                                                            <div>
                                                                                <img src="/img/icons/ui/icon-passport.svg"></img>
                                                                            </div>
                                                                            <div className="ml-3 w-full">
                                                                                <div className="flex justify-between items-center">
                                                                                    <div>
                                                                                        <p className="font-medium text-primary-700">ID Verification</p>
                                                                                        <p className="text-gray-500 text-xs">Verify ID documents</p>                                                                    </div>
                                                                                    <div>
                                                                                        <Tooltip auto={true} content="Step ID">
                                                                                            <span className="relative top-[-1px] text-xs bg-bluegray-200 mr-1 px-2 py-0.5 rounded-md text-gray-500">{item.step_id}</span>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </> : null}
                                                                {item.step === 'RESIDENCY' ? <>
                                                                    <div className="w-full mb-4">
                                                                        <div className={'flex flex-1 border border-gray-200  p-2 rounded-xl ease-in transition duration-100'}>
                                                                            <div>
                                                                                <img src="/img/icons/ui/icon-utility-bill.svg"></img>
                                                                            </div>
                                                                            <div className="ml-3 w-full">
                                                                                <div className="flex justify-between items-center">
                                                                                    <div>
                                                                                        <p className="font-medium text-primary-700">Proof of residency</p>
                                                                                        <p className="text-gray-500 text-xs">Address verification for individuals</p>
                                                                                    </div>
                                                                                    <div>
                                                                                        <Tooltip auto={true} content="Step ID">
                                                                                            <span className="relative top-[-1px] text-xs bg-bluegray-200 mr-1 px-2 py-0.5 rounded-md text-gray-500">{item.step_id}</span>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </> : null}
                                                                {item.step === 'PHONE' ? <>
                                                                    <div className="w-full mb-4">
                                                                        <div className={'flex flex-1 border border-gray-200  p-2 rounded-xl ease-in transition duration-100'}>
                                                                            <div>
                                                                                <img src="/img/icons/ui/icon-call.svg"></img>
                                                                            </div>
                                                                            <div className="ml-3 w-full">
                                                                                <div className="flex justify-between items-center">
                                                                                    <div>
                                                                                        <p className="font-medium text-primary-700">Phone</p>
                                                                                        <p className="text-gray-500 text-xs">Mobile phone verification at scale</p>
                                                                                    </div>
                                                                                    <div>
                                                                                        <Tooltip auto={true} content="Step ID">
                                                                                            <span className="relative top-[-1px] text-xs bg-bluegray-200 mr-1 px-2 py-0.5 rounded-md text-gray-500">{item.step_id}</span>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </> : null}
                                                            </div>
                                                        </div>
                                                    </>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <footer className="p-5 text-center">
                        <p onClick={() => props.Delete(props.tier)} className="text-gray-400 hover:text-red-500 transition duration-200 ease-in cursor-pointer"><FontAwesomeIcon className="mr-1" icon={faTrash} /> Remove tier</p>
                    </footer>
                </div>
            </div>
        </div >
    )
}
