export default function Empty(props) {
	return (
		<>
			<div className="p-6 mt-4 mx-auto">
				<img className="mx-auto h-36" src="/img/illustrations/empty.svg"></img>
				<p className="p-6 text-sm text-gray-700 mt-3 mx-auto">{props.value}</p>
			</div>
		</>
	)
}
