import { faEllipsisHAlt, faGlobe, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AppsService from '@/services/apps'
import { useParams } from 'react-router-dom'
import Tooltip from '@/components/ui/Tooltip'
import countries from '@/resources/countries_label.json'
import { faTrash } from '@fortawesome/pro-duotone-svg-icons'


export default function ResidencyFlow(props) {
    const [, setError] = useState(null)
    const [data, setData] = useState(null)
    const [ready, setReady] = useState(false)
    const { app_id } = useParams()
    const [size, setSize] = useState(0)

    useEffect(() => {
        GetStepDetails()
        return () => { }
    }, [])

    function GetStepDetails() {
        AppsService.GetStepDetails(app_id, props.step_id).then((data) => {
            setData(data)
            let len = 0
            Object.keys(data.setting.restrictions).map(function () {
                len++
            })
            setSize(len)
            setReady(true)
        }).catch((err) => {
            setError(err.message)
        })
    }

    function openManage() {
        if (!props.manageable) {
            props.onManage()
        }
    }


    function Delete() {
        AppsService.DeleteStep(app_id, props.step_id).then(() => {
            props.onClose()
        }).catch((err) => {
            setError(err.message)
        })
    }


    return (
        <div className={`${props.manageable ? 'modal-view' : 'w-full sm:w-1/2 lg:w-1/3 my-4'}`}>
            <div className={`z-max w-full h-full flex items-center justify-center ${!props.manageable ? 'px-3' : ''}`}>
                <div onClick={() => openManage()} className={`${!props.manageable ? 'rounded-xl cursor-pointer step-view h-full hover:shadow-lg bg-white border border-gray-200 hover:border-gray-300 transition-25 transition duration-200 ease-in' : ''} flex flex-col w-full h-full`}>
                    <header className="p-5 pb-3">
                        <div className="flex justify-between items-center">
                            <div className="flex items-center">
                                <div>
                                    <img className="h-9 relative mr-2" src="/img/icons/ui/icon-utility-bill.svg" />
                                </div>
                                <div>
                                    <h2 className="text-lg text-primary-500  font-medium"> Proof of residency
                                    </h2>
                                </div>
                            </div>
                            <div>
                                <div className="flex items-center ">
                                    <div>
                                        <Tooltip auto={true} content="Step ID">
                                            <span className="relative top-[-1px] text-xs bg-gray-200  px-2 py-0.5 rounded-md text-gray-500">{props.step_id}</span>
                                        </Tooltip>
                                    </div>
                                    <div>
                                        {props.manageable ?
                                            <h2 className="text-xl relative ml-3 top-0.5"><span><FontAwesomeIcon onClick={() => props.onClose()} className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-200 ease-in text-regular relative top-[-1px]" icon={faTimes}></FontAwesomeIcon></span></h2>
                                            : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="my-3" />
                    </header>

                    <main
                        className="flex-1 overflow-y-hidden p-5 py-0"
                        id="individual-main">
                        {ready ?
                            <>
                                <div className="flex flex-wrap">
                                    <div className="w-full">
                                        <div>
                                            <div className="flex justify-between">
                                                <div>
                                                    <p className="text-sm text-primary-700">Country Restrictions</p>
                                                </div>
                                                {props.manageable ? <div>
                                                    <p onClick={() => props.managedRestrictions()} className="text-xs py-0.5 cursor-pointer hover:bg-gray-300 ease-in duration-200 transition rounded-md bg-gray-200 px-2 text-primary-700"><FontAwesomeIcon className="mr-0.5" icon={faEllipsisHAlt} /> Manage</p>
                                                </div>
                                                    : null}
                                            </div>
                                            <div>
                                                <div className="flex mt-2">
                                                    {size === 0 ? <p className="text-sm text-gray-400"><FontAwesomeIcon className="mr-1" icon={faGlobe} /> No restrictions</p> : null}
                                                    <div className="relative z-0">
                                                        {Object.entries(data.setting.restrictions).slice(0, 4).map(([country]) => {
                                                            return <>
                                                                <div className="flex mt-3">
                                                                    <Tooltip auto={true} content={countries[country]}>
                                                                        <img key={country}
                                                                            className="relative z-30 inline-block h-8 w-6 rounded-full ring-2 ring-white"
                                                                            src={`/img/icons/flags-rounded/${country}.svg`}
                                                                        />
                                                                    </Tooltip>
                                                                    <div>
                                                                        <div className="flex ml-3">
                                                                            <div>
                                                                                <Tooltip auto={true} content="Gas Bill">
                                                                                    <img className={`h-8 mr-3 ${data.setting.restrictions[country].includes('GAS_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-gas-bill.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div>
                                                                                <Tooltip auto={true} content="Water Bill">
                                                                                    <img className={`h-8 mr-3 ${data.setting.restrictions[country].includes('WATER_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-water-bill.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div>
                                                                                <Tooltip auto={true} content="Internet Bill">
                                                                                    <img className={`h-8 mr-3 ${data.setting.restrictions[country].includes('INTERNET_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-internet-bill.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div>
                                                                                <Tooltip auto={true} content="Electricity Bill">
                                                                                    <img className={`h-8 mr-3 ${data.setting.restrictions[country].includes('ELECTRICITY_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-electricity-bill.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div>
                                                                                <Tooltip auto={true} content="Landline Bill">
                                                                                    <img className={`h-8 mr-3 ${data.setting.restrictions[country].includes('LANDLINE_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-landline-bill.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div>
                                                                                <Tooltip auto={true} content="Bank Statement">
                                                                                    <img className={`h-8 mr-3 ${data.setting.restrictions[country].includes('BANK_STATEMENT') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-bank-statement.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="text-center mt-4">
                                                    {size > 4 ? <span className="relative text-center text-sm top-1 mt-2 text-gray-500">+ {size - 4} others</span> : null}

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </> : null}
                    </main>

                    <main
                        className={`flex-1 overflow-y-auto p-5 py-0 ${props.manageable ? 'flow-body' : ''}`}
                        id="individual-main">
                        {ready ?
                            <>
                                <div className="flex flex-wrap">
                                    <div className="w-full">
                                        <div>
                                            <div className="flex justify-between">
                                                <div>
                                                    <p className="text-sm text-primary-700">Document Restrictions</p>
                                                </div>
                                                {props.manageable ? <div>
                                                    <p onClick={() => props.managedDocuments()} className="text-xs py-0.5 cursor-pointer hover:bg-gray-300 ease-in duration-200 transition rounded-md bg-gray-200 px-2 text-primary-700"><FontAwesomeIcon className="mr-0.5" icon={faEllipsisHAlt} /> Manage</p>
                                                </div>
                                                    : null}
                                            </div>
                                            <div>
                                                <div className="flex mt-2">
                                                    <div className="relative z-0">
                                                        <div className="flex mt-3">
                                                            <div>
                                                                <div className="flex">
                                                                    <div>
                                                                        <Tooltip auto={true} content="Gas Bill">
                                                                            <img className={`h-8 mr-3 ${data.setting.documents_restrictions.includes('GAS_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-gas-bill.svg" />
                                                                        </Tooltip>
                                                                    </div>
                                                                    <div>
                                                                        <Tooltip auto={true} content="Water Bill">
                                                                            <img className={`h-8 mr-3 ${data.setting.documents_restrictions.includes('WATER_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-water-bill.svg" />
                                                                        </Tooltip>
                                                                    </div>
                                                                    <div>
                                                                        <Tooltip auto={true} content="Internet Bill">
                                                                            <img className={`h-8 mr-3 ${data.setting.documents_restrictions.includes('INTERNET_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-internet-bill.svg" />
                                                                        </Tooltip>
                                                                    </div>
                                                                    <div>
                                                                        <Tooltip auto={true} content="Electricity Bill">
                                                                            <img className={`h-8 mr-3 ${data.setting.documents_restrictions.includes('ELECTRICITY_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-electricity-bill.svg" />
                                                                        </Tooltip>
                                                                    </div>
                                                                    <div>
                                                                        <Tooltip auto={true} content="Landline Bill">
                                                                            <img className={`h-8 mr-3 ${data.setting.documents_restrictions.includes('LANDLINE_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-landline-bill.svg" />
                                                                        </Tooltip>
                                                                    </div>
                                                                    <div>
                                                                        <Tooltip auto={true} content="Bank Statement">
                                                                            <img className={`h-8 mr-3 ${data.setting.documents_restrictions.includes('BANK_STATEMENT') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-bank-statement.svg" />
                                                                        </Tooltip>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center mt-4">
                                        </div>
                                    </div>

                                </div>
                            </> : null}
                    </main>
                    {props.manageable ? <footer className="p-5 text-center">
                        <p onClick={() => Delete()} className="text-gray-400 hover:text-red-500 transition duration-200 ease-in cursor-pointer"><FontAwesomeIcon className="mr-1" icon={faTrash} /> Remove step</p>
                    </footer> : null}
                </div>
            </div>
        </div>
    )
}
