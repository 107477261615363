import { useState, useContext, useEffect } from "react";
import { faCode } from "@fortawesome/pro-duotone-svg-icons";
import Footer from "@/components/menu/Footer";
import SubNav from "@/components/menu/SubNav";
import StatusWebhooks from "@/components/webhooks/StatusWebhooks";
import DeliveriesWebhooks from "@/components/webhooks/DeliveriesWebhooks";
import { AppContext } from "@/context/app";
import WebhookService from "@/services/webhooks";
import Sleep from "@/lib/sleep";
import * as Notification from "@/components/ui/Notification";
import { useHistory, useParams } from "react-router-dom";
import Button from "@/components/ui/Button";

export default function AppWebhooksPage() {
  const [ready, setReady] = useState(false);
  const [status, setStatus] = useState(null);
  const [app, AppDispatch] = useContext(AppContext);
  const { app_id } = useParams();
  const router = useHistory();

  async function getWebhookStatus() {
    await WebhookService.GetStatus(app_id)
      .then(async (data) => {
        AppDispatch({
          type: "SET_STATUS",
          payload: {
            status: data,
          },
        });

        setStatus(data);
        await Sleep(500);
        setReady(true);
      })
      .catch((err) => {
        Notification.error(err.message);
      });
  }

  useEffect(async () => {
    setReady(false);
    getWebhookStatus();
    return () => {};
  }, []);

  async function ActivateWebhook(url) {
    let body = {
      url: url,
    };
    await WebhookService.Create(app_id, body)
      .then(async () => {
        getWebhookStatus();
      })
      .catch((err) => {
        Notification.error(err.message);
      });
  }

  async function RemoveWebhook() {
    await WebhookService.Remove(app_id)
      .then(async () => {
        getWebhookStatus();
      })
      .catch((err) => {
        Notification.error(err.message);
      });
  }

  return (
    <>
      <div>
        <div>
          <SubNav></SubNav>
          <div className="max-w-7xl mx-auto relative">
            <div className="dashboard-container px-3">
              <div className="flex justify-between mt-3">
                <div>
                  <div className="flex">
                    <div
                      className="cursor-pointer"
                      onClick={() => (window.location.href = `/app/${app_id}/`)}
                    >
                      <img
                        className="w-16 h-16 mt-1 rounded-xl"
                        src={app.logo_url}
                      />
                    </div>
                    <div className="ml-5">
                      <h1 className="text-3xl text-white">
                        <span className="font-bold">Webhooks</span>
                      </h1>
                      <h3 className="text-lg mt-2 text-bluegray-300">
                        Manage notifications using webhooks
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <div className="mt-2.5">
                    <Button
                      onClick={() =>
                        (window.location.href = `/app/${app_id}/developer`)
                      }
                      currentBg="primary"
                      theme="secondary"
                      icon={faCode}
                      label="Developer"
                    ></Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-7xl mx-auto relative">
          {ready ? (
            <>
              <div>
                <StatusWebhooks
                  status={status}
                  activate={(url) => ActivateWebhook(url)}
                  remove={() => RemoveWebhook()}
                />
              </div>
              <div className="mt-8">
                <DeliveriesWebhooks />
              </div>
            </>
          ) : null}
          <div className="mt-4">
            <Footer></Footer>
          </div>
        </div>
      </div>
    </>
  );
}
