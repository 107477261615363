import { useContext, useEffect, useState } from 'react'
import Footer from '@/components/menu/Footer'
import SubNav from '@/components/menu/SubNav'
import CorporateVerificationHeader from '@/components/sessions/corporate/CorporateVerificationHeader'
import CorporateSessionSidebar from '@/components/sessions/corporate/CorporateSessionSidebar'
import CorporateService from '@/services/corporate'
import CorporateCore from '@/components/sessions/corporate/CorporateCore'
import { CorporateSessionContext } from '@/context/corporate_session'
import { useParams } from 'react-router-dom'


export default function AppCorporateSession() {
    const [corporate, corporateDispatch] = useContext(CorporateSessionContext)

    const [open] = useState(false)
    const [flowKey, setFlowKey] = useState(0)
    const { app_id, session_id } = useParams()


    useEffect(() => {
        GetCorporateResource()
        return () => { }
    }, [])

    async function GetCorporateResource() {
        await corporateDispatch({
            type: 'SET_READY',
            payload: false,
        })
        await CorporateService.GetCorporateResource(app_id, session_id).then(async (data) => {
            await corporateDispatch({
                type: 'SET_DATA',
                payload: data,
            })
        }).catch((err) => {
            console.log(err.message)
        })
    }

    useEffect(() => {
        if (!open) {
            setFlowKey(flowKey + 1)
        }
    }, [open])

    return (
        <div>
            <div>
                <SubNav></SubNav>
                <div className='max-w-7xl mx-auto relative'>
                    <div className='dashboard-container px-3'>
                        <CorporateVerificationHeader />
                        <div className="flex mt-2">
                            <div className="w-3/12 pr-2">
                                <CorporateSessionSidebar />
                            </div>
                            <div className="w-9/12 pl-2">
                                {corporate.ready ? <CorporateCore onRefresh={() => GetCorporateResource()} /> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='max-w-7xl mx-auto relative'>
                <Footer></Footer>
            </div>
        </div>
    )
}
