import { useContext, useEffect, useState, useCallback } from "react";
import { Modal } from "react-responsive-modal";
import { SearchContext } from "@/context/search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAt,
  faEmptySet,
  faIdCard,
  faLink,
  faSearch,
  faUniversity,
  faUserCircle,
} from "@fortawesome/pro-duotone-svg-icons";
import { faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import AppsService from "@/services/apps";
import { AppContext } from "@/context/app";
import countries from "@/resources/countries_label.json";
import { GlobalHotKeys, configure } from "react-hotkeys";
import axios from "axios";
import Loader from "./Loader";

configure({
  ignoreTags: ["input", "select", "textarea"],
  ignoreEventsCondition: function () {},
});

let cancelToken = null;
export default function Search() {
  const [search, searchDispatch] = useContext(SearchContext);
  const [app] = useContext(AppContext);
  const [term, setTerm] = useState("");
  const [results, setResults] = useState([]);
  const [current, setCurrent] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [searchMode, setSearchMode] = useState("name");
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    DoSearch(term);
  }, [searchMode]);

  function DoSearch(value) {
    setTerm(value);
    if (value.length < 3) {
      return;
    }
    const body = {
      q: value,
    };

    if (cancelToken) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();

    setIsSearching(true);
    AppsService.Search(app.id, body, searchMode, cancelToken)
      .then((data) => {
        setIsSearching(false);
        if (data.length > 0) {
          setResults(data);
          setCurrent(data[0]);
          setCurrentIndex(0);
        } else {
          setCurrent(0);
          setCurrent(null);
          setResults([]);
        }
      })
      .catch((err) => {
        setIsSearching(false);
      });
  }

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  //   const optimizedFn = useCallback(debounce(DoSearch), []);

  const keyMap = {
    ENTER: ["enter"],
    UP: ["up"],
    DOWN: ["down"],
  };

  function downCurrent() {
    console.log("down key");
    if (currentIndex + 1 < results.length) {
      pushCurrent(currentIndex + 1);
    }
  }

  function upCurrent() {
    console.log("up key");
    if (currentIndex - 1 >= 0) {
      pushCurrent(currentIndex - 1);
    }
  }

  const handlers = {
    UP: upCurrent,
    DOWN: downCurrent,
    ENTER: goToCurrent,
  };

  function goToCurrent() {
    goTo(
      `/app/${app.id}/${
        current.type === "INDIVIDUAL" ? "individual" : "corporate"
      }/${current.session_id}`
    );
  }

  function goTo(url) {
    window.location.href = url;
  }

  function pushCurrent(index) {
    setCurrentIndex(index);
    setCurrent(results[index]);
  }

  function InfoItem(props) {
    return (
      <div className="mt-2">
        <div className="flex border border-transparent py-1 px-1.5 rounded-xl">
          <div className="mr-3 mt-0.5 flex-shrink-0">
            <img className="h-9" src={props.icon}></img>
          </div>
          <div className="relative top-[2px]">
            <p className="text-bluegray-400 text-xs">{props.title}</p>
            <p className="text-gray-600 text-sm">{props.value}</p>
          </div>
        </div>
      </div>
    );
  }

  function CountryItem(props) {
    return (
      <div className="mt-2">
        <div className="flex border border-transparent py-1 px-1.5 rounded-xl">
          <div className="mr-3 flex-shrink-0">
            <div
              className="h-9 w-9"
              style={{
                backgroundImage: `url( ${`/img/icons/flags-rounded/${props.country}.svg`}), url(${"/img/icons/ui/icon-round.svg"})`,
                backgroundSize: "60%, contain",
                backgroundRepeat: "no-repeat, no-repeat",
                backgroundPosition: "center center, center center",
              }}
            ></div>
          </div>
          <div className="relative top-[2px]">
            <p className="text-bluegray-400 text-xs">{props.title}</p>
            <p className="text-gray-600 text-sm">{props.value}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <GlobalHotKeys
      tabIndex="1"
      className="outline-none"
      allowChanges={true}
      keyMap={keyMap}
      handlers={handlers}
    >
      <Modal
        open={search.open}
        showCloseIcon={true}
        closeIcon={true}
        onClose={() =>
          searchDispatch({
            type: "SET_CLOSE",
            payload: {},
          })
        }
        center
      >
        <div className="search-view relative">
          <div className="bg-white rounded-xl h-full">
            <div className="flex items-center">
              <div>
                {isSearching ? (
                  <Loader color="gray-500 ml-4"></Loader>
                ) : (
                  <FontAwesomeIcon
                    className="text-bluegray-200 text-2xl ml-4"
                    icon={faSearch}
                  />
                )}
              </div>
              <div className="w-full">
                <input
                  onChange={(e) => {
                    DoSearch(e.target.value);
                  }}
                  className="p-5 pl-2 w-full text-lg outline-none"
                  autoFocus
                  placeholder="Search"
                />
              </div>
            </div>

            <div className="bg-gray-100">
              <div className="flex">
                <div>
                  <div
                    onClick={() => setSearchMode("name")}
                    className={`p-2 mr-2 ${
                      searchMode === "name"
                        ? "bg-gray-200 text-primary-500"
                        : null
                    } cursor-pointer text-primary- hover:text-grey-800 transition-fast-out text-sm`}
                  >
                    <FontAwesomeIcon
                      className="text-bluegray-400 text-sm mx-1 mt-1"
                      icon={faIdCard}
                    />
                    Name{" "}
                    {results.length > 0 && searchMode == "name"
                      ? `(${results.length})`
                      : null}
                  </div>
                </div>
                <div>
                  <div
                    onClick={() => setSearchMode("session_id")}
                    className={`p-2 mr-2 ${
                      searchMode === "session_id"
                        ? "bg-gray-200 text-primary-500"
                        : null
                    } cursor-pointer text-primary-500 border-primary-100 hover:text-grey-800 transition-fast-out text-sm`}
                  >
                    <FontAwesomeIcon
                      className="text-bluegray-400 text-sm mx-1 mt-1"
                      icon={faLink}
                    />
                    Session ID{" "}
                    {results.length > 0 && searchMode == "session_id"
                      ? `(${results.length})`
                      : null}
                  </div>
                </div>
                <div>
                  <div
                    onClick={() => setSearchMode("alias")}
                    className={`p-2 mr-2 ${
                      searchMode === "alias"
                        ? "bg-gray-200 text-primary-500"
                        : null
                    } cursor-pointer text-primary-500 border-primary-100 hover:text-grey-800 transition-fast-out text-sm`}
                  >
                    <FontAwesomeIcon
                      className="text-bluegray-400 text-sm mx-1 mt-1"
                      icon={faAt}
                    />
                    Alias{" "}
                    {results.length > 0 && searchMode == "alias"
                      ? `(${results.length})`
                      : null}
                  </div>
                </div>
              </div>
            </div>
            {term.length > 0 && results.length > 0 && current ? (
              <div className="flex h-full">
                <div className="w-1/2 bg-white h-full items-stretch search-view-container">
                  {results.map((result, index) => {
                    return (
                      <div
                        onMouseEnter={() => pushCurrent(index)}
                        onClick={() =>
                          goTo(
                            `/app/${app.id}/${
                              result.type === "INDIVIDUAL"
                                ? "individual"
                                : "corporate"
                            }/${result.session_id}`
                          )
                        }
                        key={result.session_id}
                        className={`${
                          index === currentIndex
                            ? "bg-primary-500 text-white cursor-pointer"
                            : "hover:bg-gray-200 cursor-pointer"
                        }`}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center p-3">
                            <div>
                              <FontAwesomeIcon
                                className="text-3xl top-0.5 ml-1.5 relative text-bluegray-400"
                                icon={faUserCircle}
                              />
                            </div>
                            <div className="ml-3">
                              {result.type === "INDIVIDUAL" ? (
                                <p className="font-medium text-sm capitalize">
                                  {result.metadata.full_name
                                    ? result.metadata.full_name.toLowerCase()
                                    : "-"}
                                </p>
                              ) : null}
                              {result.type === "CORPORATE" ? (
                                <p className="font-medium text-sm capitalize">
                                  {result.metadata.name
                                    ? result.metadata.name.toLowerCase()
                                    : "-"}
                                </p>
                              ) : null}
                              <p className="text-xs">{result.session_id}</p>
                            </div>
                          </div>
                          <div className="mr-5">
                            <FontAwesomeIcon
                              className="text-bluegray-500"
                              icon={faAngleRight}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="w-1/2 bg-white h-full items-stretch border-r border search-view-container">
                  {current.type === "INDIVIDUAL" ? (
                    <>
                      <div className="rounded-xl m-3 pt-3">
                        <div className="h-full mx-auto text-center px-3 pb-3">
                          <FontAwesomeIcon
                            className="text-7xl text-bluegray-300"
                            icon={faUserCircle}
                          />
                        </div>
                        <h2 className="text-xl text-primary-800 text-center mb-1 capitalize">
                          {current.metadata.full_name
                            ? current.metadata.full_name.toLowerCase()
                            : "-"}
                        </h2>
                        <h2 className="text-sm text-gray-500 text-center mb-4">
                          Individual
                        </h2>
                      </div>
                      <div>
                        <div className="py-1 px-4 bg-gray-100 h-full text-xs text-bluegray-600 font-semibold tracking-wide uppercase">
                          Info
                        </div>
                        <div className="mx-4 mt-1.5">
                          {current.metadata.birth_date ? (
                            <InfoItem
                              title="Birth date"
                              value={current.metadata.birth_date}
                              icon="/img/icons/ui/icon-birthdate.svg"
                            />
                          ) : null}
                          {current.metadata.country ? (
                            <CountryItem
                              title="Country"
                              value={countries[current.metadata.country]}
                              country={current.metadata.country}
                            />
                          ) : null}
                          {current.metadata.nationality !== "N/A" ? (
                            <CountryItem
                              title="Nationality"
                              value={countries[current.metadata.nationality]}
                              country={current.metadata.nationality}
                            />
                          ) : null}
                          {current.metadata.full_phone ? (
                            <InfoItem
                              title="Phone number"
                              value={current.metadata.full_phone}
                              icon="/img/icons/ui/icon-call.svg"
                            />
                          ) : null}
                          {current.metadata.full_address ? (
                            <InfoItem
                              title="Postal address"
                              value={current.metadata.full_address}
                              icon="/img/icons/ui/icon-address.svg"
                            />
                          ) : null}
                          <InfoItem
                            title="Session ID"
                            value={current.session_id}
                            icon="/img/icons/ui/icon-sessionid.svg"
                          />
                          {current.metadata.alias ? (
                            <InfoItem
                              title="Alias"
                              value={current.metadata.alias}
                              icon="/img/icons/ui/icon-alias.svg"
                            />
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : null}
                  {current.type === "CORPORATE" ? (
                    <>
                      <div className="rounded-xl m-3 pt-3">
                        <div className="h-full mx-auto text-center px-3 pb-3">
                          <FontAwesomeIcon
                            className="text-7xl text-bluegray-300"
                            icon={faUniversity}
                          />
                        </div>
                        <h2 className="text-xl text-primary-800 text-center mb-1 capitalize">
                          {current.metadata.name ? current.metadata.name : "-"}
                        </h2>
                        <h2 className="text-sm text-gray-500 text-center mb-4">
                          Corporate
                        </h2>
                      </div>
                      <div>
                        <div className="py-1 px-4 bg-gray-100 h-full text-xs text-bluegray-600 font-semibold tracking-wide uppercase">
                          Info
                        </div>
                        <div className="mx-4 mt-1.5">
                          {current.metadata.country ? (
                            <CountryItem
                              title="Country"
                              value={countries[current.metadata.country]}
                              country={current.metadata.country}
                            />
                          ) : null}
                          {current.metadata.address ? (
                            <InfoItem
                              title="Postal address"
                              value={current.metadata.address}
                              icon="/img/icons/ui/icon-address.svg"
                            />
                          ) : null}
                          {current.metadata.city ? (
                            <InfoItem
                              title="City"
                              value={current.metadata.city}
                              icon="/img/icons/ui/icon-city.svg"
                            />
                          ) : null}
                          <InfoItem
                            title="Session ID"
                            value={current.session_id}
                            icon="/img/icons/ui/icon-sessionid.svg"
                          />
                          {current.metadata.alias ? (
                            <InfoItem
                              title="Alias"
                              value={current.metadata.alias}
                              icon="/img/icons/ui/icon-alias.svg"
                            />
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            ) : null}

            {term.length > 0 && results.length === 0 ? (
              <div className="text-center search-view-container">
                <p className="text-4xl text-bluegray-400 pt-4">
                  <FontAwesomeIcon icon={faEmptySet} />
                </p>
                <p className="text-center pt-4">
                  No results for <strong>{term}</strong>
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </Modal>
    </GlobalHotKeys>
  );
}
