import { useContext, useState } from "react";
import Footer from "@/components/menu/Footer";
import SubNav from "@/components/menu/SubNav";
import SessionsTasks from "@/components/sessions/SessionsTasks";
import { useHistory, useParams } from "react-router-dom";
import { AppContext } from "@/context/app";
import MiniButton from "@/components/ui/MiniButton";
import { Modal } from "react-responsive-modal";
import NewSession from "@/components/sessions/NewSession";
import ExportSession from "@/components/sessions/ExportSession";

import {
  faCheck,
  faFileDownload,
  faLayerGroup,
  faList,
  faPlus,
} from "@fortawesome/pro-solid-svg-icons";

export default function AppSessionsPage() {
  const { app_id } = useParams();
  const router = useHistory();
  const [app] = useContext(AppContext);
  const [status, setStatus] = useState("ALL");
  const [open, setOpen] = useState(false);
  const [openExport, setOpenExport] = useState(false);

  return (
    <div>
      <div>
        <SubNav></SubNav>
        <div className="max-w-7xl mx-auto relative">
          <div className="dashboard-container px-3">
            <div className="flex justify-between mt-3">
              <div>
                <div className="flex">
                  <div
                    className="cursor-pointer"
                    onClick={() => (window.location.href = `/app/${app_id}/`)}
                  >
                    <img
                      className="w-16 h-16 mt-1 rounded-xl"
                      src={app.logo_url}
                    />
                  </div>
                  <div className="ml-5">
                    <h1 className="text-3xl text-white">
                      <span className="font-bold">Sessions</span>
                    </h1>
                    <h3 className="text-lg mt-2 text-bluegray-300">
                      View your onboardings
                    </h3>
                  </div>
                </div>
              </div>
              <div className="flex mt-5">
                <div className="mr-3">
                  <MiniButton
                    onClick={() => setStatus("ALL")}
                    currentBg="primary"
                    theme="secondary"
                    icon={faList}
                    label="All sessions"
                  ></MiniButton>
                  {status === "ALL" ? (
                    <div className="mx-auto mt-2 w-5 h-1 rounded-xl bg-white"></div>
                  ) : (
                    <div className="mx-auto mt-2 w-5 h-1 rounded-xl bg-primary-900"></div>
                  )}
                </div>
                <div className="mr-3">
                  <MiniButton
                    onClick={() => setStatus("APPROVED")}
                    currentBg="primary"
                    theme="success"
                    icon={faCheck}
                    label="Finished"
                  ></MiniButton>
                  {status === "APPROVED" ? (
                    <div className="mx-auto mt-2 w-5 h-1 rounded-xl bg-green-500"></div>
                  ) : (
                    <div className="mx-auto mt-2 w-5 h-1 rounded-xl bg-primary-900"></div>
                  )}
                </div>
                <div className="mr-6">
                  <MiniButton
                    focus
                    onClick={() => setStatus("PENDING")}
                    currentBg="primary"
                    theme="pin"
                    icon={faLayerGroup}
                    label="Open"
                  ></MiniButton>
                  {status === "PENDING" ? (
                    <div className="mx-auto mt-2 w-5 h-1 rounded-xl bg-orange-500"></div>
                  ) : (
                    <div className="mx-auto mt-2 w-5 h-1 rounded-xl bg-primary-900"></div>
                  )}
                </div>
                <div className="mr-3">
                  <MiniButton
                    focus
                    onClick={() => setOpen(true)}
                    currentBg="primary"
                    theme="secondary"
                    icon={faPlus}
                    label="Init new session"
                  ></MiniButton>
                </div>
                <div>
                  <MiniButton
                    focus
                    onClick={() => setOpenExport(true)}
                    currentBg="primary"
                    theme="secondary"
                    icon={faFileDownload}
                    label="Export sessions"
                  ></MiniButton>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          open={open}
          showCloseIcon={true}
          closeIcon={true}
          onClose={() => setOpen(false)}
          center
        >
          <NewSession open={open} onClose={() => setOpen(false)} />
        </Modal>

        <Modal
          open={openExport}
          showCloseIcon={true}
          closeIcon={true}
          onClose={() => setOpenExport(false)}
          center
        >
          <ExportSession
            status={status}
            open={openExport}
            onClose={() => setOpenExport(false)}
          />
        </Modal>

        <div className="max-w-7xl mx-auto relative">
          <SessionsTasks status={status}></SessionsTasks>
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
}
