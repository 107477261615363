import { useState, useContext } from 'react'
import Card from '../ui/Card'
import {
	faShield,
	faUser,
} from '@fortawesome/pro-duotone-svg-icons'
import Footer from '../menu/Footer'
import SubNav from '../menu/SubNav'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Input from '@/components/ui/Input'
import SettingsTwoFactor from '../twofactor/SettingsTwoFactor'
import { AuthContext } from '@/context/auth'

export default function SettingsPage() {
	const [current, setCurrent] = useState('profile')
	const [auth] = useContext(AuthContext)


	function SettingsMenuItem({ onSelect, title, icon, active }) {
		return (
			<div className="p-2" onClick={() => onSelect()}>
				<div className={`border border-transparent p-3 rounded-lg ${active ? 'bg-gray-200' : ''} hover:bg-gray-200 transition ease-in cursor-pointer`}>
					<div className="flex flex-wrap">
						<div className="w-1/5">
							<div className="text-center">
								<FontAwesomeIcon className={active ? 'text-primary-500' : 'text-primary-200'} icon={icon} />
							</div>
						</div>
						<div className="w-4/5">
							{title}
						</div>
					</div>
				</div>
			</div>
		)
	}

	function SettingsProfile() {
		return (
			<div>
				<h3 className="text-xl">Profile</h3>
				<p className="text-gray-500 my-2">This information will be displayed in your apps (team section)</p>
				<div className="flex flex-wrap">
					<div className="w-1/2 p-2">
						<Input disabled label="Firstname" value={auth.user.firstname}></Input>
					</div>
					<div className="w-1/2 p-2">
						<Input disabled label="Lastname" value={auth.user.lastname}></Input>
					</div>
					<div className="w-1/2 p-2">
						<Input disabled label="Email" value={auth.user.email}></Input>
					</div>
				</div>

			</div>
		)
	}


	return (
		<div>
			<div>
				<SubNav></SubNav>
				<div className='max-w-7xl mx-auto relative'>
					<div className='dashboard-container'>
						<div className='flex justify-between mt-3'>
							<div>
								<h1 className='text-3xl text-white'>
									<span className='font-bold'>Settings</span>
								</h1>
								<h3 className='text-lg mt-2 text-bluegray-300'>
									Manage your account security and preferences
								</h3>
							</div>

						</div>
					</div>
				</div>

				<div className='max-w-7xl mx-auto relative'>
					<div className='flex flex-wrap mt-6'>
						<Card className='w-full'>
							<div>
								<div className="flex flex-wrap">
									<div className="w-full sm:w-1/4">
										<div className="p-3">
											<SettingsMenuItem onSelect={() => setCurrent('profile')} icon={faUser} active={current === 'profile'} title="Profile" />
											<SettingsMenuItem onSelect={() => setCurrent('twofactor')} icon={faShield} active={current === 'twofactor'} title="Two-Factor Auth" />
										</div>
									</div>
									<div className="w-full sm:w-3/4">
										<div className="p-6 bor-left-1 h-full">
											{current === 'profile' ? <SettingsProfile /> : null}
											{current === 'twofactor' ? <SettingsTwoFactor /> : null}
										</div>
									</div>
								</div>
							</div>
						</Card>
					</div>
					<Footer></Footer>
				</div>
			</div>
		</div>
	)
}
