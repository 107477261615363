import { useLocation, Redirect } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContext } from '@/context/auth'
import { b64Encode } from '@/lib/b64'

export default function AuthGuard({ children }) {
	const [auth] = useContext(AuthContext)

	let redirectURI = '/login'
	const location = useLocation()
	const pathName = location.pathname

	if (pathName !== '/') {
		redirectURI = `/login?redirect_uri=${b64Encode(pathName)}`
	}

	return auth.authenticated ? children : <Redirect to={redirectURI} />
}
