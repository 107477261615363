import { faGlobe, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from '@/components/ui/Alert'
import AppsService from '@/services/apps'
import { useParams } from 'react-router-dom'
import Tooltip from '@/components/ui/Tooltip'
import countries from '@/resources/countries_label.json'


export default function PhoneFlow(props) {
    const [error, setError] = useState(null)
    const [data, setData] = useState(null)
    const [ready, setReady] = useState(false)
    const { app_id } = useParams()
    const [size, setSize] = useState(0)

    useEffect(() => {
        GetStepDetails()
        return () => { }
    }, [])

    function GetStepDetails() {
        AppsService.GetStepDetails(app_id, props.step_id).then((data) => {
            setData(data)
            let len = 0
            Object.keys(data.setting.restrictions).map(function () {
                len++
            })
            setSize(len)
            setReady(true)
        }).catch((err) => {
            setError(err.message)
        })
    }

    function openManage() {
        if (!props.manageable) {
            props.onManage()
        }
    }


    return (
        <div className={`${props.manageable ? 'modal-view' : 'w-full h-full sm:w-1/2 lg:w-1/3 my-4'}`}>
            <div className={`z-max w-full h-full flex items-center justify-center ${!props.manageable ? 'px-3' : ''}`}>
                <div onClick={() => openManage()} className={`${!props.manageable ? 'rounded-xl step-view cursor-pointer hover:shadow-lg bg-white border border-gray-200 hover:border-gray-300 transition-25 transition duration-200 ease-in' : ''} flex flex-col w-full h-full`}>
                    <header className="p-5 pb-3">

                        <div className="flex justify-between items-center">
                            <div className="flex items-center">
                                <div>
                                    <img className="h-9 relative mr-2" src="/img/icons/ui/icon-call.svg" />
                                </div>
                                <div>
                                    <h2 className="text-lg text-primary-500  font-medium"> Phone verification
                                    </h2>
                                </div>
                            </div>
                            <div>
                                <div className="flex items-center ">
                                    <div>
                                        <Tooltip auto={true} content="Step ID">
                                            <span className="relative top-[-1px] text-xs bg-gray-200  px-2 py-0.5 rounded-md text-gray-500">{props.step_id}</span>
                                        </Tooltip>
                                    </div>
                                    <div>
                                        {props.manageable ?
                                            <h2 className="text-xl relative ml-3 top-0.5"><span><FontAwesomeIcon onClick={() => props.onClose()} className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-200 ease-in text-regular relative top-[-1px]" icon={faTimes}></FontAwesomeIcon></span></h2>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="my-3" />
                    </header>

                    <main
                        className="flex-1 overflow-y-auto p-5 py-0"
                        id="individual-main">
                        {ready ?
                            <>
                                <div className="flex flex-wrap">
                                    <div className="w-full">
                                        {error ? (
                                            <Alert type="error" title={error}></Alert>
                                        ) : null}
                                        <div>
                                            <div className="flex justify-between">
                                                <div>
                                                    <p className="text-sm text-primary-700">Restricted countries</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="flex mt-4">
                                                    {size === 0 ? <p className="text-sm text-gray-400"><FontAwesomeIcon className="mr-1" icon={faGlobe} /> No restrictions</p> : null}
                                                    <div className="flex -space-x-1 relative z-0">
                                                        {Object.entries(data.setting.restrictions).slice(0, 5).map(([country]) => {
                                                            return <>
                                                                <Tooltip auto={true} content={countries[country]}>
                                                                    <img key={country}
                                                                        className="relative z-30 inline-block h-6 w-6 rounded-full ring-2 ring-white"
                                                                        src={`/img/icons/flags-rounded/${country}.svg`}
                                                                    />
                                                                </Tooltip>
                                                            </>
                                                        })}
                                                    </div>
                                                    <span className="ml-3 relative top-[-1px]">{size > 5 ? <span className="relative text-center text-sm top-1 mt-2 text-gray-500">+ {size - 5} others</span> : null}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </> : null}
                    </main>
                </div>
            </div>
        </div>
    )
}
