import { useState } from 'react'
import {
    faCheckCircle,
    faExclamationTriangle,
    faEye,
    faLink,
    faLock,
} from '@fortawesome/pro-duotone-svg-icons'
import Input from '@/components/ui/Input'
import MiniButton from '@/components/ui/MiniButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function StatusWebhooks(props) {
    const [url, setUrl] = useState('')
    const [secret, setSecret] = useState(false)
    const [requestLoading] = useState(false)

    return (

        <div className="flex flex-wrap mt-4">
            <div className="w-1/2">
                <div className="h-full m-4 border p-6 px-8 pb-4 mb-0 border-bluegray-150 items-center bg-white rounded-xl">
                    <h3 className="text-2xl text-primary-800 mb-1 font-bold">Notification URL</h3>
                    <p className="text-gray-500">For each state change, Synaps will make a POST request directly on your webhook payload URL.</p>

                    {props.status.webhook_status === 'NOT_ACTIVED' ?
                        <div>
                            <div className="flex flex-wrap">
                                <div className="w-full mt-2">
                                    <Input
                                        type='text'
                                        icon={faLink}
                                        placeholder='Webhook URL'
                                        setValue={(value) => setUrl(value)}
                                        value={url}
                                        name='url'
                                    />
                                </div>
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <div>
                                    <p className="text-gray-500 text-sm mt-3 ml-3">
                                        <FontAwesomeIcon icon={faLock} className="mr-1" /> Secure HTTPS recommended
                                    </p>
                                </div>
                                <div>
                                    <p className="mt-3">
                                        <MiniButton disabled={!url} loading={requestLoading} theme="success" label="Activate" onClick={() => props.activate(url)}></MiniButton>
                                    </p>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <div className="flex flex-wrap">
                                <div className="w-full mt-2">
                                    <Input disabled label="Webhook URL" value={props.status.url}></Input>
                                </div>
                                <div className="w-full mt-4">
                                    <div className="flex justify-between">
                                        <div>
                                            <label
                                                className="block text-sm font-medium text-gray-700 mb-2">
                                                Webhook Secret
                                            </label>
                                        </div>
                                        <div>
                                            <label
                                                className="block text-sm font-medium text-gray-700 mb-2 cursor-pointer" onClick={() => setSecret(!secret)}>
                                                <FontAwesomeIcon icon={faEye} className="mr-1" />View
                                            </label>
                                        </div>
                                    </div>

                                    <Input disabled type={secret ? 'text' : 'password'} value={props.status.secret}></Input>

                                </div>
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <div>
                                    <p className="text-gray-500 text-sm mt-3">
                                        <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1" /> Deactivating webhooks will change the webhook secret
                                    </p>
                                </div>
                                <div>
                                    <p className="mt-3">
                                        <MiniButton loading={requestLoading} theme="error" label="Deactivate" onClick={() => props.remove()}></MiniButton>
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="w-1/2">
                <div className="h-full m-4 p-6 px-8 pb-4 mb-0 border border-bluegray-150 items-center bg-white rounded-xl">
                    <h3 className="text-2xl text-primary-800 mb-1 font-bold">Checklist</h3>
                    <p className="text-gray-500">Make sure to follow this checklist in order to check your webhook integration</p>

                    <div className="flex mt-5">
                        <div>
                            <p><FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-3 mt-0.5" /></p>
                        </div>
                        <div>
                            Use HTTPS secure webhook URL to enable secured communication
                        </div>
                    </div>


                    <div className="flex mt-5">
                        <div>
                            <p><FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-3 mt-0.5" /></p>
                        </div>
                        <div>
                            For each request, verify that the  <span className="font-medium">secret</span> query params is the one provided in your manager interface {props.status.webhook_status !== 'NOT_ACTIVATED' ? <span className="font-regular">({props.status.secret.slice(0, 10)}...)</span> : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}