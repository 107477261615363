export default function TeamMemberItemPlaceholder() {
	return (
		<>
			<div className="w-full sm:w-1/2 lg:w-1/4">
				<div className='m-4 text-center border border-bluegray-150 items-center bg-white rounded-xl transition-25'>
					<div className="m-3 my-6">
						<div className="h-24 w-24 mx-auto animate-pulse border bg-gray-100 border-bluegray-100 rounded-2xl"></div>
						<div className="mt-4">
							<div className='text-regular animate-pulse font-regular font-bold'>
								<div className="bg-gray-400 h-3 mx-auto w-16 rounded"></div>
							</div>
							<div className="flex justify-center mt-1">
								<div className='mt-3 pb-1 animate-pulse text-regular text-sm font-regular'>
									<div className="bg-gray-100 mx-1 h-8  w-8 rounded-lg"></div>
								</div>
								<div className='mt-3 pb-1 animate-pulse text-regular text-sm font-regular'>
									<div className="bg-gray-100  mx-1 h-8 w-8 rounded-lg"></div>
								</div>
								<div className='mt-3 pb-1 animate-pulse text-regular text-sm font-regular'>
									<div className="bg-gray-100  mx-1 h-8 w-8 rounded-lg"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
