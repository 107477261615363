import {
  faClock,
  faBan,
  faUserCircle,
} from "@fortawesome/pro-duotone-svg-icons";
import { faCheck, faGavel } from "@fortawesome/pro-solid-svg-icons";
import { faDotCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import countries from "@/resources/countries_label.json";
import { Modal } from "react-responsive-modal";
import { useState } from "react";
import IndividualAmlDetails from "@/components/sessions/individual/IndividualAmlDetails";
import IndividualAmlDecision from "@/components/sessions/individual/IndividualAmlDecision";

export default function IndividualAml({ data, onFinish, title }) {
  const [open, setOpen] = useState(false);
  const [screeningDetails, setScreeningDetails] = useState(null);

  const criterias_table = {
    PEP_1: "PEP 1",
    PEP_2: "PEP 2",
    PEP_3: "PEP 3",
    PEP_4: "PEP 4",
    SANCTION: "Sanction List",
    ADVERSE_MEDIA: "Adverse Media",
    WARNING: "Warning",
    FITNESS_PROBITY: "Fitness Probity",
  };

  function openScreening(data) {
    setOpen(true);
    setScreeningDetails(data);
  }

  function CountryItem(props) {
    return (
      <div className="mt-2">
        <div className="flex border border-transparent py-1 px-1.5 rounded-xl">
          <div className="mr-3 flex-shrink-0">
            <img
              className={"h-9 w-9"}
              src={"/img/icons/ui/icon-round.svg"}
            ></img>
            <img
              className={"h-5 w-5 i-nationality"}
              src={`/img/icons/flags-rounded/${props.country}.svg`}
            ></img>
          </div>
          <div className="relative top-[2px]">
            <p className="text-bluegray-400 text-xs">{props.title}</p>
            <p className="text-gray-600 text-sm">{props.value}</p>
          </div>
        </div>
      </div>
    );
  }

  function IdentityTitle({ title, state }) {
    return (
      <div>
        <div className="flex justify-between items-center">
          <div>
            <p className="font-medium text-primary-400">
              {title ? title : "Anti-Money Laundering Screening (AML)"}
            </p>
          </div>
          <div>
            <div className="flex">
              {/* <p onClick={() => setOpen(true)} className="text-bluegray-500 hover:text-bluegray-700 ease-in duration-200 transition cursor-pointer text-sm bg-bluegray-100 rounded-md px-2 py-1 mr-3"><FontAwesomeIcon className="mr-0.5" icon={faHistory} /> History</p> */}
              {state === "PENDING" ? (
                <p className="text-orange-500 text-sm bg-orange-100 rounded-md px-2 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faClock} /> Pending
                  verification
                </p>
              ) : null}
              {state === "NOT_STARTED" ? (
                <p className="text-gray-500 text-sm bg-gray-100 rounded-md px-2 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faDotCircle} /> Not
                  started
                </p>
              ) : null}
              {state === "REJECTED" ? (
                <p className="text-red-500 text-sm bg-red-100 rounded-md px-2 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faBan} /> Rejected
                </p>
              ) : null}
              {state === "FINAL_REJECTED" ? (
                <p className="text-red-500 text-sm bg-red-100 rounded-md px-2 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faBan} /> Final
                  Rejected
                </p>
              ) : null}
              {state === "VALIDATED" ? (
                <p className="text-green-500 text-sm bg-green-100 rounded-md px-2 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faCheck} /> Verified
                </p>
              ) : null}
              {state === "ACTION_REQUIRED" ? (
                <p className="text-orange-500 text-sm bg-orange-100 rounded-md px-2 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faGavel} /> Action
                  required
                </p>
              ) : null}
            </div>
          </div>
        </div>
        {state === "VALIDATED" ? (
          <hr className="my-4 border-bluegray-200" />
        ) : null}
      </div>
    );
  }

  function InfoItem(props) {
    return (
      <div className="mt-2">
        <div className="flex border border-transparent py-1 px-1.5 rounded-xl">
          <div className="mr-3 mt-0.5 flex-shrink-0">
            <img className="h-9" src={`/img/icons/ui/${props.icon}.svg`}></img>
          </div>
          <div className="relative top-[2px]">
            <p className="text-bluegray-400 text-xs">{props.title}</p>
            <p
              className={`text-gray-600 text-sm ${
                props.capitalize ? "capitalize" : null
              }`}
            >
              {props.value}
            </p>
          </div>
        </div>
      </div>
    );
  }

  function InfoCriterias(props) {
    return (
      <div className="mt-2">
        <div className="flex border border-transparent py-1 px-1.5 rounded-xl">
          <div className="mr-3 mt-0.5 flex-shrink-0">
            <img className="h-9" src={`/img/icons/ui/${props.icon}.svg`}></img>
          </div>
          <div className="relative top-[2px]">
            <p className="text-bluegray-400 text-xs">{props.title}</p>
            {props.criterias.map((criteria) => {
              return (
                <p
                  key={criteria}
                  className={`text-gray-600 text-sm ${
                    props.capitalize ? "capitalize" : null
                  }`}
                >
                  {criterias_table[criteria]}
                </p>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  function AmlReference({ criterias, id, decision }) {
    return (
      <div className="mt-3">
        <p className="mt-6 uppercase tracking-wide font-medium text-sm text-primary-400">
          Reference
        </p>
        <div className="flex flex-wrap">
          <div className="w-1/2">
            <InfoItem icon={"icon-document-number"} title="ID" value={id} />
            <InfoItem
              icon={"icon-corporate-individual"}
              title="Compliance Officer"
              value={decision.name ? decision.name : "-"}
            />
            {decision.output === "VALIDATED" ? (
              <InfoItem
                icon={"icon-decision-aml"}
                title="Decision"
                value={"Validated"}
              />
            ) : null}
            {decision.output === "REJECTED" ? (
              <InfoItem
                icon={"icon-decision-aml"}
                title="Decision"
                value={"Rejected"}
              />
            ) : null}
            {decision.output !== "VALIDATED" &&
            decision.output !== "REJECTED" &&
            data.action_required === false ? (
              <InfoItem
                icon={"icon-decision-aml"}
                title="Decision"
                value={"-"}
              />
            ) : null}
            {data.action_required ? (
              <IndividualAmlDecision
                onFinish={() => onFinish()}
                session_id={data.session_id}
              />
            ) : null}
          </div>
          <div className="w-1/2">
            <InfoCriterias
              icon={"icon-aml-synaps"}
              title="Criterias"
              criterias={criterias}
            />
          </div>
        </div>
        {decision.internal_note !== undefined &&
        decision.internal_note !== "" ? (
          <InfoItem
            icon={"icon-decision-aml"}
            title="Decision Note"
            value={decision.internal_note}
          />
        ) : null}
      </div>
    );
  }

  function AmlScreening({ matches, screening }) {
    return (
      <div className="mt-3">
        <p className="mt-6 uppercase tracking-wide font-medium text-sm text-primary-400">
          Screening ({matches})
        </p>
        {screening.map((profile, i) => {
          return (
            <div
              onClick={() => openScreening(profile)}
              key={i}
              className="border border-gray-200 p-4 mt-3 rounded-xl cursor-pointer transition-25 hover:shadow-md hover:border-bluegray-300 transition duration-200 ease-in"
            >
              <div className="flex items-center">
                <div>
                  <FontAwesomeIcon
                    icon={faUserCircle}
                    className="text-bluegray-300 ml-2 mr-4 text-5xl"
                  />
                </div>
                <div>
                  <div className="flex items-justify-between">
                    <div>
                      <p className="font-medium">{profile.info.full_name} </p>
                    </div>
                    <div>
                      <div className="ml-3">
                        {profile.types.map((aml_type) => {
                          return (
                            <span
                              key={aml_type}
                              className="bg-bluegray-100 py-0.5 rounded-full px-2.5 text-2xs text-gray-500 mr-2"
                            >
                              {criterias_table[aml_type]}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <p className="text-gray-700">{profile.info.birth_date}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  function AmlDetails({ data }) {
    return (
      <div>
        <AmlReference
          criterias={data.criterias}
          id={data.id}
          decision={data.decision}
        />
        <AmlScreening matches={data.matches} screening={data.screening} />
      </div>
    );
  }

  function AmlCurrentProfile({ info }) {
    return (
      <div className="flex flex-col w-full h-full rounded">
        <header className="p-4">
          <div className="rounded-xl m-3 pt-3 pb-0.5">
            <div className="h-full mx-auto text-center px-3 pb-3">
              <FontAwesomeIcon
                className="text-7xl text-bluegray-300"
                icon={faUserCircle}
              />
            </div>
            <h2 className="text-xl text-primary-800 text-center mb-1 capitalize">
              {info?.firstname.toLowerCase()} {info?.lastname.toLowerCase()}
            </h2>
            <h2 className="text-sm text-gray-500 text-center mb-4">Customer</h2>
          </div>
          <hr className="mx-6 my-1 border-bluegray-100" />
        </header>

        <main
          className="flex-1 overflow-y-auto px-6 py-0 flow-body"
          id="individual-main"
        >
          <InfoItem
            title="Firstname"
            value={info.firstname ? info.firstname : "-"}
            icon="icon-firstname"
          />
          <InfoItem
            title="Lastname"
            value={info.lastname ? info.lastname : "-"}
            icon="icon-lastname"
          />
          <InfoItem
            title="Birth Date"
            value={info.birth_date ? info.birth_date : "-"}
            icon="icon-birthdate"
          />
          {info.nationality ? (
            <CountryItem
              title="Nationality"
              value={countries[info.nationality]}
              country={info.nationality}
            />
          ) : null}
          {info.country ? (
            <CountryItem
              title="Document Country"
              value={countries[info.country]}
              country={info.country}
            />
          ) : null}
        </main>
      </div>
    );
  }

  function AmlScreenedUser({ screeningDetails }) {
    return <IndividualAmlDetails screeningDetails={screeningDetails} />;
  }

  return (
    <div>
      <IdentityTitle
        title={title}
        state={data.action_required ? "ACTION_REQUIRED" : data.state}
      />
      {data.state !== "NOT_STARTED" ? <AmlDetails data={data} /> : null}
      <Modal
        open={open}
        showCloseIcon={true}
        closeIcon={true}
        onClose={() => setOpen(false)}
        center
      >
        <div className="flex">
          <div className="aml-view-profile">
            <AmlCurrentProfile
              info={data.customer_info}
              session_id={data.session_id}
              action_required={data.action_required}
            />
          </div>
          <div className="aml-view-main">
            <AmlScreenedUser screeningDetails={screeningDetails} />
          </div>
        </div>
      </Modal>
    </div>
  );
}
