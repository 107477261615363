import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function DocItem(props) {
	return (
		<div className='m-4 text-center border border-bluegray-150 items-center bg-white rounded-xl cursor-pointer transition-25 hover:shadow-lg hover:border-bluegray-300 transition duration-200 ease-in'>
			<div className="m-5">
				<FontAwesomeIcon className="text-gray-600 text-4xl" icon={props.icon} />
				<div className="mt-3">
					<p className='text-regular font-regular font-bold'>
						<span>{props.name}</span>
					</p>
					<p className='text-regular text-sm font-regular'>
						{/* <span className="text-gray-500">{props.type === 'WORKFLOW' ? 'Individual' : 'Corporate'}</span> */}
					</p>
				</div>
			</div>
		</div>
	)
}
