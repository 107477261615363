import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { useEffect, useState } from 'react'
import Button from '@/components/ui/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from '@/components/ui/Alert'
import AppsService from '@/services/apps'
import { useParams } from 'react-router-dom'
import SwitchGreen from '@/components/ui/SwitchGreen'

export default function FlowIdentityUpload(props) {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [ready, setReady] = useState(false)
    const [camera, setCamera] = useState(false)
    const [file, setFile] = useState(false)
    const { app_id } = useParams()

    useEffect(() => {
        GetStepDetails()
        return () => { }
    }, [])


    function Submit() {
        setLoading(true)

        let methods = []

        if (camera) {
            methods.push('SMART_CAPTURE')
        }

        if (file) {
            methods.push('UPLOAD_DEVICE')
        }

        const body = {
            method: methods,
        }

        if (props.type === 'UBO_IDENTITY') {
            AppsService.UpdateCorporateUploadMethod(app_id, body).then(async () => {
                setLoading(false)
                props.onMain()
            }).catch((err) => {
                setError(err.message)
                setLoading(false)
            })
        } else {
            AppsService.UpdateUploadMethod(app_id, props.step_id, body).then(async () => {
                setLoading(false)
                props.onBack()
            }).catch((err) => {
                setError(err.message)
                setLoading(false)
            })
        }
    }


    function GetStepDetails() {
        setLoading(true)
        if (props.type === 'UBO_IDENTITY') {
            AppsService.GetCorporateCompliance(app_id).then((data) => {
                setReady(true)
                setLoading(false)
                setDocuments(data)
            }).catch((err) => {
                setError(err.message)
                setLoading(false)
            })
        } else {
            AppsService.GetStepDetails(app_id, props.step_id).then((data) => {
                setReady(true)
                setLoading(false)
                setDocuments(data)
            }).catch((err) => {
                setError(err.message)
                setLoading(false)
            })
        }
    }

    function setDocuments(d) {
        let methods = []

        if (props.type === 'UBO_IDENTITY') {
            methods = d.upload_method
        } else {
            methods = d.setting.upload_method
        }

        if (methods === null) {
            setCamera(true)
            setFile(true)
            return
        }

        setCamera(methods.includes('SMART_CAPTURE'))
        setFile(methods.includes('UPLOAD_DEVICE'))
    }

    return (
        <div className="modal-view">
            <div className="z-max w-full h-full flex items-center justify-center">
                <div className="flex flex-col w-full h-full rounded">
                    <header className="p-5 pb-3">
                        <div className="flex justify-between">
                            <div className="flex">
                                {props.type === 'UBO_IDENTITY' ? <><div>
                                    <img className="h-6 relative top-0.5 mr-2" src="/img/icons/ui/passport-only.svg" />
                                </div>
                                    <div>
                                        <h2 className="text-xl text-primary-500  font-medium"> ID Documents
                                        </h2>
                                    </div></> : null}
                                {props.type !== 'UBO_IDENTITY' ? <><div>
                                    <img className="h-6 relative top-0.5 mr-2" src="/img/icons/ui/passport-only.svg" />
                                </div>
                                    <div>
                                        <h2 className="text-xl text-primary-500  font-medium"> ID Verification <span className="relative top-[-1px] text-xs text-gray-500">ID: {props.step_id}</span>
                                        </h2>
                                    </div></> : null}
                            </div>
                            <div>
                                <h2 className="text-xl relative top-0.5"><span><FontAwesomeIcon onClick={() => props.onClose()} className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-200 ease-in" icon={faTimes}></FontAwesomeIcon></span></h2>
                            </div>
                        </div>
                        <hr className="my-3" />
                    </header>

                    <main
                        className="flex-1 overflow-y-auto p-5 py-0 flow-body"
                        id="individual-main">
                        {ready ?
                            <>
                                <div>
                                    {error ? (
                                        <Alert type="error" title={error}></Alert>
                                    ) : null}
                                </div>
                                <div className="flex flex-wrap">
                                    <div className="w-full">
                                        <div>
                                            <div className="flex items-center ">
                                                <p className="text-sm font-bold text-primary-700">Upload methods</p>
                                                {props.country !== undefined && props.country !== '' ? <>
                                                    <div>
                                                        <img className="relative h-4 ml-4 top-[-1px] rounded-sm mr-2" src={`/img/icons/flags/${props.country.alpha3}.svg`}></img>
                                                    </div>
                                                    <div>
                                                        <p key={props.country.alpha3}>{props.country.name}</p>
                                                    </div>
                                                </> : null}
                                            </div>
                                            <div className="flex justify-between">
                                                <div>
                                                    <p className="text-sm text-gray-600">Switch the upload document method you want to accept in your app</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="flex flex-wrap mt-3">
                                                    <div className="w-full">
                                                        <div className="flex flex-wrap justify-between border border-gray-200 p-2 rounded-xl">
                                                            <div className={'flex flex-1'}>
                                                                <div>
                                                                    <img src="/img/icons/ui/icon-another-device.svg"></img>
                                                                </div>
                                                                <div className="ml-3">
                                                                    <p className="font-medium mt-2 text-primary-700">Camera Capture</p>
                                                                </div>
                                                            </div>
                                                            <div className="mt-2 mr-2">
                                                                <SwitchGreen checked={camera}
                                                                    onChange={setCamera} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-full mt-4">
                                                        <div className="flex flex-wrap justify-between border border-gray-200 p-2 rounded-xl">
                                                            <div className={'flex flex-1'}>
                                                                <div>
                                                                    <img src="/img/icons/ui/icon-upload.svg"></img>
                                                                </div>
                                                                <div className="ml-3">
                                                                    <p className="font-medium mt-2 text-primary-700">Device Upload</p>
                                                                </div>
                                                            </div>
                                                            <div className="mt-2 mr-2">
                                                                <SwitchGreen checked={file}
                                                                    onChange={setFile} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </> : null}
                    </main>
                    <footer className="p-5">
                        <div className={'flex flex-wrap'}>
                            {props.country !== undefined && props.country !== '' ? (
                                <div className="w-1/3 pr-1.5">
                                    <Button
                                        disabled={loading}
                                        full
                                        theme="secondary"
                                        label={'Back'}
                                        onClick={() => props.onBack()}></Button>
                                </div>
                            ) : <div className="w-1/3 pr-1.5">
                                <Button
                                    disabled={loading}
                                    full
                                    theme="secondary"
                                    label={'Back'}
                                    onClick={() => props.onMain()}></Button>
                            </div>}
                            <div className="w-2/3 pl-1.5">
                                <Button
                                    full
                                    loading={loading}
                                    theme="success"
                                    label={'Update'}
                                    onClick={() => Submit()}></Button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    )
}
