import AuthGuard from '@/guard/auth'
import AppNavbar from '@/components/menu/AppNavbar'
import AppAmlPage from '@/components/pages/app/AppAmlPage'
import AppGuard from '@/guard/app'
import AppIamGuard from '@/guard/appiam'

function MyApps() {
	return (
		<AuthGuard>
			<AppGuard>
				<AppIamGuard page="tasks">
					<div className='relative min-h-screen flex flex-col'>
						<AppNavbar></AppNavbar>
						<AppAmlPage></AppAmlPage>
					</div>
				</AppIamGuard>
			</AppGuard>
		</AuthGuard>
	)
}

export default MyApps
