import { useReducer, createContext } from 'react'

export const UboContext = createContext()

const initialState = {
	type: '',
	data: {},
	open: false,
}

function setClose() {
	return {
		type: '',
		data: {},
		open: false,
	}
}

function setOpen(state, payload) {
	return {
		type: payload.type,
		data: payload.data,
		open: true,
	}
}


const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_OPEN':
			return setOpen(state, action.payload)
		case 'SET_CLOSE':
			return setClose()
		default:
			return state
	}
}

export const UboContextProvider = (props) => {
	const [state, dispatch] = useReducer(reducer, initialState)

	return (
		<UboContext.Provider value={[state, dispatch]}>
			{props.children}
		</UboContext.Provider>
	)
}
