import { useParams } from "react-router-dom";
import {
  faClock,
  faBan,
  faCheck,
  faCloudUploadAlt,
  faHistory,
} from "@fortawesome/pro-solid-svg-icons";
import { faDotCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import countries from "@/resources/countries_label.json";
import { Modal } from "react-responsive-modal";
import { useState } from "react";
import LinkMiniButton from "@/components/ui/LinkMiniButton";

export default function IndividualResidency({
  title,
  step,
  step_id,
  member_id,
  ip,
}) {
  const { app_id, session_id } = useParams();
  const [open, setOpen] = useState(false);

  function IdentityTitle({ state }) {
    return (
      <div>
        <div className="flex justify-between items-center">
          <div>
            <p className="font-medium text-primary-400">
              {title ? title : "Proof of Residency"}
            </p>
          </div>
          <div className="flex">
            <p className="text-gray-300 mt-1 mr-2">{ip ? ip : ""}</p>
            <div className="flex">
              {step.history.length > 0 ? (
                <p
                  onClick={() => setOpen(true)}
                  className="text-bluegray-500 hover:text-bluegray-700 ease-in duration-200 transition cursor-pointer text-sm bg-bluegray-100 rounded-md px-2 py-1 mr-3"
                >
                  <FontAwesomeIcon className="mr-0.5" icon={faHistory} />{" "}
                  History
                </p>
              ) : null}
              {state === "PENDING" ? (
                <p className="text-orange-500 text-sm bg-orange-100 rounded-md px-2 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faClock} /> Pending
                  verification
                </p>
              ) : null}
              {state === "NOT_STARTED" ? (
                <>
                  {step.history.length === 0 ? (
                    <p className="text-gray-500 text-sm bg-gray-100 rounded-md px-2 py-1">
                      <FontAwesomeIcon className="mr-0.5" icon={faDotCircle} />{" "}
                      Not started
                    </p>
                  ) : (
                    <p className="text-orange-500 text-sm bg-orange-100 rounded-md px-2 py-1">
                      <FontAwesomeIcon
                        className="mr-0.5"
                        icon={faCloudUploadAlt}
                      />{" "}
                      Resubmission requested
                    </p>
                  )}
                </>
              ) : null}
              {state === "REJECTED" ? (
                <p className="text-red-500 text-sm bg-red-100 rounded-md px-2 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faBan} /> Rejected
                </p>
              ) : null}
              {state === "FINAL_REJECTED" ? (
                <p className="text-red-500 text-sm bg-red-100 rounded-md px-2 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faBan} /> Final
                  Rejected
                </p>
              ) : null}
              {state === "VALIDATED" ? (
                <p className="text-green-500 text-sm bg-green-100 rounded-md px-2 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faCheck} /> Verified
                </p>
              ) : null}
            </div>
          </div>
        </div>
        {state !== "NOT_STARTED" ||
        (state === "NOT_STARTED" && step.history.length > 0) ? (
          <hr className="my-4 border-bluegray-200" />
        ) : null}
      </div>
    );
  }

  function InfoItem(props) {
    return (
      <div className="mt-2">
        <div className="flex border border-transparent py-1 px-1.5 rounded-xl">
          <div className="mr-3 mt-0.5 flex-shrink-0">
            <img className="h-9" src={`/img/icons/ui/${props.icon}.svg`}></img>
          </div>
          <div className="relative top-[2px]">
            <p className="text-bluegray-400 text-xs">{props.title}</p>
            <p
              className={`text-gray-600 text-sm ${
                props.capitalize ? "capitalize" : null
              }`}
            >
              {props.value}
            </p>
          </div>
        </div>
      </div>
    );
  }

  function CountryItem(props) {
    return (
      <div className="mt-2">
        <div className="flex border border-transparent py-1 px-1.5 rounded-xl">
          <div className="mr-3 flex-shrink-0">
            <div
              className="h-9 w-9"
              style={{
                backgroundImage: `url( ${`/img/icons/flags-rounded/${props.country}.svg`}), url(${"/img/icons/ui/icon-round.svg"})`,
                backgroundSize: "60%, contain",
                backgroundRepeat: "no-repeat, no-repeat",
                backgroundPosition: "center center, center center",
              }}
            ></div>
          </div>
          <div className="relative top-[2px]">
            <p className="text-bluegray-400 text-xs">{props.title}</p>
            <p className="text-gray-600 text-sm">{props.value}</p>
          </div>
        </div>
      </div>
    );
  }

  function ResidencyDocument({ step_id, info, member_id, document }) {
    return (
      <div>
        <div className="mt-3">
          <p className="mt-6 uppercase tracking-wide font-medium text-sm text-primary-400">
            Document Details
          </p>
          <div className="flex flex-wrap mt-3">
            <div className="w-4/6 pr-3" data-openreplay-obscured>
              <iframe
                className="w-full h-[728px]"
                src={document.document.file}
              ></iframe>
            </div>
            <div className="w-2/6 pl-3 mt-4" data-openreplay-obscured>
              <div>
                <InfoItem
                  icon={"icon-firstname"}
                  title="Firstname"
                  value={info.firstname ? info.firstname.toLowerCase() : "-"}
                />
              </div>
              <div>
                <InfoItem
                  capitalize
                  icon={"icon-lastname"}
                  title="Lastname"
                  value={info.lastname ? info.lastname.toLowerCase() : "-"}
                />
              </div>
              <div>
                <InfoItem
                  icon={"icon-address"}
                  title="Address"
                  value={info.address ? info.address : "-"}
                />
              </div>
              <div>
                <InfoItem
                  icon={"icon-city"}
                  title="City"
                  value={info.city ? info.city : "-"}
                />
              </div>
              <div>
                <InfoItem
                  icon={"icon-zipcode"}
                  title="Zip Code"
                  value={info.zipcode ? info.zipcode : "-"}
                />
              </div>
              <div>
                <CountryItem
                  title="Country"
                  value={info.country ? countries[info.country] : "-"}
                  country={info.country}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function ResidencyDocumentAccomodation({
    step_id,
    info,
    member_id,
    document,
  }) {
    if (document.certificate === undefined) {
      return <div></div>;
    }

    return (
      <div>
        <div className="mt-6">
          <p className="mt-6 mb-3 uppercase tracking-wide font-medium text-sm text-primary-400">
            Proof of accomodation
          </p>
          <div className="flex flex-wrap mt-3">
            <div className="w-4/6 pr-3" data-openreplay-obscured>
              <iframe
                className="w-full h-[728px]"
                src={document.certificate.file}
              ></iframe>
            </div>
            <div className="w-2/6 pl-3 mt-4">
              <div>
                <InfoItem
                  capitalize
                  icon={"icon-firstname"}
                  title="Firstname"
                  value={info.firstname ? info.firstname.toLowerCase() : "-"}
                />
              </div>
              <div>
                <InfoItem
                  capitalize
                  icon={"icon-lastname"}
                  title="Lastname"
                  value={info.lastname ? info.lastname.toLowerCase() : "-"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function IdentityDocument({ step_id, document, info }) {
    return (
      <div>
        <div className="mt-3">
          <p className="mt-6 uppercase tracking-wide font-medium text-sm text-primary-400">
            Host Identity
          </p>
          <div className="flex flex-wrap mt-3">
            <div className="w-1/5 pr-3">
              <div
                data-openreplay-obscured
                className="bg-bluegray-100 w-full h-[200px] px-2 rounded-md mt-3"
                style={{
                  backgroundImage: `url( ${`${document.face_file}`}`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                }}
              ></div>
            </div>
            <div className="w-4/5 pl-3 flex flex-wrap mt-4">
              <div className="w-1/3" data-openreplay-obscured>
                <InfoItem
                  capitalize
                  icon={"icon-firstname"}
                  title="Firstname"
                  value={
                    info.firstname.value
                      ? info.firstname.value.toLowerCase()
                      : "-"
                  }
                />
              </div>
              <div className="w-1/3" data-openreplay-obscured>
                <InfoItem
                  capitalize
                  icon={"icon-lastname"}
                  title="Lastname"
                  value={
                    info.lastname.value
                      ? info.lastname.value.toLowerCase()
                      : "-"
                  }
                />
              </div>
              <div className="w-1/3" data-openreplay-obscured>
                <InfoItem
                  icon={"icon-birthdate"}
                  title="Birth Date"
                  value={info.birth_date.value ? info.birth_date.value : "-"}
                />
              </div>
              <div className="w-1/3" data-openreplay-obscured>
                <CountryItem
                  title="Nationality"
                  value={
                    info.nationality.value
                      ? countries[info.nationality.value]
                      : "-"
                  }
                  country={info.nationality.value}
                />
              </div>
              <div className="w-1/3" data-openreplay-obscured>
                <CountryItem
                  title="Issuing Country"
                  value={countries[document.country]}
                  country={document.country}
                />
              </div>
              <div className="w-1/3" data-openreplay-obscured>
                <InfoItem
                  title="Document Number"
                  icon={"icon-document-number"}
                  value={
                    info.document_number.value
                      ? info.document_number.value
                      : "-"
                  }
                />
              </div>
              <div className="w-1/3" data-openreplay-obscured>
                <InfoItem
                  title="Document Expiration"
                  value={
                    info.document_expiration.value
                      ? info.document_expiration.value
                      : "-"
                  }
                  icon={"icon-recent"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div className="flex flex-wrap">
            <div
              className={`${
                document.type === "PASSPORT" ? "w-full" : "w-1/2 pr-3"
              }`}
            >
              <div className="flex flex-wrap items-center justify-between">
                <p className="uppercase tracking-wide font-medium text-sm text-primary-400">
                  {document.type === "PASSPORT"
                    ? "Passport page"
                    : "Front Document"}
                </p>
                <LinkMiniButton
                  target="_blank"
                  href={`${document.front_file}`}
                  rel="noreferrer"
                >
                  Download
                </LinkMiniButton>
              </div>

              <div
                className="bg-bluegray-100 w-full h-96 px-2 rounded-md mt-3"
                data-openreplay-obscured
              >
                <img
                  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                  className="w-full h-96"
                  style={{
                    backgroundImage: `url( ${document.front_file}`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                  }}
                />
              </div>
            </div>
            {document.type !== "PASSPORT" ? (
              <div className="w-1/2 pl-3">
                <div className="flex flex-wrap items-center justify-between">
                  <p className="uppercase tracking-wide font-medium text-sm text-primary-400">
                    Back Document
                  </p>
                  <LinkMiniButton
                    target="_blank"
                    href={`${document.back_file}`}
                    rel="noreferrer"
                  >
                    Download
                  </LinkMiniButton>
                </div>
                <div
                  className="bg-bluegray-100 w-full h-96 px-2 rounded-md mt-3"
                  data-openreplay-obscured
                >
                  <img
                    src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                    className="w-full h-96"
                    style={{
                      backgroundImage: `url( ${document.back_file}`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  function ResidencyDetails({ step, step_id, member_id }) {
    return (
      <div>
        <ResidencyDocument
          member_id={member_id}
          step_id={step_id}
          document={step.document}
          info={step.document.info}
        />
        {step.document.is_accomodation ? (
          <IdentityDocument
            member_id={member_id}
            step_id={step_id}
            document={step.document.accomodation.document}
            info={step.document.accomodation.details.info}
          />
        ) : null}
        {step.document.is_accomodation ? (
          <ResidencyDocumentAccomodation
            member_id={member_id}
            step_id={step_id}
            document={step.document}
            info={step.document.info}
          />
        ) : null}
      </div>
    );
  }

  function HistoryTitle({ state, title }) {
    return (
      <div>
        <div className="flex justify-between items-center">
          <div>
            <p className="font-medium text-primary-400">
              {title ? title : "ID Document"}
            </p>
          </div>
          <div>
            <div className="flex">
              {state === "PENDING" ? (
                <p className="text-orange-500 text-sm bg-orange-100 rounded-md px-2 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faClock} /> Pending
                  verification
                </p>
              ) : null}
              {state === "REJECTED" ? (
                <p className="text-orange-500 text-sm bg-orange-100 rounded-md px-2 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faCloudUploadAlt} />{" "}
                  Resubmission requested
                </p>
              ) : null}
              {state === "FINAL_REJECTED" ? (
                <p className="text-red-500 text-sm bg-red-100 rounded-md px-2 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faBan} /> Final
                  Rejected
                </p>
              ) : null}
              {state === "VALIDATED" ? (
                <p className="text-green-500 text-sm bg-green-100 rounded-md px-2 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faCheck} /> Verified
                </p>
              ) : null}
            </div>
          </div>
        </div>
        {state !== "NOT_STARTED" ? (
          <hr className="my-4 border-bluegray-200" />
        ) : null}
      </div>
    );
  }

  function ResidencyBaseDetails({ step, step_id, member_id }) {
    return (
      <div>
        {step.state === "REJECTED" ? (
          <div className="flex flex-wrap">
            <div className="w-1/2">
              <InfoItem
                icon={"icon-firstname"}
                title="Customer Reason"
                value={step.document.rejection.customer_reason}
              />
            </div>
            <div className="w-1/2">
              <InfoItem
                icon={"icon-lastname"}
                title="User Reason"
                value={step.document.rejection.user_reason}
              />
            </div>
          </div>
        ) : null}
        <div className="mt-5">
          <div className="flex flex-wrap mt-3">
            <div className="w-4/6 pr-3">
              <iframe
                className="w-full h-[728px]"
                src={step.document.document.file}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <IdentityTitle title={title} state={step.state} />
      {step.state === "VALIDATED" ? (
        <ResidencyDetails member_id={member_id} step_id={step_id} step={step} />
      ) : null}
      {step.state === "PENDING" ||
      step.state === "REJECTED" ||
      (step.state === "NOT_STARTED" && step.history.length > 0) ? (
        <ResidencyBaseDetails
          member_id={member_id}
          step_id={step_id}
          step={step}
        />
      ) : null}

      <Modal
        open={open}
        showCloseIcon={true}
        closeIcon={true}
        onClose={() => setOpen(false)}
        center
      >
        <div className="core-view-history p-6">
          {step.history.map((history) => {
            return (
              <div className="mt-4" key={0}>
                <HistoryTitle state={history.state} />
                {history.state !== "VALIDATED" ? (
                  <>
                    <div>
                      <InfoItem
                        icon={"icon-firstname"}
                        title="Customer Reason"
                        value={history.customer_reason}
                      />
                    </div>
                    <div>
                      <InfoItem
                        icon={"icon-lastname"}
                        title="User Reason"
                        value={history.user_reason}
                      />
                    </div>
                  </>
                ) : null}
                <div>
                  <InfoItem
                    capitalize
                    icon={"icon-timeout"}
                    title="Verified At"
                    value={history.verified_at}
                  />
                </div>
                <div>
                  <InfoItem
                    capitalize
                    icon={"icon-upload"}
                    title="Submitted At"
                    value={history.submitted_at}
                  />
                </div>
                <hr className="mt-4" />
              </div>
            );
          })}
        </div>
      </Modal>
    </div>
  );
}
