import { useParams } from 'react-router-dom'
import { useEffect, useState, useContext } from 'react'
import { AppContext } from '@/context/app'
import AppsService from '@/services/apps'

export default function AppGuard(props) {
	const [app, appDispatch] = useContext(AppContext)
	const [layout, setLayout] = useState(null)
	const { app_id } = useParams()

	useEffect(() => {
		GetInfo()
		return () => { }
	}, [])

	useEffect(() => {
		setLayout(props.children)
	}, [app])

	async function GetInfo() {
		if (app.ready === false) {
			await AppsService.GetInfo(app_id).then(async (data) => {
				await appDispatch({
					type: 'SET_APP',
					payload: data,
				})
			})
		}
	}


	return layout
}
