import { faTimes, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { faFileDownload, faFileUpload } from '@fortawesome/pro-duotone-svg-icons'
import { useEffect, useState } from 'react'
import Button from '@/components/ui/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from '@/components/ui/Alert'
import AppsService from '@/services/apps'
import { useParams } from 'react-router-dom'
import Tooltip from '@/components/ui/Tooltip'
import countries from '@/resources/countries_label.json'
import CountrySelector from '@/components/ui/CountrySelector'
import MiniButton from '@/components/ui/MiniButton'
import FileSaver from 'file-saver'
import { useFileReader } from '@/hooks/filereader'


export default function FlowRestrictedCountries(props) {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [ready, setReady] = useState(false)
    const [countryList, setCountryList] = useState([])
    const [restrictedCountries, setRestrictedCountries] = useState([])
    const [search, setSearch] = useState('')
    const [uploadKey, setUploadKey] = useState(0)
    const { app_id } = useParams()
    const [{ result }, setResult, setFile] = useFileReader({
        method: 'readAsText',
    })

    useEffect(() => {
        GetStepDetails()
        return () => { }
    }, [])

    useEffect(() => {
        let restrictedCountries = []

        Object.keys(countryList).map(function (country) {
            restrictedCountries.push({
                alpha3: country,
                name: countries[country]
            })
        })
        let orderedRestricted = restrictedCountries.sort(function (a, b) {
            var textA = a.name.toUpperCase()
            var textB = b.name.toUpperCase()
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
        })

        setRestrictedCountries(orderedRestricted)
    }, [countryList])

    function Export() {
        if (props.type === 'UBO_IDENTITY') {
            AppsService.GetCorporateIdentityRetrictedCountryExport(app_id, props.step_id).then(async (data) => {
                await download(`${props.step_id}_restricted_countries.txt`, data)
            }).catch((err) => {
                setError(err.message)
                setLoading(false)
            })
        } else if (props.type === 'UBO_RESIDENCY') {
            AppsService.GetCorporateResidencyRetrictedCountryExport(app_id, props.step_id).then(async (data) => {
                await download(`${props.step_id}_restricted_countries.txt`, data)
            }).catch((err) => {
                setError(err.message)
                setLoading(false)
            })
        } else {
            AppsService.GetRetrictedCountryExport(app_id, props.step_id).then(async (data) => {
                await download(`${props.step_id}_restricted_countries.txt`, data)
            }).catch((err) => {
                setError(err.message)
                setLoading(false)
            })
        }

    }

    function download(filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    const onInputFile = e => {
        setFile(e.target.files[0])
    }

    useEffect(() => {
        if (result) {
            importCountries(result)
            setResult(null)
            setFile(null)
            setUploadKey(uploadKey + 1)
        }
    }, [result])


    function Submit(country, documents) {
        setLoading(true)

        const body = {
            country: country,
            documents: documents,
        }

        if (props.type === 'CORPORATE' && props.subtype === 'BASIC') {
            AppsService.AddCorporateCountryRestriction(app_id, body).then(async () => {
                setLoading(false)
                props.onBack()
            }).catch((err) => {
                setError(err.message)
                setLoading(false)
            })
        } else if (props.type === 'UBO_IDENTITY') {
            if (country === '' || country == undefined) {
                props.onClose()
            } else {
                const mainBody = {
                    restrictions: [body]
                }

                AppsService.AddCorporateIdentityRestrictions(app_id, mainBody).then(async () => {
                    setReady(true)
                    GetStepDetails()
                    setLoading(false)
                }).catch((err) => {
                    setError(err.message)
                    setLoading(false)
                })
            }
        } else if (props.type === 'UBO_RESIDENCY') {
            if (country === '' || country == undefined) {
                props.onClose()
            } else {
                const mainBody = {
                    restrictions: [body]
                }
                AppsService.AddCorporateResidencyRestrictions(app_id, mainBody).then(async () => {
                    setReady(true)
                    GetStepDetails()
                    setLoading(false)
                }).catch((err) => {
                    setError(err.message)
                    setLoading(false)
                })
            }
        } else {
            const mainBody = {
                restrictions: [body]
            }

            AppsService.AddRestrictions(app_id, props.step_id, mainBody).then(async () => {
                setReady(true)
                GetStepDetails()
                setLoading(false)
            }).catch((err) => {
                setError(err.message)
                setLoading(false)
            })
        }
    }

    function GetStepDetails() {
        if (props.type === 'CORPORATE' || props.type === 'UBO_RESIDENCY' || props.type === 'UBO_IDENTITY') {
            setLoading(true)
            AppsService.GetCorporateCompliance(app_id).then((data) => {
                if (props.subtype === 'BASIC') {
                    setRestrictedCountriesToListCorporate(data.countries)
                } else if (props.type === 'UBO_IDENTITY') {
                    setCountryList(data.ubo_identity)
                } else if (props.type === 'UBO_RESIDENCY') {
                    setCountryList(data.ubo_residency)
                }
                setReady(true)
                setLoading(false)
            }).catch((err) => {
                setError(err.message)
                setLoading(false)
            })
        } else {
            setLoading(true)
            AppsService.GetStepDetails(app_id, props.step_id).then((data) => {
                setCountryList(data.setting.restrictions)
                setReady(true)
                setLoading(false)
            }).catch((err) => {
                setError(err.message)
                setLoading(false)
            })

        }
    }

    function setRestrictedCountriesToListCorporate(d) {
        if (!ready) {
            setCountryList(d)
        }
    }

    function removeCountry(newCountry) {
        Object.keys(countryList).map(function (country) {
            if (country === newCountry.alpha3) {
                Submit(newCountry.alpha3, [])
            }
        })
    }

    function getFile() {
        document.getElementById('import-file').click()
    }

    function addNewCountry(newCountry) {
        Object.keys(countryList).map(function (country) {
            if (country === newCountry.alpha3) {
                return
            }
        })
        let documents = []
        if (props.type === 'IDENTITY' || props.type === 'UBO_IDENTITY') {
            documents = ['PASSPORT', 'NATIONAL_ID', 'DRIVER_LICENSE', 'RESIDENT_PERMIT']
        }

        if (props.type === 'RESIDENCY' || props.type === 'UBO_RESIDENCY') {
            documents = ['GAS_BILL', 'WATER_BILL', 'INTERNET_BILL', 'ELECTRICITY_BILL', 'LANDLINE_BILL', 'BANK_STATEMENT']
        }

        setSearch('')
        Submit(newCountry.alpha3, documents)
    }

    function Delete() {
        AppsService.DeleteStep(app_id, props.step_id).then(() => {
            props.onClose()
        }).catch((err) => {
            setError(err.message)
        })
    }

    async function importCountries(data) {
        setLoading(true)

        const body = {
            restrictions: []
        }

        const importedCountries = data.split(',')
        for await (const restriction of importedCountries) {
            const retriction_list = restriction.split('_')
            const newCountry = retriction_list[0]

            let documents = []
            let index = 0
            retriction_list.map((document) => {
                if (index > 0) {
                    if (document === 'ID') {
                        documents.push('NATIONAL_ID')
                    }
                    if (document === 'PP') {
                        documents.push('PASSPORT')
                    }
                    if (document === 'DL') {
                        documents.push('DRIVER_LICENSE')
                    }
                    if (document === 'RP') {
                        documents.push('RESIDENT_PERMIT')
                    }
                    if (document === 'EB') {
                        documents.push('ELECTRICITY_BILL')
                    }
                    if (document === 'IB') {
                        documents.push('INTERNET_BILL')
                    }
                    if (document === 'LB') {
                        documents.push('LANDLINE_BILL')
                    }
                    if (document === 'WB') {
                        documents.push('WATER_BILL')
                    }
                    if (document === 'GB') {
                        documents.push('GAS_BILL')
                    }
                    if (document === 'BS') {
                        documents.push('BANK_STATEMENT')
                    }
                }
                index = index + 1
            })

            let restrictions = {
                country: newCountry,
                documents: documents,
            }

            body.restrictions.push(restrictions)
        }

        if (props.type === 'UBO_IDENTITY') {
            AppsService.AddCorporateIdentityRestrictions(app_id, body).then(async () => {
                props.onClose()
            }).catch((err) => {
                setError(err.message)
            })
        } else if (props.type === 'UBO_RESIDENCY') {
            AppsService.AddCorporateResidencyRestrictions(app_id, body).then(async () => {
                props.onClose()
            }).catch((err) => {
                setError(err.message)
            })
        } else {
            AppsService.AddRestrictions(app_id, props.step_id, body).then(async () => {
                props.onClose()
            }).catch((err) => {
                setError(err.message)
            })
        }
    }
    return (
        <div className="modal-view">
            <div className="z-max w-full h-full flex items-center justify-center">
                <div className="flex flex-col w-full h-full rounded">
                    <header className="p-5 pb-1">
                        <div className="flex justify-between items-center">
                            {props.type === 'IDENTITY' ? <div className="flex items-center">
                                <div>
                                    <img className="h-9 relative mr-2" src="/img/icons/ui/passport.svg" />
                                </div>
                                <div>
                                    <h2 className="text-lg text-primary-500  font-medium">ID Verification</h2>
                                </div>
                            </div> : null}
                            {props.type === 'CORPORATE' ? <div className="flex items-center">
                                <div>
                                    <img className="h-6 relative top-0.5 mr-2" src="/img/icons/ui/icon-corporate-only.svg" />
                                </div>
                                <div>
                                    <h2 className="text-xl text-primary-500  font-medium"> Corporate & Sub-Corporate
                                    </h2>
                                </div>
                            </div> : null}
                            {props.type === 'UBO_IDENTITY' ? <div className="flex items-center">
                                <div>
                                    <img className="h-6 relative top-0.5 mr-2" src="/img/icons/ui/icon-ubo-only.svg" />
                                </div>
                                <div>
                                    <h2 className="text-xl text-primary-500  font-medium"> Ultimate Beneficial Owners Identity
                                    </h2>
                                </div>
                            </div> : null}
                            {props.type === 'UBO_RESIDENCY' ? <div className="flex items-center">
                                <div>
                                    <img className="h-6 relative top-0.5 mr-2" src="/img/icons/ui/icon-ubo-only.svg" />
                                </div>
                                <div>
                                    <h2 className="text-xl text-primary-500  font-medium"> Ultimate Beneficial Owners Residency
                                    </h2>
                                </div>
                            </div> : null}
                            {props.type === 'RESIDENCY' ? <div className="flex items-center">
                                <div>
                                    <img className="h-6 relative top-0.5 mr-2" src="/img/icons/ui/icon-utility-bill.svg" />
                                </div>
                                <div>
                                    <h2 className="text-xl text-primary-500  font-medium"> Proof of residency
                                    </h2>
                                </div>
                            </div> : null}
                            {props.type === 'PHONE' ? <div className="flex items-center">
                                <div>
                                    <img className="h-9 relative mr-2" src="/img/icons/ui/icon-call.svg" />
                                </div>
                                <div>
                                    <h2 className="text-lg text-primary-500 font-medium"> Phone verification
                                    </h2>
                                </div>
                            </div> : null}
                            <div>
                                <div className="flex items-center">
                                    {props.step_id ? <div>
                                        <Tooltip auto={true} content="Step ID">
                                            <span className="relative top-[-1px] text-xs bg-bluegray-200  px-2 py-0.5 rounded-md text-gray-500">{props.step_id}</span>
                                        </Tooltip>
                                    </div>
                                        : null}
                                    <div>
                                        <h2 className="text-xl relative ml-2"><span><FontAwesomeIcon onClick={() => props.onClose()} className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-200 ease-in" icon={faTimes}></FontAwesomeIcon></span></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="my-3" />
                    </header>

                    <main
                        className="flex-1 overflow-y-auto p-5 py-0 flow-body pt-2"
                        id="individual-main">
                        {ready ?
                            <>
                                <div>
                                    {error ? (
                                        <Alert type="error" title={error}></Alert>
                                    ) : null}
                                </div>
                                <div className="flex flex-wrap">
                                    <div className="w-full">
                                        <div>
                                            <div className="flex justify-between">
                                                <div>
                                                    <p className="text-sm font-bold text-primary-700">Restricted countries</p>
                                                    <p className="text-sm text-gray-600">Add forbidden countries</p>
                                                </div>
                                                <div>
                                                    <div className="flex">
                                                        <div className="mr-2">
                                                            <label className="custom-file-upload">
                                                                <input key={uploadKey} className="hidden" type="file" id="import-file" onChange={onInputFile} />
                                                                <MiniButton icon={faFileDownload} onClick={() => getFile()} theme="secondary" label="Import"></MiniButton>
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <MiniButton onClick={() => Export()} icon={faFileUpload} theme="secondary" label="Export"></MiniButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mt-2">
                                                    <CountrySelector placeholder="Add country" value={search} setValue={(value) => setSearch(value)} setSuggestion={(value) => addNewCountry(value)} />
                                                </div>
                                                {restrictedCountries.length === 0 ? <p className="text-center mt-3 text-gray-400 text-sm font-medium">No restricted countries</p> : null}
                                                {restrictedCountries.map((country) => {
                                                    return <div key={country.alpha3} className={props.type === 'PHONE' ? 'mt-4' : 'cursor-pointer w-full mt-4 transition-25 transition duration-200 ease-in'}>
                                                        <div className="flex flex-wrap justify-between border hover:border-primary-200 ease-in transition-25 duration-200 p-2 rounded-xl">
                                                            {props.type === 'PHONE' ?
                                                                <div className={'flex flex-1'}>
                                                                    <div className="flex items-center ml-2">
                                                                        <div>
                                                                            <img className="relative h-4 top-[-1px] rounded-sm mr-2" src={`/img/icons/flags/${country.alpha3}.svg`}></img>
                                                                        </div>
                                                                        <div>
                                                                            <p key={country.alpha3}>{country.name}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div onClick={() => props.managedDocuments(country)} className={'flex flex-1'}>
                                                                    <div className="flex items-center ml-2">
                                                                        <div>
                                                                            <img className="relative h-4 top-[-1px] rounded-sm mr-2" src={`/img/icons/flags/${country.alpha3}.svg`}></img>
                                                                        </div>
                                                                        <div>
                                                                            <p key={country.alpha3}>{country.name}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>}
                                                            <div className="flex mr-2">
                                                                {props.type === 'IDENTITY' || props.type === 'UBO_IDENTITY' ?
                                                                    <div>
                                                                        <div className="flex mr-5">
                                                                            <div>
                                                                                <Tooltip auto={true} content="Passport">
                                                                                    <img className={`h-8 mr-3 ${countryList[country.alpha3] != undefined && countryList[country.alpha3].includes('PASSPORT') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-passport.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div>
                                                                                <Tooltip auto={true} content="National ID">
                                                                                    <img className={`h-8 mr-3 ${countryList[country.alpha3] != undefined && countryList[country.alpha3].includes('NATIONAL_ID') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-national-id.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div>
                                                                                <Tooltip auto={true} content="Driver License">
                                                                                    <img className={`h-8 mr-3 ${countryList[country.alpha3] != undefined && countryList[country.alpha3].includes('DRIVER_LICENSE') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-driver-license.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div>
                                                                                <Tooltip auto={true} content="Resident Permit">
                                                                                    <img className={`h-8 ${countryList[country.alpha3] != undefined && countryList[country.alpha3].includes('RESIDENT_PERMIT') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-residence-permit.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : null}
                                                                {props.type === 'RESIDENCY' || props.type === 'UBO_RESIDENCY' ?
                                                                    <div>
                                                                        <div className="flex mr-5">
                                                                            <div>
                                                                                <Tooltip auto={true} content="Gas Bill">
                                                                                    <img className={`h-8 mr-3 ${countryList[country.alpha3] != undefined && countryList[country.alpha3].includes('GAS_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-gas-bill.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div>
                                                                                <Tooltip auto={true} content="Water Bill">
                                                                                    <img className={`h-8 mr-3 ${countryList[country.alpha3] != undefined && countryList[country.alpha3].includes('WATER_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-water-bill.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div>
                                                                                <Tooltip auto={true} content="Internet Bill">
                                                                                    <img className={`h-8 mr-3 ${countryList[country.alpha3] != undefined && countryList[country.alpha3].includes('INTERNET_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-internet-bill.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div>
                                                                                <Tooltip auto={true} content="Electricity Bill">
                                                                                    <img className={`h-8 mr-3 ${countryList[country.alpha3] != undefined && countryList[country.alpha3].includes('ELECTRICITY_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-electricity-bill.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div>
                                                                                <Tooltip auto={true} content="Landline Bill">
                                                                                    <img className={`h-8 mr-3 ${countryList[country.alpha3] != undefined && countryList[country.alpha3].includes('LANDLINE_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-landline-bill.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div>
                                                                                <Tooltip auto={true} content="Bank Statement">
                                                                                    <img className={`h-8 ${countryList[country.alpha3] != undefined && countryList[country.alpha3].includes('BANK_STATEMENT') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-bank-statement.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : null}
                                                                <Tooltip auto={true} content="Remove">
                                                                    <span className="relative top-1" onClick={() => removeCountry(country)}>
                                                                        <FontAwesomeIcon className="text-sm cursor-pointer text-gray-300 hover:text-red-500 transition duration-200 ease-in" icon={faTrash} />
                                                                    </span>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </> : null}
                    </main>
                    <footer className="p-5">
                        <div className={'flex justify-between flex-wrap'}>
                            {props.type === 'CORPORATE' || props.type === 'UBO_IDENTITY' || props.type === 'UBO_RESIDENCY' ?
                                <>
                                    <div className="w-1/3 pr-1.5">
                                        <Button
                                            full
                                            loading={loading}
                                            theme="secondary"
                                            label={'Back'}
                                            onClick={() => props.onBack()}></Button>
                                    </div>
                                    <div className="w-2/3 pl-1.5">
                                        <Button
                                            full
                                            loading={loading}
                                            theme="success"
                                            label={'Update'}
                                            onClick={() => Submit()}></Button>
                                    </div>
                                </>
                                :
                                <div className="w-full pr-1.5">
                                    <p onClick={() => Delete()} className="text-center text-gray-400 hover:text-red-500 transition duration-200 ease-in cursor-pointer"><FontAwesomeIcon className="mr-1" icon={faTrash} /> Remove step</p>
                                </div>
                            }
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    )
}
