import { useContext, useEffect, useState } from "react";
import { faCheck, faEye, faList } from "@fortawesome/pro-solid-svg-icons";
import Footer from "@/components/menu/Footer";
import SubNav from "@/components/menu/SubNav";
import { AppContext } from "@/context/app";
import { useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MenuItem from "@/components/menu/MenuItem";
import SessionsTaskItem from "@/components/sessions/SessionsTaskItem";
import AppsService from "@/services/apps";
import Tooltip from "@/components/ui/Tooltip";
import Moment from "react-moment";

import {
  faBrowser,
  faCode,
  faCoins,
  faLayerGroup,
  faTasks,
  faUniversity,
  faUserFriends,
  faUsers,
  faCog,
  faBook,
} from "@fortawesome/pro-duotone-svg-icons";

export default function AppDashboard() {
  const [app] = useContext(AppContext);
  const router = useHistory();
  const [ready, setReady] = useState(false);
  const [data, setData] = useState(null);
  const { app_id } = useParams();

  useEffect(() => {
    GetDashboardInsights();
  }, []);

  async function GetDashboardInsights() {
    await AppsService.GetDashboardInsights(app_id)
      .then((data) => {
        setData(data);
        setReady(true);
      })
      .catch(() => {});
  }

  function Title({ value }) {
    return (
      <div className="w-full">
        <div className="p-3 py-2">
          <h3 className="uppercase tracking-wide font-semibold text-sm text-gray-900">
            {value}
          </h3>
        </div>
      </div>
    );
  }

  function Menu() {
    return (
      <div className="flex flex-wrap items-stretch">
        <Title value="Menu" />

        <div className="w-1/3">
          <MenuItem
            label="Dashboard"
            path={`/app/${app.id}`}
            theme="primary"
            icon={faBrowser}
          ></MenuItem>
        </div>

        {app.type === "CORPORATE" ? (
          <div className="w-1/3">
            <MenuItem
              label="Corporates"
              path={`/app/${app.id}/sessions`}
              theme="primary"
              icon={faUniversity}
            ></MenuItem>
          </div>
        ) : (
          <div className="w-1/3">
            <MenuItem
              label="Users"
              path={`/app/${app.id}/sessions`}
              theme="primary"
              icon={faUsers}
            ></MenuItem>
          </div>
        )}

        <div className="w-1/3">
          <MenuItem
            disabled={!app.iam.owner && !app.iam.admin && !app.iam.compliance}
            label="Flow"
            path={`/app/${app.id}/flow`}
            theme="primary"
            icon={faLayerGroup}
          ></MenuItem>
        </div>

        <div className="w-1/3">
          <MenuItem
            disabled={!app.iam.owner && !app.iam.admin && !app.iam.compliance}
            label="Tasks"
            path={`/app/${app.id}/tasks`}
            theme="primary"
            icon={faTasks}
          ></MenuItem>
        </div>

        <div className="w-1/3">
          <MenuItem
            disabled={!app.iam.owner && !app.iam.admin && !app.iam.developer}
            label="Developer"
            path={`/app/${app.id}/developer`}
            theme="primary"
            icon={faCode}
          ></MenuItem>
        </div>

        <div className="w-1/3">
          <MenuItem
            label="Team"
            path={`/app/${app.id}/team`}
            theme="primary"
            icon={faUserFriends}
          ></MenuItem>
        </div>

        <div className="w-1/3">
          <MenuItem
            label="Logs"
            path={`/app/${app.id}/logs`}
            theme="primary"
            icon={faBook}
          ></MenuItem>
        </div>
      </div>
    );
  }

  function Sessions({ data }) {
    return (
      <div>
        <div className="flex justify-between items-center">
          <div>
            <Title value="Sessions" />
          </div>
          <div className="p-3 py-2">
            <p
              className="p-1 px-2 rounded-md bg-bluegray-100 hover:bg-bluegray-200 ease-in transition-25 duration-200 cursor-pointer text-xs"
              onClick={() => (window.location.href = `/app/${app_id}/sessions`)}
            >
              <FontAwesomeIcon
                className="text-bluegray-600 mr-0.5"
                icon={faEye}
              />{" "}
              View all {data.app_type === "WORKFLOW" ? "users" : "corporates"}
            </p>
          </div>
        </div>
        <div className="flex mt-2">
          <div className="w-1/3 px-3">
            <div className="bg-orange-50 p-2 py-3 rounded-xl">
              <div className="flex items-center">
                <div>
                  <FontAwesomeIcon
                    className="mx-4 my-2.5 text-3xl text-orange-500"
                    icon={faLayerGroup}
                  />
                </div>
                <div>
                  <p className="text-xs">
                    <span className="uppercase font-semibold text-orange-500 text-xs">
                      Started
                    </span>
                  </p>
                  <p className="text-2xl font-bold text-gray-700">
                    {data.sessions.opened_identity}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-1/3 px-3">
            <div className="bg-green-50 p-2 py-3 rounded-xl">
              <div className="flex items-center">
                <div>
                  <FontAwesomeIcon
                    className="mx-4 my-2.5 text-3xl text-green-500"
                    icon={faCheck}
                  />
                </div>
                <div>
                  <p className="text-xs">
                    <span className="uppercase font-semibold text-green-500 text-xs">
                      Finished
                    </span>
                  </p>
                  <p className="text-2xl font-bold text-gray-700">
                    {data.sessions.finished}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-1/3 px-3">
            <div className="bg-bluegray-50 p-2 py-3 rounded-xl">
              <div className="flex items-center">
                <div>
                  <FontAwesomeIcon
                    className="mx-4 my-2.5 text-3xl text-bluegray-500"
                    icon={faList}
                  />
                </div>
                <div>
                  <p className="text-xs">
                    <span className="uppercase font-semibold text-bluegray-500 text-xs">
                      Total
                    </span>
                  </p>
                  <p className="text-2xl font-bold text-gray-700">
                    {data.sessions.total}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-3 mt-3">
          {data.sessions.last_sessions === null ||
          data.sessions.last_sessions.length === 0 ? (
            <div className="w-full mb-3 p-3 px-2 rounded-xl border bg-white transition-25 transition duration-200 ease-in">
              <div className="p-6 mt-4 mx-auto">
                <img
                  className="mx-auto h-36"
                  src="/img/illustrations/empty.svg"
                ></img>
                <p className="p-6 text-center text-gray-700 mt-3 mx-auto">
                  No sessions
                </p>
              </div>
            </div>
          ) : (
            <div>
              {data.sessions.last_sessions.map((session) => {
                return (
                  <SessionsTaskItem
                    key={session.session_id}
                    item={session}
                  ></SessionsTaskItem>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }

  function FlowItem({ item, step_id }) {
    return (
      <div>
        <div className="flex flex-wrap">
          {item.step === "LIVENESS" ? (
            <>
              <div className="w-full mb-4">
                <div
                  className={
                    "flex flex-1 items-center border border-gray-200 p-2 rounded-xl ease-in transition duration-100"
                  }
                >
                  <div>
                    <img src="/img/icons/ui/look-in-camera.svg"></img>
                  </div>
                  <div className="ml-3 w-full">
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="font-medium text-primary-700 text-sm">
                          Liveness
                        </p>
                        <p className="text-gray-500 text-xs">
                          Genuine Assurance Presence
                        </p>
                      </div>
                      <div>
                        <Tooltip auto={true} content="Step ID">
                          <span className="relative top-[-1px] text-xs bg-bluegray-200 mr-1 px-2 py-0.5 rounded-md text-gray-500">
                            {step_id}
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {item.step === "IDENTITY" ? (
            <>
              <div className="w-full mb-4">
                <div
                  className={
                    "flex flex-1 items-center border border-gray-200 p-2 rounded-xl ease-in transition duration-100"
                  }
                >
                  <div>
                    <img src="/img/icons/ui/passport.svg"></img>
                  </div>
                  <div className="ml-3 w-full">
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="font-medium text-primary-700 text-sm">
                          ID Verification
                        </p>
                        <p className="text-gray-500 text-xs">
                          Verify ID documents
                        </p>{" "}
                      </div>
                      <div>
                        <Tooltip auto={true} content="Step ID">
                          <span className="relative top-[-1px] text-xs bg-bluegray-200 mr-1 px-2 py-0.5 rounded-md text-gray-500">
                            {step_id}
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {item.step === "RESIDENCY" ? (
            <>
              <div className="w-full mb-4">
                <div
                  className={
                    "flex flex-1 items-center  border border-gray-200  p-2 rounded-xl ease-in transition duration-100"
                  }
                >
                  <div>
                    <img src="/img/icons/ui/icon-utility-bill.svg"></img>
                  </div>
                  <div className="ml-3 w-full">
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="font-medium text-primary-700 text-sm">
                          Proof of residency
                        </p>
                        <p className="text-gray-500 text-xs">
                          Address verification for individuals
                        </p>
                      </div>
                      <div>
                        <Tooltip auto={true} content="Step ID">
                          <span className="relative top-[-1px] text-xs bg-bluegray-200 mr-1 px-2 py-0.5 rounded-md text-gray-500">
                            {step_id}
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {item.step === "PHONE" ? (
            <>
              <div className="w-full mb-4">
                <div
                  className={
                    "flex flex-1 items-center border border-gray-200  p-2 rounded-xl ease-in transition duration-100"
                  }
                >
                  <div>
                    <img src="/img/icons/ui/icon-call.svg"></img>
                  </div>
                  <div className="ml-3 w-full">
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="font-medium text-primary-700 text-sm">
                          Phone
                        </p>
                        <p className="text-gray-500 text-xs">
                          Mobile phone verification at scale
                        </p>
                      </div>
                      <div>
                        <Tooltip auto={true} content="Step ID">
                          <span className="relative top-[-1px] text-xs bg-bluegray-200 mr-1 px-2 py-0.5 rounded-md text-gray-500">
                            {step_id}
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {item.step === "AML" ? (
            <>
              <div className="w-full mb-4">
                <div
                  className={
                    "flex flex-1 items-center border border-gray-200  p-2 rounded-xl ease-in transition duration-100"
                  }
                >
                  <div>
                    <img src="/img/icons/ui/icon-aml-synaps.svg"></img>
                  </div>
                  <div className="ml-3 w-full">
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="font-medium text-sm text-primary-700">
                          AML Screening
                        </p>
                        <p className="text-gray-500 text-xs">
                          Anti-Money Laundering
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {item.step === "NOTIFICATION" ? (
            <>
              <div className="w-full mb-4">
                <div
                  className={
                    "flex flex-1 items-center border border-gray-200  p-2 rounded-xl ease-in transition duration-100"
                  }
                >
                  <div>
                    <img src="/img/icons/ui/icon-notification-bell.svg"></img>
                  </div>
                  <div className="ml-3 w-full">
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="font-medium text-sm text-primary-700">
                          Notification
                        </p>
                        <p className="text-gray-500 text-xs">
                          Notify users of their verification status
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  }

  function FlowNewItem() {
    return (
      <div
        className="w-full mb-4"
        onClick={() => (window.location.href = `/app/${app_id}/flow`)}
      >
        <div
          className={
            "flex flex-1 items-center  border border-gray-200  p-2 hover:border-gray-400 cursor-pointer rounded-xl ease-in transition duration-100"
          }
        >
          <div>
            <img src="/img/icons/ui/icon-corporate-plus.svg"></img>
          </div>
          <div className="ml-3 w-full">
            <div className="flex justify-between items-center">
              <div>
                <p className="font-medium text-primary-700 text-sm">Create</p>
                <p className="text-gray-500 text-xs">your first step</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function Flow({ data }) {
    return (
      <div>
        <div className="flex justify-between items-center">
          <div>
            <Title value="Verification Flow" />
          </div>
          <div className="p-3 py-2">
            {app.iam.owner || app.iam.admin || app.iam.compliance ? (
              <p
                className="p-1 px-2 rounded-md bg-bluegray-100 hover:bg-bluegray-200 ease-in transition-25 duration-200 cursor-pointer text-xs"
                onClick={() => (window.location.href = `/app/${app_id}/flow`)}
              >
                <FontAwesomeIcon
                  className="text-bluegray-600 mr-0.5"
                  icon={faCog}
                />{" "}
                Manage
              </p>
            ) : null}
          </div>
        </div>
        <div className="px-3 pt-2">
          {Object.keys(data.flow.steps).map(function (key) {
            return (
              <>
                <FlowItem item={data.flow.steps[key]} step_id={key} key={key} />
              </>
            );
          })}
          {data.flow.aml.activated ? <FlowItem item={{ step: "AML" }} /> : null}
          {data.flow.notification.active ? (
            <FlowItem item={{ step: "NOTIFICATION" }} />
          ) : null}
          {data.flow.nb_steps === 0 && data.flow.aml.activated === false ? (
            <FlowNewItem />
          ) : null}
        </div>
      </div>
    );
  }

  function Team({ data }) {
    return (
      <div>
        <div className="flex justify-between items-center">
          <div>
            <Title value="Team" />
          </div>
          <div className="p-3 py-2">
            <p
              className="p-1 px-2 rounded-md bg-bluegray-100 hover:bg-bluegray-200 ease-in transition-25 duration-200 cursor-pointer text-xs"
              onClick={() => (window.location.href = `/app/${app_id}/team`)}
            >
              <FontAwesomeIcon
                className="text-bluegray-600 mr-0.5"
                icon={faCog}
              />{" "}
              Manage
            </p>
          </div>
        </div>

        <div className="flex flex-wrap p-3 mt-0 pt-2">
          {data.team.map((member) => {
            return (
              <div className="w-full mb-4" key={member.creation_date}>
                <div
                  className={
                    "flex flex-1 items-center border border-gray-200 p-2 rounded-xl ease-in transition duration-100"
                  }
                >
                  <div>
                    <img src="/img/icons/ui/icon-individual-2.svg"></img>
                  </div>
                  <div className="ml-3 w-full">
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="font-medium text-primary-700 text-sm">
                          {member.firstname} {member.lastname}
                        </p>
                        <p className="text-gray-500 text-xs">Member</p>
                      </div>
                      <div>
                        <div className="flex flex-wrap items-center justify-center">
                          {member.roles.map((role) => {
                            return (
                              <>
                                <div>
                                  {role === "OWNER" ? (
                                    <Tooltip auto={true} content={"Owner"}>
                                      <span>
                                        <img
                                          className="mx-1 w-6 h-6"
                                          src="/img/icons/ui/icon-admin.svg"
                                        />
                                      </span>
                                    </Tooltip>
                                  ) : null}
                                </div>
                                <div>
                                  {role === "ADMIN" ? (
                                    <Tooltip auto={true} content={"Admin"}>
                                      <span>
                                        <img
                                          className="mx-1 w-6 h-6"
                                          src="/img/icons/ui/icon-admin.svg"
                                        />
                                      </span>
                                    </Tooltip>
                                  ) : null}
                                </div>
                                <div>
                                  {role === "DEVELOPER" ? (
                                    <Tooltip auto={true} content={"Developer"}>
                                      <span>
                                        <img
                                          className="mx-1 w-6 h-6"
                                          src="/img/icons/ui/icon-developer.svg"
                                        />
                                      </span>
                                    </Tooltip>
                                  ) : null}
                                </div>
                                <div>
                                  {role === "COMPLIANCE" ? (
                                    <Tooltip
                                      auto={true}
                                      content={"Compliance Officer"}
                                    >
                                      <span>
                                        <img
                                          className="mx-1 w-6 h-6"
                                          src="/img/icons/ui/icon-compliance-officer.svg"
                                        />
                                      </span>
                                    </Tooltip>
                                  ) : null}
                                </div>
                                <div>
                                  {role === "BILLING" ? (
                                    <Tooltip auto={true} content={"Accountant"}>
                                      <span>
                                        <img
                                          className="mx-1 w-6 h-6"
                                          src="/img/icons/ui/icon-accountant.svg"
                                        />
                                      </span>
                                    </Tooltip>
                                  ) : null}
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  function Tasks({ data }) {
    return (
      <div>
        <div className="flex justify-between items-center">
          <div>
            <Title value="Tasks" />
          </div>
          <div className="p-3 py-2">
            <p
              className="p-1 px-2 rounded-md bg-bluegray-100 hover:bg-bluegray-200 ease-in transition-25 duration-200 cursor-pointer text-xs"
              onClick={() => (window.location.href = `/app/${app_id}/tasks`)}
            >
              <FontAwesomeIcon
                className="text-bluegray-600 mr-0.5"
                icon={faCog}
              />{" "}
              Manage
            </p>
          </div>
        </div>

        <div className="flex flex-wrap mt-2">
          {data.manager_tasks.slice(0, 5).map((task) => {
            return (
              <div
                className="w-full px-3 mb-4"
                key={task.creation_date}
                onClick={() =>
                  (window.location.href = `/app/${app_id}/${
                    app.type === "WORKFLOW" ? "individual" : "corporate"
                  }/${task.session_id}`)
                }
              >
                <div
                  className={
                    "hover:border-gray-400 transition-25 ease-in duration-200 cursor-pointer flex flex-1 items-center border border-gray-200 p-2 rounded-xl ease-in transition duration-100"
                  }
                >
                  <div>
                    <img src="/img/icons/ui/icon-decision-aml.svg"></img>
                  </div>
                  <div className="ml-3 w-full">
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="font-medium text-primary-700 text-sm">
                          {task.session_id}
                        </p>
                        <p className="text-gray-500 text-xs">
                          <Moment unix fromNow>
                            {task.creation_date / 1000}
                          </Moment>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {data.manager_tasks.length === 0 ? (
            <div className="w-full mb-3 p-3 px-2 rounded-xl bg-white transition-25 transition duration-200 ease-in">
              <div className="p-6 mt-4 mx-auto">
                <img
                  className="mx-auto h-36"
                  src="/img/illustrations/empty.svg"
                ></img>
                <p className="p-6 text-center text-gray-700 mt-3 mx-auto">
                  No tasks
                </p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  function TasksSandbox({ data }) {
    return (
      <div>
        <div className="flex justify-between items-center">
          <div>
            <Title value="Sandbox Tasks" />
          </div>
          <div className="p-3 py-2">
            <p
              className="p-1 px-2 rounded-md bg-bluegray-100 hover:bg-bluegray-200 ease-in transition-25 duration-200 cursor-pointer text-xs"
              onClick={() => (window.location.href = `/app/${app_id}/tasks`)}
            >
              <FontAwesomeIcon
                className="text-bluegray-600 mr-0.5"
                icon={faCog}
              />{" "}
              Manage
            </p>
          </div>
        </div>

        <div className="flex flex-wrap mt-2">
          {data.manager_tasks.slice(0, 5).map((task) => {
            return (
              <div
                className="w-full px-3 mb-4"
                key={task.creation_date}
                onClick={() =>
                  (window.location.href = `/app/${app_id}/sandbox`)
                }
              >
                <div
                  className={
                    "hover:border-gray-400 transition-25 ease-in duration-200 cursor-pointer flex flex-1 items-center border border-gray-200 p-2 rounded-xl ease-in transition duration-100"
                  }
                >
                  <div>
                    <img src="/img/icons/ui/icon-corporate-individual-identity.svg"></img>
                  </div>
                  <div className="ml-3 w-full">
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="font-medium text-primary-700 text-sm">
                          {task.session_id}
                        </p>
                        <p className="text-gray-500 text-xs">
                          <Moment unix fromNow>
                            {task.creation_date / 1000}
                          </Moment>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {data.manager_tasks.length === 0 ? (
            <div className="w-full mb-3 p-3 px-2 rounded-xl bg-white transition-25 transition duration-200 ease-in">
              <div className="p-6 mt-4 mx-auto">
                <img
                  className="mx-auto h-36"
                  src="/img/illustrations/empty.svg"
                ></img>
                <p className="p-6 text-center text-gray-700 mt-3 mx-auto">
                  No tasks
                </p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div>
      <div>
        <SubNav></SubNav>
        <div className="max-w-7xl mx-auto relative">
          <div className="dashboard-container px-3">
            <div className="flex justify-between mt-3">
              <div>
                <div className="flex">
                  <div
                    className="cursor-pointer"
                    onClick={() => (window.location.href = `/app/${app_id}`)}
                  >
                    <img
                      className="w-16 h-16 mt-1 rounded-xl"
                      src={app.logo_url}
                    />
                  </div>
                  <div className="ml-5">
                    <h1 className="text-3xl text-white">
                      <span className="font-bold">Dashboard</span>
                    </h1>
                    <h3 className="text-lg mt-2 text-bluegray-300">
                      Welcome to your all-in-one compliance platform
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {ready ? (
          <>
            <div className="max-w-7xl mx-auto relative mt-3">
              <div className="flex items-stretch h-full">
                <div className="w-2/3 p-3">
                  <div className="bg-white h-full rounded-xl p-4 shadow-sm">
                    <Sessions data={data} />
                  </div>
                </div>
                <div className="w-1/3 p-3">
                  <div className="bg-white h-full rounded-xl p-4 shadow-sm">
                    <Menu />
                  </div>
                </div>
              </div>
              <div className="flex items-stretch h-full">
                <div className="w-1/3 p-3">
                  <div className="bg-white h-full rounded-xl p-4 shadow-sm">
                    <Flow app={app} data={data} />
                  </div>
                </div>
                <div className="w-1/3 p-3">
                  <div className="bg-white h-full rounded-xl p-4 shadow-sm">
                    <Team data={data} />
                  </div>
                </div>
                <div className="w-1/3 p-3">
                  <div className="bg-white h-full rounded-xl p-4 shadow-sm">
                    {data.manager_tasks_type === "COMPLIANCE" ? (
                      <Tasks data={data} />
                    ) : null}
                    {data.manager_tasks_type === "DEVELOPER" ? (
                      <TasksSandbox data={data} />
                    ) : null}
                  </div>
                </div>
              </div>
              <Footer></Footer>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
