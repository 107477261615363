import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { useEffect, useState } from 'react'
import Button from '@/components/ui/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from '@/components/ui/Alert'
import AppsService from '@/services/apps'
import { useParams } from 'react-router-dom'
import SwitchGreen from '@/components/ui/SwitchGreen'

export default function FlowIdentityDocuments(props) {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [ready, setReady] = useState(false)
    const [passport, setPassport] = useState(false)
    const [residentPermit, setResidentPermit] = useState(false)
    const [driverLicense, setDriverLicense] = useState(false)
    const [nationalId, setNationalId] = useState(false)
    const { app_id } = useParams()

    useEffect(() => {
        GetStepDetails()
        return () => { }
    }, [])


    function Submit() {
        setLoading(true)

        let docs = []

        if (!passport) {
            docs.push('PASSPORT')
        }

        if (!driverLicense) {
            docs.push('DRIVER_LICENSE')
        }

        if (!nationalId) {
            docs.push('NATIONAL_ID')
        }

        if (!residentPermit) {
            docs.push('RESIDENT_PERMIT')
        }

        if (props.country === undefined || props.country === '') {
            const body = {
                documents: docs,
            }

            if (props.type === 'UBO_IDENTITY') {
                AppsService.AddCorporateIdentityDocumentsRestrictions(app_id, body).then(async () => {
                    setLoading(false)
                    props.onMain()
                }).catch((err) => {
                    setError(err.message)
                    setLoading(false)
                })
            } else {
                AppsService.AddDocumentsRestrictions(app_id, props.step_id, body).then(async () => {
                    setLoading(false)
                    props.onBack()
                }).catch((err) => {
                    setError(err.message)
                    setLoading(false)
                })
            }
        } else {
            const body = {
                documents: docs,
                country: props.country.alpha3,
            }
            const mainBody = {
                restrictions: [body]
            }


            if (props.type === 'UBO_IDENTITY') {
                AppsService.AddCorporateIdentityRestrictions(app_id, mainBody).then(async () => {
                    setLoading(false)
                    props.onBack()
                }).catch((err) => {
                    setError(err.message)
                    setLoading(false)
                })
            } else {
                AppsService.AddRestrictions(app_id, props.step_id, mainBody).then(async () => {
                    setLoading(false)
                    props.onBack()
                }).catch((err) => {
                    setError(err.message)
                    setLoading(false)
                })
            }
        }
    }


    function GetStepDetails() {
        setLoading(true)
        if (props.type === 'UBO_IDENTITY') {
            AppsService.GetCorporateCompliance(app_id).then((data) => {
                setReady(true)
                setLoading(false)
                setDocuments(data)
            }).catch((err) => {
                setError(err.message)
                setLoading(false)
            })
        } else {
            AppsService.GetStepDetails(app_id, props.step_id).then((data) => {
                setReady(true)
                setLoading(false)
                setDocuments(data)
            }).catch((err) => {
                setError(err.message)
                setLoading(false)
            })
        }
    }

    function setDocuments(d) {
        let docs = []

        if (props.country === undefined || props.country === '') {
            if (props.type === 'UBO_IDENTITY') {
                docs = d.ubo_identity_documents
            } else {
                docs = d.setting.documents_restrictions
            }

            setPassport(!docs.includes('PASSPORT'))
            setNationalId(!docs.includes('NATIONAL_ID'))
            setResidentPermit(!docs.includes('RESIDENT_PERMIT'))
            setDriverLicense(!docs.includes('DRIVER_LICENSE'))

        } else {
            if (props.type === 'UBO_IDENTITY') {
                docs = d.ubo_identity
            } else {
                docs = d.setting.restrictions
            }

            setPassport(!docs[props.country.alpha3].includes('PASSPORT'))
            setNationalId(!docs[props.country.alpha3].includes('NATIONAL_ID'))
            setResidentPermit(!docs[props.country.alpha3].includes('RESIDENT_PERMIT'))
            setDriverLicense(!docs[props.country.alpha3].includes('DRIVER_LICENSE'))
        }
    }

    return (
        <div className="modal-view">
            <div className="z-max w-full h-full flex items-center justify-center">
                <div className="flex flex-col w-full h-full rounded">
                    <header className="p-5 pb-3">
                        <div className="flex justify-between">
                            <div className="flex">
                                {props.type === 'UBO_IDENTITY' ? <><div>
                                    <img className="h-6 relative top-0.5 mr-2" src="/img/icons/ui/passport-only.svg" />
                                </div>
                                    <div>
                                        <h2 className="text-xl text-primary-500  font-medium"> ID Documents
                                        </h2>
                                    </div></> : null}
                                {props.type !== 'UBO_IDENTITY' ? <><div>
                                    <img className="h-6 relative top-0.5 mr-2" src="/img/icons/ui/passport-only.svg" />
                                </div>
                                    <div>
                                        <h2 className="text-xl text-primary-500  font-medium"> ID Verification <span className="relative top-[-1px] text-xs text-gray-500">ID: {props.step_id}</span>
                                        </h2>
                                    </div></> : null}
                            </div>
                            <div>
                                <h2 className="text-xl relative top-0.5"><span><FontAwesomeIcon onClick={() => props.onClose()} className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-200 ease-in" icon={faTimes}></FontAwesomeIcon></span></h2>
                            </div>
                        </div>
                        <hr className="my-3" />
                    </header>

                    <main
                        className="flex-1 overflow-y-auto p-5 py-0 flow-body"
                        id="individual-main">
                        {ready ?
                            <>
                                <div>
                                    {error ? (
                                        <Alert type="error" title={error}></Alert>
                                    ) : null}
                                </div>
                                <div className="flex flex-wrap">
                                    <div className="w-full">
                                        <div>
                                            <div className="flex items-center ">
                                                <p className="text-sm font-bold text-primary-700">Documents</p>
                                                {props.country !== undefined && props.country !== '' ? <>
                                                    <div>
                                                        <img className="relative h-4 ml-4 top-[-1px] rounded-sm mr-2" src={`/img/icons/flags/${props.country.alpha3}.svg`}></img>
                                                    </div>
                                                    <div>
                                                        <p key={props.country.alpha3}>{props.country.name}</p>
                                                    </div>
                                                </> : null}
                                            </div>
                                            <div className="flex justify-between">
                                                <div>
                                                    <p className="text-sm text-gray-600">Switch the document you want to accept in your app</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="flex flex-wrap mt-3">
                                                    <div className="w-full">
                                                        <div className="flex flex-wrap justify-between border border-gray-200 p-2 rounded-xl">
                                                            <div className={'flex flex-1'}>
                                                                <div>
                                                                    <img src="/img/icons/ui/icon-passport.svg"></img>
                                                                </div>
                                                                <div className="ml-3">
                                                                    <p className="font-medium mt-2 text-primary-700">Passport</p>
                                                                </div>
                                                            </div>
                                                            <div className="mt-2 mr-2">
                                                                <SwitchGreen checked={passport}
                                                                    onChange={setPassport} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-full mt-4">
                                                        <div className="flex flex-wrap justify-between border border-gray-200 p-2 rounded-xl">
                                                            <div className={'flex flex-1'}>
                                                                <div>
                                                                    <img src="/img/icons/ui/icon-national-id.svg"></img>
                                                                </div>
                                                                <div className="ml-3">
                                                                    <p className="font-medium mt-2 text-primary-700">National ID</p>
                                                                </div>
                                                            </div>
                                                            <div className="mt-2 mr-2">
                                                                <SwitchGreen checked={nationalId}
                                                                    onChange={setNationalId} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-full mt-4">
                                                        <div className="flex flex-wrap justify-between border border-gray-200 p-2 rounded-xl">
                                                            <div className={'flex flex-1'}>
                                                                <div>
                                                                    <img src="/img/icons/ui/icon-driver-license.svg"></img>
                                                                </div>
                                                                <div className="ml-3">
                                                                    <p className="font-medium mt-2 text-primary-700">Driver License</p>
                                                                </div>
                                                            </div>
                                                            <div className="mt-2 mr-2">
                                                                <SwitchGreen checked={driverLicense}
                                                                    onChange={setDriverLicense} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-full mt-4">
                                                        <div className="flex flex-wrap justify-between border border-gray-200 p-2 rounded-xl">
                                                            <div className={'flex flex-1'}>
                                                                <div>
                                                                    <img src="/img/icons/ui/icon-residence-permit.svg"></img>
                                                                </div>
                                                                <div className="ml-3">
                                                                    <p className="font-medium mt-2 text-primary-700">Resident Permit</p>
                                                                </div>
                                                            </div>
                                                            <div className="mt-2 mr-2">
                                                                <SwitchGreen checked={residentPermit}
                                                                    onChange={setResidentPermit} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </> : null}
                    </main>
                    <footer className="p-5">
                        <div className={'flex flex-wrap'}>
                            {props.country !== undefined && props.country !== '' ? (
                                <div className="w-1/3 pr-1.5">
                                    <Button
                                        disabled={loading}
                                        full
                                        theme="secondary"
                                        label={'Back'}
                                        onClick={() => props.onBack()}></Button>
                                </div>
                            ) : <div className="w-1/3 pr-1.5">
                                <Button
                                    disabled={loading}
                                    full
                                    theme="secondary"
                                    label={'Back'}
                                    onClick={() => props.onMain()}></Button>
                            </div>}
                            <div className="w-2/3 pl-1.5">
                                <Button
                                    full
                                    loading={loading}
                                    theme="success"
                                    label={'Update'}
                                    onClick={() => Submit()}></Button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    )
}
