import { Menu, Transition } from "@headlessui/react";
import {
  faBook,
  faBrowser,
  faCode,
  faCoins,
  faLayerGroup,
  faPassport,
  faTasks,
  faUniversity,
  faUserFriends,
  faUsers,
} from "@fortawesome/pro-duotone-svg-icons";
import { useContext } from "react";
import { AppContext } from "@/context/app";
import MenuItem from "./MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function MiniProfile() {
  const [app] = useContext(AppContext);

  return (
    <div className="flex">
      <div className="items-center mr-2">
        <a
          href="https://manager-kyc.synaps.io"
          target="_blank"
          rel="noreferrer"
        >
          <p className="text-white p-2 px-3 rounded-full text-sm hover:underline cursor-pointer">
            <FontAwesomeIcon className="mr-1" icon={faPassport} /> Access
            Manager KYC
          </p>
        </a>
      </div>
      <div className="relative inline-block text-left">
        <Menu>
          {({ open }) => (
            <>
              <Menu.Button className="outline-none-i">
                <img
                  className="outline-none w-8 p-2 mr-1 relative top-3px rounded-md hover:bg-primary-900 transition duration-100 ease-in"
                  src="/img/icons/ui/famenu.svg"
                />
              </Menu.Button>

              <Transition
                show={open}
                enter="transition ease-in duration-100"
                enterFrom="transform opacity-0"
                enterTo="transform opacity-100"
                leave="transition ease-in duration-100"
                leaveFrom="transform opacity-100"
                leaveTo="transform opacity-0"
              >
                <Menu.Items
                  static
                  className="absolute right--40px sm:right-0 sm:zoom-none navmenu mt-2 bg-white border border-gray-200 divide-y divide-gray-100 rounded-xl shadow outline-none"
                >
                  <div className="flex flex-wrap items-stretch">
                    <div className="w-full">
                      <div className="px-3 pt-4">
                        <h3 className="ml-3 uppercase tracking-wide font-semibold text-sm lg:text-xs text-gray-900">
                          Menu
                        </h3>
                      </div>
                    </div>

                    <div className="w-1/3">
                      <MenuItem
                        label="Dashboard"
                        path={`/app/${app.id}`}
                        theme="primary"
                        icon={faBrowser}
                      ></MenuItem>
                    </div>

                    {app.type === "CORPORATE" ? (
                      <div className="w-1/3">
                        <MenuItem
                          label="Corporates"
                          path={`/app/${app.id}/sessions`}
                          theme="primary"
                          icon={faUniversity}
                        ></MenuItem>
                      </div>
                    ) : (
                      <div className="w-1/3">
                        <MenuItem
                          label="Users"
                          path={`/app/${app.id}/sessions`}
                          theme="primary"
                          icon={faUsers}
                        ></MenuItem>
                      </div>
                    )}

                    <div className="w-1/3">
                      <MenuItem
                        disabled={
                          !app.iam.owner &&
                          !app.iam.admin &&
                          !app.iam.compliance
                        }
                        label="Flow"
                        path={`/app/${app.id}/flow`}
                        theme="primary"
                        icon={faLayerGroup}
                      ></MenuItem>
                    </div>

                    <div className="w-1/3">
                      <MenuItem
                        disabled={
                          !app.iam.owner &&
                          !app.iam.admin &&
                          !app.iam.compliance
                        }
                        label="Tasks"
                        path={`/app/${app.id}/tasks`}
                        theme="primary"
                        icon={faTasks}
                      ></MenuItem>
                    </div>

                    <div className="w-1/3">
                      <MenuItem
                        disabled={
                          !app.iam.owner && !app.iam.admin && !app.iam.developer
                        }
                        label="Developer"
                        path={`/app/${app.id}/developer`}
                        theme="primary"
                        icon={faCode}
                      ></MenuItem>
                    </div>

                    <div className="w-1/3">
                      <MenuItem
                        label="Team"
                        path={`/app/${app.id}/team`}
                        theme="primary"
                        icon={faUserFriends}
                      ></MenuItem>
                    </div>

                    <div className="w-1/3">
                      <MenuItem
                        label="Logs"
                        path={`/app/${app.id}/logs`}
                        theme="primary"
                        icon={faBook}
                      ></MenuItem>
                    </div>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    </div>
  );
}
