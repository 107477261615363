import { faClock, faBan, faCheck } from '@fortawesome/pro-solid-svg-icons'
import { faDotCircle } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'react-responsive-modal'
import { useState } from 'react'

export default function IndividualPhone({ step, step_id, ip }) {
    const [open, setOpen] = useState(false)

    function IdentityTitle({ state }) {
        return (
            <div>
                <div className="flex justify-between items-center">
                    <div>
                        <p className="font-medium text-primary-400">Phone number</p>
                    </div>
                    <div className='flex'>
                        <p className="text-gray-300 mt-1 mr-2">{ip ? ip : ''}</p>
                        <div className="flex">
                            {/* <p onClick={() => setOpen(true)} className="text-bluegray-500 hover:text-bluegray-700 ease-in duration-200 transition cursor-pointer text-sm bg-bluegray-100 rounded-md px-2 py-1 mr-3"><FontAwesomeIcon className="mr-0.5" icon={faHistory} /> History</p> */}
                            {state === 'PENDING' ? <p className="text-orange-500 text-sm bg-orange-100 rounded-md px-2 py-1"><FontAwesomeIcon className="mr-0.5" icon={faClock} /> Pending verification</p> : null}
                            {state === 'NOT_STARTED' ? <p className="text-gray-500 text-sm bg-gray-100 rounded-md px-2 py-1"><FontAwesomeIcon className="mr-0.5" icon={faDotCircle} /> Not started</p> : null}
                            {state === 'REJECTED' ? <p className="text-red-500 text-sm bg-red-100 rounded-md px-2 py-1"><FontAwesomeIcon className="mr-0.5" icon={faBan} /> Rejected</p> : null}
                            {state === 'FINAL_REJECTED' ? <p className="text-red-500 text-sm bg-red-100 rounded-md px-2 py-1"><FontAwesomeIcon className="mr-0.5" icon={faBan} /> Final Rejected</p> : null}
                            {state === 'VALIDATED' ? <p className="text-green-500 text-sm bg-green-100 rounded-md px-2 py-1"><FontAwesomeIcon className="mr-0.5" icon={faCheck} /> Verified</p> : null}
                        </div>
                    </div>
                </div>
                {state === 'VALIDATED' ? <hr className="my-4 border-bluegray-200" /> : null}
            </div>
        )
    }

    function InfoItem(props) {
        return (
            <div className="mt-2">
                <div className="flex border border-transparent py-1 px-1.5 rounded-xl">
                    <div className="mr-3 mt-0.5 flex-shrink-0">
                        <img className="h-9" src={`/img/icons/ui/${props.icon}.svg`}></img>
                    </div>
                    <div className="relative top-[2px]">
                        <p className="text-bluegray-400 text-xs">{props.title}</p>
                        <p className={`text-gray-600 text-sm ${props.capitalize ? 'capitalize' : null}`}>{props.value}</p>
                    </div>
                </div>
            </div>
        )
    }


    function PhoneVerification({ step }) {
        return (
            <div>
                <div className="mt-3">
                    <p className="mt-6 uppercase tracking-wide font-medium text-sm text-primary-400">Verification</p>
                    <div className="flex flex-wrap">
                        <div className="w-1/3 mt-2">
                            <InfoItem title="Phone number" icon={'icon-call'} value={`+${step.phone_country}${step.phone_number}`} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <IdentityTitle state={step.state} />
            {step.state === 'VALIDATED' || step.state === 'REJECTED' ? <PhoneVerification step_id={step_id} step={step} /> : null}
            <Modal open={open}
                showCloseIcon={true}
                closeIcon={true}
                onClose={() => setOpen(false)}
                center>
                <p>History</p>
            </Modal>
        </div>
    )
}