import DocItem from '@/components/developers/DocItem'
import { faLink, faMobile, faBracketsCurly, faBell, faUser, faBrowser } from '@fortawesome/pro-duotone-svg-icons'

export default function GetStarted() {
	return (
		<>
			<h3 className="text-2xl ml-6 text-primary-800 mb-1 font-bold">Get Started</h3>
			<div className="flex flex-wrap">
				<div className="w-1/3">
					<a href="https://docs.synaps.io/manager-1/sessions" target="_blank" rel="noreferrer">
						<DocItem name={'Sessions'} icon={faUser} />
					</a>
				</div>
				<div className="w-1/3">
					<a href="https://docs.synaps.io/integration/iframe-integration" target="_blank" rel="noreferrer">
						<DocItem name={'Web Integration'} icon={faBrowser} />
					</a>
				</div>
				<div className="w-1/3">
					<a href="https://docs.synaps.io/integration/mobile-integration" target="_blank" rel="noreferrer">
						<DocItem name={'Mobile Integration'} icon={faMobile} />
					</a>
				</div>
				<div className="w-1/3">
					<a href="https://docs.synaps.io/integration/api-integration" target="_blank" rel="noreferrer">
						<DocItem name={'API Integration'} icon={faBracketsCurly} />
					</a>
				</div>
				<div className="w-1/3">
					<a href="https://docs.synaps.io/manager-1/developer/webhooks-1" target="_blank" rel="noreferrer">
						<DocItem name={'Webhooks'} icon={faLink} />
					</a>
				</div>
				<div className="w-1/3">
					<a href="https://docs.synaps.io/integration/notifications" target="_blank" rel="noreferrer">
						<DocItem name={'Notifications'} icon={faBell} />
					</a>
				</div>

			</div>
		</>
	)
}
