import { useEffect, useState } from 'react'
import LogsService from '@/services/logs'
import SessionItemPlaceholder from '../placeholder/SessionItemPlaceholder'
import Sleep from '@/lib/sleep'
import * as Notification from '@/components/ui/Notification'
import LogItem from './LogItem'
import { useParams } from 'react-router-dom'

export default function AppLogs() {
	const [ready, setReady] = useState(false)
	const [logs, setLogs] = useState(null)
	const { app_id } = useParams()

	async function getAppLogs() {
		await LogsService.GetLogs(app_id)
			.then(async (data) => {
			
				setLogs(data)
				await Sleep(500)
				setReady(true)
			})
			.catch((err) => {
				Notification.error(err.message)
			})
	}

	useEffect(async () => {
		setReady(false)
		getAppLogs()
		return () => { }
	}, [])


	return (
		<>
			{ready ? (
				<div className={`${logs.length > 0 ? 'p-6' : ''} flex flex-wrap pt-3`}>
					{logs.map((log) => {
						return (
							<>
								<LogItem
									key={log.date}
									item={log}></LogItem>
							</>
						)
					})}
					{logs.length === 0 ? (
						<div className="w-full mt-3 px-2 rounded-xl border bg-white transition-25 transition duration-200 ease-in">
							<div className="p-6 mt-4 mx-auto">
								<img className="mx-auto h-36" src="/img/illustrations/empty.svg"></img>
								<p className="p-6 text-center text-gray-700 mt-3 mx-auto">No logs</p>
							</div>
						</div>
					) : null}
				</div>

			) : (
				<div className="p-4">
					<SessionItemPlaceholder></SessionItemPlaceholder>
				</div>
			)}
		</>
	)
}
