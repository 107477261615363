import { useContext, useEffect, useState } from "react";
import {
  faCabinetFiling,
  faLayerGroup,
} from "@fortawesome/pro-duotone-svg-icons";
import Button from "@/components/ui/Button";
import Footer from "@/components/menu/Footer";
import SubNav from "@/components/menu/SubNav";
import TierSteps from "@/components/flow/TierSteps";
import { Modal } from "react-responsive-modal";
import { AppContext } from "@/context/app";
import { useHistory, useParams } from "react-router-dom";
import CreateNewTier from "@/components/flow/CreateNewTier";

export default function AppTierPage() {
  const [app] = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const router = useHistory();
  const [flowKey, setFlowKey] = useState(0);
  const { app_id } = useParams();

  function openTier() {
    setOpen(true);
  }

  useEffect(() => {
    if (!open) {
      setFlowKey(flowKey + 1);
    }
  }, [open]);

  return (
    <div>
      <div>
        <SubNav></SubNav>
        <div className="max-w-7xl mx-auto relative">
          <div className="dashboard-container px-3">
            <div className="flex justify-between mt-3">
              <div>
                <div className="flex">
                  <div
                    className="cursor-pointer"
                    onClick={() => (window.location.href = `/app/${app_id}/`)}
                  >
                    <img
                      className="w-16 h-16 mt-1 rounded-xl"
                      src={app.logo_url}
                    />
                  </div>
                  <div className="ml-5">
                    <h1 className="text-3xl text-white">
                      <span className="font-bold">Tiers</span>
                    </h1>
                    <h3 className="text-lg mt-2 text-bluegray-300">
                      Manage your flow display
                    </h3>
                  </div>
                </div>
              </div>
              <div className="flex">
                {(app.iam.owner || app.iam.admin || app.iam.compliance) &&
                app.type !== "CORPORATE" ? (
                  <>
                    <div className="flex mt-2.5 mr-3">
                      <Button
                        onClick={() =>
                          (window.location.href = `/app/${app_id}/flow`)
                        }
                        currentBg="primary"
                        theme="secondary"
                        icon={faLayerGroup}
                        label="Manage steps"
                      ></Button>
                    </div>
                    <div className="flex mt-2.5">
                      <Button
                        onClick={() => openTier()}
                        currentBg="primary"
                        theme="success"
                        icon={faCabinetFiling}
                        label="Add new tier"
                      ></Button>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-7xl mx-auto relative">
          {app.type === "WORKFLOW" ? (
            <>
              <div className="flex flex-wrap items-start mt-6">
                <TierSteps onAdd={() => openTier()} key={flowKey}></TierSteps>
              </div>
              <Modal
                open={open}
                showCloseIcon={true}
                closeIcon={true}
                onClose={() => setOpen(false)}
                center
              >
                <CreateNewTier onClose={() => setOpen(false)} />
              </Modal>
            </>
          ) : null}
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
}
