import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";
import Button from "@/components/ui/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from "@/components/ui/Alert";
import Input from "@/components/ui/Input";
import AppsService from "@/services/apps";
import { useParams } from "react-router-dom";
import SwitchGreen from "@/components/ui/SwitchGreen";
import Sleep from "@/lib/sleep";

export default function InviteNewUser(props) {
  const [error, setError] = useState(null);
  const [firstname, setFirstname] = useState(null);
  const [lastname, setLastname] = useState(null);
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  let { app_id } = useParams();

  const [admin, setAdmin] = useState(false);
  const [compliance, setCompliance] = useState(false);
  const [developer, setDeveloper] = useState(false);
  const [accountant, setAccountant] = useState(false);

  function Submit() {
    setLoading(true);

    let appRoles = [];

    if (admin) {
      appRoles.push("ADMIN");
    }

    if (compliance) {
      appRoles.push("COMPLIANCE");
    }

    if (developer) {
      appRoles.push("DEVELOPER");
    }

    if (accountant) {
      appRoles.push("BILLING");
    }

    const body = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      roles: appRoles,
    };

    AppsService.CreateNewRole(app_id, body)
      .then(async () => {
        setLoading(false);
        setSuccess(true);
        await Sleep(1000);
        props.onFinish();
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }

  return (
    <div className="p-4 createapp-view">
      <div className="flex justify-between">
        <div>
          <h2 className="text-xl">Invite new member</h2>
        </div>
        <div>
          <h2 className="text-xl">
            <span>
              <FontAwesomeIcon
                onClick={() => props.onClose()}
                className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-100 ease-in"
                icon={faTimes}
              ></FontAwesomeIcon>
            </span>
          </h2>
        </div>
      </div>
      <hr className="my-3" />
      <div>{error ? <Alert type="error" title={error}></Alert> : null}</div>
      <div className="mt-4">
        <div className="flex flex-wrap">
          <div className="w-1/2 pr-2">
            <Input
              label="Firstname"
              setValue={(value) => setFirstname(value)}
              value={firstname}
              placeholder="John"
            />
          </div>
          <div className="w-1/2 pl-2">
            <Input
              label="Lastname"
              setValue={(value) => setLastname(value)}
              value={lastname}
              placeholder="Doe"
            />
          </div>
        </div>
        <div className="mt-3">
          <Input
            label="Email"
            setValue={(value) => setEmail(value)}
            value={email}
            placeholder="john.doe@gmail.com"
          />
        </div>
        <div className="mt-4">
          <label className="block text-sm font-medium text-primary-700">
            Roles
          </label>
          <p className="text-sm mb-3 text-gray-600">
            Grant permissions to your collaborator according to his role
          </p>

          <div className="flex flex-wrap">
            <div className="w-full">
              <div className="flex flex-wrap justify-between border border-gray-200 p-2 rounded-xl">
                <div className={"flex flex-1"}>
                  <div>
                    <img src="/img/icons/ui/icon-admin.svg"></img>
                  </div>
                  <div className="ml-3">
                    <p className="font-medium text-primary-700">Admin</p>
                    <p className="text-gray-500 text-xs">
                      View and manage everything
                    </p>
                  </div>
                </div>
                <div className="mt-2 mr-2">
                  <SwitchGreen checked={admin} onChange={setAdmin} />
                </div>
              </div>
            </div>
            <div className="w-full mt-4">
              <div className="flex flex-wrap justify-between border border-gray-200 p-2 rounded-xl">
                <div className={"flex flex-1"}>
                  <div>
                    <img src="/img/icons/ui/icon-compliance-officer.svg"></img>
                  </div>
                  <div className="ml-3">
                    <p className="font-medium text-primary-700">
                      Compliance Officer
                    </p>
                    <p className="text-gray-500 text-xs">
                      View and manage users, compliance tasks
                    </p>
                  </div>
                </div>
                <div className="mt-2 mr-2">
                  <SwitchGreen checked={compliance} onChange={setCompliance} />
                </div>
              </div>
            </div>
            <div className="w-full mt-4">
              <div className="flex flex-wrap justify-between border border-gray-200 p-2 rounded-xl">
                <div className={"flex flex-1"}>
                  <div>
                    <img src="/img/icons/ui/icon-developer.svg"></img>
                  </div>
                  <div className="ml-3">
                    <p className="font-medium text-primary-700">Developer</p>
                    <p className="text-gray-500 text-xs">
                      View and manage users, credentials, sandbox and webhooks
                    </p>
                  </div>
                </div>
                <div className="mt-2 mr-2">
                  <SwitchGreen checked={developer} onChange={setDeveloper} />
                </div>
              </div>
            </div>
            <div className="w-full mt-4">
              <div className="flex flex-wrap justify-between border border-gray-200 p-2 rounded-xl">
                <div className={"flex flex-1"}>
                  <div>
                    <img src="/img/icons/ui/icon-accountant.svg"></img>
                  </div>
                  <div className="ml-3">
                    <p className="font-medium text-primary-700">Accountant</p>
                    <p className="text-gray-500 text-xs">
                      View and manage billing
                    </p>
                  </div>
                </div>
                <div className="mt-2 mr-2">
                  <SwitchGreen checked={accountant} onChange={setAccountant} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5">
          {success ? (
            <Button
              icon={faCheck}
              full
              theme="success"
              label="Success"
            ></Button>
          ) : (
            <>
              <Button
                loading={loading}
                full
                disabled={
                  !firstname ||
                  !lastname ||
                  !email ||
                  (!admin && !compliance && !developer && !accountant)
                }
                theme="success"
                label="Invite"
                onClick={() => Submit()}
              ></Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
