import { useEffect, useState } from 'react'
import AppsService from '@/services/apps'
import TeamMemberItemPlaceholder from '../placeholder/TeamMemberItemPlaceholder'
import TeamMemberItem from '@/components/team/TeamMemberItem'
import { useParams } from 'react-router-dom'
import { Modal } from 'react-responsive-modal'
import ViewUser from '@/components/team/ViewUser'
import { useContext } from 'react'
import { AppContext } from '@/context/app'

export default function TeamMembers(props) {
	const [ready, setReady] = useState(false)
	const [data, setData] = useState(null)
	const [open, setOpen] = useState(null)
	const [member, setMember] = useState(null)
	const [app] = useContext(AppContext)

	let { app_id } = useParams()

	useEffect(async () => {
		GetAppRoles()
		return () => { }
	}, [])

	useEffect(() => {
		GetAppRoles()
		return () => { }
	}, [props.refresh])

	async function GetAppRoles() {
		setReady(false)
		await AppsService.GetAppRoles(app_id)
			.then((data) => {
				setData(data)
				setReady(true)
			})
		return () => { }
	}

	function openMember(member) {
		if (data.me !== member.id && (app.iam.owner || app.iam.admin)) {
			setMember(member.id)
			setOpen(true)
		}
	}

	function finish() {
		setOpen(false)
		GetAppRoles()
	}


	return (
		<>
			{ready ? (
				<>
					{data.team.map((user) => {
						return (
							<div className="w-full sm:w-1/2 lg:w-1/4"
								onClick={() => openMember(user)}
								key={user.id}>
								<TeamMemberItem me={data.me} key={user.id} user={user}></TeamMemberItem>
							</div>
						)
					})}

					{data.team.length < 8 ? <>
						{Array(8 - data.team.length - (data.team.length === 0 ? 1 : 0)).fill().map((i) => {
							return (<div key={i} className="w-full sm:w-1/2 lg:w-1/4">
								<div className='m-4 text-center border border-bluegray-150 items-center bg-white rounded-xl transition-25'>
									<div className="m-3 my-6">
										<div className="h-24 w-24 mx-auto border bg-gray-100 border-bluegray-100 rounded-2xl"></div>
										<div className="mt-4">
											<div className='text-regular font-regular font-bold'>
												<div className="bg-gray-400 h-3 mx-auto w-16 rounded"></div>
											</div>
											<div className="flex justify-center mt-1">
												<div className='mt-3 pb-1 text-regular text-sm font-regular'>
													<div className="bg-gray-100 mx-1 h-8  w-8 rounded-lg"></div>
												</div>
												<div className='mt-3 pb-1 text-regular text-sm font-regular'>
													<div className="bg-gray-100  mx-1 h-8 w-8 rounded-lg"></div>
												</div>
												<div className='mt-3 pb-1 text-regular text-sm font-regular'>
													<div className="bg-gray-100  mx-1 h-8 w-8 rounded-lg"></div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>)
						})}
					</> : null}

				</>
			) : (
				<>
					{Array(8).fill().map((i) => {
						return <TeamMemberItemPlaceholder key={i} />
					})}
				</>
			)}

			<Modal open={open}
				showCloseIcon={true}
				closeIcon={true}
				onClose={() => setOpen(false)}
				center>
				<ViewUser onFinish={() => finish()} onClose={() => setOpen(false)} member_id={member} />
			</Modal>

		</>
	)
}
