import { faClock, faLayerGroup } from "@fortawesome/pro-duotone-svg-icons";
import { faBan, faCheck, faUndo } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from "react-moment";
import Tooltip from "../ui/Tooltip";
import MiniButton from "../ui/MiniButton";
import SwitchGreen from "@/components/ui/SwitchGreen";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Sleep from "@/lib/sleep";

export default function SandboxTaskItem(props) {
  const [aml, setAml] = useState(false);
  const { app_id } = useParams();
  const router = useHistory();

  async function UpdateCorporateSandboxAML() {
    let newAML = true;
    props.update({
      state: 1,
      session_id: props.item.service_session_id,
      service: props.item.service,
      aml: newAML,
    });
    await Sleep(1000);
    window.location.href = `/app/${app_id}/corporate/${props.item.service_session_id}`;
  }

  return (
    <div className="w-full my-3 p-2 rounded-xl border bg-white hover:border-gray-400 transition-25 transition duration-200 ease-in">
      <div className="w-full">
        <div className="ml-1">
          <div className="sm:flex justify-between">
            <div className="flex">
              <div className="p-2">
                {props.item.service === "IDENTITY" ? (
                  <img src="/img/icons/ui/passport.svg" />
                ) : null}
                {props.item.service === "RESIDENCY" ? (
                  <img src="/img/icons/ui/icon-utility-bill.svg" />
                ) : null}
                {props.item.service === "CORPORATE" ? (
                  <img src="/img/icons/ui/icon-corporate.svg" />
                ) : null}
              </div>
              <div className="ml-3 relative">
                <p className="text-sm font-bold mt-1.5">
                  {props.item.session_id}
                </p>
                <div className="flex">
                  <div>
                    <h1 className="text-sm mt-1">
                      <Tooltip auto={true} content={"Step ID"}>
                        <span>
                          <FontAwesomeIcon
                            className="text-bluegray-500 mr-1"
                            icon={faLayerGroup}
                          />{" "}
                          {props.item.step_id}
                        </span>
                      </Tooltip>
                    </h1>
                  </div>
                  <div>
                    <h1 className="text-sm mt-1 ml-3">
                      <Tooltip auto={true} content={"Creation Date"}>
                        <span>
                          <FontAwesomeIcon
                            className="text-bluegray-500 mr-1"
                            icon={faClock}
                          />
                          <Moment unix fromNow>
                            {props.item.creation_date / 1000}
                          </Moment>
                        </span>
                      </Tooltip>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="mt-2 mr-2">
                <div className="flex">
                  <div>
                    <MiniButton
                      className="mx-1"
                      onClick={() =>
                        props.update({
                          state: 2,
                          session_id: props.item.service_session_id,
                          service: props.item.service,
                          aml,
                        })
                      }
                      icon={faCheck}
                      theme="success"
                      label="Validate"
                    ></MiniButton>
                  </div>
                  <div>
                    <MiniButton
                      className="mx-1"
                      onClick={() =>
                        props.update({
                          state: 0,
                          session_id: props.item.service_session_id,
                          service: props.item.service,
                          aml,
                        })
                      }
                      icon={faUndo}
                      theme="pin"
                      label="Reject"
                    ></MiniButton>
                  </div>
                  {props.item.service !== "CORPORATE" ? (
                    <div>
                      <MiniButton
                        className="mx-1"
                        onClick={() =>
                          props.update({
                            state: -1,
                            session_id: props.item.service_session_id,
                            service: props.item.service,
                            aml,
                          })
                        }
                        icon={faBan}
                        theme="error"
                        label="Final Reject"
                      ></MiniButton>
                    </div>
                  ) : (
                    <MiniButton
                      className="mx-1"
                      onClick={() => UpdateCorporateSandboxAML()}
                      icon={faUndo}
                      theme="pin"
                      label="Simulate AML task"
                    ></MiniButton>
                  )}
                  {props.item.service === "IDENTITY" ? (
                    <div className="ml-4">
                      <p className="text-bluegray-500 font-bold text-xs">
                        AML response
                      </p>
                      <SwitchGreen checked={aml} onChange={setAml} />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
