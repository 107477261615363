import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { faLayerGroup } from '@fortawesome/pro-duotone-svg-icons'
import { useEffect, useState, useContext } from 'react'
import Button from '@/components/ui/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from '@/components/ui/Alert'
import AppsService from '@/services/apps'
import { useParams } from 'react-router-dom'
import Sleep from '@/lib/sleep'
import Input from '@/components/ui/Input'
import Tooltip from '@/components/ui/Tooltip'
import { AppContext } from '@/context/app'

export default function CreateNewTier(props) {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [selectedTiers] = useState([])
    const [data, setData] = useState(null)
    const [tier, setTier] = useState('')
    const [ready, setReady] = useState(false)
    const [, appDispatch] = useContext(AppContext)

    const { app_id } = useParams()

    async function GetFlow() {
        setReady(false)
        await AppsService.GetFlow(app_id)
            .then(async (data) => {
                setData(data)
                await Sleep(1000)
                setReady(true)
            })
        return () => { }
    }

    async function AddTier(key) {
        let tiers = selectedTiers

        const index = tiers.indexOf(key)
        if (index > -1) {
            tiers.splice(index, 1)
            await appDispatch({
                type: 'SET_TIERS',
                payload: {
                    tiers: tiers
                },
            })
        } else {
            tiers.push(key)
            await appDispatch({
                type: 'SET_TIERS',
                payload: {
                    tiers: tiers
                },
            })
        }
    }

    useEffect(() => {
        GetFlow()
    }, [])

    function Submit() {
        setLoading(true)

        const body = {
            steps: selectedTiers,
            tier_name: tier,
        }

        AppsService.AddFlowTiers(app_id, body).then(() => {
            props.onClose()
            setLoading(false)
        }).catch((err) => {
            setError(err.message)
            setLoading(false)
        })
    }

    return (
        <div className="modal-view">
            <div className="z-max w-full h-full flex items-center justify-center">
                <div className="flex flex-col w-full h-full rounded">
                    <header className="p-5 pb-3">
                        <div className="flex justify-between">
                            <div>
                                <h2 className="text-xl text-primary-500  font-medium"><FontAwesomeIcon className="mr-1" icon={faLayerGroup} /> Add new tier</h2>
                            </div>
                            <div>
                                <h2 className="text-xl relative top-0.5"><span><FontAwesomeIcon onClick={() => props.onClose()} className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-100 ease-in" icon={faTimes}></FontAwesomeIcon></span></h2>
                            </div>
                        </div>
                        <hr className="my-3" />
                    </header>


                    <main
                        className="flex-1 overflow-y-auto p-5 py-0 flow-body"
                        id="individual-main">



                        {error ? (
                            <Alert type="error" title={error}></Alert>
                        ) : null}
                        {ready ? (
                            <div>
                                <div className="flex flex-wrap">
                                    <div className="w-full">
                                        <Input label="Tier Name" setValue={(value) => setTier(value)} value={tier} placeholder="Gold Level" />
                                    </div>
                                    <p
                                        className="block text-sm font-medium text-gray-700 mt-4">
                                        Steps
                                    </p>
                                    {Object.keys(data.steps).map(function (key) {
                                        return (
                                            <>
                                                {data.steps[key].step === 'LIVENESS' ? <>
                                                    <div key={key} className="w-full mt-4">
                                                        <div onClick={() => AddTier(key)} className={`flex flex-1 border border-gray-200 ${selectedTiers.includes(key) ? 'border-primary-700' : 'hover:border-gray-400 cursor-pointer'} p-2 rounded-xl ease-in transition duration-100`}>
                                                            <div>
                                                                <img src="/img/icons/ui/look-in-camera.svg"></img>
                                                            </div>
                                                            <div className="ml-3 w-full">
                                                                <div className="flex justify-between items-center">
                                                                    <div>
                                                                        <p className="font-medium text-primary-700">Liveness</p>
                                                                        <p className="text-gray-500 text-xs">Genuine Assurance Presence</p>
                                                                    </div>
                                                                    <div>
                                                                        <Tooltip auto={true} content="Step ID">
                                                                            <span className="relative top-[-1px] text-xs bg-bluegray-200 mr-1 px-2 py-0.5 rounded-md text-gray-500">{key}</span>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> : null}
                                                {data.steps[key].step === 'IDENTITY' ? <>
                                                    <div key={key} className="w-full mt-4">
                                                        <div onClick={() => AddTier(key)} className={`flex flex-1 border border-gray-200 ${selectedTiers.includes(key) ? 'border-primary-700' : 'hover:border-gray-400 cursor-pointer'} p-2 rounded-xl ease-in transition duration-100`}>
                                                            <div>
                                                                <img src="/img/icons/ui/icon-passport.svg"></img>
                                                            </div>
                                                            <div className="ml-3 w-full">
                                                                <div className="flex justify-between items-center">
                                                                    <div>
                                                                        <p className="font-medium text-primary-700">ID Verification</p>
                                                                        <p className="text-gray-500 text-xs">Verify ID documents</p>                                                                    </div>
                                                                    <div>
                                                                        <Tooltip auto={true} content="Step ID">
                                                                            <span className="relative top-[-1px] text-xs bg-bluegray-200 mr-1 px-2 py-0.5 rounded-md text-gray-500">{key}</span>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </> : null}
                                                {data.steps[key].step === 'RESIDENCY' ? <>
                                                    <div key={key} className="w-full mt-4">
                                                        <div onClick={() => AddTier(key)} className={`flex flex-1 border border-gray-200  ${selectedTiers.includes(key) ? 'border-primary-700' : 'hover:border-gray-400 cursor-pointer'} p-2 rounded-xl ease-in transition duration-100`}>
                                                            <div>
                                                                <img src="/img/icons/ui/icon-utility-bill.svg"></img>
                                                            </div>
                                                            <div className="ml-3 w-full">
                                                                <div className="flex justify-between items-center">
                                                                    <div>
                                                                        <p className="font-medium text-primary-700">Proof of residency</p>
                                                                        <p className="text-gray-500 text-xs">Address verification for individuals</p>
                                                                    </div>
                                                                    <div>
                                                                        <Tooltip auto={true} content="Step ID">
                                                                            <span className="relative top-[-1px] text-xs bg-bluegray-200 mr-1 px-2 py-0.5 rounded-md text-gray-500">{key}</span>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> : null}
                                                {data.steps[key].step === 'PHONE' ? <>
                                                    <div key={key} className="w-full mt-4">
                                                        <div onClick={() => AddTier(key)} className={`flex flex-1 border border-gray-200  ${selectedTiers.includes(key) ? 'border-primary-700' : 'hover:border-gray-400 cursor-pointer'} p-2 rounded-xl ease-in transition duration-100`}>
                                                            <div>
                                                                <img src="/img/icons/ui/icon-call.svg"></img>
                                                            </div>
                                                            <div className="ml-3 w-full">
                                                                <div className="flex justify-between items-center">
                                                                    <div>
                                                                        <p className="font-medium text-primary-700">Phone</p>
                                                                        <p className="text-gray-500 text-xs">Mobile phone verification at scale</p>
                                                                    </div>
                                                                    <div>
                                                                        <Tooltip auto={true} content="Step ID">
                                                                            <span className="relative top-[-1px] text-xs bg-bluegray-200 mr-1 px-2 py-0.5 rounded-md text-gray-500">{key}</span>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> : null}
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        ) : null}

                    </main>
                    <footer className="p-5">
                        <Button full loading={loading} disabled={selectedTiers.length === 0 || tier === ''} theme="success" label="Add tier" onClick={() => Submit()}></Button>
                    </footer>
                </div>
            </div>
        </div>
    )
}