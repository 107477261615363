import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { useEffect, useState } from 'react'
import Button from '@/components/ui/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from '@/components/ui/Alert'
import AppsService from '@/services/apps'
import { useParams } from 'react-router-dom'
import SwitchGreen from '@/components/ui/SwitchGreen'

export default function FlowResidencyDocuments(props) {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [ready, setReady] = useState(false)
    const [bank, setBank] = useState(false)
    const [gas, setGas] = useState(false)
    const [water, setWater] = useState(false)
    const [landline, setLandline] = useState(false)
    const [internet, setInternet] = useState(false)
    const [electricity, setElectricity] = useState(false)

    const { app_id } = useParams()

    useEffect(() => {
        GetStepDetails()
        return () => { }
    }, [])


    function Submit() {
        setLoading(true)

        let docs = []

        if (!bank) {
            docs.push('BANK_STATEMENT')
        }

        if (!gas) {
            docs.push('GAS_BILL')
        }

        if (!water) {
            docs.push('WATER_BILL')
        }

        if (!landline) {
            docs.push('LANDLINE_BILL')
        }

        if (!internet) {
            docs.push('INTERNET_BILL')
        }

        if (!electricity) {
            docs.push('ELECTRICITY_BILL')
        }

        if (props.country === undefined || props.country === '') {
            const body = {
                documents: docs
            }

            if (props.type === 'UBO_RESIDENCY') {
                AppsService.AddCorporateResidencyDocumentsRestrictions(app_id, body).then(async () => {
                    setLoading(false)
                    props.onMain()
                }).catch((err) => {
                    setError(err.message)
                    setLoading(false)
                })
            } else {
                AppsService.AddDocumentsRestrictions(app_id, props.step_id, body).then(async () => {
                    setLoading(false)
                    props.onBack()
                }).catch((err) => {
                    setError(err.message)
                    setLoading(false)
                })
            }
        } else {
            const body = {
                documents: docs,
                country: props.country.alpha3,
            }

            const mainBody = {
                restrictions: [body]
            }

            if (props.type === 'UBO_RESIDENCY') {
                AppsService.AddCorporateResidencyRestrictions(app_id, mainBody).then(async () => {
                    setLoading(false)
                    props.onBack()
                }).catch((err) => {
                    setError(err.message)
                    setLoading(false)
                })
            } else {
                AppsService.AddRestrictions(app_id, props.step_id, mainBody).then(async () => {
                    setLoading(false)
                    props.onBack()
                }).catch((err) => {
                    setError(err.message)
                    setLoading(false)
                })
            }
        }
    }

    function GetStepDetails() {
        setLoading(true)

        if (props.type === 'UBO_RESIDENCY') {
            AppsService.GetCorporateCompliance(app_id, props.step_id).then((data) => {
                setReady(true)
                setLoading(false)
                setDocuments(data)
            }).catch((err) => {
                setError(err.message)
                setLoading(false)
            })
        } else {
            AppsService.GetStepDetails(app_id, props.step_id).then((data) => {
                setReady(true)
                setLoading(false)
                setDocuments(data)
            }).catch((err) => {
                setError(err.message)
                setLoading(false)
            })
        }
    }

    function setDocuments(d) {
        let docs = []

        if (props.country === undefined || props.country === '') {
            if (props.type === 'UBO_RESIDENCY') {
                docs = d.ubo_residency_documents
            } else {
                docs = d.setting.documents_restrictions
            }

            setBank(!docs.includes('BANK_STATEMENT'))
            setGas(!docs.includes('GAS_BILL'))
            setWater(!docs.includes('WATER_BILL'))
            setLandline(!docs.includes('LANDLINE_BILL'))
            setInternet(!docs.includes('INTERNET_BILL'))
            setElectricity(!docs.includes('ELECTRICITY_BILL'))

        } else {
            if (props.type === 'UBO_RESIDENCY') {
                docs = d.ubo_residency
            } else {
                docs = d.setting.restrictions
            }

            setBank(!docs[props.country.alpha3].includes('BANK_STATEMENT'))
            setGas(!docs[props.country.alpha3].includes('GAS_BILL'))
            setWater(!docs[props.country.alpha3].includes('WATER_BILL'))
            setLandline(!docs[props.country.alpha3].includes('LANDLINE_BILL'))
            setInternet(!docs[props.country.alpha3].includes('INTERNET_BILL'))
            setElectricity(!docs[props.country.alpha3].includes('ELECTRICITY_BILL'))
        }
    }

    return (
        <div className="modal-view">
            <div className="z-max w-full h-full flex items-center justify-center">
                <div className="flex flex-col w-full h-full rounded">
                    <header className="p-5 pb-3">
                        <div className="flex justify-between">
                            {props.type !== 'UBO_RESIDENCY' ? <div className="flex">
                                <div>
                                    <img className="h-6 relative top-0.5 mr-2" src="/img/icons/ui/utility-bill-only.svg" />
                                </div>
                                <div>
                                    <h2 className="text-xl text-primary-500  font-medium"> Proof of Residency <span className="relative top-[-1px] text-xs text-gray-500">ID: {props.step_id}</span>
                                    </h2>
                                </div>
                            </div> : null}
                            {props.type === 'UBO_RESIDENCY' ? <div className="flex">
                                <div>
                                    <img className="h-6 relative top-0.5 mr-2" src="/img/icons/ui/utility-bill-only.svg" />
                                </div>
                                <div>
                                    <h2 className="text-xl text-primary-500  font-medium"> Proof of Residency
                                    </h2>
                                </div>
                            </div> : null}

                            <div>
                                <h2 className="text-xl relative top-0.5"><span><FontAwesomeIcon onClick={() => props.onClose()} className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-200 ease-in" icon={faTimes}></FontAwesomeIcon></span></h2>
                            </div>
                        </div>
                        <hr className="my-3" />
                    </header>

                    <main
                        className="flex-1 overflow-y-auto p-5 py-0 flow-body"
                        id="individual-main">
                        {ready ?
                            <>
                                <div>
                                    {error ? (
                                        <Alert type="error" title={error}></Alert>
                                    ) : null}
                                </div>
                                <div className="flex flex-wrap">
                                    <div className="w-full">
                                        <div>
                                            <div className="flex justify-between">
                                                <div>
                                                    <div className="flex items-center ">
                                                        <p className="text-sm font-bold text-primary-700">Documents</p>
                                                        {props.country !== undefined && props.country !== '' ? <>
                                                            <div>
                                                                <img className="relative h-4 ml-4 top-[-1px] rounded-sm mr-2" src={`/img/icons/flags/${props.country.alpha3}.svg`}></img>
                                                            </div>
                                                            <div>
                                                                <p key={props.country.alpha3}>{props.country.name}</p>
                                                            </div>
                                                        </> : null}
                                                    </div>
                                                    <p className="text-sm text-gray-600">Switch the document you want to accept in your app</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="flex flex-wrap mt-3">
                                                    <div className="w-full">
                                                        <div className="flex flex-wrap justify-between border border-gray-200 p-2 rounded-xl">
                                                            <div className={'flex flex-1'}>
                                                                <div>
                                                                    <img src="/img/icons/ui/icon-document-water-bill.svg"></img>
                                                                </div>
                                                                <div className="ml-3">
                                                                    <p className="font-medium mt-2 text-primary-700">Water Bill</p>
                                                                </div>
                                                            </div>
                                                            <div className="mt-2 mr-2">
                                                                <SwitchGreen checked={water}
                                                                    onChange={setWater} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-full mt-4">
                                                        <div className="flex flex-wrap justify-between border border-gray-200 p-2 rounded-xl">
                                                            <div className={'flex flex-1'}>
                                                                <div>
                                                                    <img src="/img/icons/ui/icon-document-landline-bill.svg"></img>
                                                                </div>
                                                                <div className="ml-3">
                                                                    <p className="font-medium mt-2 text-primary-700">Landline Bill</p>
                                                                </div>
                                                            </div>
                                                            <div className="mt-2 mr-2">
                                                                <SwitchGreen checked={landline}
                                                                    onChange={setLandline} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-full mt-4">
                                                        <div className="flex flex-wrap justify-between border border-gray-200 p-2 rounded-xl">
                                                            <div className={'flex flex-1'}>
                                                                <div>
                                                                    <img src="/img/icons/ui/icon-document-electricity-bill.svg"></img>
                                                                </div>
                                                                <div className="ml-3">
                                                                    <p className="font-medium mt-2 text-primary-700">Electricity Bill</p>
                                                                </div>
                                                            </div>
                                                            <div className="mt-2 mr-2">
                                                                <SwitchGreen checked={electricity}
                                                                    onChange={setElectricity} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-full mt-4">
                                                        <div className="flex flex-wrap justify-between border border-gray-200 p-2 rounded-xl">
                                                            <div className={'flex flex-1'}>
                                                                <div>
                                                                    <img src="/img/icons/ui/icon-document-internet-bill.svg"></img>
                                                                </div>
                                                                <div className="ml-3">
                                                                    <p className="font-medium mt-2 text-primary-700">Internet Bill</p>
                                                                </div>
                                                            </div>
                                                            <div className="mt-2 mr-2">
                                                                <SwitchGreen checked={internet}
                                                                    onChange={setInternet} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-full mt-4">
                                                        <div className="flex flex-wrap justify-between border border-gray-200 p-2 rounded-xl">
                                                            <div className={'flex flex-1'}>
                                                                <div>
                                                                    <img src="/img/icons/ui/icon-document-gas-bill.svg"></img>
                                                                </div>
                                                                <div className="ml-3">
                                                                    <p className="font-medium mt-2 text-primary-700">Gas Bill</p>
                                                                </div>
                                                            </div>
                                                            <div className="mt-2 mr-2">
                                                                <SwitchGreen checked={gas}
                                                                    onChange={setGas} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-full mt-4">
                                                        <div className="flex flex-wrap justify-between border border-gray-200 p-2 rounded-xl">
                                                            <div className={'flex flex-1'}>
                                                                <div>
                                                                    <img src="/img/icons/ui/icon-bank-statement.svg"></img>
                                                                </div>
                                                                <div className="ml-3">
                                                                    <p className="font-medium mt-2 text-primary-700">Bank Statement</p>
                                                                </div>
                                                            </div>
                                                            <div className="mt-2 mr-2">
                                                                <SwitchGreen checked={bank}
                                                                    onChange={setBank} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </> : null}
                    </main>
                    <footer className="p-5 pt-2">
                        <div className={'flex flex-wrap'}>
                            {props.country !== undefined && props.country !== '' ? (
                                <div className="w-1/3 pr-1.5">
                                    <Button
                                        disabled={loading}
                                        full
                                        theme="secondary"
                                        label={'Back'}
                                        onClick={() => props.onBack()}></Button>
                                </div>
                            ) : <div className="w-1/3 pr-1.5">
                                <Button
                                    disabled={loading}
                                    full
                                    theme="secondary"
                                    label={'Back'}
                                    onClick={() => props.onMain()}></Button>
                            </div>}
                            <div className="w-2/3 pl-1.5">
                                <Button
                                    full
                                    loading={loading}
                                    theme="success"
                                    label={'Update'}
                                    onClick={() => Submit()}></Button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div >
    )
}
