import Sleep from '@/lib/sleep'
import { AuthContext } from '@/context/auth'
import { Transition } from '@headlessui/react'
import { useState, useContext, useEffect } from 'react'
import Button from '../ui/Button'
import { useParams } from 'react-router-dom'
import Alert from '../ui/Alert'
import Label from '../ui/Label'
import {
	faExclamationCircle,
	faPaperPlane,
	faRedoAlt,
} from '@fortawesome/pro-duotone-svg-icons'
import ActionButton from '../ui/ActionButton'
import { faFingerprint } from '@fortawesome/pro-solid-svg-icons'
import InputDigits from '../ui/InputDigits'
import SessionsService from '@/services/sessions'
import * as Notification from '@/components/ui/Notification'

export default function ExportSession(props) {
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)
	const { app_id } = useParams()
	const [auth] = useContext(AuthContext)
	const [resendLoading, setResendLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState('Error')
	const [authCode, setAuthCode] = useState('')


	const statusList = {
		default: {
			type: 'default',
			icon: faRedoAlt,
			message: 'Resend auth code',
		},
		error: {
			type: 'error',
			icon: faExclamationCircle,
			message: errorMessage,
		},
		success: {
			type: 'success',
			icon: faPaperPlane,
			message: 'Email sent',
		},
	}
	const [status, setStatus] = useState(statusList['default'])


	async function Verify() {
		setLoading(true)
		let body = {
			'code': authCode,
			'status': props.status,
		}

		await SessionsService.GetAppExport(app_id, body).then(async (data) => {
			let csvContent = 'data:text/csv;charset=utf-8,' + data.map(e => e.join(',')).join('\n')
			let encodedUri = encodeURI(csvContent)
			var link = document.createElement('a')
			link.setAttribute('href', encodedUri)
			link.setAttribute('download', 'export_' + app_id + '.csv')
			document.body.appendChild(link)
			link.click()
			setLoading(false)
		}).catch((err) => {
			Notification.error(err.message)
			setLoading(false)
		})
	}

	function resetAction() {
		setStatus(statusList['default'])
	}

	useEffect(async () => {
		sendCode()
		return () => { }
	}, [])

	async function sendCode() {
		setResendLoading(true)
		setError(null)

		await Sleep(1000)

		await SessionsService.SendExportCode(app_id)
			.then(() => {
				setResendLoading(false)
				setStatus(statusList['success'])
			})
			.catch((err) => {
				setResendLoading(false)
				setErrorMessage(err.message)
				setStatus(statusList['error'])
			})
		return () => { }
	}



	return (
		<Transition
			show={props.open}
			enter="ease-out duration-200"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="ease-in duration-100"
			leaveFrom="opacity-100"
			leaveTo="opacity-0">
			<div className="fixed z-10 inset-0 overflow-y-auto">
				<div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
					<div
						className="fixed inset-0 transition-opacity"
						aria-hidden="true"
						onClick={() => props.onClose()}>
						<div className="absolute inset-0 bg-gray-800 opacity-75"></div>
					</div>

					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true">
						&#8203;
					</span>

					<div
						className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
						role="dialog"
						aria-modal="true"
						aria-labelledby="modal-headline">
						<div className="p-4">
							<div>
								<div>
									{error ? (
										<Alert type="error" title={error}></Alert>
									) : (
										<Alert
											type="email"
											title="Check your inbox"
											description={'Verification code has been sent to your email'}></Alert>
									)}
									<div className="flex justify-between">
										<div>
											<Label value="Export Code"></Label>
										</div>
										<div>
											<Label font="light" value={auth.user.email.length > 24 ? auth.user.email.slice(0, 24) + '...' : auth.user.email}></Label>
											{!loading ? (
												<ActionButton
													status={status}
													onClick={sendCode}
													onFinish={resetAction}
													loading={resendLoading}></ActionButton>
											) : null}
										</div>
									</div>
								</div>
								<div className="mt-3">
									<InputDigits value={[authCode, setAuthCode]} />
								</div>
								<div className="mt-4">
									<Button
										onClick={Verify}
										full
										icon={faFingerprint}
										disabled={authCode.trim().length != 6}
										loading={loading}
										label="Download Export"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Transition>
	)
}
