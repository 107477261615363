import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { useEffect, useState } from 'react'
import Button from '@/components/ui/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from '@/components/ui/Alert'
import Input from '@/components/ui/Input'
import AppsService from '@/services/apps'
import { useParams } from 'react-router-dom'
import SwitchGreen from '@/components/ui/SwitchGreen'
import Sleep from '@/lib/sleep'

export default function ViewUser(props) {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)
    const [ready, setReady] = useState(false)
    const [success, setSuccess] = useState(false)
    let { app_id } = useParams()


    const [admin, setAdmin] = useState(false)
    const [compliance, setCompliance] = useState(false)
    const [developer, setDeveloper] = useState(false)
    const [accountant, setAccountant] = useState(false)


    useEffect(() => {
        GetTeamMember()
        return () => { }
    }, [])

    async function GetTeamMember() {
        setReady(false)
        AppsService.GetTeamMember(app_id, props.member_id).then((data) => {
            setData(data)
            setPermissions(data.roles)
            setLoading(false)
            setReady(true)
        }).catch((err) => {
            setError(err.message)
            setLoading(false)
        })
    }

    function setPermissions(roles) {
        if (roles.includes('ADMIN')) {
            setAdmin(true)
        }

        if (roles.includes('COMPLIANCE')) {
            setCompliance(true)
        }


        if (roles.includes('DEVELOPER')) {
            setDeveloper(true)
        }


        if (roles.includes('BILLING')) {
            setAccountant(true)
        }
    }

    function Submit() {

        let appRoles = []

        if (admin) {
            appRoles.push('ADMIN')
        }

        if (compliance) {
            appRoles.push('COMPLIANCE')
        }

        if (developer) {
            appRoles.push('DEVELOPER')
        }

        if (accountant) {
            appRoles.push('BILLING')
        }

        const body = {
            roles: appRoles,
        }

        setLoading(true)

        AppsService.UpdateTeamMember(app_id, props.member_id, body).then(async () => {
            setSuccess(true)
            await Sleep(1000)
            props.onFinish()
            setLoading(false)
        }).catch((err) => {
            setError(err.message)
            setLoading(false)
        })
    }

    function DeleteTeamMember() {
        setLoading(true)

        AppsService.DeleteTeamMember(app_id, props.member_id).then(async () => {
            setSuccess(true)
            await Sleep(1000)
            props.onFinish()
            setLoading(false)
        }).catch((err) => {
            setError(err.message)
            setLoading(false)
        })
    }

    return (
        <div className='p-4 createapp-view'>
            <div className="flex justify-between">
                <div>
                    <h2 className="text-xl">Edit member permissions</h2>
                </div>
                <div>
                    <h2 className="text-xl"><span><FontAwesomeIcon onClick={() => props.onClose()} className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-100 ease-in" icon={faTimes}></FontAwesomeIcon></span></h2>
                </div>
            </div>
            <hr className="my-3" />
            <div>
                {error ? (
                    <Alert type="error" title={error}></Alert>
                ) : null}
            </div>
            {ready ? <div className="mt-4">
                <div className="flex flex-wrap">
                    <div className="w-1/2 pr-2">
                        <Input label="Firstname" disabled value={data.firstname} placeholder="John" />
                    </div>
                    <div className="w-1/2 pl-2">
                        <Input label="Lastname" disabled value={data.lastname} placeholder="Doe" />
                    </div>
                </div>
                <div className="mt-3">
                    <Input label="Email" disabled value={data.email} placeholder="john.doe@gmail.com" />
                </div>
                <div className="mt-4">
                    <label
                        className="block text-sm font-medium text-primary-700">
                        Roles
                    </label>
                    <p className="text-sm mb-3 text-gray-600">Grant permissions to your collaborator according to his role</p>

                    <div className="flex flex-wrap">
                        <div className="w-full">
                            <div className="flex flex-wrap justify-between border border-gray-200 p-2 rounded-xl">
                                <div className={'flex flex-1'}>
                                    <div>
                                        <img src="/img/icons/ui/icon-admin.svg"></img>
                                    </div>
                                    <div className="ml-3">
                                        <p className="font-medium text-primary-700">Admin</p>
                                        <p className="text-gray-500 text-xs">View and manage everything</p>
                                    </div>
                                </div>
                                <div className="mt-2 mr-2">
                                    <SwitchGreen checked={admin}
                                        onChange={setAdmin} />
                                </div>
                            </div>
                        </div>
                        <div className="w-full mt-4">
                            <div className="flex flex-wrap justify-between border border-gray-200 p-2 rounded-xl">
                                <div className={'flex flex-1'}>
                                    <div>
                                        <img src="/img/icons/ui/icon-compliance-officer.svg"></img>
                                    </div>
                                    <div className="ml-3">
                                        <p className="font-medium text-primary-700">Compliance Officer</p>
                                        <p className="text-gray-500 text-xs">View and manage users, flow and compliance decisions</p>
                                    </div>
                                </div>
                                <div className="mt-2 mr-2">
                                    <SwitchGreen checked={compliance}
                                        onChange={setCompliance} />
                                </div>
                            </div>
                        </div>
                        <div className="w-full mt-4">
                            <div className="flex flex-wrap justify-between border border-gray-200 p-2 rounded-xl">
                                <div className={'flex flex-1'}>
                                    <div>
                                        <img src="/img/icons/ui/icon-developer.svg"></img>
                                    </div>
                                    <div className="ml-3">
                                        <p className="font-medium text-primary-700">Developer</p>
                                        <p className="text-gray-500 text-xs">View and manage credentials, flow and webhooks</p>
                                    </div>
                                </div>
                                <div className="mt-2 mr-2">
                                    <SwitchGreen checked={developer}
                                        onChange={setDeveloper} />
                                </div>
                            </div>
                        </div>
                        <div className="w-full mt-4">
                            <div className="flex flex-wrap justify-between border border-gray-200 p-2 rounded-xl">
                                <div className={'flex flex-1'}>
                                    <div>
                                        <img src="/img/icons/ui/icon-accountant.svg"></img>
                                    </div>
                                    <div className="ml-3">
                                        <p className="font-medium text-primary-700">Accountant</p>
                                        <p className="text-gray-500 text-xs">View and manage billing</p>
                                    </div>
                                </div>
                                <div className="mt-2 mr-2">
                                    <SwitchGreen checked={accountant}
                                        onChange={setAccountant} />
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <div className="mt-5">
                    {success ? <Button icon={faCheck} full theme="success" label="Success"></Button> : <>
                        {!admin && !compliance && !developer && !accountant ? <Button loading={loading} full theme="error" label="Remove member" onClick={() => DeleteTeamMember()}></Button> : <Button loading={loading} full theme="primary" label="Edit" onClick={() => Submit()}></Button>}
                    </>}
                </div>
            </div> : null}

        </div>
    )
}
