import AuthGuard from '@/guard/auth'
import AppNavbar from '@/components/menu/AppNavbar'
import AppFlowPage from '@/components/pages/app/AppFlowPage'
import AppGuard from '@/guard/app'
import AppIamGuard from '@/guard/appiam'

function Flow() {
    return (
        <AuthGuard>
            <AppGuard>
                <AppIamGuard page="flow">
                    <div className='relative min-h-screen flex flex-col'>
                        <AppNavbar></AppNavbar>
                        <AppFlowPage></AppFlowPage>
                    </div>
                </AppIamGuard>
            </AppGuard>
        </AuthGuard>
    )
}

export default Flow
