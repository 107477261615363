import { useHistory } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "@/context/app";

export default function AppIamGuard(props) {
  const [app] = useContext(AppContext);
  const [layout, setLayout] = useState(null);
  const router = useHistory();

  useEffect(() => {
    if (app.ready) {
      if (app.iam.admin === true || app.iam.owner === true) {
        setLayout(props.children);
        return () => {};
      }

      // Developer
      if (props.page === "session") {
        setLayout(props.children);
        return () => {};
      }

      if (props.page === "developer" && app.iam.developer === true) {
        setLayout(props.children);
        return () => {};
      }

      // Compliance
      if (props.page === "tasks" && app.iam.compliance === true) {
        setLayout(props.children);
        return () => {};
      }

      if (props.page === "billing" && app.iam.compliance === true) {
        setLayout(props.children);
        return () => {};
      }
      window.location.href = `/app/${app.id}/`;
    }
    return () => {};
  }, [app]);

  return layout;
}
