import { ManagerAuth } from '@/services/axios'

export default {
	GetTasks(app) {
		return ManagerAuth.get(
			`${process.env.REACT_APP_MANAGER_HOST}/v3/tasks/sandbox/${app}`
		)
	},
	UpdateTask(app, body) {
		return ManagerAuth.put(
			`${process.env.REACT_APP_MANAGER_HOST}/v3/tasks/sandbox/${app}`, body
		)
	},
}