import AuthGuard from '@/guard/auth'
import AppNavbar from '@/components/menu/AppNavbar'
import AppCorporatSession from '@/components/pages/app/AppCorporateSession'
import AppGuard from '@/guard/app'
import AppIamGuard from '@/guard/appiam'

function CorporateSession() {
    return (
        <AuthGuard>
            <AppGuard>
                <AppIamGuard page="session">
                    <div className='relative min-h-screen flex flex-col'>
                        <AppNavbar></AppNavbar>
                        <AppCorporatSession></AppCorporatSession>
                    </div>
                </AppIamGuard>
            </AppGuard>
        </AuthGuard>
    )
}

export default CorporateSession
