import { useContext, useEffect, useState } from "react";
import {
  faCabinetFiling,
  faLayerGroup,
} from "@fortawesome/pro-duotone-svg-icons";
import Button from "@/components/ui/Button";
import Footer from "@/components/menu/Footer";
import SubNav from "@/components/menu/SubNav";
import CreateNewFlow from "@/components/flow/CreateNewFlow";
import AmlFlow from "@/components/flow/AmlFlow";
import FlowIdentityUpload from "@/components/flow/FlowIdentityUpload";
import FlowIdentityDocuments from "@/components/flow/FlowIdentityDocuments";
import FlowResidencyDocuments from "@/components/flow/FlowResidencyDocuments";
import FlowRestrictedCountries from "@/components/flow/FlowRestrictedCountries";
import AmlFlowCriterias from "@/components/flow/AmlFlowCriterias";
import AmlFlowMonitoring from "@/components/flow/AmlFlowMonitoring";
import AmlFlowDecisionMaker from "@/components/flow/AmlFlowDecisionMaker";
import LivenessFlow from "@/components/flow/LivenessFlow";
import LivenessUniqueness from "@/components/flow/LivenessUniqueness";
import FlowSteps from "@/components/flow/FlowSteps";
import CorporateFlowSteps from "@/components/flow/CorporateFlowSteps";
import { Modal } from "react-responsive-modal";
import { AppContext } from "@/context/app";
import { useHistory, useParams } from "react-router-dom";
import IdentityFlow from "@/components/flow/IdentityFlow";
import ResidencyFlow from "@/components/flow/ResidencyFlow";
import NotificationFlow from "@/components/flow/NotificationFlow";
import NotificationMethodFlow from "@/components/flow/NotificationMethodFlow";

export default function AppFlowPage() {
  const [app] = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(null);
  const [country, setCountry] = useState("");
  const router = useHistory();
  const [flowKey, setFlowKey] = useState(0);
  const [stepId, setStepId] = useState(0);
  const { app_id } = useParams();

  function openFlow() {
    setStep("main");
    setOpen(true);
  }

  function openStep(id, type) {
    setStepId(id);
    setStep(type);
    setOpen(true);
  }

  function setDocument(step, country) {
    setCountry(country);
    setStep(step);
  }

  useEffect(() => {
    if (!open) {
      setFlowKey(flowKey + 1);
    }
  }, [open]);

  return (
    <div>
      <div>
        <SubNav></SubNav>
        <div className="max-w-7xl mx-auto relative">
          <div className="dashboard-container px-3">
            <div className="flex justify-between mt-3">
              <div>
                <div className="flex">
                  <div
                    className="cursor-pointer"
                    onClick={() => (window.location.href = `/app/${app_id}/`)}
                  >
                    <img
                      className="w-16 h-16 mt-1 rounded-xl"
                      src={app.logo_url}
                    />
                  </div>
                  <div className="ml-5">
                    <h1 className="text-3xl text-white">
                      <span className="font-bold">Verification Flow</span>
                    </h1>
                    <h3 className="text-lg mt-2 text-bluegray-300">
                      Manage your compliance requirements
                    </h3>
                  </div>
                </div>
              </div>
              <div className="flex">
                {(app.iam.owner || app.iam.admin || app.iam.compliance) &&
                app.type !== "CORPORATE" ? (
                  <div className="flex mt-2.5">
                    <div className="mr-3">
                      <Button
                        onClick={() =>
                          (window.location.href = `/app/${app_id}/tier`)
                        }
                        currentBg="primary"
                        theme="secondary"
                        icon={faCabinetFiling}
                        label="Manager tiers"
                      ></Button>
                    </div>
                    <Button
                      onClick={() => openFlow()}
                      currentBg="primary"
                      theme="success"
                      icon={faLayerGroup}
                      label="Add new step"
                    ></Button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-7xl mx-auto relative">
          {app.type === "CORPORATE" ? <CorporateFlowSteps /> : null}
          {app.type === "WORKFLOW" ? (
            <>
              <div className="flex flex-wrap items-start mt-6">
                <FlowSteps
                  onAdd={() => openFlow()}
                  key={flowKey}
                  openStep={(stepId, newStep) => openStep(stepId, newStep)}
                ></FlowSteps>
              </div>
              <Modal
                open={open}
                showCloseIcon={true}
                closeIcon={true}
                onClose={() => setOpen(false)}
                center
              >
                {step === "main" ? (
                  <CreateNewFlow
                    onFinish={(stepId, newStep) => openStep(stepId, newStep)}
                    onClose={() => setOpen(false)}
                  />
                ) : null}
                {step === "IDENTITY_DOCUMENTS" ? (
                  <FlowIdentityDocuments
                    country={country}
                    step_id={stepId}
                    onMain={() => setStep("IDENTITY")}
                    onBack={() => setStep("IDENTITY")}
                    onClose={() => setOpen(false)}
                  />
                ) : null}
                {step === "IDENTITY_UPLOAD" ? (
                  <FlowIdentityUpload
                    step_id={stepId}
                    onMain={() => setStep("IDENTITY")}
                    onBack={() => setStep("IDENTITY")}
                    onClose={() => setOpen(false)}
                  />
                ) : null}
                {step === "IDENTITY_COUNTRY" ? (
                  <FlowRestrictedCountries
                    managedDocuments={(country) =>
                      setDocument("IDENTITY_DOCUMENTS", country)
                    }
                    type="IDENTITY"
                    step_id={stepId}
                    onBack={() => setStep("IDENTITY")}
                    onClose={() => setOpen(false)}
                  />
                ) : null}
                {step === "RESIDENCY_DOCUMENTS" ? (
                  <FlowResidencyDocuments
                    country={country}
                    step_id={stepId}
                    onMain={() => setStep("RESIDENCY")}
                    onBack={() => setStep("RESIDENCY")}
                    onClose={() => setOpen(false)}
                  />
                ) : null}
                {step === "RESIDENCY_COUNTRY" ? (
                  <FlowRestrictedCountries
                    managedDocuments={(country) =>
                      setDocument("RESIDENCY_DOCUMENTS", country)
                    }
                    type="RESIDENCY"
                    step_id={stepId}
                    onBack={() => setStep("RESIDENCY")}
                    onClose={() => setOpen(false)}
                  />
                ) : null}
                {step === "IDENTITY" ? (
                  <IdentityFlow
                    manageable={true}
                    onManage={() => {}}
                    managedUpload={() => setStep("IDENTITY_UPLOAD")}
                    managedDocuments={() =>
                      setDocument("IDENTITY_DOCUMENTS", "")
                    }
                    managedRestrictions={(country) =>
                      setDocument("IDENTITY_COUNTRY", country)
                    }
                    step_id={stepId}
                    onClose={() => setOpen(false)}
                  />
                ) : null}
                {step === "RESIDENCY" ? (
                  <ResidencyFlow
                    manageable={true}
                    onManage={() => {}}
                    managedDocuments={() =>
                      setDocument("RESIDENCY_DOCUMENTS", "")
                    }
                    managedRestrictions={(country) =>
                      setDocument("RESIDENCY_COUNTRY", country)
                    }
                    step_id={stepId}
                    onClose={() => setOpen(false)}
                  />
                ) : null}
                {step === "PHONE" ? (
                  <FlowRestrictedCountries
                    type="PHONE"
                    step_id={stepId}
                    onBack={() => setOpen(false)}
                    onClose={() => setOpen(false)}
                  />
                ) : null}
                {step === "AML" ? (
                  <AmlFlow
                    manageable={true}
                    manageDecision={() => setStep("AML_DECISION_MAKER")}
                    manageMonitoring={() => setStep("AML_MONITORING")}
                    manageCriterias={() => setStep("AML_CRITERIAS")}
                    step_id={stepId}
                    onClose={() => setOpen(false)}
                  />
                ) : null}
                {step === "AML_CRITERIAS" ? (
                  <AmlFlowCriterias
                    type="AML"
                    step_id={stepId}
                    onBack={() => setStep("AML")}
                    onClose={() => setOpen(false)}
                  />
                ) : null}
                {step === "AML_MONITORING" ? (
                  <AmlFlowMonitoring
                    type="AML"
                    step_id={stepId}
                    onBack={() => setStep("AML")}
                    onClose={() => setOpen(false)}
                  />
                ) : null}
                {step === "AML_DECISION_MAKER" ? (
                  <AmlFlowDecisionMaker
                    type="AML"
                    step_id={stepId}
                    onBack={() => setStep("AML")}
                    onClose={() => setOpen(false)}
                  />
                ) : null}
                {step === "LIVENESS" ? (
                  <LivenessFlow
                    manageable={true}
                    manageUniqueness={() => setStep("LIVENESS_UNIQUENESS")}
                    step_id={stepId}
                    onClose={() => setOpen(false)}
                  />
                ) : null}
                {step === "NOTIFICATION" ? (
                  <NotificationFlow
                    manageMethod={() => setStep("NOTIFICATION_METHOD")}
                    manageable={true}
                    step_id={stepId}
                    onClose={() => setOpen(false)}
                  />
                ) : null}
                {step === "NOTIFICATION_METHOD" ? (
                  <NotificationMethodFlow
                    onBack={() => setStep("NOTIFICATION")}
                    manageable={true}
                    step_id={stepId}
                    onClose={() => setOpen(false)}
                  />
                ) : null}
                {step === "LIVENESS_UNIQUENESS" ? (
                  <LivenessUniqueness
                    type="LIVENESS"
                    step_id={stepId}
                    onBack={() => setStep("LIVENESS")}
                    onClose={() => setOpen(false)}
                  />
                ) : null}
              </Modal>
            </>
          ) : null}
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
}
