import axios from "axios";
import Cookies from "js-cookie";
import { b64Decode } from "@/lib/b64";

const ManagerAuth = axios.create();
ManagerAuth.interceptors.request.use((request) => {
	let s_auth = Cookies.get("s_auth");
	s_auth = JSON.parse(b64Decode(s_auth));
	const access_token = s_auth.auth.access_token;
	request.headers["Authorization"] = access_token;
	return request;
});

ManagerAuth.interceptors.response.use(
	(response) => {
		return response.data;
	},
	(error) => {
		if (error.response.status === 401) {
			Cookies.remove("s_auth");
			if (window.location.pathname !== "/") {
				const pathName = encodeURIComponent(window.location.pathname);
				window.location.href = `/login?redirect_uri=${pathName}`;
			} else {
				window.location.href = `/login`;
			}
			return Promise.reject({
				api_code: "AUTH_SESSION_EXPIRED",
				message: "Session expired",
			});
		}
		if (error.response === undefined) {
			return Promise.reject({
				api_code: "NETWORK_ERROR",
				message: "Network Error",
			});
		}

		return Promise.reject(error.response.data);
	}
);


const ManagerPublic = axios.create();
ManagerPublic.interceptors.request.use((request) => {
	return request;
});

ManagerPublic.interceptors.response.use(
	(response) => {
		return response.data;
	},
	(error) => {
		console.log(error);
		if (error.response === undefined) {
			return Promise.reject({
				api_code: "NETWORK_ERROR",
				message: "Network Error",
			});
		}
		return Promise.reject(error.response.data);
	}
);

export { ManagerAuth, ManagerPublic };