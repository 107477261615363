import { ManagerAuth } from "@/services/axios";

export default {
  GetApps(page, search) {
    return ManagerAuth.get(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/apps/list?page=${page}&search=${search}`
    );
  },
  Search(app_id, body, searchMode, cancelToken) {
    console.log("search mode axios = ", searchMode);
    return ManagerAuth.post(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/apps/search/details/${app_id}?mode=${searchMode}`,
      body,
      { cancelToken: cancelToken.token }
    );
  },
  GetDashboardInsights(app_id) {
    return ManagerAuth.get(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/apps/dashboard/${app_id}`
    );
  },
  CreateApp(body) {
    return ManagerAuth.post(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/apps/create`,
      body
    );
  },
  GetAppRoles(app_id) {
    return ManagerAuth.get(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/apps/team/${app_id}/list`
    );
  },
  GetInfo(app_id) {
    return ManagerAuth.get(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/apps/info/${app_id}`
    );
  },
  CreateNewRole(app_id, body) {
    return ManagerAuth.post(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/apps/team/${app_id}/new_member`,
      body
    );
  },
  GetTeamMember(app_id, member_id) {
    return ManagerAuth.get(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/apps/team/${app_id}/member/${member_id}`
    );
  },
  DeleteTeamMember(app_id, member_id) {
    return ManagerAuth.delete(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/apps/team/${app_id}/member/${member_id}`
    );
  },
  UpdateTeamMember(app_id, member_id, body) {
    return ManagerAuth.post(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/apps/team/${app_id}/member/${member_id}`,
      body
    );
  },
  CreateNewFlow(app_id, body) {
    return ManagerAuth.post(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/apps/step/${app_id}`,
      body
    );
  },
  GetStepDetails(app_id, step_id) {
    return ManagerAuth.get(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/apps/steps/${app_id}/details?step_id=${step_id}`
    );
  },
  DeleteStep(app_id, step_id) {
    return ManagerAuth.delete(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/apps/step/${app_id}?step_id=${step_id}`
    );
  },
  GetFlow(app_id) {
    return ManagerAuth.get(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/apps/steps/${app_id}`
    );
  },
  GetTiers(app_id) {
    return ManagerAuth.get(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/settings/tiers/${app_id}`
    );
  },
  AddFlowTiers(app_id, body) {
    return ManagerAuth.post(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/settings/tiers/${app_id}`,
      body
    );
  },
  DeleteFlowTiers(app_id, name) {
    return ManagerAuth.delete(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/settings/tiers/${app_id}?name=${name}`
    );
  },
  GetCorporateCompliance(app_id) {
    return ManagerAuth.get(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/settings/corporate/info/${app_id}`
    );
  },
  SubmitAML(app_id, body) {
    return ManagerAuth.put(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/settings/aml/${app_id}`,
      body
    );
  },
  GetDuplicate(app_id) {
    return ManagerAuth.get(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/settings/duplicate/${app_id}`
    );
  },
  SetDuplicate(app_id, body) {
    return ManagerAuth.put(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/settings/duplicate/${app_id}`,
      body
    );
  },
  GetAmlInfo(app_id) {
    return ManagerAuth.get(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/settings/aml/${app_id}/info`
    );
  },
  AddCountryRestriction(app_id, step_id, body) {
    return ManagerAuth.post(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/settings/country/${app_id}?step_id=${step_id}`,
      body
    );
  },
  AddRestrictions(app_id, step_id, body) {
    return ManagerAuth.post(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/settings/restrictions/${app_id}?step_id=${step_id}`,
      body
    );
  },
  AddDocumentsRestrictions(app_id, step_id, body) {
    return ManagerAuth.post(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/settings/documents/restrictions/${app_id}?step_id=${step_id}`,
      body
    );
  },
  AddCorporateIdentityRestrictions(app_id, body) {
    return ManagerAuth.post(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/settings/corporate/ubo/identity/${app_id}`,
      body
    );
  },
  AddCorporateResidencyRestrictions(app_id, body) {
    return ManagerAuth.post(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/settings/corporate/ubo/residency/${app_id}`,
      body
    );
  },
  AddCorporateIdentityDocumentsRestrictions(app_id, body) {
    return ManagerAuth.post(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/settings/corporate/ubo/documents/identity/${app_id}`,
      body
    );
  },
  AddCorporateResidencyDocumentsRestrictions(app_id, body) {
    return ManagerAuth.post(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/settings/corporate/ubo/documents/residency/${app_id}`,
      body
    );
  },
  GenerateNewCred(body, app_id) {
    return ManagerAuth.put(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/apps/credentials/${app_id}`,
      body
    );
  },
  RequestProduction(app_id) {
    return ManagerAuth.put(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/apps/production/${app_id}`,
      null
    );
  },
  AddCorporateDocumentRestriction(app_id, body) {
    return ManagerAuth.post(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/settings/corporate/document/${app_id}`,
      body
    );
  },
  AddCorporateCountryRestriction(app_id, body) {
    return ManagerAuth.post(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/settings/corporate/country/${app_id}`,
      body
    );
  },
  UpdateUploadMethod(app_id, step_id, body) {
    return ManagerAuth.put(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/settings/upload/method/${app_id}?step_id=${step_id}`,
      body
    );
  },
  UpdateCorporateUploadMethod(app_id, body) {
    return ManagerAuth.put(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/settings/corporate/upload/method/${app_id}`,
      body
    );
  },
  GetRetrictedCountryExport(app_id, step_id) {
    return ManagerAuth.get(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/settings/export/${app_id}/restricted_countries?step_id=${step_id}`
    );
  },
  GetCorporateIdentityRetrictedCountryExport(app_id, step_id) {
    return ManagerAuth.get(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/settings/export/${app_id}/identity/restricted_countries?step_id=${step_id}`
    );
  },
  GetCorporateResidencyRetrictedCountryExport(app_id, step_id) {
    return ManagerAuth.get(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/settings/export/${app_id}/residency/restricted_countries?step_id=${step_id}`
    );
  },
  GetCorporateRetrictedCountryExport(app_id, step_id) {
    return ManagerAuth.get(
      `${process.env.REACT_APP_MANAGER_HOST}/v3/settings/export/${app_id}/restricted_countries?step_id=${step_id}`
    );
  },
};
