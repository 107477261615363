import { useEffect, useState } from 'react'
import AppsService from '@/services/apps'
import { useParams } from 'react-router-dom'
import IdentityFlow from '@/components/flow/IdentityFlow'
import ResidencyFlow from '@/components/flow/ResidencyFlow'
import LivenessFlow from '@/components/flow/LivenessFlow'
import PhoneFlow from '@/components/flow/PhoneFlow'
import AmlFlow from '@/components/flow/AmlFlow'
import NotificationFlow from '@/components/flow/NotificationFlow'
import FlowItemPlaceholder from '@/components/placeholder/FlowItemPlaceholder'
import Sleep from '@/lib/sleep'

export default function FlowSteps(props) {
	const [ready, setReady] = useState(false)
	const [data, setData] = useState(null)

	let { app_id } = useParams()

	useEffect(async () => {
		GetFlow()
		return () => { }
	}, [])

	useEffect(() => {
		GetFlow()
		return () => { }
	}, [props.refresh])

	async function GetFlow() {
		setReady(false)
		await AppsService.GetFlow(app_id)
			.then(async (data) => {
				setData(data)
				await Sleep(1000)
				setReady(true)
			})
		return () => { }
	}


	return (
		<>
			{ready ? <>
				{Object.keys(data.steps).map(function (key) {
					return (
						<>
							{data.steps[key].step === 'IDENTITY' ? <IdentityFlow manageable={false} step_id={key} onManage={() => props.openStep(key, data.steps[key].step)} /> : null}
							{data.steps[key].step === 'RESIDENCY' ? <ResidencyFlow manageable={false} step_id={key} onManage={() => props.openStep(key, data.steps[key].step)} /> : null}
							{data.steps[key].step === 'LIVENESS' ? <LivenessFlow manageable={false} step_id={key} onManage={() => props.openStep(key, data.steps[key].step)} /> : null}
							{data.steps[key].step === 'PHONE' ? <PhoneFlow manageable={false} step_id={key} onManage={() => props.openStep(key, data.steps[key].step)} /> : null}
						</>
					)
				})}
				<>{data.aml.activated ? <AmlFlow manageable={false} step_id={0} onManage={() => props.openStep(0, 'AML')} /> : null}</>
				<>{data.notification.active ? <NotificationFlow manageable={false} step_id={0} onManage={() => props.openStep(0, 'NOTIFICATION')} /> : null}</>

				<>
					<div className={'step-view w-full sm:w-1/2 lg:w-1/3 my-4'}>
						<div className={'z-max w-full h-full flex items-center justify-center px-3'}>
							<div onClick={() => props.onAdd()} className={'rounded-xl cursor-pointer step-view hover:shadow-lg bg-white border border-gray-200 hover:border-gray-300 transition-25 items-center transition-25 transition duration-200 ease-in flex flex-col w-full h-full'}>
								<div className="h-full">
									<img className="mt-32 h-28 w-28 mx-auto border border-transparent rounded-2xl" src={'/img/icons/ui/icon-plus.svg'} />
									<div className="my-5 mb-12 text-center">
										<p className='text-regular font-regular font-bold'>
											<span>Add</span>
										</p>
										<p className='text-regular text-sm font-regular'>
											{data.nb_steps === 0 ? <span className="text-gray-500">your first step</span> : null}
											{data.nb_steps > 0 ? <span className="text-gray-500">new step</span> : null}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>

				{/* {data.nb_steps + 1 < 5 ? <>
					{Array(6 - (data.nb_steps + 1 + (data.aml.activated ? 1 : 0))).fill().map((i) => {
						return (<div key={i} className={'step-view w-full sm:w-1/2 lg:w-1/3 my-4'}>
							<div className={'z-max w-full h-full flex items-center justify-center px-3'}>
								<div className={'rounded-xl bg-white border border-gray-200 transition-25 step-view transition duration-200 ease-in flex flex-col w-full h-full'}>
									<div>
										<div className="p-4 flex items-center">
											<div className="w-8 h-8 bg-primary-200 rounded-md"></div>
											<div className="ml-3 w-64 h-4 bg-gray-200 rounded-md"></div>
										</div>
										<div className="p-4 flex items-center">
											<div className="w-24 h-2 bg-gray-200 rounded-md"></div>
										</div>
										<div className="p-4 pt-0 flex items-center">
											<div className="mr-3 w-8 h-8 bg-bluegray-200 rounded-md"></div>
											<div className="mr-3 w-8 h-8 bg-bluegray-200 rounded-md"></div>
											<div className="mr-3 w-8 h-8 bg-bluegray-200 rounded-md"></div>
											<div className="mr-3 w-8 h-8 bg-bluegray-200 rounded-md"></div>
										</div>
										<div className="p-4 flex items-center">
											<div className="w-32 h-2 bg-gray-200 rounded-md"></div>
										</div>
										<div className="p-4 pt-0 flex items-center">
											<div className="mr-3 w-8 h-8 bg-bluegray-200 rounded-md"></div>
											<div className="mr-3 w-8 h-8 bg-bluegray-200 rounded-md"></div>
											<div className="mr-3 w-8 h-8 bg-bluegray-200 rounded-md"></div>
											<div className="mr-3 w-8 h-8 bg-bluegray-200 rounded-md"></div>
										</div>
									</div>
								</div>
							</div>
						</div>)
					})}
				</> : null} */}


			</> : <>
				<FlowItemPlaceholder />
				<FlowItemPlaceholder />
				<FlowItemPlaceholder />
				{/* <FlowItemPlaceholder />
				<FlowItemPlaceholder />
				<FlowItemPlaceholder /> */}
			</>}
		</>
	)
}
