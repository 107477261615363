import { faCheckCircle, faEllipsisHAlt, faTimes, faTimesCircle, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from '@/components/ui/Alert'
import AppsService from '@/services/apps'
import { useParams } from 'react-router-dom'
import Tooltip from '@/components/ui/Tooltip'

export default function IdentityFlow(props) {
    const [error, setError] = useState(null)
    const [data, setData] = useState(null)
    const [ready, setReady] = useState(false)
    const { app_id } = useParams()

    useEffect(() => {
        GetStepDetails()
        return () => { }
    }, [])

    function GetStepDetails() {
        AppsService.GetDuplicate(app_id).then((data) => {
            setData(data)
            setReady(true)
        }).catch((err) => {
            setError(err.message)
        })
    }

    function openManage() {
        if (!props.manageable) {
            props.onManage()
        }
    }

    function Delete() {
        AppsService.DeleteStep(app_id, props.step_id).then(() => {
            props.onClose()
        }).catch((err) => {
            setError(err.message)
        })
    }


    return (
        <div className={`${props.manageable ? 'modal-view' : 'step-view w-full sm:w-1/2 lg:w-1/3 my-4'}`}>
            <div className={`z-max w-full h-full flex items-center justify-center ${!props.manageable ? 'px-3' : ''}`}>
                <div onClick={() => openManage()} className={`${!props.manageable ? 'rounded-xl cursor-pointer step-view hover:shadow-lg bg-white border border-gray-200 hover:border-gray-300 transition-25 transition duration-200 ease-in' : ''} flex flex-col w-full h-full`}>
                    <header className="p-5 pb-3">
                        <div className="flex justify-between items-center">
                            <div className="flex items-center">
                                <div>
                                    <img className="h-9 relative mr-2" src="/img/icons/ui/look-in-camera.svg" />
                                </div>
                                <div>
                                    <h2 className="text-lg text-primary-500  font-medium"> Liveness
                                    </h2>
                                </div>
                            </div>
                            <div>
                                <div className="flex items-center ">
                                    <div>
                                        <Tooltip auto={true} content="Step ID">
                                            <span className="relative top-[-1px] text-xs bg-bluegray-200  px-2 py-0.5 rounded-md text-gray-500">{props.step_id}</span>
                                        </Tooltip>
                                    </div>
                                    <div>
                                        {props.manageable ?
                                            <h2 className="text-xl relative ml-3 top-0.5"><span><FontAwesomeIcon onClick={() => props.onClose()} className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-200 ease-in text-regular relative top-[-1px]" icon={faTimes}></FontAwesomeIcon></span></h2>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="my-3" />
                    </header>

                    <main
                        className="flex-1 overflow-y-auto p-5 py-0"
                        id="individual-main">
                        {ready ?
                            <>
                                <div className="flex flex-wrap">
                                    <div className="w-full">
                                        <div>
                                            {error ? (
                                                <Alert type="error" title={error}></Alert>
                                            ) : null}
                                            <div className="flex justify-between">
                                                <div>
                                                    <p className="text-sm text-primary-700">Uniqueness</p>
                                                </div>
                                                {props.manageable ? <div>
                                                    <p onClick={() => props.manageUniqueness()} className="text-xs py-0.5 cursor-pointer hover:bg-gray-300 ease-in duration-100 transition rounded-md bg-gray-200 px-2 text-primary-700"><FontAwesomeIcon className="mr-0.5" icon={faEllipsisHAlt} /> Manage</p>
                                                </div> : null}
                                            </div>
                                            <div>
                                                <div className="flex mt-2">
                                                    {data.status ? <p className="text-green-500"><FontAwesomeIcon className="mr-1" icon={faCheckCircle} /> Active</p> : <p><FontAwesomeIcon className="mr-1 text-gray-500" icon={faTimesCircle} /> Inactive</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </> : null}
                    </main>
                    {props.manageable ? <footer className="p-5 text-center">
                        <p onClick={() => Delete()} className="text-gray-400 hover:text-red-500 transition duration-200 ease-in cursor-pointer"><FontAwesomeIcon className="mr-1" icon={faTrash} /> Remove step</p>
                    </footer>
                        : null}
                </div>
            </div>
        </div>
    )
}
