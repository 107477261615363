import { useEffect } from 'react'
import AppsService from '@/services/apps'
import { useParams } from 'react-router'

export default function AppRedirectionV2() {
    const { app_id, session_id } = useParams()

    useEffect(() => {
        GetDashboardInsights()
    }, [])

    async function GetDashboardInsights() {
        await AppsService.GetDashboardInsights(app_id).then((data) => {
            if (data.app_type === 'WORKFLOW') {
                window.location = `/app/${app_id}/individual/${session_id}`
            } else {
                window.location = `/app/${app_id}/corporate/${session_id}`
            }
        }).catch(() => {
        })
    }

    return <></>
}
