import { useEffect, useState } from 'react'
import AppsService from '@/services/apps'
import { useParams } from 'react-router-dom'
import { useContext } from 'react'
import { AppContext } from '@/context/app'
import Input from '@/components/ui/Input'
import Button from '@/components/ui/Button'
import { faClock } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'react-responsive-modal'
import CredentialsConfirmation from './CredentialsConfirmation'
import { faUndo } from '@fortawesome/pro-solid-svg-icons'

export default function Credentials(props) {
	const [ready, setReady] = useState(false)
	const [openAPIKey, setOpenAPIKey] = useState(false)
	const [APIKeyType, setAPIKeyType] = useState('')
	const [loading, setLoading] = useState(false)

	const [app, appDispatch] = useContext(AppContext)

	let { app_id } = useParams()

	useEffect(async () => {
		GetAppRoles()
		console.log('app')
		console.log(app)
		return () => { }
	}, [])

	useEffect(() => {
		GetAppRoles()
		return () => { }
	}, [props.refresh])

	async function GetAppRoles() {
		setReady(false)
		await AppsService.GetAppRoles(app_id)
			.then(() => {
				setReady(true)
			})
		return () => { }
	}

	async function GetInfo() {
		await AppsService.GetInfo(app_id).then(async (data) => {
			await appDispatch({
				type: 'SET_APP',
				payload: data,
			})
		})
	}

	function OpenConfirmModal(type) {
		setAPIKeyType(type)
		setOpenAPIKey(true)
	}

	function CloseConfirmModal() {
		GetInfo()
		setOpenAPIKey(false)
	}

	function RequestProduction() {
		AppsService.RequestProduction(app_id).then(() => {
			setLoading(false)
			GetInfo()
		}).catch(() => {
			setLoading(false)
		})

	}


	return (
		<>
			{ready ? (
				<>
					<div className="flex flex-wrap mt-4">
						<div className="w-1/2">
							<div className="h-full m-4 border p-6 px-8 pb-4 mb-0 border-bluegray-150 items-center bg-white rounded-xl">
								<h3 className="text-2xl text-primary-800 mb-1 font-bold">Sandbox</h3>
								<p className="text-gray-500">Use sandbox credentials to try your integration. Sandbox API sessions are free of charge but does not use real-world data</p>
								<div className="mt-3">
									<Input disabled label="Client ID" value={app.client_id}></Input>
								</div>
								<div className="mt-3">
									<div className="flex justify-between">
										<div>
											<label
												className="block text-sm font-medium text-gray-700 mb-2">
												API Key
											</label>
										</div>
										<div>
											<label
												className="block text-sm font-medium text-red-700 mb-2 cursor-pointer" onClick={() => OpenConfirmModal('SANDBOX')}>
												<FontAwesomeIcon icon={faUndo} className="mr-1" />Reset
											</label>
										</div>
									</div>
									<Input disabled type='text' value={app.sandbox_api_key}></Input>
								</div>
							</div>
						</div>
						<div className="w-1/2">
							<div className="h-full m-4 p-6 px-8 pb-4 mb-0 border border-bluegray-150 items-center bg-white rounded-xl">
								<h3 className="text-2xl text-primary-800 mb-1 font-bold">Production</h3>
								<p className="text-gray-500">Use production credentials for your real-world application. Switch your credential from Sandbox to Production to verify real users.</p>

								{app.sandbox ? (
									<div>
										{app.production_requested === false ? (
											<div className="mt-5">
												<Button loading={loading} onClick={() => RequestProduction()} label="Promote to production" theme="success" />
											</div>
										) :
											<div className="mt-5">
												<p className="text-orange-500"><FontAwesomeIcon className="mr-1" icon={faClock} /> Your production promotion request is pending approval</p>
											</div>
										}
									</div>
								) :
									<div>
										<div className="mt-3">
											<Input disabled label="Client ID" value={app.client_id}></Input>
										</div>								<div className="mt-3">
											<div className="flex justify-between">
												<div>
													<label
														className="block text-sm font-medium text-gray-700 mb-2">
														API Key
													</label>
												</div>
												<div>
													<label
														className="block text-sm font-medium text-red-700 mb-2 cursor-pointer" onClick={() => OpenConfirmModal('PRODUCTION')}>
														<FontAwesomeIcon icon={faUndo} className="mr-1" />Reset

													</label>
												</div>
											</div>
											<Input disabled type='text' value={app.production_api_key}></Input>
										</div>
									</div>}
							</div>
						</div>
					</div>
					<Modal open={openAPIKey}
						showCloseIcon={true}
						closeIcon={true}
						onClose={() => CloseConfirmModal()}
						center>
						<CredentialsConfirmation onClose={() => CloseConfirmModal()} type={APIKeyType} />
					</Modal>
				</>
			) : (
				null
			)}

		</>
	)
}
