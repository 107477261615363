import AuthGuard from '@/guard/auth'
import HomeNavbar from '@/components/menu/HomeNavbar'
import SettingsPage from '@/components/pages/SettingsPage'

function Settings() {

	return (
		<AuthGuard>
			<div className='relative min-h-screen flex flex-col'>
				<HomeNavbar></HomeNavbar>
				<SettingsPage />
			</div>
		</AuthGuard>
	)
}

export default Settings
