import { useReducer, createContext } from 'react'

export const AuthContext = createContext()

const initialState = {
	user: {
		email: '',
		firstname: '',
		lastname: '',
		profile_pic: '',
	},
	iam: {
		admin: false,
		residency: false,
		identity: false,
		aml: false,
		corporate: false,
		facematch: false,
		dupplicate: false,
	},
	auth: {
		access_token: "",
		refresh_token: "",
	},
	authenticated: false,
	online_socket: {},
}

function setAuth(auth) {
	if (auth.ima !== undefined) {
		const newIAM = setIAM(auth.iam)
		auth.iam = newIAM
	}
	return auth
}

function setIAM(iam) {
	return {
		admin: iam.includes('ADMIN'),
		residency: iam.includes('RESIDENCY'),
		identity: iam.includes('IDENTITY'),
		aml: iam.includes('AML'),
		facematch: iam.includes('FACEMATCH'),
		corporate: iam.includes('CORPORATE'),
		dupplicate: iam.includes('DUPPLICATE'),
	}
}

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_AUTH':
			return setAuth(action.payload)
		default:
			return state
	}
}

export const AuthContextProvider = (props) => {
	const [state, dispatch] = useReducer(reducer, initialState)

	return (
		<AuthContext.Provider value={[state, dispatch]}>
			{props.children}
		</AuthContext.Provider>
	)
}
