import { useContext } from "react";
import Footer from "@/components/menu/Footer";
import SubNav from "@/components/menu/SubNav";
import SandboxTasks from "@/components/sandbox/SandboxTasks";
import { useHistory, useParams } from "react-router-dom";
import { AppContext } from "@/context/app";

export default function AppSandboxPage() {
  const { app_id } = useParams();
  const router = useHistory();
  const [app] = useContext(AppContext);

  return (
    <div>
      <div>
        <SubNav></SubNav>
        <div className="max-w-7xl mx-auto relative">
          <div className="dashboard-container px-3">
            <div className="flex justify-between mt-3">
              <div>
                <div className="flex">
                  <div
                    className="cursor-pointer"
                    onClick={() => (window.location.href = `/app/${app_id}/`)}
                  >
                    <img
                      className="w-16 h-16 mt-1 rounded-xl"
                      src={app.logo_url}
                    />
                  </div>
                  <div className="ml-5">
                    <h1 className="text-3xl text-white">
                      <span className="font-bold">Sandbox</span>
                    </h1>
                    <h3 className="text-lg mt-2 text-bluegray-300">
                      Test your Synaps integration easily
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto relative">
          <SandboxTasks></SandboxTasks>
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
}
