import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from "react-moment";
import Tooltip from "../ui/Tooltip";
import { useHistory, useParams } from "react-router-dom";
import {
  faAt,
  faLink,
  faCode,
  faLayerGroup,
} from "@fortawesome/pro-duotone-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

export default function SessionsTaskItem({ item }) {
  const router = useHistory();
  const { app_id } = useParams();

  return (
    <div className="w-full mb-3 p-2 rounded-xl cursor-pointer hover:border-gray-400 border bg-white transition-25 transition duration-200 ease-in">
      <a
        href={`/app/${app_id}/${
          item.session_type === "WORKFLOW" ? "individual" : "corporate"
        }/${item.session_id}`}
        target="_blank"
        rel="noreferrer"
        className="flex-shrink-0 group block"
      >
        <div className="w-full">
          <div className="ml-1">
            <div className="sm:flex justify-between">
              <div className="flex">
                <div className="p-2">
                  {item.session_type === "WORKFLOW" ? (
                    <img src="/img/icons/ui/icon-individual-2.svg" />
                  ) : (
                    <img src="/img/icons/ui/icon-corporate.svg" />
                  )}
                </div>
                <div className="ml-3 relative">
                  <p
                    className="text-sm font-bold mt-1.5"
                    data-openreplay-obscured
                  >
                    {item.session_type === "WORKFLOW" ? (
                      <>
                        {item.firstname !== "" || item.lastname !== ""
                          ? `${item.firstname} ${item.lastname}`
                          : "-"}
                      </>
                    ) : null}
                    {item.session_type === "CORPORATE" ? (
                      <>{item.name}</>
                    ) : null}
                  </p>
                  <h1 className="text-sm mt-1">
                    <Tooltip auto={true} content={"Session ID"}>
                      <span>
                        <FontAwesomeIcon
                          className="text-bluegray-500"
                          icon={faLink}
                        />{" "}
                        {item.session_id}
                      </span>
                    </Tooltip>
                    {item.alias ? (
                      <Tooltip auto={true} content={"Alias"}>
                        <span>
                          <FontAwesomeIcon
                            className="ml-2 text-bluegray-500"
                            icon={faAt}
                          />{" "}
                          {item.alias}
                        </span>
                      </Tooltip>
                    ) : null}
                    {item.sandbox ? (
                      <Tooltip auto={true} content={"Sandbox Session"}>
                        <span>
                          <FontAwesomeIcon
                            className="ml-2 text-bluegray-500"
                            icon={faCode}
                          />{" "}
                          Sandbox
                        </span>
                      </Tooltip>
                    ) : null}
                  </h1>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="mt-2 mr-2 text-right">
                  {item.state === 1 ? (
                    <p className="text-orange-500 text-sm">
                      <FontAwesomeIcon className="mr-0.5" icon={faLayerGroup} />{" "}
                      Open
                    </p>
                  ) : null}
                  {item.state === 2 ? (
                    <p className="text-green-500 text-sm">
                      <FontAwesomeIcon className="mr-0.5" icon={faCheck} />{" "}
                      Finished
                    </p>
                  ) : null}
                  <p className="ml-2 mt-1 items-center text-right text-sm text-gray-400">
                    <span>
                      <Moment unix fromNow>
                        {item.created_at / 1000}
                      </Moment>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}
