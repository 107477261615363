import { useHistory } from "react-router-dom";

export default function AppItem(props) {
  const router = useHistory();
  return (
    <div
      onClick={() => (window.location.href = `/app/${props._key}/`)}
      className="m-4 text-center border border-bluegray-150 items-center bg-white rounded-xl cursor-pointer transition-25 hover:shadow-lg hover:border-bluegray-300 transition duration-200 ease-in"
    >
      <div className="m-3 my-6 mt-12">
        <img
          className="h-28 w-28 mx-auto border border-bluegray-100 rounded-2xl"
          src={props.logo_url}
          alt={props.name}
        />
        <div className="my-5 mb-12">
          <p className="text-regular font-regular font-bold">
            <span>{props.name}</span>
          </p>
          <p className="text-regular text-sm font-regular">
            <span className="text-gray-500">
              {props.type === "WORKFLOW" ? "Individual" : "Corporate"}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
