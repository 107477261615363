import UboTreeSubCorporate from './UboTreeSubCorporate'
import UboTreeSubIndividual from './UboTreeSubIndividual'
import { useContext } from 'react'
import { CorporateSessionContext } from '@/context/corporate_session'
import { UboContext } from '@/context/ubo'
import { Modal } from 'react-responsive-modal'
import UboSingleCorporate from '@/components/sessions/corporate/ubo-tree/UboSingleCorporate'
import UboSingleIndividual from '@/components/sessions/corporate/ubo-tree/UboSingleIndividual'

export default function UboTreeCorporate() {

    const [corporate] = useContext(CorporateSessionContext)
    const [ubo, uboDispatch] = useContext(UboContext)

    function closeUbo() {
        uboDispatch({
            type: 'SET_CLOSE',
        })
    }

    return (
        <div>
            <div className='p-2.5 border border-gray-150 rounded-md'>
                <div>
                    <img
                        className='h-9 w-9 m-auto'
                        src='/img/icons/ui/icon-corporate.svg'
                        alt='Corporate'></img>
                    <p className='text-2xs mt-2 font-bold'>{corporate.corporate.info.name}</p>
                </div>
            </div>
            <ul>
                {corporate.corporate.beneficial_owners.members.map((member) => {
                    return (
                        <li key={member.member_id}>
                            <UboTreeSubIndividual key={member.member_id} member={member} />
                        </li>
                    )
                })}
                {corporate.corporate.beneficial_owners.corporates.map((corporate) => {
                    return (
                        <li key={corporate.registration_number}>
                            <UboTreeSubCorporate
                                corporate={corporate}
                            />
                        </li>
                    )
                })}
            </ul>
            <Modal open={ubo.open}
                showCloseIcon={true}
                closeIcon={true}
                onClose={() => closeUbo()}
                center>
                <>
                    {ubo.type === 'SUBCORPORATE' ? <UboSingleCorporate data={ubo.data} /> : null}
                    {ubo.type === 'INDIVIDUAL' ? <UboSingleIndividual data={ubo.data} /> : null}
                </>
            </Modal>
        </div>
    )
}