import UboTreeSubIndividual from './UboTreeSubIndividual'
import Tooltip from '@/components/ui/Tooltip'
import { UboContext } from '@/context/ubo'
import { useContext } from 'react'

export default function UboTreeSubCorporate({ corporate
}) {
    const [, uboDispatch] = useContext(UboContext)

    function openDetails(corporate) {
        uboDispatch({
            type: 'SET_OPEN',
            payload: {
                data: corporate,
                type: 'SUBCORPORATE'
            },
        })
        return () => { }
    }

    function UboCorporateDocState({ type, state }) {
        const doc_registry = {
            'COMPANY_BANK_STATEMENT': 'Company Bank Statement',
            'REGISTER_OF_DIRECTORS_SHAREHOLDERS': 'Registrer of UBOs',
            'MEMORANDUM_OF_ASSOCIATION': 'Memorandum of Association',
            'CERTIFICATE_OF_INCORPORATION': 'Certificate of Incorporation'
        }

        const state_registry = {
            'PENDING': '-pending',
            'VALIDATED': '-valid',
            'REJECTED': '-rejected',
            'NOT_STARTED': ''
        }

        return (<div className="flex">
            <div className="mr-3 mt-0.5 flex-shrink-0">
                <Tooltip auto={true} content={doc_registry[type]}>
                    <div>
                        {type === 'CERTIFICATE_OF_INCORPORATION' ? <img className="h-9" src={`/img/icons/ui/icon-corporate-certificate${state_registry[state]}.svg`}></img> : null}
                        {type === 'MEMORANDUM_OF_ASSOCIATION' ? <img className="h-9" src={`/img/icons/ui/icon-corporate-association${state_registry[state]}.svg`}></img> : null}
                        {type === 'REGISTER_OF_DIRECTORS_SHAREHOLDERS' ? <img className="h-9" src={`/img/icons/ui/icon-corporate-register${state_registry[state]}.svg`}></img> : null}
                        {type === 'COMPANY_BANK_STATEMENT' ? <img className="h-9" src={`/img/icons/ui/icon-corporate-bank-statement${state_registry[state]}.svg`}></img> : null}
                    </div>
                </Tooltip>
            </div>
        </div>)
    }

    return (
        <div>
            <div
                onClick={() => openDetails(corporate)}
                className='p-2.5 border border-gray-150 rounded-md hover:border-gray-400 transition duration-100 ease-in cursor-pointer'>
                <img
                    className='h-9 w-9 m-auto'
                    src='/img/icons/ui/icon-corporate.svg'
                    alt='Corporate'></img>
                <p className='text-2xs mt-2 font-bold'>{corporate.info.name}</p>
                <div className='text-left p-0 m-0'>
                    {Object.entries(corporate.documents).map(([slug]) => {
                        return <UboCorporateDocState key={slug} type={slug} state={corporate.documents[slug].state} />
                    })}
                </div>
            </div>
            <ul>
                {corporate.beneficial_owners.members.map((member) => {
                    return (
                        <li key={member.member_id}>
                            <UboTreeSubIndividual key={member.member_id} member={member} />
                        </li>
                    )
                })}
                {corporate.beneficial_owners.corporates.map((corporate) => {
                    return (
                        <li key={corporate.registration_number}>
                            <UboTreeSubCorporate
                                corporate={corporate}
                            />
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
