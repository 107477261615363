import { useContext } from 'react'
import Tooltip from '@/components/ui/Tooltip'
import { UboContext } from '@/context/ubo'

export default function UboTreeSubIndividual({ member
}) {

    const [, uboDispatch] = useContext(UboContext)


    function openDetails(individual) {
        uboDispatch({
            type: 'SET_OPEN',
            payload: {
                data: individual,
                type: 'INDIVIDUAL'
            },
        })
        return () => { }
    }
    function UboIndividualDocState({ type, state }) {
        const doc_registry = {
            'IDENTITY': 'ID Document',
            'RESIDENCY': 'Proof of Residency',
        }

        const state_registry = {
            'PENDING': '-pending',
            'VALIDATED': '-valid',
            'REJECTED': '-rejected',
            'NOT_STARTED': ''
        }

        return (<div className="flex">
            <div className="mr-3 mt-0.5 flex-shrink-0">
                <Tooltip auto={true} content={doc_registry[type]}>
                    <div>
                        {type === 'IDENTITY' ? <img className="h-9" src={`/img/icons/ui/icon-corporate-individual-identity${state_registry[state]}.svg`}></img> : null}
                        {type === 'RESIDENCY' ? <img className="h-9" src={`/img/icons/ui/icon-corporate-individual-bank-statement${state_registry[state]}.svg`}></img> : null}
                    </div>
                </Tooltip>
            </div>
        </div>)
    }

    return (
        <div
            onClick={() => openDetails(member)}
            key={member.member_id}
            className='p-2.5 border border-gray-150 rounded-md hover:border-gray-400 transition duration-100 ease-in cursor-pointer'>
            <img
                className='m-auto h-9 w-9'
                src='/img/icons/ui/icon-corporate-individual.svg'
                alt='Corporate Individual'></img>
            <p className='text-2xs mt-2 font-bold capitalize'>
                {member.firstname.toLowerCase()} {member.lastname.toLowerCase()}
            </p>
            <div className='text-left p-0 m-0'>
                <UboIndividualDocState type="IDENTITY" state={member.identity.state} />
                <UboIndividualDocState type="RESIDENCY" state={member.residency.state} />
            </div>
        </div>
    )
}