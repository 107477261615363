import {
	faUniversity,
	faFileInvoice,
	faPassport,
} from '@fortawesome/pro-duotone-svg-icons'
import { faCamera, faUserCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Moment from 'react-moment'
import Tooltip from '../ui/Tooltip'

export default function DeliveryItem(props) {
	return (
		<div className="w-full mb-2 list-item">
			<a
				className="flex-shrink-0 group block">
				<div className="w-full">
					<div className="ml-1">
						<div className="flex justify-between">
							<div className="flex ">
								<div>
									<Tooltip auto={true} content={props.item.service}>
										<span className="inline-block relative">
											{props.item.service === 'IDENTITY' ? (
												<div className='dashboard-task'>
													<FontAwesomeIcon
														className="text-identity-500 text-4xl"
														icon={faPassport}></FontAwesomeIcon>
												</div>
											) : null}
											{props.item.service === 'RESIDENCY' ? (
												<div className='dashboard-task'>
													<FontAwesomeIcon
														className="text-residency-500 text-4xl"
														icon={faFileInvoice}></FontAwesomeIcon>
												</div>
											) : null}
											{props.item.service === 'LIVENESS' ? (
												<div className='dashboard-task'>
													<FontAwesomeIcon
														className="text-blue-500 text-4xl"
														icon={faUserCheck}></FontAwesomeIcon>
												</div>
											) : null}
											{props.item.service === 'CORPORATE' ? (
												<div className='dashboard-task'>
													<FontAwesomeIcon
														className="text-corporate-500 text-4xl"
														icon={faUniversity}></FontAwesomeIcon>
												</div>
											) : null}
										</span>
									</Tooltip>
								</div>
								<div className="inline-block">
									<p className="ml-4 text-sm font-medium text-gray-700">
										<span>{props.item.session_id}</span>
									</p>
									<p className="ml-4 text-sm font-medium text-gray-400">
										<span>Step id: {props.item.step_id}</span>
									</p>
									<p className="ml-4 text-sm font-medium text-gray-400">
										<span>
											<Moment unix fromNow>
												{props.item.delivery_time / 1000}
											</Moment>
										</span>
									</p>
								</div>

							</div>
							<div className="inline-block">
								{props.item.state === 'SUBMITTED' ? (
									<span className="inline-flex items-center mt-5 px-3 py-0.5 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
										{props.item.state}
									</span>
								) : null}
								{props.item.state === 'VALIDATED' ? (
									<span className="inline-flex items-center mt-5 px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
										{props.item.state}
									</span>
								) : null}
								{props.item.state === 'REJECTED' ? (
									<span className="inline-flex items-center mt-5 px-3 py-0.5 rounded-full text-sm font-medium bg-orange-100 text-orange-800">
										{props.item.state}
									</span>
								) : null}
								{props.item.state === 'RESET' ? (
									<span className="inline-flex items-center mt-5 px-3 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
										{props.item.state}
									</span>
								) : null}
								{props.item.state === 'FINAL_REJECTED' ? (
									<span className="inline-flex items-center mt-5 px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">
										{props.item.state}
									</span>
								) : null}
							</div>
							<div className="inline-block">
								<p className="ml-4 mr-2 text-sm font-medium text-gray-700">
									<span>#{props.item.reference}</span>
								</p>
								<div className="mt-3 mr-2 flex justify-between">
									<div className='ml-4'>
										{props.item.attempts} attempts
									</div>

									{props.item.delivery_status >= 200 && props.item.delivery_status < 300 ? (
										<span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
											{props.item.delivery_status}
										</span>
									) : null}
									{props.item.delivery_status >= 300 && props.item.delivery_status < 500 ? (
										<span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-orange-100 text-orange-800">
											{props.item.delivery_status}
										</span>
									) : null}
									{props.item.delivery_status >= 500 ? (
										<span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">
											{props.item.delivery_status}
										</span>
									) : null}
								</div>

							</div>
						</div>
					</div>
				</div>
			</a>
		</div>
	)
}
