import { faEllipsisHAlt, faGlobe, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from '@/components/ui/Alert'
import AppsService from '@/services/apps'
import { useParams } from 'react-router-dom'
import Tooltip from '@/components/ui/Tooltip'
import countries from '@/resources/countries_label.json'


export default function CorporateEntitySettings(props) {
    const [error, setError] = useState(null)
    const [data, setData] = useState(null)
    const [ready, setReady] = useState(false)
    const { app_id } = useParams()

    useEffect(() => {
        GetStepDetails()
        return () => { }
    }, [])

    function GetStepDetails() {
        AppsService.GetCorporateCompliance(app_id, props.step_id).then((data) => {
            setData(data)
            setReady(true)
        }).catch((err) => {
            setError(err.message)
        })
    }

    function openManage() {
        if (!props.manageable) {
            props.onManage()
        }
    }

    return (
        <div className={`${props.manageable ? 'modal-view' : 'w-full sm:w-1/3 mt-4'}`}>
            <div className={`z-max w-full h-full flex items-center justify-center ${!props.manageable ? 'px-3' : ''}`}>
                <div onClick={() => openManage()} className={`${!props.manageable ? 'rounded-xl cursor-pointer hover:shadow-lg bg-white border border-gray-200 hover:border-gray-300 transition-25 transition duration-200 ease-in' : ''} flex flex-col w-full h-full`}>
                    <header className="p-5 pb-3">
                        <div className="flex justify-between">
                            <div className="flex items-center">
                                <div>
                                    <img className="h-9 relative top-0.5 mr-3" src="/img/icons/ui/icon-corporate.svg" />
                                </div>
                                <div>
                                    <h2 className="text-lg text-primary-500  font-medium"> Corporate & Sub-Corporate
                                    </h2>
                                </div>
                            </div>
                            {props.manageable ? <div>
                                <h2 className="text-xl relative top-0.5"><span><FontAwesomeIcon onClick={() => props.onClose()} className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-200 ease-in" icon={faTimes}></FontAwesomeIcon></span></h2>
                            </div> : null}
                        </div>
                        <hr className="my-3" />
                    </header>

                    <main
                        className={`flex-1 overflow-y-auto p-5 py-0 ${props.manageable ? 'flow-body' : ''}`}
                        id="individual-main">
                        {ready ?
                            <>
                                <div className="flex flex-wrap">
                                    <div className="w-full">
                                        <div>
                                            {error ? (
                                                <Alert type="error" title={error}></Alert>
                                            ) : null}
                                            <div className="flex justify-between">
                                                <div>
                                                    <p className="text-sm text-primary-700">Documents</p>
                                                </div>
                                                {props.manageable ? <div>
                                                    <p onClick={() => props.manageDocuments()} className="text-xs py-0.5 cursor-pointer hover:bg-gray-300 ease-in duration-100 transition rounded-md bg-gray-200 px-2 text-primary-700"><FontAwesomeIcon className="mr-0.5" icon={faEllipsisHAlt} /> Manage</p>
                                                </div> : null}
                                            </div>
                                            <div>
                                                <div className="flex mt-3">
                                                    <div>
                                                        <Tooltip auto={true} content="Certificate of Incorporation">
                                                            <img className={`h-8 mr-2 ${!data.documents.includes('CERTIFICATE_OF_INCORPORATION') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-corporate-certificate.svg" />
                                                        </Tooltip>
                                                    </div>
                                                    <div>
                                                        <Tooltip auto={true} content="Memorandum of Association">
                                                            <img className={`h-8 mr-2 ${!data.documents.includes('MEMORANDUM_OF_ASSOCIATION') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-corporate-association.svg" />
                                                        </Tooltip>
                                                    </div>
                                                    <div>
                                                        <Tooltip auto={true} content="Register of directors & shareholders">
                                                            <img className={`h-8 mr-2 ${!data.documents.includes('REGISTER_OF_DIRECTORS_SHAREHOLDERS') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-corporate-register.svg" />
                                                        </Tooltip>
                                                    </div>
                                                    <div>
                                                        <Tooltip auto={true} content="Company Bank Statement">
                                                            <img className={`h-8 mr-2 ${!data.documents.includes('COMPANY_BANK_STATEMENT') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-corporate-bank-statement.svg" />
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="flex flex-wrap mt-6">
                                    <div className="w-full">
                                        <div>
                                            <div className="flex justify-between">
                                                <div>
                                                    <p className="text-sm text-primary-700">Restricted countries</p>
                                                </div>
                                                {props.manageable ? <div>
                                                    <p onClick={() => props.managedRestrictions()} className="text-xs py-0.5 cursor-pointer hover:bg-gray-300 ease-in duration-200 transition rounded-md bg-gray-200 px-2 text-primary-700"><FontAwesomeIcon className="mr-0.5" icon={faEllipsisHAlt} /> Manage</p>
                                                </div>
                                                    : null}
                                            </div>
                                            <div>
                                                <div className="flex mt-2">
                                                    {data.countries.length === 0 ? <p className="text-sm text-gray-400"><FontAwesomeIcon className="mr-1" icon={faGlobe} /> No restrictions</p> : null}
                                                    <div className="flex -space-x-1 relative z-0">
                                                        {data.countries.slice(0, 8).map((country) => {
                                                            return <>
                                                                <Tooltip auto={true} content={countries[country]}>
                                                                    <img key={country}
                                                                        className="relative z-30 inline-block h-6 w-6 rounded-full ring-2 ring-white"
                                                                        src={`/img/icons/flags-rounded/${country}.svg`}
                                                                    />
                                                                </Tooltip>
                                                            </>
                                                        })}
                                                    </div>
                                                    {data.countries.length - 8 > 0 ? <span className="relative text-sm top-1 ml-2 text-gray-500">+ {data.countries.length - 8} others</span> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </> : null}
                    </main>
                </div>
            </div>
        </div>
    )
}
