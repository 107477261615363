import { useContext, useEffect, useState } from 'react'
import SandboxService from '@/services/sandbox'
import SessionItemPlaceholder from '../placeholder/SessionItemPlaceholder'
import Sleep from '@/lib/sleep'
import * as Notification from '@/components/ui/Notification'
import { AppContext } from '@/context/app'
import SandboxTaskItem from './SandboxTaskItem'
import { useParams } from 'react-router-dom'

export default function SandboxTasks() {
	const [ready, setReady] = useState(false)
	const [, appDispatch] = useContext(AppContext)
	const [tasks, setTasks] = useState(null)
	const { app_id } = useParams()

	async function getSandboxTask() {
		await SandboxService.GetTasks(app_id)
			.then(async (data) => {
				appDispatch({
					type: 'SET_TASKS',
					payload: {
						sandbox_tasks: data,
					},
				})

				setTasks(data)
				await Sleep(500)
				setReady(true)
			})
			.catch((err) => {
				Notification.error(err.message)
			})
	}

	useEffect(async () => {
		setReady(false)
		getSandboxTask()
		return () => { }
	}, [])

	async function updateSandbox(body) {
		setReady(false)
		await SandboxService.UpdateTask(app_id, body)
			.then(async () => {
				getSandboxTask()
				await Sleep(500)
				setReady(true)
			})
			.catch((err) => {
				Notification.error(err.message)
			})
	}

	return (
		<>
			{ready ? (
				<div className={`${tasks.length > 0 ? 'p-6' : ''} flex flex-wrap pt-3`}>
					{tasks.map((task) => {
						return (
							<>
								<SandboxTaskItem
									key={task.service_session_id}
									update={(body) => updateSandbox(body)}
									item={task}></SandboxTaskItem>
							</>
						)
					})}
					{tasks.length === 0 ? (
						<div className="w-full mt-3 px-2 rounded-xl border bg-white transition-25 transition duration-200 ease-in">
							<div className="p-6 mt-4 mx-auto">
								<img className="mx-auto h-36" src="/img/illustrations/empty.svg"></img>
								<p className="p-6 text-center text-gray-700 mt-3 mx-auto">No tasks</p>
							</div>
						</div>
					) : null}
				</div>

			) : (
				<SessionItemPlaceholder></SessionItemPlaceholder>
			)}
		</>
	)
}
