import { useReducer, createContext } from 'react'

export const AppContext = createContext()

const initialState = {
	info: {},
	iam: {
		owner: false,
		admin: false,
		compliance: false,
		developer: false,
		billing: false,
	},
	ready: false,
	sandbox_tasks: [],
	aml_tasks: [],
	sessions: [],
	webhook_status: {},
	webhook_deliveries: [],
	tiers: []
}

function setApp(app) {
	const newIAM = setRoles(app.roles)
	return {
		...app,
		iam: newIAM,
		ready: true,
	}
}

function setRoles(iam) {
	return {
		owner: iam.includes('OWNER'),
		admin: iam.includes('ADMIN'),
		compliance: iam.includes('COMPLIANCE'),
		developer: iam.includes('DEVELOPER'),
		billing: iam.includes('BILLING'),
	}
}

function setSandboxTasks(state, payload) {
	return {
		...state,
		sandbox_tasks: payload.tasks,
	}
}

function setAmlTasks(state, payload) {
	return {
		...state,
		aml_tasks: payload.tasks,
	}
}

function setSessions(state, payload) {
	return {
		...state,
		sessions: payload.tasks,
	}
}

function setWebhookStatus(state, payload) {
	return {
		...state,
		webhook_status: payload.status,
	}
}

function setWebhookDeliveries(state, payload) {
	return {
		...state,
		webhook_deliveries: payload.deliveries,
	}
}
function setAppTiers(state, payload) {
	return {
		...state,
		tiers: payload.tiers,
	}
}

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_APP':
			return setApp(action.payload)
		case 'SET_TASKS':
			return setSandboxTasks(state, action.payload)
		case 'SET_AML':
			return setAmlTasks(state, action.payload)
		case 'SET_STATUS':
			return setWebhookStatus(state, action.payload)
		case 'SET_SESSIONS':
			return setSessions(state, action.payload)
		case 'SET_DELIVERIES':
			return setWebhookDeliveries(state, action.payload)
		case 'SET_TIERS':
			return setAppTiers(state, action.payload)
		default:
			return state
	}
}

export const AppContextProvider = (props) => {
	const [state, dispatch] = useReducer(reducer, initialState)

	return (
		<AppContext.Provider value={[state, dispatch]}>
			{props.children}
		</AppContext.Provider>
	)
}
