import { ManagerAuth } from '@/services/axios'

export default {
	GetStatus(app) {
		return ManagerAuth.get(
			`${process.env.REACT_APP_MANAGER_HOST}/v3/webhooks/status/${app}`
		)
	},
	GetDeliveries(app, page, search, status) {
		return ManagerAuth.get(
			`${process.env.REACT_APP_MANAGER_HOST}/v3/webhooks/deliveries/${app}?page=${page}&search=${search}&status=${status}`
		)
	},
	Create(app, body) {
		return ManagerAuth.post(
			`${process.env.REACT_APP_MANAGER_HOST}/v3/webhooks/create/${app}`, body
		)
	},
	Remove(app) {
		return ManagerAuth.delete(
			`${process.env.REACT_APP_MANAGER_HOST}/v3/webhooks/remove/${app}`, null
		)
	},
}