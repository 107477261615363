import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons'
import UltraMiniButton from '@/components/ui/UltraMiniButton'
import AmlService from '@/services/aml'
import { useParams } from 'react-router'
import { useState } from 'react'

export default function IndividualAmlDecision({ session, onFinish }) {
    const { app_id } = useParams()
    const [successLoading, setSuccessLoading] = useState(false)
    const [declineLoading, setDeclineLoading] = useState(false)
    const { session_id } = useParams()

    async function SetDecisionDecline(session_id) {
        const body = {
            session_id: session_id,
            state: -1,
            service: 'AML',
        }
        setDeclineLoading(true)
        AmlService.SetAmlDecision(app_id, body).then((data) => {
            console.log(data)
            setDeclineLoading(false)
            onFinish()
        }).catch((err) => {
            console.log(err)
            setDeclineLoading(false)
        })
    }

    async function SetDecisionAccept(session_id) {
        const body = {
            session_id: session_id,
            state: 2,
            service: 'AML',
        }
        setSuccessLoading(true)
        AmlService.SetAmlDecision(app_id, body).then((data) => {
            setSuccessLoading(false)
            onFinish()
        }).catch((err) => {
            console.log(err)
            setSuccessLoading(false)
        })
    }

    return (

        <div className="mt-2">
            <div className="flex border border-transparent py-1 px-1.5 items-center rounded-xl">
                <div className="mr-3 mt-0.5 flex-shrink-0">
                    <img className="h-9" src={'/img/icons/ui/icon-decision-aml.svg'}></img>
                </div>
                <div className="relative">
                    <p className="text-bluegray-400 text-xs">Decision</p>
                    <div className="flex mt-1 items-center">
                        <div className="mr-2">
                            <UltraMiniButton disabled={declineLoading} onClick={() => SetDecisionAccept(session_id)} loading={successLoading} className="w-full" full icon={faCheck} label="Accept" theme="success" />
                        </div>
                        <div>
                            <UltraMiniButton disabled={successLoading} onClick={() => SetDecisionDecline(session_id)} loading={declineLoading} full icon={faTimes} label="Reject" theme="error" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}