import { useReducer, createContext } from 'react'

export const SearchContext = createContext()

const initialState = {
	open: false,
}

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_OPEN':
			return {
				open: true
			}
		case 'SET_CLOSE':
			return {
				open: false
			}
		default:
			return state
	}
}

export const SearchContextProvider = (props) => {
	const [state, dispatch] = useReducer(reducer, initialState)

	return (
		<SearchContext.Provider value={[state, dispatch]}>
			{props.children}
		</SearchContext.Provider>
	)
}
