import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useState } from "react";
import Button from "@/components/ui/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from "@/components/ui/Alert";
import Input from "@/components/ui/Input";
import UploadLogo from "@/components/ui/UploadLogo";
import AppsService from "@/services/apps";
import { useHistory } from "react-router-dom";

export default function CreateNewApp(props) {
  const [error, setError] = useState(null);
  const [target, setTarget] = useState("CORPORATE");
  const [name, setName] = useState(null);
  const [logo, setLogo] = useState(null);
  const [loading, setLoading] = useState(false);
  const router = useHistory();

  useEffect(() => {
    setName(props.name);
  }, []);

  function Submit() {
    setLoading(true);

    let bodyFormData = new FormData();
    bodyFormData.append("app_name", name);
    bodyFormData.append("app_logo", logo);
    bodyFormData.append("app_type", target);

    AppsService.CreateApp(bodyFormData)
      .then((data) => {
        window.location.href = `/app/${data.app_key}/`;
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }

  return (
    <div className="p-4 createapp-view">
      <div className="flex justify-between">
        <div>
          <h2 className="text-xl">Create a new app</h2>
        </div>
        <div>
          <h2 className="text-xl">
            <span>
              <FontAwesomeIcon
                onClick={() => props.onClose()}
                className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-100 ease-in"
                icon={faTimes}
              ></FontAwesomeIcon>
            </span>
          </h2>
        </div>
      </div>
      <hr className="my-3" />
      <div>{error ? <Alert type="error" title={error}></Alert> : null}</div>
      <div className="mt-4">
        <div className="mt-3">
          <label className="block text-sm font-medium text-primary-700">
            Name
          </label>
          <p className="text-sm mb-3 text-gray-600">
            Your app name will be displayed on your verification flow
          </p>
          <Input
            setValue={(value) => setName(value)}
            value={name}
            placeholder="TrustExchange"
          />
        </div>
        <div className="mt-4">
          <label className="block text-sm font-medium text-primary-700">
            Logo
          </label>
          <p className="text-sm mb-3 text-gray-600">
            Your app logo will be shown on your verification flow. It must be
            recognizable
          </p>
          <UploadLogo setLogo={(blob) => setLogo(blob)} />
        </div>
        <div className="mt-4">
          <label className="block text-sm font-medium text-primary-700">
            Target
          </label>
          <p className="text-sm mb-3 text-gray-600">
            Choose whether your app will be used by individual or corporate
          </p>

          <div className="flex flex-wrap">
            <div className="w-full pl-2">
              <div
                onClick={() => setTarget("CORPORATE")}
                className={`flex flex-1 border border-gray-200  ${
                  target === "CORPORATE"
                    ? "border-primary-700"
                    : "hover:border-gray-400 cursor-pointer"
                } p-2 rounded-xl ease-in transition duration-100`}
              >
                <div>
                  <img src="/img/icons/ui/icon-corporate.svg"></img>
                </div>
                <div className="ml-3">
                  <p className="font-medium text-primary-700">Corporate</p>
                  <p className="text-gray-500 text-xs">For a business entity</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <Button
            loading={loading}
            full
            disabled={!name || !target || !logo}
            theme="success"
            label="Create"
            onClick={() => Submit()}
          ></Button>
        </div>
      </div>
    </div>
  );
}
