import {
	faSearch,
	faClock,
    faBook,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Moment from 'react-moment'
import Tooltip from '../ui/Tooltip'
import MiniButton from '../ui/MiniButton'
import { useHistory, useParams } from 'react-router-dom'

export default function LogItem(props) {
	const { app_id } = useParams()
	const router = useHistory()

	return (
		<div className="w-full my-3 p-2 rounded-xl border bg-white transition-25 transition duration-200 ease-in">
			<div className="w-full">
				<div className="ml-1">
					<div className="sm:flex justify-between">
						<div className="flex">
							<div className="p-2">
                            <FontAwesomeIcon className="text-bluegray-500 text-3xl" icon={faBook} />
							</div>
							<div className="ml-3 relative">
								<p className="text-sm font-bold">
									{props.item.description}
								</p>
								<div className="flex">
									<div>
										<h1 className="text-sm mt-1">
												<span>
													<FontAwesomeIcon className="text-bluegray-500 mr-1" icon={faClock} />
													<Moment unix fromNow>
														{props.item.date / 1000}
													</Moment>
												</span>
										</h1>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
