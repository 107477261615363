export default function FlowItemCorporatePlaceholder() {
	return (
		<>
			<div className={'step-view w-full sm:w-1/2 mt-4'}>
				<div className={'z-max w-full h-full flex items-center justify-center px-3'}>
					<div className={'rounded-xl bg-white border border-gray-200 transition-25 transition duration-200 ease-in flex flex-col w-full h-full'}>
						<div className="animate-pulse">
							<div className="p-4 flex items-center">
								<div className="w-8 h-8 bg-primary-200 rounded-md"></div>
								<div className="ml-3 w-64 h-4 bg-gray-200 rounded-md"></div>
							</div>
							<div className="p-4 flex items-center">
								<div className="w-24 h-2 bg-gray-200 rounded-md"></div>
							</div>
							<div className="p-4 pt-0 flex items-center">
								<div className="mr-3 w-8 h-8 bg-bluegray-200 rounded-md"></div>
								<div className="mr-3 w-8 h-8 bg-bluegray-200 rounded-md"></div>
								<div className="mr-3 w-8 h-8 bg-bluegray-200 rounded-md"></div>
								<div className="mr-3 w-8 h-8 bg-bluegray-200 rounded-md"></div>
							</div>
							<div className="p-4 flex items-center">
								<div className="w-32 h-2 bg-gray-200 rounded-md"></div>
							</div>
							<div className="p-4 pt-0 flex items-center">
								<div className="mr-3 w-8 h-8 bg-bluegray-200 rounded-md"></div>
								<div className="mr-3 w-8 h-8 bg-bluegray-200 rounded-md"></div>
								<div className="mr-3 w-8 h-8 bg-bluegray-200 rounded-md"></div>
								<div className="mr-3 w-8 h-8 bg-bluegray-200 rounded-md"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
