import { useEffect, useState } from 'react'
import AppsService from '@/services/apps'
import { useParams } from 'react-router-dom'
import CorporateEntitySettings from '@/components/flow/CorporateEntitySettings'
import CorporateUboFlow from '@/components/flow/CorporateUboFlow'
import CorporateRestrictedCountries from '@/components/flow/CorporateRestrictedCountries'
import FlowItemCorporatePlaceholder from '@/components/placeholder/FlowItemCorporatePlaceholder'
import Sleep from '@/lib/sleep'
import { Modal } from 'react-responsive-modal'
import FlowCorporateDocuments from '@/components/flow/FlowCorporateDocuments'
import FlowIdentityDocuments from '@/components/flow/FlowIdentityDocuments'
import FlowResidencyDocuments from '@/components/flow/FlowResidencyDocuments'
import FlowRestrictedCountries from '@/components/flow/FlowRestrictedCountries'
import AmlFlowCriterias from '@/components/flow/AmlFlowCriterias'
import AmlFlowMonitoring from '@/components/flow/AmlFlowMonitoring'
import AmlFlowDecisionMaker from '@/components/flow/AmlFlowDecisionMaker'
import AmlFlow from '@/components/flow/AmlFlow'

export default function CorporateFlowSteps(props) {
	const [ready, setReady] = useState(false)
	const [open, setOpen] = useState(false)
	const [step, setStep] = useState(null)
	const [country, setCountry] = useState('')

	let { app_id } = useParams()

	useEffect(async () => {
		GetFlow()
		return () => { }
	}, [])

	useEffect(() => {
		GetFlow()
		return () => { }
	}, [props.refresh])

	async function GetFlow() {
		setReady(false)
		await AppsService.GetCorporateCompliance(app_id)
			.then(async () => {
				await Sleep(1000)
				setReady(true)
			})
		return () => { }
	}

	function setDocument(step, country) {
		setCountry(country)
		setStep(step)
	}

	function openStep(step) {
		setOpen(true)
		setStep(step)
		console.log(step)
	}

	async function closeFlow() {
		setOpen(false)
		await GetFlow()
	}

	return (
		<>
			{ready ? <>
				<div>
					<div className="flex mt-4">
						<CorporateEntitySettings onManage={() => openStep('CORPORATE')} />
						<CorporateUboFlow onManage={() => openStep('UBO')} />
						<AmlFlow manageable={false} step_id={''} type="CORPORATE" onManage={() => openStep('AML')} />
						<Modal open={open}
							showCloseIcon={true}
							closeIcon={true}
							onClose={() => closeFlow()}
							center>
							{step === 'CORPORATE' ? <CorporateEntitySettings manageDocuments={() => setStep('CORPORATE_DOCUMENTS')} managedRestrictions={() => setStep('CORPORATE_COUNTRIES')} manageable={true} onBack={() => setStep('CORPORATE')} onClose={() => closeFlow()} /> : null}
							{step === 'CORPORATE_COUNTRIES' ? <CorporateRestrictedCountries type="CORPORATE" subtype="BASIC" manageable={true} onBack={() => setStep('CORPORATE')} onClose={() => closeFlow()} /> : null}
							{step === 'CORPORATE_DOCUMENTS' ? <FlowCorporateDocuments type="UBO_IDENTITY" onBack={() => setStep('CORPORATE')} onClose={() => setOpen(false)} /> : null}
							{step === 'UBO' ? <CorporateUboFlow type="UBO" manageUboIdentity={() => setStep('IDENTITY')} manageUboIdentityDocuments={() => setDocument('IDENTITY_DOCUMENTS', '')} manageUboResidency={() => setStep('RESIDENCY')} manageUboResidencyDocuments={() => setDocument('RESIDENCY_DOCUMENTS', '')} manageable={true} onBack={() => closeFlow()} onClose={() => closeFlow()} /> : null}
							{step === 'IDENTITY_DOCUMENTS' ? <FlowIdentityDocuments country={country} step_id={''} onBack={() => setStep('IDENTITY')} onMain={() => setStep('UBO')} type="UBO_IDENTITY" onClose={() => setOpen(false)} /> : null}
							{step === 'IDENTITY' ? <FlowRestrictedCountries managedDocuments={(country) => setDocument('IDENTITY_DOCUMENTS', country)} type="UBO_IDENTITY" step_id={''} onBack={() => setStep('UBO')} onClose={() => closeFlow(false)} /> : null}
							{step === 'RESIDENCY_DOCUMENTS' ? <FlowResidencyDocuments country={country} step_id={''} onBack={() => setStep('RESIDENCY')} onMain={() => setStep('UBO')} type="UBO_RESIDENCY" onClose={() => setOpen(false)} /> : null}
							{step === 'RESIDENCY' ? <FlowRestrictedCountries managedDocuments={(country) => setDocument('RESIDENCY_DOCUMENTS', country)} type="UBO_RESIDENCY" step_id={''} onBack={() => setStep('UBO')} onClose={() => closeFlow(false)} /> : null}
							{step === 'AML' ? <AmlFlow manageable={true} type="CORPORATE" manageDecision={() => setStep('AML_DECISION_MAKER')} manageMonitoring={() => setStep('AML_MONITORING')} manageCriterias={() => setStep('AML_CRITERIAS')} step_id={''} onClose={() => setOpen(false)} /> : null}
							{step === 'AML_CRITERIAS' ? <AmlFlowCriterias type="AML" step_id={''} onBack={() => setStep('AML')} onClose={() => setOpen(false)} /> : null}
							{step === 'AML_MONITORING' ? <AmlFlowMonitoring type="AML" step_id={''} onBack={() => setStep('AML')} onClose={() => setOpen(false)} /> : null}
							{step === 'AML_DECISION_MAKER' ? <AmlFlowDecisionMaker type="AML" step_id={''} onBack={() => setStep('AML')} onClose={() => setOpen(false)} /> : null}
						</Modal>
					</div>
				</div>
			</> : <>
				<div className="flex mt-4">
					<FlowItemCorporatePlaceholder />
					<FlowItemCorporatePlaceholder />
					<FlowItemCorporatePlaceholder />
				</div>
			</>}
		</>
	)
}
