import { useContext, useEffect, useState } from "react";
import { faLink, faTasks } from "@fortawesome/pro-solid-svg-icons";
import Button from "@/components/ui/Button";
import Footer from "@/components/menu/Footer";
import SubNav from "@/components/menu/SubNav";
import Credentials from "@/components/developers/Credentials";
import GetStarted from "@/components/developers/GetStarted";
import { AppContext } from "@/context/app";
import { useHistory, useParams } from "react-router-dom";
import SandboxService from "@/services/sandbox";
import * as Notification from "@/components/ui/Notification";

export default function AppDeveloperPage() {
  const [app] = useContext(AppContext);
  const [tasks, setTasks] = useState(0);
  const router = useHistory();
  const { app_id } = useParams();

  useEffect(async () => {
    getSandboxTask();
    return () => {};
  }, []);

  async function getSandboxTask() {
    await SandboxService.GetTasks(app_id)
      .then(async (data) => {
        setTasks(data.length);
      })
      .catch((err) => {
        Notification.error(err.message);
      });
  }

  return (
    <div>
      <div>
        <SubNav></SubNav>
        <div className="max-w-7xl mx-auto relative">
          <div className="dashboard-container px-3">
            <div className="flex justify-between mt-3">
              <div>
                <div className="flex">
                  <div
                    className="cursor-pointer"
                    onClick={() => (window.location.href = `/app/${app_id}`)}
                  >
                    <img
                      className="w-16 h-16 mt-1 rounded-xl"
                      src={app.logo_url}
                    />
                  </div>
                  <div className="ml-5">
                    <h1 className="text-3xl text-white">
                      <span className="font-bold">Developer</span>
                    </h1>
                    <h3 className="text-lg mt-2 text-bluegray-300">
                      Manage integration and follow documentation
                    </h3>
                  </div>
                </div>
              </div>
              <div className="flex">
                <div className="mt-2.5">
                  <Button
                    onClick={() =>
                      (window.location.href = `/app/${app_id}/webhooks`)
                    }
                    currentBg="primary"
                    theme="secondary"
                    icon={faLink}
                    label="Webhooks"
                  ></Button>
                </div>
                <div className="mt-2.5 ml-3.5">
                  <Button
                    onClick={() =>
                      (window.location.href = `/app/${app_id}/sandbox`)
                    }
                    currentBg="primary"
                    theme="pin"
                    icon={faTasks}
                    label="Sandbox Tasks"
                    badge={tasks === 0 ? "0" : tasks}
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-7xl mx-auto relative">
          <div>
            <Credentials />
          </div>
          <div className="mt-8">
            <GetStarted />
          </div>
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
}
