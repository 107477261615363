import AuthGuard from '@/guard/auth'
import AppNavbar from '@/components/menu/AppNavbar'
import AppTeamPage from '@/components/pages/app/AppTeamPage'
import AppGuard from '@/guard/app'

function Team() {
    return (
        <AuthGuard>
            <AppGuard>
                <div className='relative min-h-screen flex flex-col'>
                    <AppNavbar></AppNavbar>
                    <AppTeamPage></AppTeamPage>
                </div>
            </AppGuard>
        </AuthGuard>
    )
}

export default Team
