import { ManagerAuth } from '@/services/axios'

export default {
	Request() {
		return ManagerAuth.get(`${process.env.REACT_APP_MANAGER_HOST}/v3/twofactor/request`)
	},
	Activate(data) {
		return ManagerAuth.post(`${process.env.REACT_APP_MANAGER_HOST}/v3/twofactor/activate`, data)
	},
	Disable(data) {
		return ManagerAuth.post(`${process.env.REACT_APP_MANAGER_HOST}/v3/twofactor/disable`, data)
	},
	Status() {
		return ManagerAuth.get(`${process.env.REACT_APP_MANAGER_HOST}/v3/twofactor/status`)
	},
}
