import Tooltip from '@/components/ui/Tooltip'
import { useContext } from 'react'
import { AppContext } from '@/context/app'

export default function TeamMemberItem({ user, me }) {
	const [app] = useContext(AppContext)
	return (
		<div key={user.id} className={`m-4 text-center border border-bluegray-150 items-center bg-white rounded-xl ${me !== user.id && (app.iam.owner || app.iam.admin) ? 'cursor-pointer hover:shadow-lg hover:border-bluegray-300' : ''} transition-25 transition duration-200 ease-in`}>
			<div className="m-3 my-6">
				<span className="inline-flex items-center justify-center h-24 w-24 rounded-2xl bg-gray-500">
					<span className="text-3xl font-medium leading-none text-white">{user.firstname[0]}{user.lastname[0]}</span>
				</span>
				<div className="mt-3">
					<p className='text-regular font-regular font-bold'>
						{user.firstname.concat(' ' + user.lastname).length > 24 ? user.firstname.concat(' ' + user.lastname).slice(0, 24) + '...' : user.firstname.concat(' ' + user.lastname)}
					</p>
					<div className="flex flex-wrap mt-3 justify-center">
						{user.roles.map((role) => {
							return (
								<>
									<div>
										{role === 'OWNER' ? <Tooltip auto={true} content={'Owner'}>
											<span>
												<img className="mx-1 w-8 h-8" src="/img/icons/ui/icon-admin.svg" />
											</span>
										</Tooltip> : null}
									</div>
									<div>
										{role === 'ADMIN' ? <Tooltip auto={true} content={'Admin'}>
											<span>
												<img className="mx-1 w-8 h-8" src="/img/icons/ui/icon-admin.svg" />
											</span>
										</Tooltip> : null}
									</div>
									<div>
										{role === 'DEVELOPER' ? <Tooltip auto={true} content={'Developer'}>
											<span>
												<img className="mx-1 w-8 h-8" src="/img/icons/ui/icon-developer.svg" />
											</span>
										</Tooltip> : null}
									</div>
									<div>
										{role === 'COMPLIANCE' ? <Tooltip auto={true} content={'Compliance Officer'}>
											<span>
												<img className="mx-1 w-8 h-8" src="/img/icons/ui/icon-compliance-officer.svg" />
											</span>
										</Tooltip> : null}
									</div>
									<div>
										{role === 'BILLING' ? <Tooltip auto={true} content={'Accountant'}>
											<span>
												<img className="mx-1 w-8 h-8" src="/img/icons/ui/icon-accountant.svg" />
											</span>
										</Tooltip> : null}
									</div>
								</>
							)
						})}
					</div>
				</div>
			</div>
		</div>
	)
}
