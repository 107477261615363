import { faEllipsisHAlt, faGlobe, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AppsService from '@/services/apps'
import { useParams } from 'react-router-dom'
import Tooltip from '@/components/ui/Tooltip'
import countries from '@/resources/countries_label.json'


export default function CorporateUboFlow(props) {
    const [, setError] = useState(null)
    const [data, setData] = useState(null)
    const [ready, setReady] = useState(false)
    const { app_id } = useParams()
    const [size_identity, setSizeIdentity] = useState(0)
    const [size_residency, setSizeResidency] = useState(0)

    useEffect(() => {
        GetStepDetails()
        return () => { }
    }, [])

    function GetStepDetails() {
        AppsService.GetCorporateCompliance(app_id, props.step_id).then((data) => {
            setData(data)
            let len = 0
            Object.keys(data.ubo_identity).map(function () {
                len++
            })
            setSizeIdentity(len)

            let len2 = 0
            Object.keys(data.ubo_residency).map(function () {
                len2++
            })
            setSizeResidency(len2)
            setReady(true)
        }).catch((err) => {
            setError(err.message)
        })
    }

    function openManage() {
        if (!props.manageable) {
            props.onManage()
        }
    }

    return (
        <div className={`${props.manageable ? 'modal-view' : 'w-full sm:w-1/3 mt-4'}`}>
            <div className={`z-max w-full h-full flex items-center justify-center ${!props.manageable ? 'px-3' : ''}`}>
                <div onClick={() => openManage()} className={`${!props.manageable ? 'step-view rounded-xl cursor-pointer hover:shadow-lg bg-white border border-gray-200 hover:border-gray-300 transition-25 transition duration-200 ease-in' : ''} flex flex-col w-full h-full`}>
                    <header className="p-5 pb-3">
                        <div className="flex justify-between">
                            <div className="flex items-center">
                                <div>
                                    <img className="h-9 relative top-0.5 mr-3" src="/img/icons/ui/icon-individual-ubo.svg" />
                                </div>
                                <div>
                                    <h2 className="text-lg text-primary-500  font-medium"> Benefical Owners
                                    </h2>
                                </div>
                            </div>
                            {props.manageable ? <div>
                                <h2 className="text-xl relative top-0.5"><span><FontAwesomeIcon onClick={() => props.onClose()} className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-200 ease-in" icon={faTimes}></FontAwesomeIcon></span></h2>
                            </div> : null}
                        </div>
                        <hr className="my-3" />
                    </header>

                    <main
                        className={`flex-1 overflow-y-auto p-5 py-0 ${props.manageable ? 'flow-body' : ''}`}
                        id="individual-main">
                        {ready ?
                            <>
                                <div className="flex flex-wrap">
                                    <div className="w-full">
                                        <div>
                                            <div className="flex justify-between">
                                                <div>
                                                    <p className="text-sm text-primary-700">Identity Country Restrictions</p>
                                                </div>
                                                {props.manageable ? <div>
                                                    <p onClick={() => props.manageUboIdentity()} className="text-xs py-0.5 cursor-pointer hover:bg-gray-300 ease-in duration-100 transition rounded-md bg-gray-200 px-2 text-primary-700"><FontAwesomeIcon className="mr-0.5" icon={faEllipsisHAlt} /> Manage</p>
                                                </div> : null}
                                            </div>
                                            <div>
                                                <div className="flex mt-2">
                                                    {size_identity === 0 ? <p className="text-sm text-gray-400"><FontAwesomeIcon className="mr-1" icon={faGlobe} /> No restrictions</p> : null}
                                                    <div className="relative z-0">
                                                        {Object.entries(data.ubo_identity).slice(0, 5).map(([country]) => {
                                                            return <>
                                                                <div className="flex mt-3">
                                                                    <Tooltip auto={true} content={countries[country]}>
                                                                        <img key={country}
                                                                            className="relative z-30 inline-block h-8 w-6 rounded-full ring-2 ring-white"
                                                                            src={`/img/icons/flags-rounded/${country}.svg`}
                                                                        />
                                                                    </Tooltip>
                                                                    <div>
                                                                        <div className="flex ml-3">
                                                                            <div>
                                                                                <Tooltip auto={true} content="Passport">
                                                                                    <img className={`h-8 mr-3 ${data.ubo_identity[country].includes('PASSPORT') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-passport.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div>
                                                                                <Tooltip auto={true} content="National ID">
                                                                                    <img className={`h-8 mr-3 ${data.ubo_identity[country].includes('NATIONAL_ID') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-national-id.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div>
                                                                                <Tooltip auto={true} content="Driver License">
                                                                                    <img className={`h-8 mr-3 ${data.ubo_identity[country].includes('DRIVER_LICENSE') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-driver-license.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div>
                                                                                <Tooltip auto={true} content="Resident Permit">
                                                                                    <img className={`h-8 mr-3 ${data.ubo_identity[country].includes('RESIDENT_PERMIT') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-residence-permit.svg" />
                                                                                </Tooltip>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center mt-4 mb-4">
                                            {size_identity >= 5 ? <span className="relative text-center text-sm top-1 mt-2 text-gray-500">+ {size_identity - 5} others</span> : null}
                                        </div>
                                    </div>
                                    {data.ubo_identity_documents ?
                                    <div className="w-full">
                                        <div>
                                            <div className="flex justify-between">
                                                <div>
                                                    <p className="text-sm text-primary-700">Identity Documents Restrictions</p>
                                                </div>
                                                {props.manageable ? <div>
                                                    <p onClick={() => props.manageUboIdentityDocuments()} className="text-xs py-0.5 cursor-pointer hover:bg-gray-300 ease-in duration-100 transition rounded-md bg-gray-200 px-2 text-primary-700"><FontAwesomeIcon className="mr-0.5" icon={faEllipsisHAlt} /> Manage</p>
                                                </div> : null}
                                            </div>
                                            <div>
                                                <div className="flex mt-2">
                                                    <div className="relative z-0">
                                                        <div className="flex">
                                                            <div>
                                                                <div className="flex">
                                                                    <div>
                                                                        <Tooltip auto={true} content="Passport">
                                                                            <img className={`h-8 mr-3 ${data.ubo_identity_documents.includes('PASSPORT') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-passport.svg" />
                                                                        </Tooltip>
                                                                    </div>
                                                                    <div>
                                                                        <Tooltip auto={true} content="National ID">
                                                                            <img className={`h-8 mr-3 ${data.ubo_identity_documents.includes('NATIONAL_ID') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-national-id.svg" />
                                                                        </Tooltip>
                                                                    </div>
                                                                    <div>
                                                                        <Tooltip auto={true} content="Driver License">
                                                                            <img className={`h-8 mr-3 ${data.ubo_identity_documents.includes('DRIVER_LICENSE') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-driver-license.svg" />
                                                                        </Tooltip>
                                                                    </div>
                                                                    <div>
                                                                        <Tooltip auto={true} content="Resident Permit">
                                                                            <img className={`h-8 mr-3 ${data.ubo_identity_documents.includes('RESIDENT_PERMIT') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-residence-permit.svg" />
                                                                        </Tooltip>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center mt-4 mb-4">
                                            {data.ubo_identity_documents.length >= 5 ? <span className="relative text-center text-sm top-1 mt-2 text-gray-500">+ {data.ubo_identity_documents.length - 5} others</span> : null}
                                        </div>
                                    </div>
                                    : null}
                                    <div className="w-full">
                                        <div>
                                            <div className="flex justify-between">
                                                <div>
                                                    <p className="text-sm text-primary-700">Residency Country Restrictions</p>
                                                </div>
                                                {props.manageable ? <div>
                                                    <p onClick={() => props.manageUboResidency()} className="text-xs py-0.5 cursor-pointer hover:bg-gray-300 ease-in duration-100 transition rounded-md bg-gray-200 px-2 text-primary-700"><FontAwesomeIcon className="mr-0.5" icon={faEllipsisHAlt} /> Manage</p>
                                                </div> : null}
                                            </div>
                                            <div>
                                                <div className="flex mt-2">
                                                    {size_residency === 0 ? <p className="text-sm text-gray-400"><FontAwesomeIcon className="mr-1" icon={faGlobe} /> No restrictions</p> : null}
                                                    <div className="relative z-0">
                                                        {Object.entries(data.ubo_residency).slice(0, 5).map(([country]) => {
                                                            return <>
                                                                <div className="flex mt-3">
                                                                    <Tooltip auto={true} content={countries[country]}>
                                                                        <img key={country}
                                                                            className="relative z-30 inline-block h-8 w-6 rounded-full ring-2 ring-white"
                                                                            src={`/img/icons/flags-rounded/${country}.svg`}
                                                                        />
                                                                    </Tooltip>
                                                                    <div>
                                                                        <div className="flex ml-3">
                                                                            <div>
                                                                                <Tooltip auto={true} content="Gas Bill">
                                                                                    <img className={`h-8 mr-2 ${data.ubo_residency[country].includes('GAS_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-gas-bill.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div>
                                                                                <Tooltip auto={true} content="Water Bill">
                                                                                    <img className={`h-8 mr-2 ${data.ubo_residency[country].includes('WATER_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-water-bill.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div>
                                                                                <Tooltip auto={true} content="Internet Bill">
                                                                                    <img className={`h-8 mr-2 ${data.ubo_residency[country].includes('INTERNET_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-internet-bill.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div>
                                                                                <Tooltip auto={true} content="Electricity Bill">
                                                                                    <img className={`h-8 mr-2 ${data.ubo_residency[country].includes('ELECTRICITY_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-electricity-bill.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div>
                                                                                <Tooltip auto={true} content="Landline Bill">
                                                                                    <img className={`h-8 mr-2 ${data.ubo_residency[country].includes('LANDLINE_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-landline-bill.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div>
                                                                                <Tooltip auto={true} content="Bank Statement">
                                                                                    <img className={`h-8 mr-2 ${data.ubo_residency[country].includes('BANK_STATEMENT') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-bank-statement.svg" />
                                                                                </Tooltip>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center mt-4 mb-4">
                                            {size_residency >= 5 ? <span className="relative text-center text-sm top-1 mt-2 text-gray-500">+ {size_residency - 5} others</span> : null}

                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <div>
                                            <div className="flex justify-between">
                                                <div>
                                                    <p className="text-sm text-primary-700">Residency Documents Restrictions</p>
                                                </div>
                                                {props.manageable ? <div>
                                                    <p onClick={() => props.manageUboResidencyDocuments()} className="text-xs py-0.5 cursor-pointer hover:bg-gray-300 ease-in duration-100 transition rounded-md bg-gray-200 px-2 text-primary-700"><FontAwesomeIcon className="mr-0.5" icon={faEllipsisHAlt} /> Manage</p>
                                                </div> : null}
                                            </div>
                                            {data.ubo_residency_documents ?
                                            <div>
                                                <div className="flex mt-2 mb-5">
                                                    <div className="relative z-0">
                                                        <div className="flex">
                                                            <div>
                                                                <div className="flex">
                                                                    <div>
                                                                        <div className="flex">
                                                                            <div>
                                                                                <Tooltip auto={true} content="Gas Bill">
                                                                                    <img className={`h-8 mr-2 ${data.ubo_residency_documents.includes('GAS_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-gas-bill.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div>
                                                                                <Tooltip auto={true} content="Water Bill">
                                                                                    <img className={`h-8 mr-2 ${data.ubo_residency_documents.includes('WATER_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-water-bill.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div>
                                                                                <Tooltip auto={true} content="Internet Bill">
                                                                                    <img className={`h-8 mr-2 ${data.ubo_residency_documents.includes('INTERNET_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-internet-bill.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div>
                                                                                <Tooltip auto={true} content="Electricity Bill">
                                                                                    <img className={`h-8 mr-2 ${data.ubo_residency_documents.includes('ELECTRICITY_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-electricity-bill.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div>
                                                                                <Tooltip auto={true} content="Landline Bill">
                                                                                    <img className={`h-8 mr-2 ${data.ubo_residency_documents.includes('LANDLINE_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-landline-bill.svg" />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div>
                                                                                <Tooltip auto={true} content="Bank Statement">
                                                                                    <img className={`h-8 mr-2 ${data.ubo_residency_documents.includes('BANK_STATEMENT') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-bank-statement.svg" />
                                                                                </Tooltip>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                        </div>
                                    </div>
                                </div>
                            </> : null}
                    </main>
                </div>
            </div>
        </div>
    )
}
