import { faAngleRight, faUniversity } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import countries from '@/resources/countries_label.json'
import CorporateDocument from '@/components/sessions/corporate/CorporateDocument'
import CorporateAml from '@/components/sessions/corporate/CorporateAml'
import { useState } from 'react'

export default function UboSingleCorporate({ data }) {
    const [currentStep, setCurrentStep] = useState('ubo_corporate_document_certificate_of_incorporation')

    function AnchorItem({ type, state, step_id }) {
        return (<div className="mx-4 mt-2">
            <div>
                <div onClick={() => setCurrentStep(step_id)} className="flex flex-wrap pb-1.5 justify-between items-center hover:bg-bluegray-50 border border-transparent hover:border-bluegray-100 hover:cursor-pointer transition ease-in duration-200 py-1 px-1.5 rounded-xl">
                    <div className="flex">
                        <div className="mr-3 mt-0.5 flex-shrink-0">
                            {type === 'LIVENESS' ? <img className="h-9" src={'/img/icons/ui/look-in-camera.svg'}></img> : null}
                            {type === 'IDENTITY' ? <img className="h-9" src={'/img/icons/ui/icon-passport.svg'}></img> : null}
                            {type === 'RESIDENCY' ? <img className="h-9" src={'/img/icons/ui/icon-utility-bill.svg'}></img> : null}
                            {type === 'PHONE' ? <img className="h-9" src={'/img/icons/ui/icon-call.svg'}></img> : null}
                            {type === 'AML' ? <img className="h-9" src={'/img/icons/ui/icon-aml-synaps.svg'}></img> : null}
                            {type === 'UBO' ? <img className="h-9" src={'/img/icons/ui/icon-corporate-register.svg'}></img> : null}
                            {type === 'CERTIFICATE_OF_INCORPORATION' ? <img className="h-9" src={'/img/icons/ui/icon-corporate-certificate.svg'}></img> : null}
                            {type === 'MEMORANDUM_OF_ASSOCIATION' ? <img className="h-9" src={'/img/icons/ui/icon-corporate-association.svg'}></img> : null}
                            {type === 'REGISTER_OF_DIRECTORS_SHAREHOLDERS' ? <img className="h-9" src={'/img/icons/ui/icon-corporate-register.svg'}></img> : null}
                            {type === 'COMPANY_BANK_STATEMENT' ? <img className="h-9" src={'/img/icons/ui/icon-corporate-bank-statement.svg'}></img> : null}
                        </div>
                        <div className="relative top-[2px]">

                            {type === 'UBO' ? <p className="text-bluegray-400 text-xs">Ultimate Beneficial Owner</p> : null}
                            {type === 'COMPANY_BANK_STATEMENT' ? <p className="text-bluegray-400 text-xs">Company Bank Statement</p> : null}
                            {type === 'REGISTER_OF_DIRECTORS_SHAREHOLDERS' ? <p className="text-bluegray-400 text-xs">Registrer of UBOs</p> : null}
                            {type === 'MEMORANDUM_OF_ASSOCIATION' ? <p className="text-bluegray-400 text-xs">Memorandum of Association</p> : null}
                            {type === 'CERTIFICATE_OF_INCORPORATION' ? <p className="text-bluegray-400 text-xs">Certificate of Incorporation</p> : null}
                            {type === 'IDENTITY' ? <p className="text-bluegray-400 text-xs">ID Document</p> : null}
                            {type === 'AML' ? <p className="text-bluegray-400 text-xs">Anti-Money Laundering (AML)</p> : null}
                            {type === 'RESIDENCY' ? <p className="text-bluegray-400 text-xs">Proof of residency</p> : null}
                            {type === 'PHONE' ? <p className="text-bluegray-400 text-xs">Phone number</p> : null}
                            {type === 'LIVENESS' ? <p className="text-bluegray-400 text-xs">Liveness</p> : null}
                            {state === 'PENDING' ? <p className="text-orange-500 text-sm rounded-md">Pending verification</p> : null}
                            {state === 'NOT_STARTED' ? <p className="text-gray-500 text-sm rounded-md">Not started</p> : null}
                            {state === 'REJECTED' ? <p className="text-red-500 text-sm rounded-md">Rejected</p> : null}
                            {state === 'FINAL_REJECTED' ? <p className="text-red-500 text-sm rounded-md">Final Rejected</p> : null}
                            {state === 'VALIDATED' ? <p className="text-green-500 text-sm rounded-md">Verified</p> : null}
                            {state === 'ACTION_REQUIRED' ? <p className="text-orange-500 text-sm rounded-md">Action required</p> : null}
                            {state === 'VIEW' ? <p className="text-gray-500 text-sm rounded-md">View</p> : null}
                        </div>
                    </div>
                    <div className="flex">
                        <FontAwesomeIcon className="mr-2 text-gray-500" icon={faAngleRight} />
                    </div>
                </div>
            </div>
        </div>)
    }


    function InfoItem(props) {
        return (<div className="mt-2">
            <div className="flex border border-transparent py-1 px-1.5 rounded-xl">
                <div className="mr-3 mt-0.5 flex-shrink-0">
                    <img className="h-9" src={props.icon}></img>
                </div>
                <div className="relative top-[2px]">
                    <p className="text-bluegray-400 text-xs">{props.title}</p>
                    <p className="text-gray-600 text-sm">{props.value}</p>
                </div>
            </div>
        </div>)
    }


    function CountryItem(props) {
        return (<div className="mt-2">
            <div className="flex border border-transparent py-1 px-1.5 rounded-xl">
                <div className="mr-3 flex-shrink-0">
                    <div className="h-9 w-9" style={{
                        backgroundImage: `url( ${`/img/icons/flags-rounded/${props.country}.svg`}), url(${'/img/icons/ui/icon-round.svg'})`,
                        backgroundSize: '60%, contain',
                        backgroundRepeat: 'no-repeat, no-repeat',
                        backgroundPosition: 'center center, center center',
                    }}></div>
                </div>
                <div className="relative top-[2px]">
                    <p className="text-bluegray-400 text-xs">{props.title}</p>
                    <p className="text-gray-600 text-sm">{props.value}</p>
                </div>
            </div>
        </div>)
    }



    function CorporateSidebar({ corporate }) {
        return (
            <>
                <div className="rounded-xl m-3 pt-3">
                    <div className="h-full mx-auto text-center px-3 pb-3">
                        <FontAwesomeIcon className="text-7xl text-bluegray-300" icon={faUniversity} />
                    </div>
                    <h2 className="text-xl text-primary-800 text-center mb-1 capitalize">{corporate.info.name ? corporate.info.name.toLowerCase() : '-'}</h2>
                    <h2 className="text-sm text-gray-500 text-center mb-4">Corporate</h2>
                </div>
                <hr className="mx-6 mb-3 border-bluegray-100" />
                <div>
                    <div>
                        <p className="px-3 uppercase tracking-wide font-medium text-sm mx-3 text-primary-400">Info</p>
                        <div className="mx-4 mt-1.5">
                            {corporate.info.registration_number ? <InfoItem title="Registration Number" value={corporate.info.registration_number} icon="/img/icons/ui/icon-document-number.svg" /> : null}
                            {corporate.info.address ? <InfoItem title="Postal address" value={`${corporate.info.address} - ${corporate.info.city}`} icon="/img/icons/ui/icon-address.svg" /> : null}
                            {corporate.info.country ? <CountryItem title="Country" value={countries[corporate.info.country]} country={corporate.info.country} /> : null}
                        </div>
                    </div>
                    <div className="mt-4">
                        <p className="px-3 uppercase tracking-wide font-medium text-sm mx-3 text-primary-400">Corporate Documents</p>
                    </div>
                    <div>
                        {corporate.documents['CERTIFICATE_OF_INCORPORATION'] ? <AnchorItem step_id={'ubo_corporate_document_certificate_of_incorporation'} type={'CERTIFICATE_OF_INCORPORATION'} state={corporate.documents['CERTIFICATE_OF_INCORPORATION'].state} /> : null}
                        {corporate.documents['MEMORANDUM_OF_ASSOCIATION'] ? <AnchorItem step_id={'ubo_corporate_document_memorandum_of_association'} type={'MEMORANDUM_OF_ASSOCIATION'} state={corporate.documents['MEMORANDUM_OF_ASSOCIATION'].state} /> : null}
                        {corporate.documents['REGISTER_OF_DIRECTORS_SHAREHOLDERS'] ? <AnchorItem step_id={'ubo_corporate_document_registry_of_ubos'} type={'REGISTER_OF_DIRECTORS_SHAREHOLDERS'} state={corporate.documents['REGISTER_OF_DIRECTORS_SHAREHOLDERS'].state} /> : null}
                        {corporate.documents['COMPANY_BANK_STATEMENT'] ? <AnchorItem step_id={'ubo_corporate_document_corporate_bank_statement'} type={'COMPANY_BANK_STATEMENT'} state={corporate.documents['COMPANY_BANK_STATEMENT'].state} /> : null}
                        <AnchorItem step_id={'ubo_corporate_aml'} type={'AML'} state={corporate.aml.state} />
                    </div>

                </div>
            </>
        )
    }

    function CorporateView({ corporate, onRefresh }) {
        return (
            <>
                {currentStep === 'ubo_corporate_document_certificate_of_incorporation' ? <div id={'ubo_corporate_document_certificate_of_incorporation'} className="bg-white rounded-xl shadow-sm p-6 mb-6">
                    <CorporateDocument corporate_id={corporate.info.corporate_id} document={corporate.documents['CERTIFICATE_OF_INCORPORATION'].file} title="Certificate of Incorporation" slug={'CERTIFICATE_OF_INCORPORATION'} state={corporate.documents['CERTIFICATE_OF_INCORPORATION'].state} isPdf={corporate.documents['CERTIFICATE_OF_INCORPORATION'].is_pdf} />
                </div>
                    : null}

                {currentStep === 'ubo_corporate_document_memorandum_of_association' ? <div id={'ubo_corporate_document_memorandum_of_association'} className="bg-white rounded-xl shadow-sm p-6 mb-6">
                    <CorporateDocument corporate_id={corporate.info.corporate_id} document={corporate.documents['MEMORANDUM_OF_ASSOCIATION'].file} title="Memorandum of Association" slug={'MEMORANDUM_OF_ASSOCIATION'} state={corporate.documents['MEMORANDUM_OF_ASSOCIATION'].state} isPdf={corporate.documents['MEMORANDUM_OF_ASSOCIATION'].is_pdf} />
                </div>
                    : null}
                {currentStep === 'ubo_corporate_document_registry_of_ubos' ? <div id={'ubo_corporate_document_registry_of_ubos'} className="bg-white rounded-xl shadow-sm p-6 mb-6">
                    <CorporateDocument corporate_id={corporate.info.corporate_id} document={corporate.documents['REGISTER_OF_DIRECTORS_SHAREHOLDERS'].file} title="Register of Directors and Shareholders" slug={'REGISTER_OF_DIRECTORS_SHAREHOLDERS'} state={corporate.documents['REGISTER_OF_DIRECTORS_SHAREHOLDERS'].state} isPdf={corporate.documents['REGISTER_OF_DIRECTORS_SHAREHOLDERS'].is_pdf} />
                </div>
                    : null}
                {currentStep === 'ubo_corporate_document_corporate_bank_statement' ? <div id={'ubo_corporate_document_corporate_bank_statement'} className="bg-white rounded-xl shadow-sm p-6 mb-6">
                    <CorporateDocument corporate_id={corporate.info.corporate_id} document={corporate.documents['COMPANY_BANK_STATEMENT'].file} title="Company Bank Statement" slug={'COMPANY_BANK_STATEMENT'} state={corporate.documents['COMPANY_BANK_STATEMENT'].state} isPdf={corporate.documents['COMPANY_BANK_STATEMENT'].is_pdf} />
                </div>
                    : null}
                {currentStep === 'ubo_corporate_aml' ? <div id={'ubo_corporate_aml'} className="bg-white rounded-xl shadow-sm p-6 mb-6">
                    <CorporateAml corporate title="Anti-Money Laundering Screening (AML)" onFinish={() => onRefresh()} data={corporate.aml} />
                </div>
                    : null}
            </>
        )
    }

    return (
        <div className="flex">
            <div className="core-view-profile">
                <CorporateSidebar corporate={data} />
            </div>
            <div className="core-view-main">
                <CorporateView corporate={data} />
            </div>
        </div>
    )
}