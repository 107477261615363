import { useContext, useEffect, useState } from 'react'
import { faAngleLeft, faAngleRight, faCheck, faCross, faFileDownload, faLayerGroup, faList, faPlus, faTimes } from '@fortawesome/pro-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import WebhookService from '@/services/webhooks'
import { useParams } from 'react-router-dom'
import { AppContext } from '@/context/app'
import Empty from '../misc/Empty'
import DeliveryItem from './DeliveryItem'
import ContentSearchBar from '../ui/ContentSearchBar'
import Pagination from 'react-js-pagination'
import { faShippingFast } from '@fortawesome/pro-duotone-svg-icons'
import * as Notification from '@/components/ui/Notification'
import Loader from '../ui/Loader'
import MiniButton from '@/components/ui/MiniButton'

export default function DeliveriesWebhooks() {
	const [deliveries, setDeliveries] = useState([])
	const { app_id } = useParams()
	const [, AppDispatch] = useContext(AppContext)
	const [currentPage, setCurrentPage] = useState(1)
	const [search, setSearch] = useState('')
	const [next, setNext] = useState(false)
	const [loading, setLoading] = useState(true)
	const [status, setStatus] = useState('')

	useEffect(() => {
		if (search !== "") {
			setCurrentPage(1)
		}
		getWebhookDeliveries()
	}, [currentPage, search, status])

	async function getWebhookDeliveries() {
		setLoading(true)
		await WebhookService.GetDeliveries(app_id, currentPage - 1, search, status)
			.then(async (data) => {
				setNext(data.next)
				AppDispatch({
					type: 'SET_DELIVERIES',
					payload: {
						webhook_deliveries: data.deliveries,
					},
				})
				setDeliveries(data.deliveries)
				setLoading(false)
			})
			.catch((err) => {
				setLoading(false)
				Notification.error(err.message)
			})
	}

	return (
		<div className="flex flex-wrap mt-4">
			<div className="w-full">
				<div className="h-full m-4 border p-6 px-8 pb-4 mb-0 border-bluegray-150 items-center bg-white rounded-xl">
					<div>
						<div className="flex justify-between items-center">
							<h3 className="text-xl"><FontAwesomeIcon className="text-gray-500 mr-2" icon={faShippingFast} /> Last Webhook deliveries</h3>
							<div className="flex mt-5">
								<div className="mr-3">
									<MiniButton onClick={() => setStatus('')} currentBg="primary" theme="secondary" icon={faList}
										label="All webhooks"></MiniButton>
									{status === '' ? <div className="mx-auto mt-2 w-5 h-1 rounded-xl bg-primary-500"></div> : null}
								</div>
								<div className="mr-3">
									<MiniButton onClick={() => setStatus('SUCCESSFUL')} currentBg="primary" theme="success" icon={faCheck}
										label="Success"></MiniButton>
									{status === 'SUCCESSFUL' ? <div className="mx-auto mt-2 w-5 h-1 rounded-xl bg-green-500"></div> : null}
								</div>
								<div className="mr-6">
									<MiniButton focus onClick={() => setStatus('FAILED')} currentBg="primary" theme="error" icon={faTimes}
										label="Failed"></MiniButton>
									{status === 'FAILED' ? <div className="mx-auto mt-2 w-5 h-1 rounded-xl bg-red-500"></div> : null}
								</div>
							</div>
							<div>
								<ContentSearchBar
									onChange={(el) =>
										setSearch(el.target.value)
									}></ContentSearchBar>
							</div>
						</div>

						<hr className="my-6" />
						{loading === false ?
							<div className="flex flex-wrap p-6 pt-3">
								{deliveries.map((delivery) => {
									return (
										<>
											<DeliveryItem
												key={delivery.service_session_id}
												item={delivery}></DeliveryItem>
										</>
									)
								})}
								{deliveries.length === 0 ? (
									<Empty value="No deliveries yet"></Empty>
								) : null}
							</div> :
							<div className="text-center mt-6"><Loader size="maxi" className="mr-2"></Loader> </div>}
						<div className="flex justify-between">
							<div className="mt-5">
								<p className="font-light">
									Showing{' '}
									<span className="font-medium">
										{(currentPage - 1) * 20 + 1}
									</span>{' '}
									to{' '}
									<span className="font-medium">
										{deliveries.length === 20 ?
											currentPage * 20 : (currentPage - 1) * 20 + deliveries.length}
									</span>{' '}
								</p>

							</div>
							{loading === false ?
								<div className="mt-3 px-3 sm:px-0 flex ">
									{currentPage > 1 ?
										<div className="relative inline-flex items-center px-2 py-2 cursor-pointer rounded-l-md border border-gray-200 bg-white text-sm font-regular text-gray-500 hover:bg-gray-50">
											<FontAwesomeIcon onClick={() => setCurrentPage(currentPage - 1)} icon={faAngleLeft}></FontAwesomeIcon>
										</div>
										:
										<div className="relative inline-flex items-center px-2 py-2 cursor-not-allowed rounded-l-md border border-gray-200 text-sm font-regular text-gray-500 bg-gray-200">
											<FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon>
										</div>
									}
									<div className="relative inline-flex items-center px-2 py-2 cursor-pointer border border-gray-200 bg-white text-sm font-regular text-gray-500 hover:bg-gray-50">
										<p className="mr-2 ml-2">{currentPage}</p>
									</div>
									{next ?
										<div className="relative inline-flex items-center px-2 py-2 rounded-r-md cursor-pointer border border-gray-200 bg-white text-sm font-regular text-gray-500 hover:bg-gray-50">
											<FontAwesomeIcon onClick={() => setCurrentPage(currentPage + 1)} icon={faAngleRight}></FontAwesomeIcon>
										</div>
										:
										<div className="relative inline-flex items-center px-2 py-2 rounded-r-md border cursor-not-allowed border-gray-200 text-sm font-regular text-gray-500 bg-gray-200">
											<FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>
										</div>
									}
								</div>
								: null}
						</div>
					</div>
				</div>
			</div>
		</div>

	)
}