import { Modal } from 'react-responsive-modal'
import MiniButton from '@/components/ui/MiniButton'
import { useEffect, useState } from 'react'
import InputDigits from '@/components/ui/InputDigits'
import Input2FA from '@/components/ui/Input2FA'
import {
    faTimes,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TwoFactorService from '@/services/twofactor'
import Alert from '@/components/ui/Alert'

export default function SettingsTwoFactor() {
    const [open, setOpen] = useState(false)
    const [disableOpen, setDisableOpen] = useState(false)
    const [authCode, setAuthCode] = useState('')
    const [enabled, setEnabled] = useState(false)
    const [request, setRequest] = useState(false)
    const [requestLoading, setRequestLoading] = useState(false)
    const [enableLoading, setEnableLoading] = useState(false)
    const [error, setError] = useState(null)
    const [backupCodes, setBackupCodes] = useState([])
    const [openRecovery, setOpenRecovery] = useState(false)

    useEffect(() => {
        GetStatus()
        return () => { }
    }, [])

    async function GetStatus() {
        await TwoFactorService.Status().then((data) => {
            setEnabled(data.enabled)
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        setAuthCode('')
        setError(null)
    }, [open, disableOpen, openRecovery])

    async function Request2FA() {
        setRequestLoading(true)
        await TwoFactorService.Request().then((data) => {
            setRequestLoading(false)
            setRequest(data)
            setOpen(true)
        }).catch((err) => {
            console.log(err)
        })
    }

    async function Enable2FA() {
        setEnableLoading(true)
        setError(null)
        const body = {
            code: authCode,
        }
        await TwoFactorService.Activate(body).then((data) => {
            setEnableLoading(false)
            setOpen(false)
            setOpenRecovery(true)
            setBackupCodes(data.backup_codes)
            setEnabled(true)
        }).catch((err) => {
            setEnableLoading(false)
            setError(err.message)
        })
    }

    async function Disable2FA() {
        setEnableLoading(true)
        setError(null)
        const body = {
            code: authCode,
        }
        await TwoFactorService.Disable(body).then(() => {
            setEnableLoading(false)
            setDisableOpen(false)
            setEnabled(false)
        }).catch((err) => {
            setEnableLoading(false)
            setError(err.message)
        })
    }

    return (
        <div>
            <h3 className="text-xl">Two-Factor Authentication</h3>
            <p className="text-gray-500 my-2">Enhance the security of your account by adding a second layer of security</p>
            <hr className="my-6" />
            <div className="flex justify-between items-center">
                <div>
                    <p className="text-sm text-gray-500 font-bold">Current status</p>
                    {enabled ? <p className="mt-1">
                        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
                            <svg className="-ml-1 mr-1.5 h-2 w-2 text-green-400" fill="currentColor" viewBox="0 0 8 8">
                                <circle cx={4} cy={4} r={3} />
                            </svg>
                            Enabled
                        </span>
                    </p> : <p className="mt-1">
                        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">
                            <svg className="-ml-1 mr-1.5 h-2 w-2 text-red-400" fill="currentColor" viewBox="0 0 8 8">
                                <circle cx={4} cy={4} r={3} />
                            </svg>
                            Disabled
                        </span>
                    </p>}

                </div>
                <div>
                    <p>
                        {enabled === false ? <MiniButton loading={requestLoading} theme="success" label="Enable 2FA" onClick={() => Request2FA()}></MiniButton> : <MiniButton loading={requestLoading} theme="error" label="Disable 2FA" onClick={() => setDisableOpen(true)}></MiniButton>}
                    </p>

                    <Modal
                        open={open}
                        showCloseIcon={true}
                        closeIcon={true}
                        onClose={() => setOpen(false)}
                        center>
                        <div className='p-4 twofactor-view'>
                            <div className="flex justify-between">
                                <div>
                                    <h2 className="text-xl">Enable Two-Factor Auth</h2>
                                </div>
                                <div>
                                    <h2 className="text-xl"><span><FontAwesomeIcon onClick={() => setOpen(false)} className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-100 ease-in" icon={faTimes}></FontAwesomeIcon></span></h2>
                                </div>
                            </div>
                            <hr className="my-3" />
                            <div>
                                {error ? (
                                    <Alert type="error" title={error}></Alert>
                                ) : null}
                            </div>
                            <p className="font-bold"><span>1</span>. Scan QR Code</p>
                            <p className="text-sm mt-2">Scan the image below with the two-factor authentication app on your phone. If you cannot use a barcode, enter the key above instead.</p>
                            <div className="text-center">
                                <p className="text-sm text-gray-500 font-bold">QR Code</p>
                                <img className="mx-auto w-40" src={`data:image/png;base64,${request.qr_code}`}></img>
                                <div className="mt-3">
                                    <p className="text-sm text-gray-500 font-bold">Secret key</p>
                                    <div className="w-full mb-2 mt-2">
                                        <p className="text-sm p-2 rounded-md bg-gray-100">{request.key}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-6">
                                <p className="font-bold"><span>2</span>. Confirm</p>
                                <p className="text-sm mt-2">Enter 6 digits code to confirm 2FA activation</p>
                                <div className="my-4">
                                    <InputDigits value={[authCode, setAuthCode]} />
                                </div>
                                <MiniButton loading={enableLoading} full disabled={authCode.trim().length != 6} theme="success" label="Enable 2FA" onClick={() => Enable2FA()}></MiniButton>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        open={disableOpen}
                        showCloseIcon={true}
                        closeIcon={true}
                        onClose={() => setDisableOpen(false)}
                        center>
                        <div className='p-4 twofactor-view'>
                            <div className="flex justify-between">
                                <div>
                                    <h2 className="text-xl">Disable Two-Factor Auth</h2>
                                </div>
                                <div>
                                    <h2 className="text-xl"><span><FontAwesomeIcon onClick={() => setDisableOpen(false)} className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-100 ease-in" icon={faTimes}></FontAwesomeIcon></span></h2>
                                </div>
                            </div>
                            <hr className="my-3" />
                            <div>
                                {error ? (
                                    <Alert type="error" title={error}></Alert>
                                ) : null}
                            </div>
                            <div className="mt-4">
                                <p className="text-sm mt-2">Enter 6 digits code to confirm 2FA deactivation</p>
                                <div className="my-4">
                                    <Input2FA value={[authCode, setAuthCode]} />
                                </div>
                                <MiniButton loading={enableLoading} full disabled={authCode.trim().length != 6} theme="error" label="Disable 2FA" onClick={() => Disable2FA()}></MiniButton>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        open={openRecovery}
                        showCloseIcon={true}
                        closeIcon={true}
                        onClose={() => setOpenRecovery(false)}
                        center>
                        <div className='p-4 twofactor-view'>
                            <div className="flex justify-between">
                                <div>
                                    <h2 className="text-xl">Recovery codes</h2>
                                </div>
                                <div>
                                    <h2 className="text-xl"><span><FontAwesomeIcon onClick={() => setDisableOpen(false)} className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-100 ease-in" icon={faTimes}></FontAwesomeIcon></span></h2>
                                </div>
                            </div>
                            <hr className="my-3" />
                            <div>
                                {error ? (
                                    <Alert type="error" title={error}></Alert>
                                ) : null}
                            </div>
                            <div className="mt-4">
                                <p className="text-sm mt-2">Recovery codes are used to access your account in the event you cannot receive two-factor authentication codes. We advise you to copy your recovery codes before continuing two-factor authentication setup below.</p>
                                <div className="bg-gray-100 p-3 my-3 rounded-md">
                                    {backupCodes.map((code) => {
                                        return <p key={code}>{code}</p>
                                    })}

                                </div>
                                <MiniButton loading={enableLoading} full theme="primary" label="OK" onClick={() => setOpenRecovery(false)}></MiniButton>
                            </div>
                        </div>
                    </Modal>

                </div>
            </div>
        </div>
    )
}