import { useContext, useState } from "react";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import TeamMembers from "@/components/team/TeamMembers";
import Button from "@/components/ui/Button";
import Footer from "@/components/menu/Footer";
import SubNav from "@/components/menu/SubNav";
import InviteNewUser from "@/components/team/InviteNewUser";
import { Modal } from "react-responsive-modal";
import { AppContext } from "@/context/app";
import { useHistory, useParams } from "react-router-dom";

export default function AppTeam() {
  const [app] = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const router = useHistory();
  const { app_id } = useParams();

  function finish() {
    setOpen(false);
    setRefresh(refresh + 1);
  }

  return (
    <div>
      <div>
        <SubNav></SubNav>
        <div className="max-w-7xl mx-auto relative">
          <div className="dashboard-container px-3">
            <div className="flex justify-between mt-3">
              <div>
                <div className="flex">
                  <div
                    className="cursor-pointer"
                    onClick={() => (window.location.href = `/app/${app_id}/`)}
                  >
                    <img
                      className="w-16 h-16 mt-1 rounded-xl"
                      src={app.logo_url}
                    />
                  </div>
                  <div className="ml-5">
                    <h1 className="text-3xl text-white">
                      <span className="font-bold">Team</span>
                    </h1>
                    <h3 className="text-lg mt-2 text-bluegray-300">
                      Invite member of your organization to manage your app
                    </h3>
                  </div>
                </div>
              </div>
              {app.iam.owner || app.iam.admin ? (
                <div className="mt-2.5">
                  <Button
                    onClick={() => setOpen(true)}
                    currentBg="primary"
                    theme="success"
                    icon={faPlus}
                    label="Invite new member"
                  ></Button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="max-w-7xl mx-auto relative">
          <div className="flex flex-wrap items-start mt-6">
            <TeamMembers refresh={refresh}></TeamMembers>
          </div>
          <Modal
            open={open}
            showCloseIcon={true}
            closeIcon={true}
            onClose={() => setOpen(false)}
            center
          >
            <InviteNewUser
              onFinish={() => finish()}
              onClose={() => setOpen(false)}
            />
          </Modal>

          <Footer></Footer>
        </div>
      </div>
    </div>
  );
}
