import { faCheckCircle, faEllipsisHAlt, faTimes, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NotificationService from '@/services/notification'
import { useParams } from 'react-router-dom'


export default function NotificationFlow(props) {
    const [error, setError] = useState(null)
    const [data, setData] = useState(null)
    const [ready, setReady] = useState(false)
    const [method, setMethod] = useState([])
    const { app_id } = useParams()

    useEffect(() => {
        GetInfo()
        return () => { }
    }, [])

    function GetInfo() {
        NotificationService.GetNotificationStatus(app_id).then((data) => {
            setData(data)
            setMethod(data.method)
            setReady(true)
        }).catch((err) => {
            setError(err.message)
        })
    }

    function openManage() {
        if (!props.manageable) {
            props.onManage()
        }
    }

    function Deactivate() {
        const body = {
            activated: false,
            method: data.method
        }
        NotificationService.SetNotificationSetting(app_id, body).then(async () => {
            props.onClose()
        }).catch((err) => {
            setError(err.message)
        })
    }


    return (
        <div className={`${props.manageable ? 'modal-view' : 'step-view w-full sm:w-1/2 lg:w-1/3 my-4'}`}>
            <div className={`z-max w-full h-full flex items-center justify-center ${!props.manageable ? 'px-3' : ''}`}>
                <div onClick={() => openManage()} className={`${!props.manageable ? 'rounded-xl cursor-pointer step-view hover:shadow-lg bg-white border border-gray-200 hover:border-gray-300 transition-25 transition duration-200 ease-in' : ''} flex flex-col w-full h-full`}>
                    <header className="p-5 pb-3">
                        <div className="flex justify-between items-center">
                            <div className="flex items-center">
                                <div>
                                    <img className="h-9 relative mr-2" src="/img/icons/ui/icon-notification-bell.svg" />
                                </div>
                                <div>
                                    <h2 className="text-lg text-primary-500  font-medium"> Notifications
                                    </h2>
                                </div>
                            </div>
                            <div>
                                <div className="flex items-center ">
                                    <div>
                                        {props.manageable ?
                                            <h2 className="text-xl relative ml-3 top-0.5"><span><FontAwesomeIcon onClick={() => props.onClose()} className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-200 ease-in text-regular relative top-[-1px]" icon={faTimes}></FontAwesomeIcon></span></h2>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="my-3" />
                    </header>

                    <main
                        className="flex-1 overflow-y-auto p-5 py-0"
                        id="individual-main">
                        {ready ?
                            <>
                                <div className="flex flex-wrap">
                                    <div className="w-full">
                                        <div>
                                            <div className="flex justify-between">
                                                <div>
                                                    <p className="text-sm text-primary-700">Integrations</p>
                                                </div>
                                                {props.manageable ? <div>
                                                    <p onClick={() => props.manageMethod()} className="text-xs py-0.5 cursor-pointer hover:bg-gray-300 ease-in duration-100 transition rounded-md bg-gray-200 px-2 text-primary-700"><FontAwesomeIcon className="mr-0.5" icon={faEllipsisHAlt} /> Manage</p>
                                                </div>
                                                    : null}
                                            </div>
                                            {Object.entries(data.method).map(([index]) => {
                                                return <>
                                                    <p key={index} className="mt-2 capitalize"><FontAwesomeIcon className="mr-1 text-green-500" icon={faCheckCircle} /> {data.method[index]}</p>
                                                </>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </> : null}
                    </main>
                    {props.manageable ? <footer className="p-5 text-center">
                        <p onClick={() => Deactivate()} className="text-gray-400 hover:text-red-500 transition duration-200 ease-in cursor-pointer"><FontAwesomeIcon className="mr-1" icon={faTrash} /> Remove step</p>
                    </footer>
                        : null}
                </div>
            </div>
        </div>
    )
}
