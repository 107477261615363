import { useParams } from "react-router-dom";
import { faClock, faBan, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { faDotCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-responsive-modal";
import { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function IndividualLiveness({ title, step, step_id, ip }) {
  const { app_id, session_id } = useParams();
  const [open, setOpen] = useState(false);

  function IdentityTitle({ state }) {
    return (
      <div>
        <div className="flex justify-between items-center">
          <div>
            <p className="font-medium text-primary-400">
              {title ? title : "Liveness"}
            </p>
          </div>
          <div className="flex">
            <p className="text-gray-300 mt-1 mr-2">{ip ? ip : ""}</p>
            <div className="flex">
              {/* <p onClick={() => setOpen(true)} className="text-bluegray-500 hover:text-bluegray-700 ease-in duration-200 transition cursor-pointer text-sm bg-bluegray-100 rounded-md px-2 py-1 mr-3"><FontAwesomeIcon className="mr-0.5" icon={faHistory} /> History</p> */}
              {state === "PENDING" ? (
                <p className="text-orange-500 text-sm bg-orange-100 rounded-md px-2 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faClock} /> Pending
                  verification
                </p>
              ) : null}
              {state === "NOT_STARTED" ? (
                <p className="text-gray-500 text-sm bg-gray-100 rounded-md px-2 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faDotCircle} /> Not
                  started
                </p>
              ) : null}
              {state === "REJECTED" ? (
                <p className="text-red-500 text-sm bg-red-100 rounded-md px-2 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faBan} /> Rejected
                </p>
              ) : null}
              {state === "FINAL_REJECTED" ? (
                <p className="text-red-500 text-sm bg-red-100 rounded-md px-2 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faBan} /> Final
                  Rejected
                </p>
              ) : null}
              {state === "VALIDATED" ? (
                <p className="text-green-500 text-sm bg-green-100 rounded-md px-2 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faCheck} /> Verified
                </p>
              ) : null}
            </div>
          </div>
        </div>
        {state === "VALIDATED" ? (
          <hr className="my-4 border-bluegray-200" />
        ) : null}
      </div>
    );
  }

  function InfoItem(props) {
    return (
      <div className="mt-2">
        <div className="flex border border-transparent py-1 px-1.5 rounded-xl">
          <div className="mr-3 mt-0.5 flex-shrink-0">
            <img className="h-9" src={`/img/icons/ui/${props.icon}.svg`}></img>
          </div>
          <div className="relative top-[2px]">
            <p className="text-bluegray-400 text-xs">{props.title}</p>
            <p
              className={`text-gray-600 text-sm ${
                props.capitalize ? "capitalize" : null
              }`}
            >
              {props.value}
            </p>
          </div>
        </div>
      </div>
    );
  }

  function InfoFeature(props) {
    return (
      <div className="mt-2">
        <div className="flex border border-transparent py-1 px-1.5 rounded-xl">
          <div className="mr-3 mt-0.5 flex-shrink-0">
            <img className="h-9" src={`/img/icons/ui/${props.icon}.svg`}></img>
          </div>
          <div className="relative top-[2px]">
            <p className="text-bluegray-400 text-xs capitalize">
              {props.title}
            </p>
            {props.included ? (
              <p className="text-green-500 text-sm">{props.label}</p>
            ) : (
              <p className="text-red-500 text-sm">Not {props.label}</p>
            )}
          </div>
        </div>
      </div>
    );
  }

  function LivenessDetails({ step, step_id }) {
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };

    return (
      <div>
        <p className="mt-6 uppercase tracking-wide font-medium text-sm text-primary-400">
          Challenge
        </p>
        <div className="flex flex-wrap mt-3">
          <div className="w-1/2 pr-3">
            {step.audit !== null &&
            step.audit !== undefined &&
            step.audit.length === 6 ? (
              <>
                <div className="w-full" data-openreplay-obscured>
                  <Carousel responsive={responsive}>
                    <img src={step.audit[0]} key={step.audit[0]} alt="ssa" />
                    <img src={step.audit[1]} key={step.audit[1]} alt="ssa" />
                    <img src={step.audit[2]} key={step.audit[2]} alt="ssa" />
                    <img src={step.audit[3]} key={step.audit[3]} alt="ssa" />
                    <img src={step.audit[4]} key={step.audit[4]} alt="ssa" />
                    <img src={step.audit[5]} key={step.audit[5]} alt="ssa" />
                  </Carousel>
                </div>
              </>
            ) : (
              <div
                className="w-1/2 bg-bluegray-100 w-full h-[250px] px-2 rounded-md mt-3"
                style={{
                  backgroundImage: `url('${step.face}')`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                }}
              ></div>
            )}
          </div>
          <div className="w-1/2 pl-3">
            <div>
              <InfoFeature
                title="Challenge"
                icon={"icon-valid"}
                included={true}
                label="Passed"
              />
            </div>
            <div>
              <InfoItem
                title="Attempts"
                icon={"look-in-camera"}
                included={true}
                value={step.attempts}
              />
            </div>
            <div>
              <InfoItem
                title="Enrolled at"
                icon={"icon-timeout"}
                included={true}
                value={step.enrollment_date ? step.enrollment_date : "-"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <IdentityTitle title={title} state={step.state} />
      {step.state === "VALIDATED" || step.state === "REJECTED" ? (
        <LivenessDetails step_id={step_id} step={step} />
      ) : null}
      <Modal
        open={open}
        showCloseIcon={true}
        closeIcon={true}
        onClose={() => setOpen(false)}
        center
      >
        <p>History</p>
      </Modal>
    </div>
  );
}
