import AuthGuard from '@/guard/auth'
import AppNavbar from '@/components/menu/AppNavbar'
import AppDeveloperPage from '@/components/pages/app/AppDeveloperPage'
import AppGuard from '@/guard/app'
import AppIamGuard from '@/guard/appiam'

function Developer() {
    return (
        <AuthGuard>
            <AppGuard>
                <AppIamGuard page="developer">
                    <div className='relative min-h-screen flex flex-col'>
                        <AppNavbar></AppNavbar>
                        <AppDeveloperPage></AppDeveloperPage>
                    </div>
                </AppIamGuard>
            </AppGuard>
        </AuthGuard>
    )
}

export default Developer
