import AuthGuard from '@/guard/auth'
import HomeNavbar from '@/components/menu/HomeNavbar'
import MyAppPage from '@/components/pages/MyAppPage'
import { useState } from 'react'

function MyApps() {
	const [search, setSearch] = useState('')

	return (
		<AuthGuard>
			<div className='relative min-h-screen flex flex-col'>
				<HomeNavbar onSearch={(term) => setSearch(term)}></HomeNavbar>
				<MyAppPage search={search}></MyAppPage>
			</div>
		</AuthGuard>
	)
}

export default MyApps
