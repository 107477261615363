import { ManagerAuth } from '@/services/axios'

export default {
    GetNotificationStatus(app_id) {
        return ManagerAuth.get(`${process.env.REACT_APP_MANAGER_HOST}/v3/settings/notifications/${app_id}`)
    },
    SetNotificationSetting(app_id, body) {
        return ManagerAuth.put(`${process.env.REACT_APP_MANAGER_HOST}/v3/settings/notifications/${app_id}`, body)
    },
}


