import { useEffect, useState } from 'react'
import AppsService from '@/services/apps'
import AppItemPlaceholder from '../placeholder/AppItemPlaceholder'
import AppItem from '@/components/pages/apps/AppItem'

export default function DasbhoardApps(props) {

	const [ready, setReady] = useState(false)
	const [data, setData] = useState(null)

	useEffect(async () => {
		setReady(false)
		await AppsService.GetApps(1, props.search)
			.then((data) => {
				setData(data)
				setReady(true)
			})
		return () => { }
	}, [props.search])


	return (
		<>
			{ready ? (
				<>
					{data.apps.map((app) => {
						return (
							<div className="w-1/2 sm:w-1/3 lg:w-1/4"
								onClick={() => { }}
								key={app._key}>
								<AppItem key={app._key} _key={app._key} type={app.type} logo_url={app.logo_url} name={app.name}></AppItem>
							</div>
						)
					})}

					{data.apps.length === 0 ? <div className="w-1/2 sm:w-1/3 lg:w-1/4"
						onClick={() => props.createApp()}
						key={'first'}>
						<div className='m-4 text-center border border-bluegray-150 items-center bg-white rounded-xl cursor-pointer transition-25 hover:shadow-lg hover:border-bluegray-300 transition duration-200 ease-in'>
							<div className="m-3 my-6 mt-12">
								<img className="h-28 w-28 mx-auto border border-transparent rounded-2xl" src={'/img/icons/ui/icon-plus.svg'} />
								<div className="my-5 mb-12">
									<p className='text-regular font-regular font-bold'>
										<span>Create</span>
									</p>
									<p className='text-regular text-sm font-regular'>
										<span className="text-gray-500">{props.search ? props.search : 'your first app'}</span>
									</p>
								</div>
							</div>
						</div>
					</div> : null}

					{data.apps.length < 8 ? <>
						{Array(8 - data.apps.length - (data.apps.length === 0 ? 1 : 0)).fill().map((i) => {
							return (<div key={i} className="w-1/2 sm:w-1/3 lg:w-1/4">
								<div className='m-4 text-center border border-bluegray-150 items-center bg-white rounded-xl transition-25'>
									<div className="m-3 my-6 mt-12">
										<div className="h-28 w-28 mx-auto border bg-gray-100 border-bluegray-100 rounded-2xl"></div>
										<div className="my-6 mb-12">
											<div className='text-regular font-regular font-bold'>
												<div className="bg-gray-300 h-3 mx-auto w-16 rounded"></div>
											</div>
											<div className='mt-3 pb-1 text-regular text-sm font-regular'>
												<div className="bg-gray-200  h-3 mx-auto w-12 rounded"></div>
											</div>
										</div>
									</div>
								</div>
							</div>)
						})}
					</> : null}

				</>
			) : (
				<>
					{Array(8).fill().map((i) => {
						return <AppItemPlaceholder key={i} />
					})}
				</>
			)}
		</>
	)
}
