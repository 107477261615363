import AuthGuard from '@/guard/auth'
import AppRedirectionV2 from '@/components/pages/app/AppRedirectionV2'
import AppGuard from '@/guard/app'

function v2Redirection() {
    return (
        <AuthGuard>
            <AppGuard>
                <AppRedirectionV2></AppRedirectionV2>
            </AppGuard>
        </AuthGuard>
    )
}

export default v2Redirection
