import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons'
import { useEffect, useState } from 'react'
import countriesList from '@/resources/countries.json'
import CountryFlag from './CountryFlag'

export default function CountrySelector(props) {
	let errorMessage = null
	let errorIcon = null
	let inputIcon = null
	let inputLabel = null
	const [localValue, setLocalValue] = useState(props.value ? props.value : '')
	const [localSuggestions, setLocalSuggestions] = useState([])
	const [focus, setFocus] = useState(false)

	if (props.label) {
		inputLabel = (
			<label
				htmlFor={props.name}
				className="block text-sm font-medium text-gray-700 mb-2">
				{props.label}
			</label>
		)
	}

	if (props.error) {
		errorMessage = (
			<p className="mt-2 text-sm text-red-600" id="email-error">
				{props.message}
			</p>
		)

		if (!props.icon) {
			errorIcon = (
				<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
					<FontAwesomeIcon
						className="text-red-500"
						icon={faExclamationCircle}
					/>
				</div>
			)
		}
	}

	if (props.icon) {
		inputIcon = (
			<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
				<FontAwesomeIcon
					className={`${props.error ? 'text-red-500' : 'text-gray-500'}`}
					icon={props.icon}
				/>
			</div>
		)
	}

	useEffect(() => {
		setSuggestions()
	}, [localValue])

	function setSuggestions() {
		if (countriesList) {
			const suggestions = countriesList.filter((reg) =>
				reg.name.toLowerCase().includes(localValue.toLowerCase())
			)
			setLocalSuggestions(suggestions)
		} else {
			setLocalSuggestions([])
		}
	}

	return (
		<>
			{inputLabel}
			<div className="relative rounded-xl">
				{inputIcon}
				<input
					onFocus={() => setFocus(true)}
					onBlur={() => setFocus(false)}
					disabled={props.disabled}
					ref={props.reference}
					onChange={(e) => {
						if (props.setValue) {
							props.setValue(e.target.value)
						}
						setLocalValue(e.target.value)
					}}
					value={props.value}
					id={props.name}
					name={props.name}
					type={props.type}
					placeholder={props.placeholder}
					className={`${props.className
						} appearance-none block w-full py-2.5 border transition duration-100 ease-in border-gray-300 rounded-xl placeholder-gray-400 focus:outline-none sm:text-sm ${props.icon ? 'pl-9 pr-3' : 'px-3'
						} ${props.error
							? 'text-red-500 border-red-500 focus:border-red-500 focus:ring-red-500'
							: 'focus:ring-primary-500 focus:border-primary-500'
						}`}
				/>
				{localValue.length > 0 &&
					countriesList &&
					countriesList.length > 0 &&
					localSuggestions.length > 0 &&
					focus ? (
					<ul
						className="absolute z-50 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
						tabIndex="-1"
						role="listbox"
						aria-labelledby="listbox-label"
						aria-activedescendant="listbox-option-3">
						{localSuggestions.slice(0, 5).map((suggestion, i) => {
							return (
								<li
									key={i}
									onMouseDown={() => props.setSuggestion(suggestion)}
									className="text-gray-900 cursor-pointer hover:bg-bluegray-200 transition ease-in duration-100 select-none relative py-2 pl-3 pr-9"
									id="listbox-option-0"
									role="option">
									<div className="flex items-center">
										<span className="font-normal ml-3 block truncate">
											<CountryFlag
												className="mr-3 rounded-sm h-3"
												country={suggestion.alpha3}></CountryFlag>
											{suggestion.name}
										</span>
									</div>
								</li>
							)
						})}
					</ul>
				) : null}
				{errorIcon}
			</div>
			{errorMessage}
		</>
	)
}
