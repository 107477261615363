import { LoginContext } from "@/context/login";
import { faPassport } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";

export default function Login() {
  const [loginState] = useContext(LoginContext);

  return (
    <>
      <div className="min-h-screen px-8 bg-primary-500 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="mx-auto">
          <img
            className="w-64"
            src="/img/logo/synaps-manager-logo-white.svg"
          ></img>
        </div>
        <div>
          <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm bg-white rounded-xl p-4 shadow">
            {loginState.currentComponent}
          </div>
          <p className="text-center mt-3 text-white">
            <a className="hover:underline" href="https://manager-kyc.synaps.io">
              <FontAwesomeIcon icon={faPassport} className="mr-2" /> Access
              Manager KYC
            </a>
          </p>
        </div>
      </div>
    </>
  );
}
