export default function Swtich(props) {
	return (
		<span
			role="checkbox"
			aria-checked={props.checked}
			tabIndex="0"
			onClick={() => props.onChange(!props.checked)}
			className={`${props.checked ? 'bg-green-500' : 'bg-gray-200'
				} relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}>
			<span
				aria-hidden="true"
				className={`${props.checked ? 'translate-x-5' : 'translate-x-0'
					} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}></span>
		</span>
	)
}
