import { LoginContext } from '@/context/login'
import { useContext, useState, useEffect } from 'react'
import Input from '@/components/ui/Input'
import Button from '@/components/ui/Button'
import { useForm } from 'react-hook-form'
import { faEnvelope, faRepeat } from '@fortawesome/pro-duotone-svg-icons'
import { faFingerprint as fasFingerprint } from '@fortawesome/pro-solid-svg-icons'
import AuthService from '@/services/auth'
import AuthCode from '@/components/login/AuthCode'
import Alert from '@/components/ui/Alert'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import Cookies from "js-cookie";
import { b64Decode } from "@/lib/b64";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@/components/ui/Tooltip";

export default function InitLogin() {
	const [, loginDispatch] = useContext(LoginContext)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const { executeRecaptcha } = useGoogleReCaptcha()
	const { register, handleSubmit, errors } = useForm()
	const [profile, setProfile] = useState(null);


	async function AuthLogin(email) {
		setError(null)
		setLoading(true)

		const recaptchaToken = await executeRecaptcha('login_page')

		const config = {
			headers: {
				Recaptcha: recaptchaToken,
			},
		}

		const body = {
			email: email,
		}

		await AuthService.Init(body, config)
			.then(() => {
				loginDispatch({
					type: 'SET_LOGIN',
					payload: {
						auth: {
							email: body.email,
							code: '',
						},
						currentComponent: <AuthCode></AuthCode>,
					},
				})
			})
			.catch((err) => {
				setLoading(false)
				setError(err.message)
			})
		return () => { }
	}

	useEffect(() => {
		const sProfile = Cookies.get("s_profile");
		if (sProfile) {
			const profile = JSON.parse(b64Decode(sProfile));
			setProfile(profile);
		}
	}, []);

	const onSubmit = async (data) => {
		AuthLogin(data.email);

		return () => { };
	};

	const formSchema = {
		email: {
			required: 'Email required',
			pattern: {
				value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
				message: 'Invalid email',
			},
		},
	}

	return (
		<>
			{error ? <Alert type="error" title={error}></Alert> : null}
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="mb-5">
					{profile ? (
						<>
							<div
								className={`flex rounded-xl bg-gray-200 p-3 mb-3 mx-0 justify-between`}
							>
								<div className="flex items-center">
									<div>
										<h3 className={`text-sm font-medium text-gray-800`}>
											{profile.user.firstname} {profile.user.lastname}
										</h3>
										<h3 className={`text-sm font-regular text-gray-900`}>
											{profile.user.email}
										</h3>
									</div>
								</div>
								<Tooltip auto={true} content={"Change account"}>
									<div
										className="cursor-pointer hover:opacity-50"
										onClick={() => setProfile(null)}
									>
										<FontAwesomeIcon className="mt-3 mr-2" icon={faRepeat} />
									</div>
								</Tooltip>
							</div>
						</>
					) : (
						<Input
							icon={faEnvelope}
							label="Email"
							referrer={register(formSchema.email)}
							placeholder="john.doe@synaps.io"
							name="email"
							error={errors.email}
							message={errors.email?.message}
						/>
					)}
				</div>
				<div>
					{profile ? (
						<Button
							type="button"
							full
							icon={fasFingerprint}
							loading={loading}
							label="Sign In"
							onClick={() => AuthLogin(profile.user.email)}
						/>
					) : (
						<Button
							type="submit"
							full
							icon={fasFingerprint}
							loading={loading}
							label="Sign In"
						/>
					)}
				</div>
			</form>
		</>
	);
}