import { useContext } from 'react'
import IndividualIdentity from '@/components/sessions/individual/IndividualIdentity'
import IndividualResidency from '@/components/sessions/individual/IndividualResidency'
import CorporateDocument from '@/components/sessions/corporate/CorporateDocument'
import IndividualLiveness from '@/components/sessions/individual/IndividualLiveness'
import IndividualAml from '@/components/sessions/individual/IndividualAml'
import CorporateUbos from '@/components/sessions/corporate/CorporateUbos'
import CorporateAml from '@/components/sessions/corporate/CorporateAml'
import { CorporateSessionContext } from '@/context/corporate_session'

export default function CorporateCore(props) {
    const [corporate] = useContext(CorporateSessionContext)

    return (
        <>
            <div id={'corporate_document_certificate_of_incorporation'} className="bg-white rounded-xl shadow-sm p-6 mb-6">
                <CorporateDocument title="Certificate of Incorporation" slug={'CERTIFICATE_OF_INCORPORATION'} state={corporate.corporate.documents['CERTIFICATE_OF_INCORPORATION'].state} document={corporate.corporate.documents['CERTIFICATE_OF_INCORPORATION'].file} isPdf={corporate.corporate.documents['CERTIFICATE_OF_INCORPORATION'].is_pdf} />
            </div>
            <div id={'corporate_document_memorandum_of_association'} className="bg-white rounded-xl shadow-sm p-6 mb-6">
                <CorporateDocument title="Memorandum of Association" slug={'MEMORANDUM_OF_ASSOCIATION'} state={corporate.corporate.documents['MEMORANDUM_OF_ASSOCIATION'].state} document={corporate.corporate.documents['MEMORANDUM_OF_ASSOCIATION'].file} isPdf={corporate.corporate.documents['MEMORANDUM_OF_ASSOCIATION'].is_pdf} />
            </div>
            <div id={'corporate_document_registry_of_ubos'} className="bg-white rounded-xl shadow-sm p-6 mb-6">
                <CorporateDocument title="Register of Directors and Shareholders" slug={'REGISTER_OF_DIRECTORS_SHAREHOLDERS'} state={corporate.corporate.documents['REGISTER_OF_DIRECTORS_SHAREHOLDERS'].state} document={corporate.corporate.documents['REGISTER_OF_DIRECTORS_SHAREHOLDERS'].file} isPdf={corporate.corporate.documents['REGISTER_OF_DIRECTORS_SHAREHOLDERS'].is_pdf} />
            </div>
            {corporate.corporate.documents['COMPANY_BANK_STATEMENT'] !== undefined && corporate.corporate.documents['COMPANY_BANK_STATEMENT'] !== null ?
                <div id={'corporate_document_corporate_bank_statement'} className="bg-white rounded-xl shadow-sm p-6 mb-6">
                    <CorporateDocument title="Company Bank Statement" slug={'COMPANY_BANK_STATEMENT'} state={corporate.corporate.documents['COMPANY_BANK_STATEMENT'].state} document={corporate.corporate.documents['COMPANY_BANK_STATEMENT'].file} isPdf={corporate.corporate.documents['COMPANY_BANK_STATEMENT'].is_pdf} />
                </div>
                : null}
            <div id={'corporate_main_aml'} className="bg-white rounded-xl shadow-sm p-6 mb-6">
                <CorporateAml title="Corporate - Anti-Money Laundering Screening (AML)" onFinish={() => props.onRefresh()} data={corporate.corporate.aml} />
            </div>
            <div id={'corporate_officer_liveness'} className="bg-white rounded-xl shadow-sm p-6 mb-6">
                <IndividualLiveness title="Corporate Officer - Liveness" step_id={'corporate_officer_liveness'} step={corporate.corporate.officer.liveness} />
            </div>
            <div id={'corporate_officer_identity'} className="bg-white rounded-xl shadow-sm p-6 mb-6">
                <IndividualIdentity member_id={0} title="Corporate Officer - ID Document" step_id={'corporate_officer_identity'} step={corporate.corporate.officer.identity} />
            </div>
            <div id={'corporate_officer_residency'} className="bg-white rounded-xl shadow-sm p-6 mb-6">
                <IndividualResidency member_id={0} title="Corporate Officer - Proof of Residency" step_id={'corporate_officer_residency'} step={corporate.corporate.officer.residency} />
            </div>
            <div id={'corporate_officer_aml'} className="bg-white rounded-xl shadow-sm p-6 mb-6">
                <IndividualAml title="Corporate Officer - Anti-Money Laundering Screening (AML)" onFinish={() => props.onRefresh()} data={corporate.corporate.officer.aml} />
            </div>
            <div id={'corporate_officer_ubos'} className="bg-white rounded-xl shadow-sm p-6 mb-6">
                <CorporateUbos onFinish={() => props.onRefresh()} data={corporate.corporate.officer.aml} />
            </div>
        </>
    )
}