import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Searchbar from "../ui/SearchBar";
import MiniProfile from "../user/MiniProfile";
import { faPassport } from "@fortawesome/pro-duotone-svg-icons";

export default function HomeNavbar(props) {
  return (
    <nav className="flex-shrink-0 bg-primary-500">
      <div className="max-w-7xl mx-auto py-3">
        <div className="flex px-3 sm:p-0 justify-between">
          <div className="flex">
            <a href="/">
              <div className="cursor-pointer relative top-1px">
                <img
                  className="w-8 h-8 mt-0.5 mx-3"
                  src="/img/logo/synaps-logo-s.svg"
                ></img>
              </div>
            </a>
            <div className="ml-3">
              <Searchbar
                placeholder={"Search app"}
                setValue={(e) => props.onSearch(e)}
              ></Searchbar>
            </div>
          </div>
          <div className="flex">
            <div className="items-center mr-2">
              <a
                href="https://manager-kyc.synaps.io"
                target="_blank"
                rel="noreferrer"
              >
                <p className="text-white p-2 px-3 rounded-full text-sm hover:underline cursor-pointer">
                  <FontAwesomeIcon className="mr-1" icon={faPassport} /> Access
                  Manager KYC
                </p>
              </a>
            </div>
            <div className="z-5 ml-2">
              <MiniProfile></MiniProfile>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
