import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { useEffect, useState } from 'react'
import Button from '@/components/ui/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from '@/components/ui/Alert'
import AppsService from '@/services/apps'
import { useParams } from 'react-router-dom'
import SwitchGreen from '@/components/ui/SwitchGreen'

export default function FlowCorporateDocuments(props) {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [ready, setReady] = useState(false)
    const [bankStatement, setBankStatement] = useState(false)
    const { app_id } = useParams()

    useEffect(() => {
        GetStepDetails()
        return () => { }
    }, [])


    function Submit() {
        setLoading(true)

        let docs = []

        if (bankStatement) {
            docs.push('COMPANY_BANK_STATEMENT')
        }

        const body = {
            documents: docs,
        }

        AppsService.AddCorporateDocumentRestriction(app_id, body).then(async () => {
            setLoading(false)
            props.onBack()
        }).catch((err) => {
            setError(err.message)
            setLoading(false)
        })
    }


    function GetStepDetails() {
        setLoading(true)
        AppsService.GetCorporateCompliance(app_id).then((data) => {
            setReady(true)
            setLoading(false)
            setDocuments(data)
        }).catch((err) => {
            setError(err.message)
            setLoading(false)
        })
    }

    function setDocuments(d) {
        setBankStatement(d.documents.includes('COMPANY_BANK_STATEMENT'))
    }

    return (
        <div className="modal-view">
            <div className="z-max w-full h-full flex items-center justify-center">
                <div className="flex flex-col w-full h-full rounded">
                    <header className="p-5 pb-3">
                        <div className="flex justify-between">
                            <div className="flex">
                                <div>
                                    <img className="h-6 relative top-0.5 mr-2" src="/img/icons/ui/icon-corporate-only.svg" />
                                </div>
                                <div>
                                    <h2 className="text-xl text-primary-500  font-medium"> Corporate Documents
                                    </h2>
                                </div>
                            </div>
                            <div>
                                <h2 className="text-xl relative top-0.5"><span><FontAwesomeIcon onClick={() => props.onClose()} className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-200 ease-in" icon={faTimes}></FontAwesomeIcon></span></h2>
                            </div>
                        </div>
                        <hr className="my-3" />
                    </header>

                    <main
                        className="flex-1 overflow-y-auto p-5 py-0 flow-body"
                        id="individual-main">
                        {ready ?
                            <>
                                <div>
                                    {error ? (
                                        <Alert type="error" title={error}></Alert>
                                    ) : null}
                                </div>
                                <div className="flex flex-wrap">
                                    <div className="w-full">
                                        <div>
                                            <div className="flex justify-between">
                                                <div>
                                                    <p className="text-sm font-bold text-primary-700">Documents</p>
                                                    <p className="text-sm text-gray-600">Switch the document you want to accept in your app</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="flex flex-wrap mt-3">
                                                    <div className="w-full">
                                                        <div className="flex flex-wrap justify-between border border-gray-200 p-2 rounded-xl">
                                                            <div className={'flex flex-1'}>
                                                                <div>
                                                                    <img src="/img/icons/ui/icon-corporate-certificate.svg"></img>
                                                                </div>
                                                                <div className="ml-3">
                                                                    <p className="font-medium mt-2 text-primary-700">Certificate of Incorporation</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-full mt-4">
                                                        <div className="flex flex-wrap justify-between border border-gray-200 p-2 rounded-xl">
                                                            <div className={'flex flex-1'}>
                                                                <div>
                                                                    <img src="/img/icons/ui/icon-corporate-association.svg"></img>
                                                                </div>
                                                                <div className="ml-3">
                                                                    <p className="font-medium mt-2 text-primary-700">Memorandum of Association</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-full mt-4">
                                                        <div className="flex flex-wrap justify-between border border-gray-200 p-2 rounded-xl">
                                                            <div className={'flex flex-1'}>
                                                                <div>
                                                                    <img src="/img/icons/ui/icon-corporate-register.svg"></img>
                                                                </div>
                                                                <div className="ml-3">
                                                                    <p className="font-medium mt-2 text-primary-700">Register of directors & shareholders</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-full mt-4">
                                                        <div className="flex flex-wrap justify-between border border-gray-200 p-2 rounded-xl">
                                                            <div className={'flex flex-1'}>
                                                                <div>
                                                                    <img src="/img/icons/ui/icon-corporate-bank-statement.svg"></img>
                                                                </div>
                                                                <div className="ml-3">
                                                                    <p className="font-medium mt-2 text-primary-700">Company Bank Statement</p>
                                                                </div>
                                                            </div>
                                                            <div className="mt-2 mr-2">
                                                                <SwitchGreen checked={bankStatement}
                                                                    onChange={setBankStatement} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </> : null}
                    </main>
                    <footer className="p-5">
                        <div className={'flex flex-wrap'}>
                            <div className="w-1/3 pr-1.5">
                                <Button
                                    disabled={loading}
                                    full
                                    theme="secondary"
                                    label={'Back'}
                                    onClick={() => props.onBack()}></Button>
                            </div>
                            <div className="w-2/3 pl-1.5">
                                <Button
                                    full
                                    loading={loading}
                                    theme="success"
                                    label={'Update'}
                                    onClick={() => Submit()}></Button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    )
}
