import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from '@/components/ui/Alert'
import AppsService from '@/services/apps'
import { useParams } from 'react-router-dom'
import SwitchGreen from '@/components/ui/SwitchGreen'
import Button from '@/components/ui/Button'


export default function AmlFlowCriterias(props) {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)
    const [ready, setReady] = useState(false)
    const [pep1, setPep1] = useState(false)
    const [pep2, setPep2] = useState(false)
    const [pep3, setPep3] = useState(false)
    const [pep4, setPep4] = useState(false)
    const [adverseMedia, setAdverseMedia] = useState(false)
    const [sanction, setSanction] = useState(false)
    const [warning, setWarning] = useState(false)
    const [fitnessProbity, setFitnessProbity] = useState(false)

    const { app_id } = useParams()

    useEffect(() => {
        GetInfo()
        return () => { }
    }, [])

    function GetInfo() {
        AppsService.GetAmlInfo(app_id).then((data) => {
            setData(data)
            setCriterias(data)
            setReady(true)
        }).catch((err) => {
            setError(err.message)
        })
    }

    function Submit() {
        let criterias = []

        if (pep1) {
            criterias.push('PEP_1')
        }

        if (pep2) {
            criterias.push('PEP_2')
        }

        if (pep3) {
            criterias.push('PEP_3')
        }

        if (pep4) {
            criterias.push('PEP_4')
        }

        if (sanction) {
            criterias.push('SANCTION')
        }

        if (adverseMedia) {
            criterias.push('ADVERSE_MEDIA')
        }

        if (warning) {
            criterias.push('WARNING')
        }

        if (fitnessProbity) {
            criterias.push('FITNESS_PROBITY')
        }

        const body = {
            activated: data.aml.activated,
            monitoring: data.aml.monitoring,
            manager_check: data.aml.manager_check,
            criterias_list: criterias,
        }

        AppsService.SubmitAML(app_id, body).then(async () => {
            setLoading(false)
            props.onBack()
        }).catch((err) => {
            setError(err.message)
            setLoading(false)
        })
    }

    function setCriterias(data) {
        if (data.aml.criterias_list.includes('PEP_1')) {
            setPep1(true)
        }

        if (data.aml.criterias_list.includes('PEP_2')) {
            setPep2(true)
        }

        if (data.aml.criterias_list.includes('PEP_3')) {
            setPep3(true)
        }

        if (data.aml.criterias_list.includes('PEP_4')) {
            setPep4(true)
        }

        if (data.aml.criterias_list.includes('SANCTION')) {
            setSanction(true)
        }

        if (data.aml.criterias_list.includes('ADVERSE_MEDIA')) {
            setAdverseMedia(true)
        }

        if (data.aml.criterias_list.includes('WARNING')) {
            setWarning(true)
        }

        if (data.aml.criterias_list.includes('FITNESS_PROBITY')) {
            setFitnessProbity(true)
        }
    }

    return (
        <div className="modal-view">
            <div className="z-max w-full h-full flex items-center justify-center">
                <div className="flex flex-col w-full h-full rounded">
                    <header className="p-5 pb-3">
                        <div className="flex justify-between">
                            <div className="flex">
                                <div>
                                    <img className="h-6 relative top-0.5 mr-2" src="/img/icons/ui/icon-aml-only.svg" />
                                </div>
                                <div>
                                    <h2 className="text-xl text-primary-500  font-medium"> AML/CFT Screening
                                    </h2>
                                </div>
                            </div>
                            <div>
                                <h2 className="text-xl relative top-0.5"><span><FontAwesomeIcon onClick={() => props.onClose()} className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-200 ease-in" icon={faTimes}></FontAwesomeIcon></span></h2>
                            </div>
                        </div>
                        <hr className="my-3" />
                    </header>

                    <main
                        className="flex-1 overflow-y-auto p-5 py-0 flow-body"
                        id="individual-main">
                        {ready ?
                            <>
                                <div className="flex flex-wrap">
                                    <div className="w-full">
                                        {error ? (
                                            <Alert type="error" title={error}></Alert>
                                        ) : null}
                                        <div>
                                            <div className="flex justify-between">
                                                <div>
                                                    <p className="text-sm font-bold text-primary-700">Screening Criterias</p>
                                                    <p className="text-sm text-gray-600">Select the AML criterias you want to check</p>
                                                </div>
                                            </div>
                                            <div className="w-full mt-2">
                                                <div className="flex items-center border border-gray-200 p-2 rounded-xl">
                                                    <div className="w-full my-1 mx-2">
                                                        <div className="flex justify-between">
                                                            <div>
                                                                <p className="font-medium text-primary-700">PEP Tier 1</p>
                                                            </div>
                                                            <div>
                                                                {/* <SwitchGreen checked={pep1}
                                                                    onChange={setPep1} /> */}
                                                            </div>
                                                        </div>
                                                        <p className="text-sm text-gray-600">
                                                            <li>Heads of state and government</li>
                                                            <li>Members of government</li>
                                                            <li>Members of Parliament or similar legislative bodies</li>
                                                            <li>See full reference</li>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-full mt-4">
                                                <div className="flex items-center border border-gray-200 p-2 rounded-xl">
                                                    <div className="w-full my-1 mx-2">
                                                        <div className="flex justify-between">
                                                            <div>
                                                                <p className="font-medium text-primary-700">PEP Tier 2</p>
                                                            </div>
                                                            <div>
                                                                {/* <SwitchGreen checked={pep2}
                                                                    onChange={setPep2} /> */}
                                                            </div>
                                                        </div>
                                                        <p className="text-sm text-gray-600">
                                                            <li>Members of legislative (e.g. aldermen,
                                                                councillors) and executive (e.g. prefects)
                                                                bodies at regional, provincial, cantonal or
                                                                equivalent levels</li>
                                                            <li>Members of Parliament or similar legislative bodies</li>
                                                            <li>See full reference</li>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-full mt-4">
                                                <div className="flex items-center border border-gray-200 p-2 rounded-xl">
                                                    <div className="w-full my-1 mx-2">
                                                        <div className="flex justify-between">
                                                            <div>
                                                                <p className="font-medium text-primary-700">PEP Tier 3</p>
                                                            </div>
                                                            <div>
                                                                {/* <SwitchGreen checked={pep3}
                                                                    onChange={setPep3} /> */}
                                                            </div>
                                                        </div>
                                                        <p className="text-sm text-gray-600">
                                                            <li>Presidents/Chairperson and board members of
                                                                state owned businesses and organisations</li>
                                                            <li>Heads and board members / senior officials of
                                                                Trade Unions. In case of Chambers of Commerce
                                                                and Charities a risk based approach is followed.</li>
                                                            <li>See full reference</li>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-full mt-4">
                                                <div className="flex items-center border border-gray-200 p-2 rounded-xl">
                                                    <div className="w-full my-1 mx-2">
                                                        <div className="flex justify-between">
                                                            <div>
                                                                <p className="font-medium text-primary-700">PEP Tier 4</p>
                                                            </div>
                                                            <div>
                                                                {/* <SwitchGreen checked={pep4}
                                                                    onChange={setPep4} /> */}
                                                            </div>
                                                        </div>
                                                        <p className="text-sm text-gray-600">
                                                            <li>Mayors and members of local councils at
                                                                municipal, town, village or equivalent levels</li>
                                                            <li>Senior officials of administrative bodies at
                                                                local levels</li>
                                                            <li>See full reference</li>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-full mt-4">
                                                <div className="flex items-center border border-gray-200 p-2 rounded-xl">
                                                    <div className="w-full my-1 mx-2">
                                                        <div className="flex justify-between">
                                                            <div>
                                                                <p className="font-medium text-primary-700">Global Watchlists</p>
                                                            </div>
                                                            <div>
                                                                {/* <SwitchGreen checked={sanction}
                                                                    onChange={setSanction} /> */}
                                                            </div>
                                                        </div>
                                                        <p className="text-sm text-gray-600">
                                                            <li>Search sanctions accross more than 230+ sanctions lists</li>
                                                            <li>See full reference</li>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-full mt-4">
                                                <div className="flex items-center border border-gray-200 p-2 rounded-xl">
                                                    <div className="w-full my-1 mx-2">
                                                        <div className="flex justify-between">
                                                            <div>
                                                                <p className="font-medium text-primary-700">Adverse Media</p>
                                                            </div>
                                                            <div>
                                                                {/* <SwitchGreen checked={adverseMedia}
                                                                    onChange={setAdverseMedia} /> */}
                                                            </div>
                                                        </div>
                                                        <p className="text-sm text-gray-600">
                                                            <li>Adverse media or negative news is defined as any kind of unfavorable information found across a wide variety of news sources</li>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-full mt-4">
                                                <div className="flex items-center border border-gray-200 p-2 rounded-xl">
                                                    <div className="w-full my-1 mx-2">
                                                        <div className="flex justify-between">
                                                            <div>
                                                                <p className="font-medium text-primary-700">Warning</p>
                                                            </div>
                                                            <div>
                                                                {/* <SwitchGreen checked={warning}
                                                                    onChange={setWarning} /> */}
                                                            </div>
                                                        </div>
                                                        <p className="text-sm text-gray-600">
                                                            <li>Adverse media or negative news is defined as any kind of unfavorable information found across a wide variety of news sources</li>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-full mt-4">
                                                <div className="flex items-center border border-gray-200 p-2 rounded-xl">
                                                    <div className="w-full my-1 mx-2">
                                                        <div className="flex justify-between">
                                                            <div>
                                                                <p className="font-medium text-primary-700">Probity Fitness</p>
                                                            </div>
                                                            <div>
                                                                {/* <SwitchGreen checked={fitnessProbity}
                                                                    onChange={setFitnessProbity} /> */}
                                                            </div>
                                                        </div>
                                                        <p className="text-sm text-gray-600">
                                                            <li>Adverse media or negative news is defined as any kind of unfavorable information found across a wide variety of news sources</li>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </> : null}
                    </main>
                    <footer className="p-5 pt-2">
                        <div className={'flex flex-wrap'}>
                            <div className="w-1/3 pr-1.5">
                                <Button
                                    disabled={loading}
                                    full
                                    theme="secondary"
                                    label={'Back'}
                                    onClick={() => props.onBack()}></Button>
                            </div>
                            <div className="w-2/3 pl-1.5">
                                {/* <Button
                                    full
                                    loading={loading}
                                    theme="success"
                                    label={'Update'}
                                    onClick={() => Submit()}></Button> */}
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    )
}
