export default function SessionItemPlaceholder() {
	return (
		<>
			<div className="px-3 mt-5">
				<div className="w-full mb-3 p-3 mt-3 rounded-xl border bg-white transition-25 transition duration-200 ease-in">
					<a
						className="flex-shrink-0 group block animate-pulse">
						<div className="w-full">
							<div className="ml-1">
								<div className="sm:flex justify-between">
									<div className="flex">
										<div className="p-2"><img src="/img/icons/ui/icon-round.svg" /></div>
										<div className="ml-3 relative">
											<div className="mt-4 h-2 w-24 bg-gray-400 rounded-md"></div>
											<div className="mt-2 h-2 w-48 bg-gray-300 rounded-md"></div>
										</div>
									</div>
									<div className="flex">
										<div className="mr-2 text-right">
											<div className="ml-auto mt-4 h-2 w-12 bg-gray-400 rounded-md"></div>
											<div className="mt-2 h-2 w-24 bg-gray-300 rounded-md"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</a>
				</div>
				<div className="w-full mb-3 p-3 mt-3 rounded-xl border bg-white transition-25 transition duration-200 ease-in">
					<a
						className="flex-shrink-0 group block animate-pulse">
						<div className="w-full">
							<div className="ml-1">
								<div className="sm:flex justify-between">
									<div className="flex">
										<div className="p-2"><img src="/img/icons/ui/icon-round.svg" /></div>
										<div className="ml-3 relative">
											<div className="mt-4 h-2 w-24 bg-gray-400 rounded-md"></div>
											<div className="mt-2 h-2 w-48 bg-gray-300 rounded-md"></div>
										</div>
									</div>
									<div className="flex">
										<div className="mr-2 text-right">
											<div className="ml-auto mt-4 h-2 w-12 bg-gray-400 rounded-md"></div>
											<div className="mt-2 h-2 w-24 bg-gray-300 rounded-md"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</a>
				</div>
				<div className="w-full mb-3 p-3 mt-3 rounded-xl border bg-white transition-25 transition duration-200 ease-in">
					<a
						className="flex-shrink-0 group block animate-pulse">
						<div className="w-full">
							<div className="ml-1">
								<div className="sm:flex justify-between">
									<div className="flex">
										<div className="p-2"><img src="/img/icons/ui/icon-round.svg" /></div>
										<div className="ml-3 relative">
											<div className="mt-4 h-2 w-24 bg-gray-400 rounded-md"></div>
											<div className="mt-2 h-2 w-48 bg-gray-300 rounded-md"></div>
										</div>
									</div>
									<div className="flex">
										<div className="mr-2 text-right">
											<div className="ml-auto mt-4 h-2 w-12 bg-gray-400 rounded-md"></div>
											<div className="mt-2 h-2 w-24 bg-gray-300 rounded-md"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</a>
				</div>
				<div className="w-full mb-3 p-3 mt-3 rounded-xl border bg-white transition-25 transition duration-200 ease-in">
					<a
						className="flex-shrink-0 group block animate-pulse">
						<div className="w-full">
							<div className="ml-1">
								<div className="sm:flex justify-between">
									<div className="flex">
										<div className="p-2"><img src="/img/icons/ui/icon-round.svg" /></div>
										<div className="ml-3 relative">
											<div className="mt-4 h-2 w-24 bg-gray-400 rounded-md"></div>
											<div className="mt-2 h-2 w-48 bg-gray-300 rounded-md"></div>
										</div>
									</div>
									<div className="flex">
										<div className="mr-2 text-right">
											<div className="ml-auto mt-4 h-2 w-12 bg-gray-400 rounded-md"></div>
											<div className="mt-2 h-2 w-24 bg-gray-300 rounded-md"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</a>
				</div>
				<div className="w-full mb-3 p-3 mt-3 rounded-xl border bg-white transition-25 transition duration-200 ease-in">
					<a
						className="flex-shrink-0 group block animate-pulse">
						<div className="w-full">
							<div className="ml-1">
								<div className="sm:flex justify-between">
									<div className="flex">
										<div className="p-2"><img src="/img/icons/ui/icon-round.svg" /></div>
										<div className="ml-3 relative">
											<div className="mt-4 h-2 w-24 bg-gray-400 rounded-md"></div>
											<div className="mt-2 h-2 w-48 bg-gray-300 rounded-md"></div>
										</div>
									</div>
									<div className="flex">
										<div className="mr-2 text-right">
											<div className="ml-auto mt-4 h-2 w-12 bg-gray-400 rounded-md"></div>
											<div className="mt-2 h-2 w-24 bg-gray-300 rounded-md"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</a>
				</div>
				<div className="w-full mb-3 p-3 mt-3 rounded-xl border bg-white transition-25 transition duration-200 ease-in">
					<a
						className="flex-shrink-0 group block animate-pulse">
						<div className="w-full">
							<div className="ml-1">
								<div className="sm:flex justify-between">
									<div className="flex">
										<div className="p-2"><img src="/img/icons/ui/icon-round.svg" /></div>
										<div className="ml-3 relative">
											<div className="mt-4 h-2 w-24 bg-gray-400 rounded-md"></div>
											<div className="mt-2 h-2 w-48 bg-gray-300 rounded-md"></div>
										</div>
									</div>
									<div className="flex">
										<div className="mr-2 text-right">
											<div className="ml-auto mt-4 h-2 w-12 bg-gray-400 rounded-md"></div>
											<div className="mt-2 h-2 w-24 bg-gray-300 rounded-md"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</a>
				</div>
				<div className="w-full mb-3 p-3 mt-3 rounded-xl border bg-white transition-25 transition duration-200 ease-in">
					<a
						className="flex-shrink-0 group block animate-pulse">
						<div className="w-full">
							<div className="ml-1">
								<div className="sm:flex justify-between">
									<div className="flex">
										<div className="p-2"><img src="/img/icons/ui/icon-round.svg" /></div>
										<div className="ml-3 relative">
											<div className="mt-4 h-2 w-24 bg-gray-400 rounded-md"></div>
											<div className="mt-2 h-2 w-48 bg-gray-300 rounded-md"></div>
										</div>
									</div>
									<div className="flex">
										<div className="mr-2 text-right">
											<div className="ml-auto mt-4 h-2 w-12 bg-gray-400 rounded-md"></div>
											<div className="mt-2 h-2 w-24 bg-gray-300 rounded-md"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</a>
				</div>

			</div>
		</>
	)
}
