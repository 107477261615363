import { useEffect, useState } from 'react'
import AppsService from '@/services/apps'
import { useParams } from 'react-router-dom'
import TierItem from '@/components/flow/TierItem'
import FlowItemPlaceholder from '@/components/placeholder/FlowItemPlaceholder'
import Sleep from '@/lib/sleep'
import * as Notification from '@/components/ui/Notification'

export default function TierSteps(props) {
	const [ready, setReady] = useState(false)
	const [data, setData] = useState(null)

	let { app_id } = useParams()

	useEffect(async () => {
		GetTiers()
		return () => { }
	}, [])

	useEffect(() => {
		GetTiers()
		return () => { }
	}, [props.refresh])

	async function GetTiers() {
		setReady(false)
		await AppsService.GetTiers(app_id)
			.then(async (data) => {
				setData(data)
				await Sleep(1000)
				setReady(true)
			})
		return () => { }
	}

	function Delete(tier) {
		AppsService.DeleteFlowTiers(app_id, tier).then(() => {
			GetTiers()
		}).catch((err) => {
			Notification.error(err.message)
		})
	}

	return (
		<>
			{ready ? <>
				{Object.keys(data.tiers).map(function (key) {
					return (
						<>
							<TierItem Delete={(tier) => Delete(tier)} items={data.tiers[key]} key={key} tier={key} />
						</>
					)
				})}
				<></>


				<div className={'step-view w-full sm:w-1/2 lg:w-1/3 my-4'}>
					<div className={'z-max w-full h-full flex items-center justify-center px-3'}>
						<div onClick={() => props.onAdd()} className={'rounded-xl cursor-pointer step-view hover:shadow-lg bg-white border border-gray-200 hover:border-gray-300 transition-25 items-center transition-25 transition duration-200 ease-in flex flex-col w-full h-full'}>
							<div className="h-full">
								<img className="mt-32 h-28 w-28 mx-auto border border-transparent rounded-2xl" src={'/img/icons/ui/icon-plus.svg'} />
								<div className="my-5 mb-12 text-center">
									<p className='text-regular font-regular font-bold'>
										<span>Add</span>
									</p>
									<p className='text-regular text-sm font-regular'>
										{data.nb_tier === 0 ? <span className="text-gray-500">your first tier</span> : null}
										{data.nb_tier > 0 ? <span className="text-gray-500">new tier</span> : null}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>


				{data.nb_tier < 6 ? <>
					{Array(6 - (data.nb_tier + 1)).fill().map((i) => {
						return (<div key={i} className={'step-view w-full sm:w-1/2 lg:w-1/3 my-4'}>
							<div className={'z-max w-full h-full flex items-center justify-center px-3'}>
								<div className={'rounded-xl bg-white border border-gray-200 transition-25 step-view transition duration-200 ease-in flex flex-col w-full h-full'}>
									<div>
										<div className="p-4 flex items-center">
											<div className="w-8 h-8 bg-primary-200 rounded-md"></div>
											<div className="ml-3 w-64 h-4 bg-gray-200 rounded-md"></div>
										</div>
										<div className="p-4 flex items-center">
											<div className="w-24 h-2 bg-gray-200 rounded-md"></div>
										</div>
										<div className="p-4 pt-0 flex items-center">
											<div className="mr-3 w-8 h-8 bg-bluegray-200 rounded-md"></div>
											<div className="mr-3 w-8 h-8 bg-bluegray-200 rounded-md"></div>
											<div className="mr-3 w-8 h-8 bg-bluegray-200 rounded-md"></div>
											<div className="mr-3 w-8 h-8 bg-bluegray-200 rounded-md"></div>
										</div>
										<div className="p-4 flex items-center">
											<div className="w-32 h-2 bg-gray-200 rounded-md"></div>
										</div>
										<div className="p-4 pt-0 flex items-center">
											<div className="mr-3 w-8 h-8 bg-bluegray-200 rounded-md"></div>
											<div className="mr-3 w-8 h-8 bg-bluegray-200 rounded-md"></div>
											<div className="mr-3 w-8 h-8 bg-bluegray-200 rounded-md"></div>
											<div className="mr-3 w-8 h-8 bg-bluegray-200 rounded-md"></div>
										</div>
									</div>
								</div>
							</div>
						</div>)
					})}
				</> : null}
			</> : <>
				<FlowItemPlaceholder />
				<FlowItemPlaceholder />
				<FlowItemPlaceholder />
			</>}
		</>
	)
}
