export default function AppItemPlaceholder() {
	return (
		<>
			<div className="w-1/2 sm:w-1/3 lg:w-1/4">
				<div className='m-4 text-center border border-bluegray-150 items-center bg-white rounded-xl transition-25'>
					<div className="m-3 my-6 mt-12">
						<div className="h-28 w-28 animate-pulse mx-auto border bg-gray-100 border-bluegray-100 rounded-2xl"></div>
						<div className="my-6 mb-12">
							<div className='text-regular animate-pulse font-regular font-bold'>
								<div className="bg-gray-300 h-3 mx-auto w-16 rounded"></div>
							</div>
							<div className='mt-3 pb-1 animate-pulse text-regular text-sm font-regular'>
								<div className="bg-gray-200  h-3 mx-auto w-12 rounded"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
