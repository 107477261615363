import { faLink, faAt } from '@fortawesome/pro-solid-svg-icons'
import { useContext } from 'react'
import { AppContext } from '@/context/app'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from '@/components/ui/Tooltip'
import { CorporateSessionContext } from '@/context/corporate_session'

export default function CorporateVerificationHeader() {
    const [app] = useContext(AppContext)
    const [corporate] = useContext(CorporateSessionContext)
    const { session_id } = useParams()


    return (
        <>
            {corporate.ready ? <div className="flex flex-wrap justify-between pb-3">
                <div className="flex">
                    <div className="ml-3 sm:ml-0">
                        <img
                            className="h-10 rounded-lg"
                            src={app.logo_url}></img>
                    </div>
                    <div className="ml-3 relative">
                        <p className="text-white text-sm font-bold">
                            {app.name}
                        </p>
                        <h1 className="text-sm text-white">
                            <Tooltip auto={true} content={'Session ID'}>
                                <span>
                                    <FontAwesomeIcon className="text-bluegray-500" icon={faLink} /> {session_id}
                                </span>
                            </Tooltip>
                            {corporate.alias ? <Tooltip auto={true} content={'Alias'}>
                                <span>
                                    <FontAwesomeIcon className="ml-2 text-bluegray-500" icon={faAt} /> {corporate.alias}
                                </span>
                            </Tooltip> : null}
                        </h1>
                    </div>
                </div>
                <div className="flex flex-wrap mt-4 sm:mt-0">
                    {/* <MiniButton className="mr-1.5" onClick={() => { }} currentBg="primary" theme="secondary" icon={faStream}
                        label="Activity" badge={3}></MiniButton> */}
                    {/* <MiniButton onClick={() => { }} currentBg="primary" theme="pin" icon={faBug}
                        label="Report mistake"></MiniButton> */}
                </div>
            </div> : null}
        </>
    )
}
