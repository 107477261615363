import Sleep from "@/lib/sleep";
import {
  faHashtag,
  faLink,
  faLayerGroup,
} from "@fortawesome/pro-duotone-svg-icons";
import { Transition } from "@headlessui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../ui/Button";
import Input from "../ui/Input";
import Alert from "../ui/Alert";
import CardTitle from "../ui/CardTitle";
import MiniButton from "@/components/ui/MiniButton";
import { useParams } from "react-router-dom";

import SessionsService from "@/services/sessions";

export default function NewSession(props) {
  const [error, setError] = useState(null);
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [sandbox, setSandbox] = useState(true);
  const [link, setLink] = useState("");
  const { app_id } = useParams();

  const formSchema = {
    alias: {},
  };

  const onSubmit = async (data) => {
    setError(null);

    setLoading(true);

    await Sleep(2000);

    let body = {
      alias: data.alias,
      sandbox: sandbox,
    };

    await SessionsService.InitNewSession(app_id, body)
      .then((res) => {
        setLink(res.session_link);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
        setLoading(false);
        setError(err.message);
      });
    return () => {};
  };

  return (
    <Transition
      show={props.open}
      enter="ease-out duration-200"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 transition-opacity"
            aria-hidden="true"
            onClick={() => props.onClose()}
          >
            <div className="absolute inset-0 bg-gray-800 opacity-75"></div>
          </div>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div
            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="p-4">
              <div>
                <div>
                  <div>
                    <CardTitle
                      value="New Session"
                      icon={faLayerGroup}
                    ></CardTitle>
                  </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <div className="py-2 px-3">
                      {error ? (
                        <Alert type="error" title={error}></Alert>
                      ) : null}
                    </div>
                    <div className="flex flex-wrap justify-center w-full">
                      <div className="mr-3">
                        <MiniButton
                          onClick={() => setSandbox(true)}
                          currentBg="primary"
                          theme="pin"
                          label="Sandbox session"
                        ></MiniButton>
                        {sandbox === true ? (
                          <div className="mx-auto mt-2 w-5 h-1 rounded-xl bg-orange-500"></div>
                        ) : (
                          <div className="mx-auto mt-2 w-5 h-1 rounded-xl bg-white-900"></div>
                        )}
                      </div>
                      <div className="mr-3">
                        <MiniButton
                          onClick={() => setSandbox(false)}
                          currentBg="primary"
                          theme="success"
                          label="Production session"
                        ></MiniButton>
                        {sandbox === false ? (
                          <div className="mx-auto mt-2 w-5 h-1 rounded-xl bg-green-500"></div>
                        ) : (
                          <div className="mx-auto mt-2 w-5 h-1 rounded-xl bg-white-900"></div>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-wrap">
                      <div className="p-3 w-full">
                        <Input
                          type="text"
                          icon={faHashtag}
                          referrer={register(formSchema.alias)}
                          label="Alias (optional)"
                          placeholder="032032130"
                          name="alias"
                        />
                      </div>
                    </div>
                  </div>

                  {link !== "" ? (
                    <div className="flex flex-wrap">
                      <div className="p-3 w-full">
                        <Input
                          type="text"
                          icon={faLink}
                          value={link.replace(
                            "verify.synaps.io",
                            "verify-v3.synaps.io"
                          )}
                          label="Session link"
                          name="link"
                        />
                      </div>
                    </div>
                  ) : null}

                  <div>
                    <div className="flex justify-between">
                      <div className="p-3">
                        {link !== "" ? (
                          <div className="mr-3">
                            <Button
                              theme="primary"
                              onClick={() =>
                                navigator.clipboard.writeText(
                                  link.replace(
                                    "verify.synaps.io",
                                    "verify-v3.synaps.io"
                                  )
                                )
                              }
                              label="Copy"
                            />
                          </div>
                        ) : null}
                      </div>
                      <div className="p-3">
                        <div className="flex">
                          <div className="mr-3">
                            <Button
                              theme="secondary"
                              onClick={() => props.onClose()}
                              label="Cancel"
                            />
                          </div>
                          <div>
                            <Button
                              type="submit"
                              theme="success"
                              loading={loading}
                              onClick={() => handleSubmit(onSubmit)}
                              label="Init session"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}
