import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from '@/components/ui/Alert'
import AppsService from '@/services/apps'
import { useParams } from 'react-router-dom'
import SwitchGreen from '@/components/ui/SwitchGreen'
import Button from '@/components/ui/Button'
import NotificationService from '@/services/notification'


export default function NotificationMethodFlow(props) {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [ready, setReady] = useState(false)
    const [updated, setUpdated] = useState(true)
    const [method, setMethod] = useState(
        {
            'Email': {
                'active': false,
                'icon': '/img/icons/ui/icon-notification-mail.svg'
            },
        }
    )
    const [data, setData] = useState(null)
    const { app_id } = useParams()

    useEffect(() => {
        GetInfo()
        return () => { }
    }, [])

    function GetInfo() {
        NotificationService.GetNotificationStatus(app_id).then((data) => {
            setData(data)
            for (var key in method) {
                if (data.method.includes(key)) {
                    method[key].active = true
                    setMethod(method)
                }
            }
            setReady(true)
        }).catch((err) => {
            setError(err.message)
        })
    }

    async function UpdateMethod(targetMethod, active) {
        setUpdated(false)
        for (var key in method) {
            if (key === targetMethod) {
                method[key].active = active
                await setMethod(method)
                setUpdated(true)
            }
        }
    }

    function Submit() {
        let newMethod = []
        for (var key in method) {
            if (method[key].active === true) {
                newMethod.push(key)
            }
        }

        const body = {
            activated: false,
            method: newMethod
        }

        if (newMethod.length > 0) {
            body.activated = true
        }

        NotificationService.SetNotificationSetting(app_id, body).then(async () => {
            props.onClose()
        }).catch((err) => {
            setError(err.message)
        })
    }



    return (
        <div className="modal-view">
            <div className="z-max w-full h-full flex items-center justify-center">
                <div className="flex flex-col w-full h-full rounded">
                    <header className="p-5 pb-3">
                        <div className="flex justify-between">
                            <div className="flex items-center">
                                <div>
                                    <img className="h-9 relative mr-2" src="/img/icons/ui/icon-notification-bell.svg" />
                                </div>
                                <div>
                                    <h2 className="text-lg text-primary-500  font-medium"> Notifications
                                    </h2>
                                </div>
                            </div>
                            <div>
                                <h2 className="text-xl relative top-0.5"><span><FontAwesomeIcon onClick={() => props.onClose()} className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-200 ease-in" icon={faTimes}></FontAwesomeIcon></span></h2>
                            </div>
                        </div>
                        <hr className="my-3" />
                    </header>

                    <main
                        className="flex-1 overflow-y-auto p-5 py-0 flow-body"
                        id="individual-main">
                        {ready ?
                            <>
                                <div className="flex flex-wrap">
                                    <div className="w-full">
                                        {error ? (
                                            <Alert type="error" title={error}></Alert>
                                        ) : null}
                                        <div>
                                            <div className="flex justify-between">
                                                <div>
                                                    <p className="text-sm font-bold text-primary-700">Integration</p>
                                                    <p className="text-sm text-gray-600">Choose a method to notify users of their verification status</p>
                                                </div>
                                            </div>
                                            {updated ?
                                                <>
                                                    {Object.entries(method).map(([index]) => {
                                                        return <>
                                                            <div key={index} className="w-full mt-3">
                                                                <div className="flex items-center border border-gray-200 p-2 rounded-xl">
                                                                    <div className="w-full my-1 mx-2">
                                                                        <div className="flex justify-between">
                                                                            <div className='flex'>
                                                                                <div>
                                                                                    <img className="h-9 relative mr-2" src={method[index].icon} />
                                                                                </div>
                                                                                <div className='mt-2'>
                                                                                    <p className="font-medium text-primary-700">{index}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className='mt-2'>
                                                                                <SwitchGreen checked={method[index].active}
                                                                                    onChange={() => UpdateMethod(index, !method[index].active)} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    })} </> : null}
                                        </div>
                                    </div>
                                </div>
                            </> : null}
                    </main>
                    <footer className="p-5 pt-2">
                        <div className={'flex flex-wrap'}>
                            <div className="w-1/3 pr-1.5">
                                <Button
                                    disabled={loading}
                                    full
                                    theme="secondary"
                                    label={'Back'}
                                    onClick={() => props.onBack()}></Button>
                            </div>
                            <div className="w-2/3 pl-1.5">
                                <Button
                                    full
                                    loading={loading}
                                    theme="success"
                                    label={'Update'}
                                    onClick={() => Submit()}></Button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    )
}
