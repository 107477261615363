import { useContext, useEffect, useState } from 'react'
import SessionsService from '@/services/sessions'
import Sleep from '@/lib/sleep'
import * as Notification from '@/components/ui/Notification'
import { AppContext } from '@/context/app'
import SessionsTaskItem from './SessionsTaskItem'
import { useParams } from 'react-router-dom'
import {
	faAngleLeft,
	faAngleRight,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Pagination from 'react-js-pagination'
import SessionItemPlaceholder from '../placeholder/SessionItemPlaceholder'

export default function SessionsTasks(props) {
	const [ready, setReady] = useState(false)
	const [, appDispatch] = useContext(AppContext)
	const [sessions, setSessions] = useState(null)
	const { app_id } = useParams()
	const [currentPage, setCurrentPage] = useState(1)

	async function getSessions() {
		await SessionsService.RetrieveSessions(app_id, currentPage - 1, props.status)
			.then(async (data) => {
				appDispatch({
					type: 'SET_SESSIONS',
					payload: {
						sessions: data,
					},
				})

				setSessions(data)
				await Sleep(500)
				setReady(true)
			})
			.catch((err) => {
				Notification.error(err.message)
			})
	}

	useEffect(async () => {
		setReady(false)
		getSessions()
		return () => { }
	}, [currentPage, props.status])

	return (
		<>
			{ready ? (
				<div>
					<div className="w-full px-3 mt-5">
						{sessions.sessions.map((session) => {
							return (
								<>
									<SessionsTaskItem
										key={session.session_id}
										item={session}></SessionsTaskItem>
								</>
							)
						})}
						{sessions.sessions.length === 0 ? (
							<div className="w-full mb-3 p-3 px-2 rounded-xl border bg-white transition-25 transition duration-200 ease-in">
								<div className="p-6 mt-4 mx-auto">
									<img className="mx-auto h-36" src="/img/illustrations/empty.svg"></img>
									<p className="p-6 text-center text-gray-700 mt-3 mx-auto">No sessions</p>
								</div>
							</div>
						) : null}
					</div>
					{sessions.sessions.length > 0 ?
						<div className="w-full px-3 mt-5">
							<div className="w-full mb-3 p-3 px-2 rounded-xl border bg-white transition-25 transition duration-200 ease-in">
								<div className="flex justify-between items-center px-2">
									<div>
										<p className="font-light">
											Showing{' '}
											<span className="font-medium">
												{(currentPage - 1) * 20 + 1}
											</span>{' '}
											to{' '}
											<span className="font-medium">
												{currentPage * 20 < sessions.limit
													? currentPage * 20
													: sessions.limit}
											</span>{' '}
											of <span className="font-medium">{sessions.total} results</span>
										</p>

									</div>
									<div>
										<Pagination
											hideFirstLastPages={true}
											prevPageText={
												<FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon>
											}
											nextPageText={
												<FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>
											}
											innerClass="relative z-0 inline-flex rounded-md shadow-sm -space-x-px cursor-pointer"
											activePage={currentPage}
											itemsCountPerPage={20}
											totalItemsCount={sessions.total}
											pageRangeDisplayed={5}
											activeClass={'font-bold'}
											itemClassPrev="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-200 bg-white text-sm font-regular text-gray-500 hover:bg-gray-50"
											itemClassNext="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-200 bg-white text-sm font-regular text-gray-500 hover:bg-gray-50"
											itemClass="relative inline-flex items-center px-4 py-2 border border-gray-200 bg-white text-sm font-light text-gray-700 hover:bg-gray-50"
											onChange={(e) => setCurrentPage(e)}
										/>

									</div>
								</div>
							</div>
						</div> : null}
				</div>
			) : (
				<SessionItemPlaceholder></SessionItemPlaceholder>
			)}
		</>
	)
}
