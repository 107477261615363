import { LoginContext } from '@/context/login'
import { useContext, useEffect } from 'react'
import { AuthContext } from '@/context/auth'
import Loader from '../ui/Loader'
import Sleep from '@/lib/sleep'
import Cookie from "js-cookie";
import { useHistory } from 'react-router-dom'

export default function AuthProcess() {
	const [loginState] = useContext(LoginContext)
	const [, authDispatch] = useContext(AuthContext)
	const router = useHistory()

	useEffect(async () => {
		const query = new URLSearchParams(router.location.search)


		let s_auth = {
			user: {
				email: loginState.auth.email,
				firstname: loginState.auth.firstname,
				lastname: loginState.auth.lastname,
				profile_pic: loginState.auth.profile_pic,
			},
			iam: loginState.auth.iam,
			authenticated: true,
			auth: {
				access_token: loginState.auth.auth.access_token,
				refresh_token: loginState.auth.auth.refresh_token,
			},
		}

		authDispatch({
			type: 'SET_AUTH',
			payload: s_auth,
		})

		Cookie.set(
			"s_auth",
			Buffer.from(JSON.stringify(s_auth)).toString("base64")
		);

		Cookie.set(
			"s_profile",
			Buffer.from(
				JSON.stringify({
					user: {
						email: loginState.auth.email,
						firstname: loginState.auth.firstname,
						lastname: loginState.auth.lastname,
						profile_pic: loginState.auth.profile_pic,
					},
				})
			).toString("base64")
		);

		await Sleep(1500);

		const redirect_uri = query.get("redirect_uri");
		if (redirect_uri) {
			const pathName = decodeURIComponent(redirect_uri);
			if (pathName !== "") {
				router.push(pathName);
			} else {
				router.push("/");
			}
		} else {
			router.push("/");
		}
		return () => { };
	}, []);

	return (
		<>
			<div className='mx-auto text-center mt-5'>
				<Loader color='primary-500' size='maxi'></Loader>
				<p className='text-primary-500 mt-4 mb-5'>Authentication</p>
			</div>
		</>
	)
}
