import { faTimes, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { useEffect, useState } from 'react'
import Button from '@/components/ui/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from '@/components/ui/Alert'
import AppsService from '@/services/apps'
import { useParams } from 'react-router-dom'

export default function CreateNewApp(props) {
    const [error, setError] = useState(null)
    const [type, setType] = useState(null)
    const [loading, setLoading] = useState(false)
    let { app_id } = useParams()

    useEffect(() => {
        setType(props.type)
    }, [])

    function GenerateNewKey() {
        setLoading(true)

        let body = {
            type: type
        }

        AppsService.GenerateNewCred(body, app_id).then(() => {
            setLoading(false)
            props.onClose()
        }).catch((err) => {
            setError(err.message)
            setLoading(false)
        })
    }

    return (
        <div className='p-4 createapp-view'>
            <div className="flex justify-between">
                <div>
                    <h2 className="text-xl"><FontAwesomeIcon className="text-red-500 mt-1 mr-2 transition duration-100 ease-in" icon={faExclamationTriangle}></FontAwesomeIcon> Reset my credentials</h2>
                </div>
                <div>
                    <h2 className="text-xl"><span><FontAwesomeIcon onClick={() => props.onClose()} className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-100 ease-in" icon={faTimes}></FontAwesomeIcon></span></h2>
                </div>
            </div>
            <hr className="my-3" />
            <div>
                {error ? (
                    <Alert type="error" title={error}></Alert>
                ) : null}
            </div>
            <div className="mt-4">
                <div className="mt-3">
                    <p className="text-l mb-3 text-gray-600">This action is going to remove your <strong className="capitalize">{type === 'SANDBOX' ? 'Sandbox' : 'Production'}</strong>  API Key and generate a new one. You will not be able to use your current <strong className="capitalize">{type === 'SANDBOX' ? 'Sandbox' : 'Production'}</strong> API key after. </p>
                </div>
                <div className="mt-5">
                    <Button loading={loading} full theme="error" label={'Generate a new API Key'} onClick={() => GenerateNewKey()}></Button>
                </div>
            </div>
        </div>
    )
}
