import { faBalanceScaleLeft, faBalanceScaleRight, faCheckCircle, faEllipsisHAlt, faTimes, faTimesCircle, faTimesOctagon } from '@fortawesome/pro-solid-svg-icons'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from '@/components/ui/Alert'
import AppsService from '@/services/apps'
import { useParams } from 'react-router-dom'


export default function AmlFlow(props) {
    const [error, setError] = useState(null)
    const [data, setData] = useState(null)
    const [ready, setReady] = useState(false)
    const { app_id } = useParams()

    useEffect(() => {
        GetInfo()
        return () => { }
    }, [])

    function GetInfo() {
        AppsService.GetAmlInfo(app_id).then((data) => {
            setData(data)
            setReady(true)
        }).catch((err) => {
            setError(err.message)
        })
    }

    function openManage() {
        if (!props.manageable) {
            props.onManage()
        }
    }

    function Deactivate() {
        const body = {
            activated: false,
            monitoring: false,
            manager_check: false,
            criterias_list: [],
        }

        AppsService.SubmitAML(app_id, body).then(async () => {
            props.onClose()
        }).catch((err) => {
            setError(err.message)
        })
    }


    return (
        <div className={`${props.manageable ? 'modal-view' : 'w-full sm:w-1/2 lg:w-1/3 mt-4'}`}>
            <div className={`z-max w-full mb-3 h-full flex items-center justify-center ${!props.manageable ? 'px-3' : ''}`}>
                <div onClick={() => openManage()} className={`${!props.manageable ? 'rounded-xl cursor-pointer step-view h-full hover:shadow-lg bg-white border border-gray-200 hover:border-gray-300 transition-25 transition duration-200 ease-in' : ''} flex flex-col w-full h-full`}>
                    <header className="p-5 pb-3">
                        <div className="flex justify-between items-center">
                            <div className="flex items-center">
                                <div>
                                    <img className="h-9 relative mr-2" src="/img/icons/ui/icon-aml-synaps.svg" />
                                </div>
                                <div>
                                    <h2 className="text-lg text-primary-500  font-medium"> AML/CFT Screening
                                    </h2>
                                </div>
                            </div>
                            <div>
                                <div className="flex items-center ">
                                    <div>
                                        {props.manageable ?
                                            <h2 className="text-xl relative ml-3 top-0.5"><span><FontAwesomeIcon onClick={() => props.onClose()} className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-200 ease-in text-regular relative top-[-1px]" icon={faTimes}></FontAwesomeIcon></span></h2>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="my-3" />
                    </header>

                    <main
                        className="flex-1 overflow-y-auto p-5 py-0"
                        id="individual-main">
                        {ready ?
                            <>
                                <div className="flex flex-wrap">
                                    <div className="w-full">
                                        <div>
                                            {error ? (
                                                <Alert type="error" title={error}></Alert>
                                            ) : null}
                                            <div className="flex justify-between">
                                                <div>
                                                    <p className="text-sm text-primary-700">Screening</p>
                                                </div>
                                            </div>
                                            <div>
                                                {data.aml.activated || props.type === 'CORPORATE' ? <div className="flex mt-1">
                                                    <p className="text-green-500"><FontAwesomeIcon className="mr-1" icon={faCheckCircle} /> Active</p>
                                                </div>
                                                    : <div className="flex mt-1">
                                                        <p className="text-red-500"><FontAwesomeIcon className="mr-1" icon={faTimesCircle} /> Inactive</p>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full mt-4">
                                        <div>
                                            <div className="flex justify-between">
                                                <div>
                                                    <p className="text-sm text-primary-700">Final Decision Maker</p>
                                                </div>
                                                {props.manageable ? <div>
                                                    <p onClick={() => props.manageDecision()} className="text-xs py-0.5 cursor-pointer hover:bg-gray-300 ease-in duration-100 transition rounded-md bg-gray-200 px-2 text-primary-700"><FontAwesomeIcon className="mr-0.5" icon={faEllipsisHAlt} /> Manage</p>
                                                </div>
                                                    : null}
                                            </div>
                                            <div>
                                                <div className="flex mt-3">
                                                    {data.aml.manager_check ? <p><FontAwesomeIcon className="mr-1 text-gray-500" icon={faBalanceScaleLeft} /> Your compliance officer</p> : <p><FontAwesomeIcon className="mr-1 text-gray-500" icon={faBalanceScaleRight} /> Synaps Compliance Officer</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full mt-4">
                                        <div>
                                            <div className="flex justify-between">
                                                <div>
                                                    <p className="text-sm text-primary-700">Screening Criterias</p>
                                                </div>
                                                {props.manageable ? <div>
                                                    <p onClick={() => props.manageCriterias()} className="text-xs py-0.5 cursor-pointer hover:bg-gray-300 ease-in duration-100 transition rounded-md bg-gray-200 px-2 text-primary-700"><FontAwesomeIcon className="mr-0.5" icon={faEllipsisHAlt} /> Manage</p>
                                                </div> : null}
                                            </div>
                                            {data.aml.criterias_list ?
                                            <div>
                                                <div className="mt-3">
                                                    <div className="flex">
                                                        <div className="w-1/2">
                                                            <p>{data.aml.criterias_list.includes('PEP_1') ? <FontAwesomeIcon className="mr-1 text-green-500" icon={faCheckCircle} /> : <FontAwesomeIcon className="mr-1 text-gray-500" icon={faTimesCircle} />} PEP Tier 1</p>
                                                        </div>
                                                        <div className="w-1/2">
                                                            <p>{data.aml.criterias_list.includes('PEP_2') ? <FontAwesomeIcon className="mr-1 text-green-500" icon={faCheckCircle} /> : <FontAwesomeIcon className="mr-1 text-gray-500" icon={faTimesCircle} />} PEP Tier 2</p>
                                                        </div>
                                                    </div>
                                                    <div className="flex">
                                                        <div className="w-1/2">
                                                            <p className="mt-2">{data.aml.criterias_list.includes('PEP_3') ? <FontAwesomeIcon className="mr-1 text-green-500" icon={faCheckCircle} /> : <FontAwesomeIcon className="mr-1 text-gray-500" icon={faTimesCircle} />} PEP Tier 3</p>
                                                        </div>
                                                        <div className="w-1/2">
                                                            <p className="mt-2">{data.aml.criterias_list.includes('PEP_4') ? <FontAwesomeIcon className="mr-1 text-green-500" icon={faCheckCircle} /> : <FontAwesomeIcon className="mr-1 text-gray-500" icon={faTimesCircle} />} PEP Tier 4</p>
                                                        </div>
                                                    </div>
                                                    <div className="flex">
                                                        <div className="w-1/2">
                                                            <p className="mt-2">{data.aml.criterias_list.includes('SANCTION') ? <FontAwesomeIcon className="mr-1 text-green-500" icon={faCheckCircle} /> : <FontAwesomeIcon className="mr-1 text-gray-500" icon={faTimesCircle} />} Sanctions Lists</p>
                                                        </div>
                                                        <div className="w-1/2">
                                                            <p className="mt-2">{data.aml.criterias_list.includes('ADVERSE_MEDIA') ? <FontAwesomeIcon className="mr-1 text-green-500" icon={faCheckCircle} /> : <FontAwesomeIcon className="mr-1 text-gray-500" icon={faTimesCircle} />} Adverse Media</p>
                                                        </div>
                                                    </div>
                                                    <div className="flex">
                                                        <div className="w-1/2">
                                                            <p className="mt-2">{data.aml.criterias_list.includes('WARNING') ? <FontAwesomeIcon className="mr-1 text-green-500" icon={faCheckCircle} /> : <FontAwesomeIcon className="mr-1 text-gray-500" icon={faTimesCircle} />} Warning</p>
                                                        </div>
                                                        <div className="w-1/2">
                                                            <p className="mt-2">{data.aml.criterias_list.includes('FITNESS_PROBITY') ? <FontAwesomeIcon className="mr-1 text-green-500" icon={faCheckCircle} /> : <FontAwesomeIcon className="mr-1 text-gray-500" icon={faTimesCircle} />} Fitness Probity</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                        </div>
                                    </div>
                                    <div className="w-full mt-4 pb-3">
                                        <div>
                                            <div className="flex justify-between">
                                                <div>
                                                    <p className="text-sm text-primary-700">Monitoring</p>
                                                </div>
                                                {props.manageable ? <div>
                                                    <p onClick={() => props.manageMonitoring()} className="text-xs py-0.5 cursor-pointer hover:bg-gray-300 ease-in duration-100 transition rounded-md bg-gray-200 px-2 text-primary-700"><FontAwesomeIcon className="mr-0.5" icon={faEllipsisHAlt} /> Manage</p>
                                                </div>
                                                    : null}
                                            </div>
                                            <div>
                                                <div className="flex mt-2">
                                                    {data.aml.monitoring ? <p className="text-green-500"><FontAwesomeIcon className="mr-1" icon={faCheckCircle} /> Active</p> : <p><FontAwesomeIcon className="mr-1 text-gray-500" icon={faTimesCircle} /> Inactive</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </> : null}
                    </main>
                    {props.manageable && props.type !== 'CORPORATE' ? <footer className="p-5 text-center">
                        <p onClick={() => Deactivate()} className="text-gray-400 hover:text-red-500 transition duration-200 ease-in cursor-pointer"><FontAwesomeIcon className="mr-1" icon={faTimesOctagon} /> Deactivate AML/CFT screening</p>
                    </footer>
                        : null}
                </div>
            </div>
        </div>
    )
}
