import { faIdCard, faGavel, faNewspaper, faLandmarkAlt, faUniversity } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import MiniButton from '@/components/ui/MiniButton'
import Moment from 'react-moment'

export default function CorporateAmlDetails({ screeningDetails }) {
    const [currentTab, setCurrentTab] = useState('info')


    function InfoItem(props) {
        return (
            <div className="mt-2">
                <div className="flex border border-transparent py-1 px-1.5 rounded-xl">
                    <div className="mr-3 mt-0.5 flex-shrink-0">
                        <img className="h-9" src={`/img/icons/ui/${props.icon}.svg`}></img>
                    </div>
                    <div className="relative top-[2px]">
                        <p className="text-bluegray-400 text-xs">{props.title}</p>
                        <p className={`text-gray-600 text-sm ${props.capitalize ? 'capitalize' : null}`}>{props.value}</p>
                    </div>
                </div>
            </div>
        )
    }

    function InfoItemSimple(props) {
        return (
            <div className="mt-2">
                <div className="flex border border-transparent py-1 rounded-xl">
                    <div className="relative top-[2px]">
                        <p className="text-bluegray-400 text-xs">{props.title}</p>
                        <p className={`text-gray-600 text-sm ${props.capitalize ? 'capitalize' : null}`}>{props.value}</p>
                    </div>
                </div>
            </div>
        )
    }


    function InfoAka(props) {
        return (
            <div className="mt-2">
                <div className="flex border border-transparent py-1 px-1.5 rounded-xl">
                    <div className="mr-3 mt-0.5 flex-shrink-0">
                        <img className="h-9" src={`/img/icons/ui/${props.icon}.svg`}></img>
                    </div>
                    <div className="relative top-[2px]">
                        <p className="text-bluegray-400 text-xs">{props.title}</p>
                        <p className={`text-gray-600 text-sm ${props.capitalize ? 'capitalize' : null}`}>
                            {props.value.map((name) => {
                                return <>{name}, </>
                            })}
                        </p>
                    </div>
                </div>
            </div>
        )
    }


    function AmlInfo({ screeningDetails }) {
        return (
            <div>
                <div className="flex">
                    <div className="w-1/2">
                        <InfoItem icon={'icon-firstname'} title="Full name" value={screeningDetails.info.full_name} />
                    </div>
                </div>
                <InfoAka icon={'icon-alias'} title="Alias" value={screeningDetails.info.aka} />
            </div>
        )
    }

    function AmlSanctions({ list }) {
        return (
            <div>
                {list.map((sanction, i) => {
                    return (
                        <div key={i} className="mb-6">
                            <h2 className="font-medium">{sanction.name}</h2>
                            <div className="text-sm">
                                {sanction.listing_started ? <span className="mr-2">From <Moment format="YYYY-MM-DD">{sanction.listing_started}</Moment></span> : null}
                                {sanction.listing_ended ? <span>to <Moment format="YYYY-MM-DD">{sanction.listing_ended}</Moment></span> : null}
                            </div>
                            <hr className="my-3 border-bluegray-200" />
                            {sanction.fields.map((field, i) => {
                                return (<div key={i}>
                                    <InfoItemSimple title={field.name} value={field.value} />
                                </div>)
                            })}
                        </div>
                    )
                })}
            </div>
        )
    }

    function AmlAdverseMedia({ media }) {
        return (
            <div>
                {media.map((news, i) => {
                    return (
                        <div key={i} className="mb-6">
                            <h2 className="font-medium">{news.title}</h2>
                            <div className="text-sm">
                                <Moment format="YYYY-MM-DD">{news.date}</Moment>
                            </div>
                            <hr className="my-3 border-bluegray-200" />
                            <p className="text-gray-700">{news.snippet}</p>
                            <p className="mt-3">
                                <a href={news.url} target="_blank" rel="noreferrer">
                                    <MiniButton label="Read more"></MiniButton>
                                </a>
                            </p>
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <div className="flex flex-col w-full h-full rounded">
            <header className="p-4">
                <div className="rounded-xl m-3 mb-0 pt-3 pb-0.5">
                    <div className="flex items-center">
                        <div className="h-full px-3 pb-3">
                            <FontAwesomeIcon className="text-5xl text-bluegray-300" icon={faUniversity} />
                        </div>
                        <div>
                            <h2 className="text-lg text-primary-800 mb-1">{screeningDetails.info.full_name}</h2>
                            <h2 className="text-sm text-gray-500 mb-4">{screeningDetails.info.birth_date}</h2>
                        </div>
                    </div>
                </div>
                <div className="p-1 rounded-xl">
                    <div className="flex p-2 rounded-lg">
                        <div onClick={() => setCurrentTab('info')} className="px-1 py-1">
                            <p className={`text-primary-500 hover:bg-bluegray-200 px-2 py-1 rounded-md transition duration-200 ease-in cursor-pointer ${currentTab === 'info' ? 'bg-bluegray-200' : ''}`}><FontAwesomeIcon className="mr-1" icon={faIdCard} /> Info</p>
                        </div>
                        <div onClick={() => setCurrentTab('sanctions')} className="px-1 py-1">
                            <p className={`text-primary-500 hover:bg-bluegray-200 px-2 py-1 rounded-md transition duration-200 ease-in cursor-pointer ${currentTab === 'sanctions' ? 'bg-bluegray-200' : ''}`}><FontAwesomeIcon className="mr-1" icon={faGavel} /> Sanctions <span className="bg-primary-600 text-white px-2 py-0.5 rounded-md ml-1 text-sm">{screeningDetails.sanctions.length}</span></p>
                        </div>
                        <div onClick={() => setCurrentTab('pep')} className="px-1 py-1">
                            <p className={`text-primary-500 hover:bg-bluegray-200 px-2 py-1 rounded-md transition duration-200 ease-in cursor-pointer ${currentTab === 'pep' ? 'bg-bluegray-200' : ''}`}><FontAwesomeIcon className="mr-1" icon={faLandmarkAlt} /> PEP <span className="bg-primary-600 text-white p-1 px-2 py-0.5 rounded-md ml-1 text-sm">{screeningDetails.pep.length}</span></p>
                        </div>
                        <div onClick={() => setCurrentTab('media')} className="px-1 py-1">
                            <p className={`text-primary-500 hover:bg-bluegray-200 px-2 py-1 rounded-md transition duration-200 ease-in cursor-pointer ${currentTab === 'media' ? 'bg-bluegray-200' : ''}`}><FontAwesomeIcon className="mr-1" icon={faNewspaper} /> Adverse Media <span className="bg-primary-600 text-white p-1 px-2 py-0.5 rounded-md ml-1 text-sm">{screeningDetails.adverse_media.length}</span></p>
                        </div>
                    </div>
                </div>
            </header>

            <main
                className="flex-1 overflow-y-auto px-6 py-0 flow-body"
                id="individual-main">
                <div>
                    {currentTab === 'info' ? <AmlInfo screeningDetails={screeningDetails} /> : null}
                    {currentTab === 'sanctions' ? <AmlSanctions list={screeningDetails.sanctions} /> : null}
                    {currentTab === 'pep' ? <AmlSanctions list={screeningDetails.pep} /> : null}
                    {currentTab === 'media' ? <AmlAdverseMedia media={screeningDetails.adverse_media} /> : null}
                </div>
            </main>
        </div>
    )
}