import { ManagerPublic, ManagerAuth } from '@/services/axios'

export default {
	Init(request, config) {
		return ManagerPublic.post(`${process.env.REACT_APP_MANAGER_HOST}/v3/users/init`, request, config)
	},
	Login(data, config) {
		return ManagerPublic.post(`${process.env.REACT_APP_MANAGER_HOST}/v3/users/login`, data, config)
	},
	Me() {
		return ManagerAuth.post(`${process.env.REACT_APP_MANAGER_HOST}/v3/users/me`)
	},
	Ws() {
		return ManagerPublic.post(`${process.env.REACT_APP_MANAGER_HOST}/v3/users/ws`)
	},
	Logout() {
		return ManagerPublic.post(`${process.env.REACT_APP_MANAGER_HOST}/v3/users/logout`)
	},
}
