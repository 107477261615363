import { useContext } from "react";
import Searchbar from "../ui/SearchBar";
import MiniProfile from "../user/MiniProfile";
import NavMenu from "./NavMenu";
import { AppContext } from "@/context/app";
import { SearchContext } from "@/context/search";
import Search from "@/components/ui/Search";

export default function AppNavbar() {
  const [app] = useContext(AppContext);
  const [, searchDispatch] = useContext(SearchContext);

  function doSearch() {
    searchDispatch({
      type: "SET_OPEN",
      payload: {},
    });
  }

  return (
    <nav className="flex-shrink-0 bg-primary-500">
      <div className="max-w-7xl mx-auto py-3">
        <div className="flex px-3 sm:p-0 justify-between">
          <div className="flex">
            <a href="/">
              <div className="cursor-pointer relative top-1px">
                <img
                  className="w-8 h-8 mt-0.5 mx-3"
                  src="/img/logo/synaps-logo-s.svg"
                ></img>
              </div>
            </a>
            <div className="ml-3">
              <Searchbar
                setValue={() => {}}
                onClick={() => doSearch()}
              ></Searchbar>
            </div>
          </div>
          <div className="flex">
            <div className="z-5">
              <NavMenu></NavMenu>
            </div>
            <div className="z-5 ml-2">
              <MiniProfile app_name={app.name}></MiniProfile>
            </div>
          </div>
        </div>
      </div>
      <Search />
    </nav>
  );
}
