import UboTreeCorporate from './ubo-tree/UboTreeCorporate'
import { UboContextProvider } from '@/context/ubo'

export default function CorporateUbos() {

    function Title({ title }) {
        return (
            <div>
                <div className="flex justify-between items-center">
                    <div>
                        <p className="font-medium text-primary-400">{title ? title : 'Ultimate Beneficial Owners'}</p>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <Title />
            <div className='tree mt-8'>
                <ul>
                    <li>
                        <UboContextProvider>
                            <UboTreeCorporate />
                        </UboContextProvider>
                    </li>
                </ul>
            </div>
        </div>
    )
}