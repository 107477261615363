import { useParams } from 'react-router-dom'
import { faBan, faCheck, faClone } from '@fortawesome/pro-solid-svg-icons'
import { faClock, faHistory, faCloudUploadAlt } from '@fortawesome/pro-duotone-svg-icons'
import { faDotCircle } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import countries from '@/resources/countries_label.json'
import { Modal } from 'react-responsive-modal'
import { useState, useEffect } from 'react'
import LinkMiniButton from '@/components/ui/LinkMiniButton'

export default function IndividualIdentity({ title, step, step_id, member_id }) {
    const { app_id, session_id } = useParams()
    const [open, setOpen] = useState(false)
    const [aliasCopied, setAliasCopied] = useState(false);
    const [sessionCopied, setSessionCopied] = useState(false);

    useEffect(() => {
        setTimeout(() => { setAliasCopied(false) }, 10000);
        return () => { }
    }, [aliasCopied])

    useEffect(() => {
        setTimeout(() => { setSessionCopied(false) }, 10000);
        return () => { }
    }, [sessionCopied])

    function IdentityTitle({ state, title }) {
        return (
            <div>
                <div className="flex justify-between items-center">
                    <div>
                        <p className="font-medium text-primary-400">{title ? title : 'ID Document'}</p>
                    </div>
                    <div>
                        <div className="flex">
                            {step.history.length > 0 ? <p onClick={() => setOpen(true)} className="text-bluegray-500 hover:text-bluegray-700 ease-in duration-200 transition cursor-pointer text-sm bg-bluegray-100 rounded-md px-2 py-1 mr-3"><FontAwesomeIcon className="mr-0.5" icon={faHistory} /> History</p> : null}
                            {state === 'PENDING' ? <p className="text-orange-500 text-sm bg-orange-100 rounded-md px-2 py-1"><FontAwesomeIcon className="mr-0.5" icon={faClock} /> Pending verification</p> : null}
                            {state === 'NOT_STARTED' ? <>
                                {step.history.length === 0 ? <p className="text-gray-500 text-sm bg-gray-100 rounded-md px-2 py-1"><FontAwesomeIcon className="mr-0.5" icon={faDotCircle} /> Not started</p> : <p className="text-orange-500 text-sm bg-orange-100 rounded-md px-2 py-1"><FontAwesomeIcon className="mr-0.5" icon={faCloudUploadAlt} /> Resubmission requested</p>}
                            </> : null}
                            {state === 'REJECTED' ? <p className="text-red-500 text-sm bg-red-100 rounded-md px-2 py-1"><FontAwesomeIcon className="mr-0.5" icon={faBan} /> Rejected</p> : null}
                            {state === 'FINAL_REJECTED' ? <p className="text-red-500 text-sm bg-red-100 rounded-md px-2 py-1"><FontAwesomeIcon className="mr-0.5" icon={faBan} /> Final Rejected</p> : null}
                            {state === 'VALIDATED' ? <p className="text-green-500 text-sm bg-green-100 rounded-md px-2 py-1"><FontAwesomeIcon className="mr-0.5" icon={faCheck} /> Verified</p> : null}
                        </div>
                    </div>
                </div>
                {state !== 'NOT_STARTED' ? <hr className="my-4 border-bluegray-200" /> : null}
            </div>
        )
    }

    function HistoryTitle({ state, title }) {
        return (
            <div>
                <div className="flex justify-between items-center">
                    <div>
                        <p className="font-medium text-primary-400">{title ? title : 'ID Document'}</p>
                    </div>
                    <div>
                        <div className="flex">
                            {state === 'PENDING' ? <p className="text-orange-500 text-sm bg-orange-100 rounded-md px-2 py-1"><FontAwesomeIcon className="mr-0.5" icon={faClock} /> Pending verification</p> : null}
                            {state === 'REJECTED' ? <p className="text-orange-500 text-sm bg-orange-100 rounded-md px-2 py-1"><FontAwesomeIcon className="mr-0.5" icon={faCloudUploadAlt} /> Resubmission requested</p> : null}
                            {state === 'FINAL_REJECTED' ? <p className="text-red-500 text-sm bg-red-100 rounded-md px-2 py-1"><FontAwesomeIcon className="mr-0.5" icon={faBan} /> Final Rejected</p> : null}
                            {state === 'VALIDATED' ? <p className="text-green-500 text-sm bg-green-100 rounded-md px-2 py-1"><FontAwesomeIcon className="mr-0.5" icon={faCheck} /> Verified</p> : null}
                        </div>
                    </div>
                </div>
                {state !== 'NOT_STARTED' ? <hr className="my-4 border-bluegray-200" /> : null}
            </div>
        )
    }

    function InfoItem(props) {
        return (
            <div className="mt-2">
                <div className="flex border border-transparent py-1 px-1.5 rounded-xl">
                    <div className="mr-3 mt-0.5 flex-shrink-0">
                        <img className="h-9" src={`/img/icons/ui/${props.icon}.svg`}></img>
                    </div>
                    <div className="relative top-[2px]">
                        <p className="text-bluegray-400 text-xs">{props.title}</p>
                        <p className={`text-gray-600 text-sm ${props.capitalize ? 'capitalize' : null}`}>{props.value}</p>
                    </div>
                </div>
            </div>
        )
    }


    function CountryItem(props) {
        return (<div className="mt-2">
            <div className="flex border border-transparent py-1 px-1.5 rounded-xl">
                <div className="mr-3 flex-shrink-0">
                    <div className="h-9 w-9" style={{
                        backgroundImage: `url( ${`/img/icons/flags-rounded/${props.country}.svg`}), url(${'/img/icons/ui/icon-round.svg'})`,
                        backgroundSize: '60%, contain',
                        backgroundRepeat: 'no-repeat, no-repeat',
                        backgroundPosition: 'center center, center center',
                    }}></div>
                </div>
                <div className="relative top-[2px]">
                    <p className="text-bluegray-400 text-xs">{props.title}</p>
                    <p className="text-gray-600 text-sm">{props.value}</p>
                </div>
            </div>
        </div>)
    }



    function InfoFeature(props) {
        return (
            <div className="mt-2">
                <div className="flex border border-transparent py-1 px-1.5 rounded-xl">
                    <div className="mr-3 mt-0.5 flex-shrink-0">
                        <img className="h-9" src={`/img/icons/ui/${props.icon}.svg`}></img>
                    </div>
                    <div className="relative top-[2px]">
                        <p className="text-bluegray-400 text-xs capitalize">{props.title}</p>
                        {props.included ? <p className="text-green-500 text-sm">{props.label}</p> : <p className="text-red-500 text-sm">Not {props.label}</p>}
                    </div>
                </div>
            </div>
        )
    }



    function IdentityDocument({ step_id, document, info }) {
        return (
            <div>
                <div className="mt-3">
                    <p className="mt-6 uppercase tracking-wide font-medium text-sm text-primary-400">Document Details</p>
                    <div className="flex flex-wrap mt-3">
                        <div className="w-1/5 pr-3">
                            <div className="bg-bluegray-100 w-full h-[200px] px-2 rounded-md mt-3" style={{
                                backgroundImage: `url( ${`${document.document.face_file}`}`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center center',
                            }}>
                            </div>
                        </div>
                        <div className="w-4/5 pl-3 flex flex-wrap mt-4">
                            <div className="w-1/3">
                                <InfoItem capitalize icon={'icon-firstname'} title="Firstname" value={info.firstname.value ? info.firstname.value.toLowerCase() : '-'} />
                            </div>
                            <div className="w-1/3">
                                <InfoItem capitalize icon={'icon-lastname'} title="Lastname" value={info.lastname.value ? info.lastname.value.toLowerCase() : '-'} />
                            </div>
                            <div className="w-1/3">
                                <InfoItem icon={'icon-birthdate'} title="Birth Date" value={info.birth_date.value ? info.birth_date.value : '-'} />
                            </div>
                            <div className="w-1/3">
                                <CountryItem title="Nationality" value={info.nationality.value ? countries[info.nationality.value] : '-'} country={info.nationality.value} />
                            </div>
                            <div className="w-1/3">
                                <CountryItem title="Issuing Country" value={countries[document.document.country]} country={document.document.country} />
                            </div>
                            <div className="w-1/3">
                                <InfoItem title="Document Number" icon={'icon-document-number'} value={info.document_number.value ? info.document_number.value : '-'} />
                            </div>
                            <div className="w-1/3">
                                <InfoItem title="Document Expiration" value={info.document_expiration.value ? info.document_expiration.value : '-'} icon={'icon-recent'} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5">
                    <div className="flex flex-wrap">
                        <div className={`${document.document.type === 'PASSPORT' ? 'w-full' : 'w-1/2 pr-3'}`}>
                            <div className="flex flex-wrap items-center justify-between">
                                <p className="uppercase tracking-wide font-medium text-sm text-primary-400">{document.document.type === 'PASSPORT' ? 'Passport page' : 'Front Document'}</p>
                                <LinkMiniButton target="_blank" href={`${document.document.front_file}`} rel="noreferrer">Download</LinkMiniButton>
                            </div>

                            <div className="bg-bluegray-100 w-full h-96 px-2 rounded-md mt-3">
                                <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" className="w-full h-96" style={{
                                    backgroundImage: `url( ${document.document.front_file}`,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center center',
                                }} />
                            </div>
                        </div>
                        {document.document.type !== 'PASSPORT' ? <div className="w-1/2 pl-3">
                            <div className="flex flex-wrap items-center justify-between">
                                <p className="uppercase tracking-wide font-medium text-sm text-primary-400">Back Document</p>
                                <LinkMiniButton target="_blank" href={`${document.document.back_file}`} rel="noreferrer">Download</LinkMiniButton>
                            </div>
                            <div className="bg-bluegray-100 w-full h-96 px-2 rounded-md mt-3">
                                <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" className="w-full h-96" style={{
                                    backgroundImage: `url( ${document.document.back_file}`,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center center',
                                }} />
                            </div>
                        </div>
                            : null}
                    </div>
                </div>
            </div>
        )
    }


    function IdentityVisualChecks({ visual }) {
        return (
            <div>
                <div className="mt-3">
                    <p className="mt-6 uppercase tracking-wide font-medium text-sm text-primary-400">Visual Checks</p>
                    <div className="flex flex-wrap">
                        <div className="w-1/3 mt-2">
                            <InfoFeature title="Colored picture" icon={`${visual.color_picture ? 'icon-valid' : 'icon-rejected'}`} included={visual.color_picture} label="Verified" />
                        </div>
                        <div className="w-1/3 mt-2">
                            <InfoFeature title="4 corners visible" icon={`${visual.full_document_visible ? 'icon-valid' : 'icon-rejected'}`} included={visual.full_document_visible} label="Verified" />
                        </div>
                        <div className="w-1/3 mt-2">
                            <InfoFeature title="Good environment capture" icon={`${visual.good_environment ? 'icon-valid' : 'icon-rejected'}`} included={visual.good_environment} label="Verified" />
                        </div>
                        <div className="w-1/3 mt-2">
                            <InfoFeature title="Good quality document" icon={`${visual.good_quality ? 'icon-valid' : 'icon-rejected'}`} included={visual.good_quality} label="Verified" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    function IdentityFeaturesChecks({ title, features }) {
        return (
            <div>
                <div className="mt-3">
                    <p className="mt-6 uppercase tracking-wide font-medium text-sm text-primary-400">{title}</p>
                    <div className="flex flex-wrap">
                        {features.map((feature) => {
                            return (
                                <>
                                    <div className="w-1/3 mt-2">
                                        <InfoFeature title={feature.name} icon={`${feature.included ? 'icon-valid' : 'icon-rejected'}`} included={feature.included} label="Included" />
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }


    function IdentityMrzChecks({ title, mrz, type }) {
        return (
            <div>
                <div className="mt-3">
                    <p className="mt-6 uppercase tracking-wide font-medium text-sm text-primary-400">{title}</p>
                    <div className="flex flex-wrap">
                        <div className="w-1/3 mt-2">
                            <InfoFeature icon={`${type === 'mrz' ? 'icon-mrz' : 'icon-barcode'}`} title={'Feature'} included={mrz.valid} label="Included" />
                        </div>
                        <div className="w-1/3 mt-2">
                            <InfoFeature icon={`${mrz.valid ? 'icon-valid' : 'icon-rejected'}`} title={'Checksum'} included={mrz.valid} label="Valid" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    function IdentityDetails({ step, step_id, member_id }) {
        return (
            <div>
                <IdentityDocument member_id={member_id} step_id={step_id} document={step.document} info={step.document.details.info} />
                <IdentityVisualChecks document={step.document} visual={step.document.visual_checks} />
                <IdentityFeaturesChecks title={'Fields Checks'} document={step.document} features={step.document.details.features.fields} />
                <IdentityFeaturesChecks title={'Security Features Checks'} document={step.document} features={step.document.details.features.security} />
                {step.document.details.mrz.lines !== undefined && step.document.details.mrz.lines.required ? <IdentityMrzChecks type='mrz' title={'Machine Readable Zone (MRZ)'} mrz={step.document.details.mrz.lines} /> : null}
                {step.document.details.mrz.barcode !== undefined && step.document.details.mrz.barcode.required ? <IdentityMrzChecks type='barcode' title={'Barcode'} mrz={step.document.details.mrz.barcode} /> : null}
            </div>
        )
    }

    function IdentityBaseDetails({ step, step_id, member_id }) {
        return (
            <div>
                {step.state === 'REJECTED' ? <div className="flex flex-wrap">
                    <div className="w-1/2">
                        <InfoItem icon={'icon-firstname'} title="Customer Reason" value={step.document.rejection.customer_reason} />
                    </div>
                    <div className="w-1/2">
                        <InfoItem icon={'icon-lastname'} title="User Reason" value={step.document.rejection.user_reason} />
                    </div>
                </div> : null}
                <div className="mt-5">
                    <div className="flex flex-wrap">
                        <div className={`${step.document.document.type === 'PASSPORT' ? 'w-full' : 'w-1/2 pr-3'}`}>
                            <p className="uppercase tracking-wide font-medium text-sm text-primary-400">{step.document.document.type === 'PASSPORT' ? 'Passport page' : 'Front Document'}</p>
                            <div className="bg-bluegray-100 w-full h-96 px-2 rounded-md mt-3">
                                <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" className="w-full h-96" style={{
                                    backgroundImage: `url( ${step.document.document.front_file}`,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center center',
                                }} />
                            </div>
                        </div>
                        {step.document.document.type !== 'PASSPORT' ? <div className="w-1/2 pl-3">
                            <p className="uppercase tracking-wide font-medium text-sm text-primary-400">Back Document</p>
                            <div className="bg-bluegray-100 w-full h-96 px-2 rounded-md mt-3">
                                <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" className="w-full h-96" style={{
                                    backgroundImage: `url( ${step.document.document.back_file}`,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center center',
                                }} />
                            </div>
                        </div>
                            : null}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <IdentityTitle title={title} state={step.state} />
            {step.state === 'VALIDATED' ? <IdentityDetails member_id={member_id} step_id={step_id} step={step} /> : null}
            {step.state === 'PENDING' || step.state === 'REJECTED' || (step.state === 'NOT_STARTED' && step.history.length > 0) ? <IdentityBaseDetails member_id={member_id} step_id={step_id} step={step} /> : null}
            <Modal open={open}
                showCloseIcon={true}
                closeIcon={true}
                onClose={() => setOpen(false)}
                center>
                <div className="core-view-history p-6">
                    {step.history.map((history) => {
                        console.log("step")
                        console.log(step)
                        return <div className="mt-4" key={0}>
                            <HistoryTitle state={history.state} />
                            {history.state !== 'VALIDATED' ? <>
                                <div>
                                    <InfoItem icon={'icon-firstname'} title="Customer Reason" value={history.customer_reason} />
                                </div>
                                <div>
                                    <InfoItem icon={'icon-lastname'} title="User Reason" value={history.user_reason} />
                                </div>
                            </> : null}
                            <div>
                                <InfoItem capitalize icon={'icon-timeout'} title="Verified At" value={history.verified_at} />
                            </div>
                            <div>
                                <InfoItem capitalize icon={'icon-upload'} title="Submitted At" value={history.submitted_at} />
                            </div>
                            {history.customer_reason === "User already have submitted his document for this app" && step.duplicate.session_id !== "" ?
                                <>
                                    <div className='flex'>
                                        <InfoItem icon={'icon-sessionid'} title="Duplicate session" value={step.duplicate.session_id} />
                                        <FontAwesomeIcon className="text-regular mx-2 mt-6 font-bold cursor-pointer text-md text-blue-500" onClick={() => navigator.clipboard.writeText(step.duplicate.session_id) & setSessionCopied(true)} icon={faClone} />
                                        {sessionCopied ? <p className='text-sm mt-6 text-green-500'>
                                            Text copied!
                                        </p> : null}
                                    </div>
                                    <div className='flex'>
                                        <InfoItem icon={'icon-alias'} title="Duplicate alias" value={step.duplicate.alias} />
                                        <FontAwesomeIcon className="text-regular mx-2 mt-6 font-bold cursor-pointer text-md text-blue-500" onClick={() => navigator.clipboard.writeText(step.duplicate.alias) & setAliasCopied(true)} icon={faClone} />
                                        {aliasCopied ? <p className='text-sm mt-6 text-green-500'>
                                            Text copied!
                                        </p> : null}
                                    </div>

                                </>
                                : null}
                            <hr className="mt-4" />
                        </div>
                    })}
                </div>
            </Modal>
        </div>
    )
}