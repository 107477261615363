import { faCheckCircle, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from '@/components/ui/Alert'
import AppsService from '@/services/apps'
import { useParams } from 'react-router-dom'
import Button from '@/components/ui/Button'


export default function AmlFlowDecisionMaker(props) {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)
    const [ready, setReady] = useState(false)
    const [synapsDecision, setSynapsDecision] = useState(false)
    const [ownDecision, setOwnDecision] = useState(false)
    const [decision, setDecision] = useState('synaps')

    const { app_id } = useParams()

    useEffect(() => {
        GetInfo()
        return () => { }
    }, [])

    useEffect(() => {
        if (ownDecision) {
            setSynapsDecision(false)
        }
    }, [ownDecision])

    useEffect(() => {
        if (synapsDecision) {
            setOwnDecision(false)
        }
    }, [synapsDecision])

    function GetInfo() {
        AppsService.GetAmlInfo(app_id).then((data) => {
            setData(data)

            if (data.aml.manager_check) {
                setDecision('own')
            }
            setReady(true)
        }).catch((err) => {
            setError(err.message)
        })
    }

    function Submit() {
        let manager_check = false
        if (decision === 'own') {
            manager_check = true
        }

        const body = {
            activated: data.aml.activated,
            monitoring: data.aml.manager_check,
            manager_check: manager_check,
            criterias_list: data.aml.criterias_list,
        }

        AppsService.SubmitAML(app_id, body).then(async () => {
            setLoading(false)
            props.onBack()
        }).catch((err) => {
            setError(err.message)
            setLoading(false)
        })
    }

    return (
        <div className="modal-view">
            <div className="z-max w-full h-full flex items-center justify-center">
                <div className="flex flex-col w-full h-full rounded">
                    <header className="p-5 pb-3">
                        <div className="flex justify-between">
                            <div className="flex">
                                <div>
                                    <img className="h-6 relative top-0.5 mr-2" src="/img/icons/ui/icon-aml-synaps.svg" />
                                </div>
                                <div>
                                    <h2 className="text-xl text-primary-500  font-medium"> AML/CFT Screening
                                    </h2>
                                </div>
                            </div>
                            <div>
                                <h2 className="text-xl relative top-0.5"><span><FontAwesomeIcon onClick={() => props.onClose()} className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-200 ease-in" icon={faTimes}></FontAwesomeIcon></span></h2>
                            </div>
                        </div>
                        <hr className="my-3" />
                    </header>

                    <main
                        className="flex-1 overflow-y-auto p-5 py-0 flow-body"
                        id="individual-main">
                        {ready ?
                            <>
                                <div className="flex flex-wrap">
                                    <div className="w-full">
                                        {error ? (
                                            <Alert type="error" title={error}></Alert>
                                        ) : null}
                                        <div>
                                            <div className="flex justify-between">
                                                <div>
                                                    <p className="text-sm font-bold text-primary-700">Final Decision Maker</p>
                                                    <p className="text-sm text-gray-600">Choose who from your Compliance Officer or Synaps Compliance Officer will make the final decision in case of an AML match</p>
                                                </div>
                                            </div>
                                            <div className="w-full mt-2">
                                                <div className="flex flex-wrap mt-3">
                                                    <div className="w-full">
                                                        <div onClick={() => setDecision('own')} className={`flex flex-wrap items-center justify-between border border-gray-200  p-2 rounded-xl ${decision !== 'own' ? 'hover:border-gray-400 transition duration-200 ease-in cursor-pointer' : null}`}>
                                                            <div className={'flex flex-1'}>
                                                                <div>
                                                                    <img src="/img/icons/ui/icon-aml-synaps.svg"></img>
                                                                </div>
                                                                <div className="ml-3">
                                                                    <p className="font-medium mt-2 text-primary-700">Your compliance officer</p>
                                                                </div>
                                                            </div>
                                                            <div className="mr-2">
                                                                {decision === 'own' ? <FontAwesomeIcon className="text-green-500 text-xl relative" icon={faCheckCircle} /> : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-full mt-4">
                                                        <div onClick={() => setDecision('synaps')} className={`flex flex-wrap items-center justify-between border border-gray-200  p-2 rounded-xl ${decision !== 'synaps' ? 'hover:border-gray-400 transition duration-200 ease-in cursor-pointer' : null}`}>
                                                            <div className={'flex flex-1'}>
                                                                <div>
                                                                    <img src="/img/icons/ui/icon-aml-synaps.svg"></img>
                                                                </div>
                                                                <div className="ml-3">
                                                                    <p className="font-medium mt-2 text-primary-700">Synaps Compliance Officer</p>
                                                                </div>
                                                            </div>
                                                            <div className="mr-2">
                                                                {decision === 'synaps' ? <FontAwesomeIcon className="text-green-500 text-xl relative" icon={faCheckCircle} /> : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </> : null}
                    </main>
                    <footer className="p-5 pt-2">
                        <div className={'flex flex-wrap'}>
                            <div className="w-1/3 pr-1.5">
                                <Button
                                    disabled={loading}
                                    full
                                    theme="secondary"
                                    label={'Back'}
                                    onClick={() => props.onBack()}></Button>
                            </div>
                            <div className="w-2/3 pl-1.5">
                                <Button
                                    full
                                    loading={loading}
                                    theme="success"
                                    label={'Update'}
                                    onClick={() => Submit()}></Button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    )
}
