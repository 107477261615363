import { useContext } from 'react'
import IndividualIdentity from '@/components/sessions/individual/IndividualIdentity'
import IndividualResidency from '@/components/sessions/individual/IndividualResidency'
import IndividualPhone from '@/components/sessions/individual/IndividualPhone'
import IndividualLiveness from '@/components/sessions/individual/IndividualLiveness'
import IndividualAml from '@/components/sessions/individual/IndividualAml'
import { IndividualSessionContext } from '@/context/individual_session'

export default function IndividualCore(props) {
    const [individual] = useContext(IndividualSessionContext)


    return (
        <>
            {individual.ordered_steps.map((step_id) => {
                return (
                    <div id={step_id} key={step_id} className="bg-white rounded-xl shadow-sm p-6 mb-6">
                        {individual.steps[step_id].type === 'IDENTITY' ? <IndividualIdentity member_id={0} step_id={step_id} step={individual.steps[step_id].verification} ip={individual.steps[step_id].ip} /> : null}
                        {individual.steps[step_id].type === 'RESIDENCY' ? <IndividualResidency member_id={0} step_id={step_id} step={individual.steps[step_id].verification} ip={individual.steps[step_id].ip} /> : null}
                        {individual.steps[step_id].type === 'PHONE' ? <IndividualPhone member_id={0} step_id={step_id} step={individual.steps[step_id].verification} ip={individual.steps[step_id].ip} /> : null}
                        {individual.steps[step_id].type === 'LIVENESS' ? <IndividualLiveness member_id={0} step_id={step_id} step={individual.steps[step_id].verification} ip={individual.steps[step_id].ip} /> : null}
                    </div>
                )
            })}
            {individual.aml.active ?
                <div id={'aml'} className="bg-white rounded-xl shadow-sm p-6 mb-6">
                    <IndividualAml onFinish={() => props.onRefresh()} data={individual.aml} />
                </div> : null}
        </>
    )
}