import AuthGuard from '@/guard/auth'
import AppNavbar from '@/components/menu/AppNavbar'
import AppDashboard from '@/components/pages/app/AppDashboard'
import AppGuard from '@/guard/app'

function Dasbhoard() {
    return (
        <AuthGuard>
            <AppGuard>
                <div className='relative min-h-screen flex flex-col'>
                    <AppNavbar></AppNavbar>
                    <AppDashboard></AppDashboard>
                </div>
            </AppGuard>
        </AuthGuard>
    )
}

export default Dasbhoard
