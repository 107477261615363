import { useState } from "react";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import DasbhoardApps from "../dashboard/DasbhoardApps";
import Button from "../ui/Button";
import Footer from "../menu/Footer";
import SubNav from "../menu/SubNav";
import CreateNewApp from "./apps/CreateNewApp";
import { Modal } from "react-responsive-modal";

export default function MyAppPage(props) {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div>
        <SubNav></SubNav>
        <div className="max-w-7xl mx-auto relative">
          <div className="dashboard-container px-3">
            <div className="flex justify-between mt-3">
              <div>
                <h1 className="text-3xl text-white">
                  <span className="font-bold">My KYB apps</span>
                </h1>
                <h3 className="text-lg mt-2 text-bluegray-300">
                  Create new apps and manage existing ones
                </h3>
              </div>
              <div className="mt-2.5">
                <Button
                  onClick={() => setOpen(true)}
                  currentBg="primary"
                  theme="success"
                  icon={faPlus}
                  label="Create new app"
                ></Button>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-7xl mx-auto relative">
          <div className="flex flex-wrap items-start mt-6">
            <DasbhoardApps
              createApp={() => setOpen(true)}
              search={props.search}
            ></DasbhoardApps>
          </div>
          <Modal
            open={open}
            showCloseIcon={true}
            closeIcon={true}
            onClose={() => setOpen(false)}
            center
          >
            <CreateNewApp name={props.search} onClose={() => setOpen(false)} />
          </Modal>

          <Footer></Footer>
        </div>
      </div>
    </div>
  );
}
